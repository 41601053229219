import type { Store } from 'vuex';
import { clientInitStoreModule, useStoreModule } from '../useStoreModule';
import mediaqueryStore from './mediaquery.store';
import mediaqueryApi from './mediaquery.api';
import { MediaqueryState, RootState } from '~/@api/store.types';
import { MEDIAQUERY } from '~/@constants/store';

export function useMediaqueryStore(store: Store<RootState>) {
	return useStoreModule<MediaqueryState, ReturnType<typeof mediaqueryApi>>(
		MEDIAQUERY,
		store,
		mediaqueryStore,
		mediaqueryApi,
	);
}

export async function clientInitMediaqueryStore(store: Store<RootState>): Promise<void> {
	return await clientInitStoreModule(MEDIAQUERY, store, mediaqueryStore, mediaqueryApi);
}
