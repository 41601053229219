

export default () => {
    if (process.server) {
        return;
    }

            const polyfill = require("@/polyfills/form-attribute-polyfill.ts");

            console.log('Load ' + "@/polyfills/form-attribute-polyfill.ts");

            const install = (formAttributePolyfill) => formAttributePolyfill.default();

            install(polyfill);
}