import { Context } from '@nuxt/types';
import Logger from '@/node_modules/@osp/utils/src/logger';
import { runTask } from '@/node_modules/@osp/design-system/assets/js/utilities/runTask';
import { useMediaqueryStore } from '~/@api/store/mediaqueryApi';

// BridgeNavigator interface as navigator.platform is marked as deprecated but navigator.userAgentData.platform not yet
// supported by some browsers
interface BridgeNavigator extends Navigator {
	platform: string;
	userAgentData?: {
		platform?: string;
	};
}

// Notification for 1st party browser storage fail
function handleStorageFallbackError(err, storageType) {
	if (err?.name === 'SecurityError' && err?.message === 'The operation is insecure.') {
		Logger.info(
			`%cUnable to access ${storageType} and failed to use cookie storage fallback. ` +
				'Please check your browser settings. ' +
				'Blocking 1st party cookies might cause limitations and errors on this website.',
			'background-color: #FF5733; color: white',
		);
	} else {
		Logger.error(err?.message ?? 'Error occured!');
	}
}

// eslint-disable-next-line require-await
async function PluginIntegration(context: Context) {
	const keyDelimiter = '/';

	// For 1st party code, create enhanced versions of localStorage and sessionStorage functions, which
	// provide a fallback via cookie storage when sessionStorage or localStorage are not available (eg in iOS Safari
	// private mode)
	// By that, 1st party code gets a chance to still work with disabled localStorage / sessionStorage
	['localStorage', 'sessionStorage'].forEach((storageType) => {
		if (!(window as any).osp) {
			(window as any).osp = {};
		}

		if (!(window as any).osp[storageType]) {
			(window as any).osp[storageType] = {};
		}

		attachOspStorageCheck(storageType);
		attachOspSetItem(storageType, context, keyDelimiter);
		attachOspGetItem(storageType, context, keyDelimiter);
		attachOspRemoveItem(storageType, context, keyDelimiter);
		attachOspClear(storageType, context, keyDelimiter);
	});

	// If user is operating in conditions that avoid usage of localStorage / sessionStorage, replace the original
	// functions with notifications when SecurityError appears and avoid error in console that would be caused by
	// 3rd party code, imported by external hosted javascript files
	conditionallyReplaceNativeBrowserStorageFunctions(context);
}

function attachOspStorageCheck(storageType: string) {
	const storageObject = (window as any).osp[storageType];

	storageObject._enabled = undefined;

	storageObject.isEnabled = () => {
		if (typeof storageObject._enabled === 'undefined') {
			try {
				const key = `__storage__test`;
				window[storageType].setItem(key, null);
				window[storageType].removeItem(key);
				storageObject._enabled = true;
			} catch (e) {
				storageObject._enabled = false;
			}
		}

		return storageObject._enabled;
	};
}

function attachOspSetItem(storageType: string, context: Context, keyDelimiter: string): void {
	const storageObject = (window as any).osp[storageType];

	storageObject.setItem = (key: string, value: string): void => {
		if (storageObject.isEnabled()) {
			// Use original function, if storage type is enabled
			window[storageType].setItem(key, value);
			return;
		}

		// ... otherwise try to use fallback with cookies
		try {
			// Keep value in cookies only for today
			const expires = new Date();
			context.$cookies.set(`${storageType}${keyDelimiter}${key}`, value, { expires });
		} catch (err) {
			handleStorageFallbackError(err, storageType);
		}
	};
}

function attachOspGetItem(
	storageType: string,
	context: Context,
	keyDelimiter: string,
): string | void {
	const storageObject = (window as any).osp[storageType];

	storageObject.getItem = (key: string): string | null => {
		if (storageObject.isEnabled()) {
			// Use original function, if storage type is enabled
			return window[storageType].getItem(key);
		}

		// ... otherwise try to use fallback with cookies
		try {
			return context.app.$cookies.get(`${storageType}${keyDelimiter}${key}`) ?? null;
		} catch (err) {
			handleStorageFallbackError(err, storageType);
			return null;
		}
	};
}

function attachOspRemoveItem(storageType: string, context: Context, keyDelimiter: string): void {
	const storageObject = (window as any).osp[storageType];

	storageObject.removeItem = (key: string): void => {
		if (storageObject.isEnabled()) {
			// Use original function, if storage type is enabled
			window[storageType].removeItem(key);
			return;
		}

		// ... otherwise try to use fallback with cookies
		try {
			context.app.$cookies.remove(`${storageType}${keyDelimiter}${key}`);
		} catch (err) {
			handleStorageFallbackError(err, storageType);
		}
	};
}

function attachOspClear(storageType: string, context: Context, keyDelimiter: string): void {
	const storageObject = (window as any).osp[storageType];

	storageObject.clear = (): void => {
		if (storageObject.isEnabled()) {
			// Use original function, if storage type is enabled
			window[storageType].clear();
			return;
		}

		// ... otherwise try to use fallback with cookies
		try {
			const browserStorageKeys = Object.keys(context.app.$cookies.getAll()).filter(
				(key) => key.indexOf(`${storageType}${keyDelimiter}`) === 0,
			);
			browserStorageKeys.forEach((browserStorageKey) =>
				context.app.$cookies.remove(browserStorageKey),
			);
		} catch (err) {
			handleStorageFallbackError(err, storageType);
		}
	};
}

function conditionallyReplaceNativeBrowserStorageFunctions(context: Context): void {
	// Prevent console errors caused by 3rd party scripts using localStorage or sessionStorage on iOS mobile in private
	// mode. To prevent SecurityErrors caused by external file loaded 3rd party code, we'll first delete localStorage
	// & sessionStorage and then recreated functions with original namings, with console notification output about
	// checking browser settings due to limitation and error potential.
	// Currently, the check is very strict for:
	//    - Safari Browser,
	//    - IOS mobile
	//    - not accessible localStorage & sessionStorage
	//    - mobile viewport
	// ... but strictness can be reduced in future in case SecurityIssue is reported also for desktop devices (which is
	// theoretically possible)

	const isSafari = context.$ua.browser() === 'Safari';
	const platform =
		(navigator as BridgeNavigator)?.userAgentData?.platform ||
		(navigator as BridgeNavigator)?.platform ||
		'unknown';
	const isIOSMobile =
		['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
			platform,
		) ||
		// iPad on iOS 13 detection
		(navigator.userAgent.includes('Mac') && 'ontouchend' in document);

	if (isIOSMobile && isSafari) {
		const scriptBody = ((window as any).osp = (window as any).osp || {});

		if (!scriptBody.localStorage.isEnabled() && !scriptBody.sessionStorage.isEnabled()) {
			if (useMediaqueryStore(context.store).api.isMobile()) {
				adjustBrowserStorageFor3rdPartyUsages();
			}
		}
	}
}

// Notification for 3rd party browser storage fail
function logOperationInsecureNotice(): void {
	Logger.info(
		`%cUnable to access localStorage/sessionStorage. ` +
			'Please check your browser settings. ' +
			'Blocking browser storage might cause limitations and errors on this website.',
		'background-color: #FF5733; color: white',
	);
}

// Handle 3rd party security errors
function handle3rdPartyFallbackError(err): void {
	if (err?.message === 'The operation is insecure.') {
		logOperationInsecureNotice();
	} else {
		Logger.error(err?.message ?? 'Error occured!');
	}
}

function adjustBrowserStorageFor3rdPartyUsages() {
	window.onerror = function (message, _file, _line, _col, error) {
		handle3rdPartyFallbackError({ name: error, message });
	};

	window.addEventListener('error', function (e) {
		handle3rdPartyFallbackError(e);
	});

	try {
		// Overwrite / wrap native console.error as 3rd parties like scarab use this to report security error
		((win) => {
			const nativeConsoleError = win.console.error;

			const modifiedErrorHandling = (err) => {
				if (err?.m?.includes('operation is insecure')) {
					handle3rdPartyFallbackError({ message: err.m });
				} else {
					nativeConsoleError(err);
				}
			};

			// Assign the newly created function to console
			Object.defineProperty(win.console, 'error', {
				value: modifiedErrorHandling,
				writable: true,
			});
		})(window);

		// localStorage and sessionStorage are not available (we checked that before), so we delete and replace
		// this window functions to cover usage of localStorage and sessionStorage by 3rd party scripts
		['localStorage', 'sessionStorage'].forEach((storageType) => {
			((win) => {
				// Has to be deleted first, as any other access, check or modification would directly pop up the SecurityError
				delete win[storageType];

				class DeactivatedBrowserStorage {
					setItem(_key: string, _value: string): void {
						logOperationInsecureNotice();
					}

					getItem(_key: string): null {
						logOperationInsecureNotice();
						return null;
					}

					removeItem(_key: string): void {
						logOperationInsecureNotice();
					}

					clear(): void {
						logOperationInsecureNotice();
					}
				}

				const browserStorage = new DeactivatedBrowserStorage();
				// Assign the newly created instance to localStorage
				Object.defineProperty(win, storageType, {
					value: browserStorage,
					writable: true,
				});
			})(window);
		});
	} catch (err) {
		handle3rdPartyFallbackError(err);
	}
}

// Enhanced function of sessionStorage and localStorage with cookie fallback, so do only execute plugin integration
// async, but do not defer execution
export default function (context: Context) {
	runTask(() => {
		PluginIntegration(context);
	});
}
