import { Optional } from './core';

export enum TARGET {
	BLANK = '_blank',
	SELF = '_self',
	PARENT = '_parent',
	TOP = '_top',
}

export enum REL {
	ALTERNATE = 'alternate',
	AUTHOR = 'author',
	BOOKMARK = 'bookmark',
	EXTERNAL = 'external',
	HELP = 'help',
	LICENSE = 'license',
	NEXT = 'next',
	NOFOLLOW = 'nofollow',
	NOOPENER = 'noopener',
	NOREFERRER = 'noreferrer',
	PREV = 'prev',
	SEARCH = 'search',
	TAG = 'tag',
}

export interface Link {
	text: string;
	href: string;
	target?: TARGET;
	rel?: REL;
	uid?: string;
}

export interface LinkWithIcon extends Link {
	icon: string;
}

export type LinkWithOptionalHref = Optional<Link, 'href'>;
