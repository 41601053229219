import { NuxtRuntimeConfig } from '@nuxt/types/config/runtime';
import { OspNuxtRuntimeConfig, OspNuxtRuntimeConfigEntries } from '~/@types/OspNuxtRuntimeConfig';

export interface ApiRuntimeConfigEntry {
	key: string;
	value: string | number | boolean;
}

export enum RuntimeConfigEnvs {
	TRACKING_PERFORMANCE = 'TRACKING_PERFORMANCE',
	TRACKING_PERFORMANCE_DEBUG = 'TRACKING_PERFORMANCE_DEBUG',
	TRACKING_CLS = 'TRACKING_CLS',
	TRACKING_CLS_THRESHOLD_TOTAL = 'TRACKING_CLS_THRESHOLD_TOTAL',
	TRACKING_CLS_THRESHOLD_PAGE = 'TRACKING_CLS_THRESHOLD_PAGE',
	TRACKING_CLS_THRESHOLD_ITEM = 'TRACKING_CLS_THRESHOLD_ITEM',
	TRACKING_API_REQUESTS = 'TRACKING_API_REQUESTS',
	TRACKING_LONG_TASKS = 'TRACKING_LONG_TASKS',
	TRACKING_TTI = 'TRACKING_TTI',
	TRACKING_SPA_RUM_THRESHOLD_REPORTING = 'TRACKING_SPA_RUM_THRESHOLD_REPORTING',
	TRACKING_SPA_RUM_LCP_THRESHOLD = 'TRACKING_SPA_RUM_LCP_THRESHOLD',
	TRACKING_SPA_RUM_FCP_THRESHOLD = 'TRACKING_SPA_RUM_FCP_THRESHOLD',
	TRACKING_SPA_RUM_INP_THRESHOLD = 'TRACKING_SPA_RUM_INP_THRESHOLD',
	TRACKING_SPA_RUM_FID_THRESHOLD = 'TRACKING_SPA_RUM_FID_THRESHOLD',
	TRACKING_SPA_RUM_TTI_THRESHOLD = 'TRACKING_SPA_RUM_TTI_THRESHOLD',
	TRACKING_SPA_RUM_TBT_THRESHOLD = 'TRACKING_SPA_RUM_TBT_THRESHOLD',
}

function useFetch() {
	if (process.client && fetch) {
		return fetch;
	}

	// eslint-disable-next-line @typescript-eslint/no-var-requires
	return require('node-fetch').default;
}

export async function fetchApiRuntimeConfigEnvs(config?: NuxtRuntimeConfig) {
	try {
		const apiHost =
			config?.runtimeConfigProxy || process.env.PROXY_TARGET_HYBRIS || process.env.BASE_URL;
		const apiPath = '/de/shop/api/v2/serversettings';
		const apiRuntimeConfigEndpoint = new URL(apiPath, apiHost);
		const result = await useFetch()(apiRuntimeConfigEndpoint);
		const content = await result.text();
		const environmentVariables = JSON.parse(content)?.nuxtEnvironmentVariables || [];

		return Promise.resolve(environmentVariables);
	} catch (error) {
		console.error('Could not fetch serversettings from hybris', error);

		return Promise.resolve([]);
	}
}

export function updateRuntimeEnvVariables(
	configEntries: OspNuxtRuntimeConfigEntries,
	runtimeConfigReference?: OspNuxtRuntimeConfig,
) {
	if (!Object.keys(configEntries).length) {
		return;
	}

	Object.keys(configEntries).forEach((configEntryKey) => {
		if (!(configEntryKey in RuntimeConfigEnvs)) return;

		const envValue = configEntries[configEntryKey];

		let typedValue;

		if (typeof envValue === 'boolean' || typeof envValue === 'number') {
			typedValue = envValue;
		} else if (typeof envValue === 'string') {
			if (!envValue.length) {
				typedValue = undefined;
			} else if (envValue.toLowerCase() === 'true') {
				typedValue = true;
			} else if (envValue.toLowerCase() === 'false') {
				typedValue = false;
			} else {
				const numberValue = parseFloat(envValue);

				if (!isNaN(numberValue)) {
					typedValue = numberValue;
				}
			}
		}

		if (runtimeConfigReference?.env) {
			runtimeConfigReference.env[configEntryKey] = typedValue;
		}

		if (process) {
			process.env[configEntryKey] = String(typedValue);
		}
	});

	if (runtimeConfigReference?.lux) {
		runtimeConfigReference.lux.enabled =
			runtimeConfigReference.env[RuntimeConfigEnvs.TRACKING_PERFORMANCE];
		runtimeConfigReference.lux.debugMode =
			runtimeConfigReference.env[RuntimeConfigEnvs.TRACKING_PERFORMANCE_DEBUG];
	}
}

export async function updateEnvironmentVariables(runtimeConfig?: NuxtRuntimeConfig): Promise<void> {
	const apiConfigEntries = await fetchApiRuntimeConfigEnvs(runtimeConfig);

	if (!apiConfigEntries.length) return;

	const runtimeConfigEntries = convertApiConfigEntriesToObject(apiConfigEntries);

	updateRuntimeEnvVariables(
		runtimeConfigEntries,
		typeof runtimeConfig.env !== 'undefined' ? (runtimeConfig as OspNuxtRuntimeConfig) : undefined,
	);
}

export function convertApiConfigEntriesToObject(
	apiEnvs: ApiRuntimeConfigEntry[],
): OspNuxtRuntimeConfigEntries {
	const envObject = {};

	apiEnvs.forEach((envDefinition) => {
		if (envDefinition.key in RuntimeConfigEnvs) {
			envObject[envDefinition.key] = String(envDefinition.value);
		}
	});

	return envObject;
}
