import { BrandListModel } from '@/node_modules/@osp/design-system/components/BrandList/BrandList.props';
import { CampaignTeaserModel } from '@/node_modules/@osp/design-system/components/CampaignTeaser/CampaignTeaser.props';
import { CategoryListModel } from '@/node_modules/@osp/design-system/components/CategoryList/CategoryList.props';
import { ImageTileModel } from '@/node_modules/@osp/design-system/components/ImageTile/ImageTile.props';
import { ImageTileWithDescriptionModel } from '@/node_modules/@osp/design-system/components/ImageTileWithDescription/ImageTileWithDescription.props';
import { PromotionTeaserModel } from '@/node_modules/@osp/design-system/components/PromotionTeaser/PromotionTeaser.props';
import { RichTextModel } from '@/node_modules/@osp/design-system/components/RichText/RichText.props';
import { SliderModel } from '@/node_modules/@osp/design-system/components/Slider/Slider.props';
import { TestimonialModel } from '@/node_modules/@osp/design-system/components/Testimonial/Testimonial.props';
import { CategorySliderModel } from '@/node_modules/@osp/design-system/components/CategorySlider/CategorySlider.props';
import { DYBrandListModel } from '~/components/dynamic-yield/dy-brand-list/dy-brand-list.props';
import { DYCategoryListModel } from '~/components/dynamic-yield/dy-category-list/dy-category-list.props';
import { DYProductRecommendationModel } from '~/components/dynamic-yield/dy-product-recommendation/dy-product-recommendation.props';
import { SectionLayoutDataListModel } from '~/components/section-layout/section-layout__data-list.props';

export enum SectionLayoutRowType {
	FULL_WIDTH = 'FULL_WIDTH',
	HERO = 'HERO',
	PERCENT_100 = 'PERCENT_100',
	PERCENT_50 = 'PERCENT_50',
}

export type SectionLayoutComponentData =
	| BrandListModel
	| CampaignTeaserModel
	| CategoryListModel
	| CategorySliderModel
	| DYBrandListModel
	| DYCategoryListModel
	| DYProductRecommendationModel
	| ImageTileModel
	| ImageTileWithDescriptionModel
	| PromotionTeaserModel
	| RichTextModel
	| SectionLayoutDataListModel
	| SliderModel
	| TestimonialModel;

export interface SectionLayoutData {
	componentName: string;
	id: string;
	isDynamicYieldComponent: boolean;
	componentData: SectionLayoutComponentData;
}

export interface SectionLayoutRow {
	type: SectionLayoutRowType;
	data: SectionLayoutData[];
}

export enum SectionLayoutBackgroundPosition {
	FULL = 'FULL',
	PARTIAL = 'PARTIAL',
}
