import type { Store } from 'vuex';
import { clientInitStoreModule, useStoreModule } from '../useStoreModule';
import searchTrackingApi from './searchTracking.api';
import searchTrackingStore from './searchTracking.store';
import { RootState, TrackingSearchState } from '~/@api/store.types';
import { SEARCHTRACKING } from '~/@constants/store';

export function useSearchTrackingStore(store: Store<RootState>) {
	return useStoreModule<TrackingSearchState, ReturnType<typeof searchTrackingApi>>(
		SEARCHTRACKING,
		store,
		searchTrackingStore,
		searchTrackingApi,
	);
}

export async function clientInitSearchTrackingStore(store: Store<RootState>): Promise<void> {
	return await clientInitStoreModule(SEARCHTRACKING, store, searchTrackingStore, searchTrackingApi);
}
