import { Context } from '@nuxt/types';
import { runTask } from '@/node_modules/@osp/design-system/assets/js/utilities/runTask';
import { clientInitRoutingStore, useRoutingStore } from '~/@api/store/routingApi';

// No performance optimization by deferring onNuxtReady to ensure digitalData state
// will be up to date immediately
// eslint-disable-next-line require-await
async function PluginIntegration(context: Context) {
	clientInitRoutingStore(context.store).then(() => {
		(window as any).appState = context.store.state;
		(window as any).digitalData = useRoutingStore(context.store).state.spaData.datalayer;
	});
}

// Do not defer this plugin until Nuxt is ready, to watch for occuring user interaction
export default function (context: Context) {
	runTask(() => {
		PluginIntegration(context);
	});
}
