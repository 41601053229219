import { setSafeTimeout } from '~/node_modules/@osp/design-system/assets/js/utilities/timeout';

function browserNeedsPolyfill() {
	const TEST_FORM_NAME = 'form-attribute-polyfill-test';
	const testForm = document.createElement('form');
	testForm.setAttribute('id', TEST_FORM_NAME);
	testForm.setAttribute('type', 'hidden');

	const testInput = document.createElement('input');
	testInput.setAttribute('type', 'hidden');
	testInput.setAttribute('form', TEST_FORM_NAME);
	testForm.appendChild(testInput);
	document.body.appendChild(testInput);
	document.body.appendChild(testForm);

	const sampleElementFound = testForm.elements.length === 1;
	document.body.removeChild(testInput);
	document.body.removeChild(testForm);

	return !sampleElementFound;
}

// Ideas from jQuery form attribute polyfill https://stackoverflow.com/a/26696165/2372674
function executeFormPolyfill() {
	function appendDataToForm(data, form) {
		Object.keys(data).forEach((name) => {
			const inputElem = document.createElement('input');

			inputElem.setAttribute('type', 'hidden');
			inputElem.setAttribute('name', name);

			inputElem.value = data[name];

			form.appendChild(inputElem);
		});
	}

	const forms = document.body.querySelectorAll('form[id]');

	Array.prototype.forEach.call(forms, (form) => {
		const fields = document.querySelectorAll('[form="' + form.id + '"]');
		const dataFields = [];

		Array.prototype.forEach.call(fields, (field) => {
			if (field.disabled === false && field.hasAttribute('name')) {
				dataFields.push(field);
			}
		});

		function addResetClickListener(field) {
			field.addEventListener('click', () => {
				form.reset();
				Array.prototype.forEach.call(dataFields, (dataField) => {
					if (dataField.nodeName === 'SELECT') {
						Array.prototype.forEach.call(dataField.querySelectorAll('option'), (option) => {
							option.selected = option.defaultSelected;
						});
					} else {
						dataField.value = dataField.defaultValue;
						dataField.checked = dataField.defaultChecked;
					}
				});
			});
		}

		function addSubmitClickListener(field) {
			field.addEventListener('click', () => {
				const obj = {};

				obj[field.name] = field.value;

				appendDataToForm(obj, form);
				form.dispatchEvent(new CustomEvent('submit', { bubbles: true, cancelable: true }));
			});
		}

		Array.prototype.forEach.call(fields, (field) => {
			if (field.type === 'reset') {
				addResetClickListener(field);
			} else if (field.type === 'submit' || field.type === 'image') {
				addSubmitClickListener(field);
			}
		});

		form.addEventListener('submit', () => {
			const data = {};

			Array.prototype.forEach.call(dataFields, (dataField) => {
				data[dataField.name] = dataField.value;
			});
			appendDataToForm(data, form);
		});
	});
}

// Poll for new forms and execute polyfill for them
function detectedNewForms() {
	const ALREADY_DETECTED_CLASS = 'form-already-detected';
	const newForms = document.querySelectorAll('form:not([class*="' + ALREADY_DETECTED_CLASS + '"])');

	if (newForms.length !== 0) {
		Array.prototype.forEach.call(newForms, (form) => {
			form.className += ALREADY_DETECTED_CLASS;
		});
		executeFormPolyfill();
	}

	setSafeTimeout(detectedNewForms, 100);
}

export default () => {
	if (browserNeedsPolyfill()) {
		detectedNewForms(); // Poll for new forms and execute form attribute polyfill for new forms
	}
};
