export function showStagingDebugInfo(content: string, headline?: string): void {
	const debugPanelId = 'staging-debug-panel';

	ensureStagingDebugPanel(debugPanelId);

	if (headline) {
		document.getElementById(`${debugPanelId}-headline`).innerHTML = headline;
	}

	document.getElementById(`${debugPanelId}-content`).innerHTML = content;

	// Make sure all overlaying cmp wrapper will be in the background of tti information
	pushElementToBack(document.getElementById('cmpwrapper'));

	[
		document.getElementById('cmpwrapper'),
		document.getElementById('cmpbox'),
		document.getElementById('cmpbox1'),
	].forEach((cmpBox: HTMLElement) => {
		pushElementToBack(cmpBox);
	});
}

function pushElementToBack(elm?: HTMLElement) {
	if (!elm) return;

	elm.style.position = 'fixed';
	elm.style.zIndex = '998';
}

function ensureStagingDebugPanel(debugPanelId: string): void {
	const firstBodyChild = document.body.firstChild as HTMLElement;
	let debugPanel = document.getElementById(debugPanelId);

	// Make sure TTI info panel is always first child of body
	if (debugPanel && firstBodyChild?.id !== debugPanelId) {
		document.body.insertBefore(debugPanel, document.body.firstChild);
		return;
	}

	if (debugPanel) {
		return;
	}

	// If no TTI info panel on page, create one
	debugPanel = document.createElement('div');
	debugPanel.id = debugPanelId;
	debugPanel.style.position = 'fixed';
	debugPanel.style.left = '0';
	debugPanel.style.top = '0';
	debugPanel.style.zIndex = '999';
	debugPanel.style.background = '#fff';
	debugPanel.style.padding = '1rem';
	debugPanel.style.margin = '1%';
	debugPanel.style.border = 'solid .1rem black';
	debugPanel.style.boxShadow = 'rgba(0, 0, 0, .33) 0px 0px 100vw 100vw';
	debugPanel.style.wordBreak = 'break-all';
	debugPanel.innerHTML = `<h1 id="${debugPanelId}-headline"></h1><hr /><div id="${debugPanelId}-content"></div>`;
	document.body.insertBefore(debugPanel, document.body.firstChild);
}
