import { routingCheckoutSteps } from '@/routing/checkout-steps';
import { SpaType, SpaOtherId } from '@/generated/hybris-raml-api';
import { PageTypes } from '~/@api/store.types';

export const loginRoutes = (defaultPath: string) => [
	{
		path: `${defaultPath}/checkout/login`,
		component: () =>
			import('~/layouts/default-route/default-route.vue').then((m) => m.default || m),
		meta: {
			checkoutStep: routingCheckoutSteps.LOGIN_AND_REGISTRATION,
			spaType: SpaType.other,
			pageType: PageTypes.CHECKOUTLOGIN,
		},
		children: [
			{
				path: '',
				component: () => import('~/pages/login-page/login-page.vue').then((m) => m.default || m),
				props: {
					isCheckout: true,
				},
			},
		],
	},
	{
		path: `${defaultPath}/login/index.html`,
		component: () =>
			import('~/layouts/default-route/default-route.vue').then((m) => m.default || m),
		meta: {
			pageType: PageTypes.LOGIN,
			spaType: SpaType.other,
			spaOtherId: SpaOtherId.login,
		},
		children: [
			{
				path: '',
				component: () => import('~/pages/login-page/login-page.vue').then((m) => m.default || m),
				props: {
					isCheckout: false,
				},
			},
		],
	},
];
