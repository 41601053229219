import { Context } from '@nuxt/types';
import { runTask } from '@/node_modules/@osp/design-system/assets/js/utilities/runTask';

// Helper function to defer client plugins to be loaded after NUXT finished all it's work
export default function clientPluginOnNuxtReady(
	callback: (context?: Context, _inject?: any) => Promise<void>,
) {
	// Check if Nuxt related function exists on window object
	if (typeof (window as any).onNuxtReady === 'function') {
		// ... then execute callback (loading of client plugin) when Nuxt is ready
		(window as any).onNuxtReady(() => {
			runTask(callback);
		});
	} else {
		// ... otherwise as fallback, just execute the callback
		callback();
	}
}
