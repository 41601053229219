import { Store } from 'vuex';
import {
	OVERLAY_A_CLOSE,
	OVERLAY_A_OPEN,
	OVERLAY_A_REGISTER,
	OVERLAY_A_TOGGLE,
	OVERLAY_G_ANY_OPEN,
	OVERLAY_G_OPEN,
	OVERLAY_G_WITH_HISTORY,
	OVERLAY_M_REMOVE,
} from '~/@constants/store';
import { OverlayRegisterPayload, RootState } from '~/@api/store.types';

export default function (store: Store<RootState>) {
	return {
		// Length of the transition is defined in OverlayLegacy.scss and copied from there
		get transitionTime(): number {
			return 300;
		},

		isOpen: (id: string): boolean => store.getters[OVERLAY_G_OPEN](id),

		isAnyOpen: (): boolean => store.getters[OVERLAY_G_ANY_OPEN],

		withHistory: (id: string): boolean => store.getters[OVERLAY_G_WITH_HISTORY](id),

		close: (id: string) => store.dispatch(OVERLAY_A_CLOSE, id, { root: true }),

		open: (id: string) => store.dispatch(OVERLAY_A_OPEN, id, { root: true }),

		register: (payload: OverlayRegisterPayload) =>
			store.dispatch(OVERLAY_A_REGISTER, payload, { root: true }),

		remove: (id: string) => store.commit(OVERLAY_M_REMOVE, id, { root: true }),

		toggle: (id: string) => store.dispatch(OVERLAY_A_TOGGLE, id, { root: true }),
	};
}
