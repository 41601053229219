import type { Store } from 'vuex';
import { clientInitStoreModule, useStoreModule } from '../useStoreModule';
import loadingStore from './loading.store';
import loadingApi from './loading.api';
import { LoadingState, RootState } from '~/@api/store.types';
import { LOADING } from '~/@constants/store';

export function useLoadingStore(store: Store<RootState>) {
	return useStoreModule<LoadingState, ReturnType<typeof loadingApi>>(
		LOADING,
		store,
		loadingStore,
		loadingApi,
	);
}

export async function clientInitLoadingStore(store: Store<RootState>): Promise<void> {
	return await clientInitStoreModule(LOADING, store, loadingStore, loadingApi);
}
