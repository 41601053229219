import { ActionContext, Store } from 'vuex';
import { PaymentModeType } from '@/node_modules/@osp/design-system/types/paymentMode';
import Logger from '@/node_modules/@osp/utils/src/logger';
import { elapsedTime } from '@/node_modules/@osp/utils/src/performance';
import {
	CART_A_ADD_ITEM,
	CART_A_CHANGE_SIZE,
	CART_A_MODIFY_ITEM_QUANTITY,
	CART_A_REMOVE_ITEM,
	CART_A_STORE_CART,
	CART_A_UPDATE,
	CART_A_UPDATE_INSTALLMENT_CONDITION,
	CART_A_UPDATE_TRUSTIES,
	CART_G_HAS_BULKY,
	CART_G_QUANTITY,
	CART_M_SAVE_MODIFICATION_STATUS,
	CART_M_STORE_CART_STATE,
	CART_M_STORE_INSTALLMENT_CONDITION,
	CART_M_STORE_TRUSTIES,
	mapFn,
} from '~/@constants/store';
import { CartState, CartStatusMessage, RootState } from '~/@api/store.types';
import { backend } from '~/@api/backend';
import { useLoadingStore } from '~/@api/store/loadingApi';
import { useProductsStore } from '~/@api/store/productsApi';
import { useRoutingStore } from '~/@api/store/routingApi';
import { useVoucherStore } from '~/@api/store/voucherApi';
import { getJson, loadJsonBody, postJson } from '~/app-utils/http';
import {
	AddToCartRequest,
	AddToCartResponse,
	Cart,
	CartEntry,
	InstallmentCondition,
	ModifyCartEntryResponse,
	SpaDigitalDataCart,
	TrustiesResponse,
	Trusty,
} from '~/generated/hybris-raml-api';
import { mapAndAddToCart } from '~/tracking/events/cart.addEntry';
import { changeCartEntryQuantity } from '~/tracking/events/cart.changeQuantity';
import { removeFromCart } from '~/tracking/events/cart.removeEntry';
import { eecCartSynced } from '~/tracking/events/eec.cartSynced';
import type { GTM } from '~/@types/vue';

// Constants ---------------------------------------------------------------------------------------

const PRODUCTS_CART_GROUP = 'cart';
const EMPTY_STATE: CartState = {
	cart: {
		clubDiscount: null,
		deliveryAddress: null,
		deliveryCost: null,
		diffPrice: null,
		entries: [],
		giftCardRedemptions: [],
		originPrice: null,
		paymentAddress: null,
		paymentCost: null,
		paymentInfo: null,
		paymentMode: null,
		subTotalCrossPrice: null,
		subTotalPrice: null,
		totalPrice: null,
		vouchers: [],
	},
	installmentCondition: null,
	modificationStatus: null,
	trusties: [],
};

// Initial state -----------------------------------------------------------------------------------

const state = () => ({
	...EMPTY_STATE,
	cart: { ...EMPTY_STATE.cart },
});

// Mutations ---------------------------------------------------------------------------------------

const mutations = {
	[mapFn(CART_M_SAVE_MODIFICATION_STATUS)](state: CartState, status: CartStatusMessage) {
		state.modificationStatus = status;
	},

	[mapFn(CART_M_STORE_CART_STATE)](state: CartState, cart: Cart) {
		state.cart = {
			...EMPTY_STATE.cart,
			entries: [],
			...cart,
		};
	},

	[mapFn(CART_M_STORE_TRUSTIES)](state: CartState, trusties: Trusty[]) {
		state.trusties = trusties;
	},

	[mapFn(CART_M_STORE_INSTALLMENT_CONDITION)](
		state: CartState,
		installmentCondition: InstallmentCondition,
	) {
		state.installmentCondition = installmentCondition;
	},
};

// Actions -----------------------------------------------------------------------------------------

const actions = {
	async [mapFn(CART_A_UPDATE)](
		context: ActionContext<CartState, RootState>,
		payload: { recalculate: boolean; updateExternalStocks: boolean; updateDatalayer: boolean },
	) {
		try {
			await _startCartModification(this);

			const cart: Cart = (
				await getJson(
					backend.API.V2.CART.INFO(this, payload.updateExternalStocks, payload.recalculate),
					this,
				)
			).json;

			await context.dispatch(mapFn(CART_A_STORE_CART), {
				cart,
				updateDatalayer: payload.updateDatalayer,
			});
		} finally {
			await _finishCartModification(this, context.state, (this as Store<RootState>).$gtm);
		}

		elapsedTime(CART_A_UPDATE);
	},

	async [mapFn(CART_A_STORE_CART)](
		context: ActionContext<CartState, RootState>,
		payload: { cart: Cart; updateDatalayer: boolean },
	) {
		const oldTotalPrice = context.state.cart?.totalPrice?.value || 0;
		const products = (payload.cart.entries || []).map((entry) => entry.product);

		useProductsStore(this).api.addProducts(products, PRODUCTS_CART_GROUP);
		context.commit(mapFn(CART_M_STORE_CART_STATE), payload.cart);

		if (payload.cart.paymentMode?.code?.split('#')[0] === PaymentModeType.SWISSBILLING) {
			if (
				// No installment infos available or totalPrice updated
				context.state.installmentCondition == null ||
				oldTotalPrice !== payload.cart?.totalPrice?.value
			) {
				await context.dispatch(mapFn(CART_A_UPDATE_INSTALLMENT_CONDITION));
			}
		}

		useVoucherStore(this).api.setVouchers(payload.cart.vouchers || []);

		if (payload.updateDatalayer) {
			useRoutingStore(this).api.updateDatalayer();
		}
	},

	async [mapFn(CART_A_UPDATE_INSTALLMENT_CONDITION)](context: ActionContext<CartState, RootState>) {
		try {
			const installmentCondition: InstallmentCondition = (
				await getJson(
					backend.API.V2.INSTALLMENT(this, context.state.cart.totalPriceWithoutPaymentCosts.value),
					this,
				)
			)?.json;
			context.commit(mapFn(CART_M_STORE_INSTALLMENT_CONDITION), installmentCondition);
		} catch (e) {
			Logger.error('Could not update installment conditions!', e);
			context.commit(mapFn(CART_M_STORE_INSTALLMENT_CONDITION), null);
		}
	},

	async [mapFn(CART_A_ADD_ITEM)](
		context: ActionContext<CartState, RootState>,
		product: AddToCartRequest,
	) {
		await _startCartModification(this);

		try {
			const url = backend.API.V2.CART.ADD(this);
			const response = await postJson(url, product, this);
			const json: AddToCartResponse = (await loadJsonBody(response, this)).json;

			await context.dispatch(mapFn(CART_A_STORE_CART), { cart: json.cart, updateDatalayer: true });
			context.commit(
				mapFn(CART_M_SAVE_MODIFICATION_STATUS),
				json.modificationStatus as CartStatusMessage,
			);

			return true;
		} catch (exception) {
			Logger.error(exception);

			return false;
		} finally {
			await _finishCartModification(this, context.state, (this as Store<RootState>).$gtm);
		}
	},

	async [mapFn(CART_A_CHANGE_SIZE)](
		context: ActionContext<CartState, RootState>,
		payload: { entryNumber: number; newSizeVariantCode: string },
	) {
		await _startCartModification(this);

		try {
			const url = backend.API.V2.CART.MODIFY(this);
			const response = await postJson(
				url,
				{
					entryNumber: payload.entryNumber,
					productCode: payload.newSizeVariantCode,
				},
				this,
			);
			const json: ModifyCartEntryResponse = (await loadJsonBody(response, this)).json;

			_trackRemoveFromCart(
				useRoutingStore(this).state.spaData.datalayer.cart,
				(context.state.cart.entries || []).find(
					(entry) => entry.entryNumber === payload.entryNumber,
				),
			);
			await context.dispatch(mapFn(CART_A_STORE_CART), { cart: json.cart, updateDatalayer: true });
			mapAndAddToCart(
				(json.cart.entries || []).find((entry) => entry.entryNumber === payload.entryNumber)
					.product,
			);
		} catch (exception) {
			Logger.error('modifyQuantity', exception);
		}

		await _finishCartModification(this, context.state, (this as Store<RootState>).$gtm);
	},

	async [mapFn(CART_A_REMOVE_ITEM)](
		context: ActionContext<CartState, RootState>,
		entryNumber: number,
	) {
		await _startCartModification(this);

		try {
			const url = backend.API.V2.CART.MODIFY(this);
			const response = await postJson(url, { entryNumber, quantity: 0 }, this);
			const json: ModifyCartEntryResponse = (await loadJsonBody(response, this)).json;

			_trackRemoveFromCart(
				useRoutingStore(this).state.spaData.datalayer.cart,
				(context.state.cart.entries || []).find((entry) => entry.entryNumber === entryNumber),
			);
			await context.dispatch(mapFn(CART_A_STORE_CART), { cart: json.cart, updateDatalayer: true });
		} catch (exception) {
			Logger.error('removeFromBasket', exception);
		}

		await _finishCartModification(this, context.state, (this as Store<RootState>).$gtm);
	},

	async [mapFn(CART_A_MODIFY_ITEM_QUANTITY)](
		context: ActionContext<CartState, RootState>,
		payload: { entryNumber: number; quantity: number },
	) {
		const oldEntry = _currentEntry(context.state.cart.entries, payload.entryNumber);

		if (oldEntry.quantity !== payload.quantity) {
			await _startCartModification(this);

			try {
				const url = backend.API.V2.CART.MODIFY(this);
				const response = await postJson(url, payload, this);
				const json: ModifyCartEntryResponse = (await loadJsonBody(response, this)).json;
				const newEntry = _currentEntry(json.cart.entries, payload.entryNumber);
				const quantityAdded = newEntry.quantity - oldEntry.quantity;

				await context.dispatch(mapFn(CART_A_STORE_CART), {
					cart: json.cart,
					updateDatalayer: true,
				});
				_trackChangeQuantity(
					useRoutingStore(this).state.spaData.datalayer.cart,
					newEntry,
					quantityAdded,
				);
			} catch (exception) {
				Logger.error('modifyQuantity', exception);
			}

			await _finishCartModification(this, context.state, (this as Store<RootState>).$gtm);
		}
	},

	async [mapFn(CART_A_UPDATE_TRUSTIES)](context: ActionContext<CartState, RootState>) {
		const response: TrustiesResponse = (await getJson(backend.API.V2.CART.TRUSTIES(this), this))
			.json;

		context.commit(mapFn(CART_M_STORE_TRUSTIES), response.trusties);
	},
};

// Getters -----------------------------------------------------------------------------------------

const getters = {
	[mapFn(CART_G_QUANTITY)]: (state: CartState) =>
		(state.cart.entries || []).map((entry: CartEntry) => entry.quantity).reduce((a, b) => a + b, 0),

	[mapFn(CART_G_HAS_BULKY)]: (state: CartState) =>
		(state.cart.entries || []).some((entry: CartEntry) => entry?.product?.bulky),
};

export default {
	state,
	mutations,
	actions,
	getters,
};

// Helpers -----------------------------------------------------------------------------------------

const _finishCartModification = async (store: Store<RootState>, _state: CartState, $gtm: GTM) => {
	await useLoadingStore(store).api.setLoading('cartModification', false);
	eecCartSynced(_state.cart, $gtm);
};

const _startCartModification = (store: Store<RootState>) =>
	useLoadingStore(store).api.setLoading('cartModification', true);

const _trackChangeQuantity = (
	cart: SpaDigitalDataCart,
	entry: CartEntry,
	quantityAdded: number,
) => {
	changeCartEntryQuantity(
		cart.item.find((dataEntry) => dataEntry.productInfo.productNr === entry.product.code),
		quantityAdded,
	);
};

const _trackRemoveFromCart = (cart: SpaDigitalDataCart, entry: CartEntry) => {
	removeFromCart(
		cart.item.find((dataEntry) => dataEntry.productInfo.productNr === entry.product.code),
	);
};

const _currentEntry = (entries: CartEntry[] | undefined, entryNumber: number): CartEntry =>
	(entries || []).find((entry) => entry.entryNumber === entryNumber);
