import { i18nKeys } from '~/generated/translations';
import { routingCheckoutSteps } from '~/routing/checkout-steps';

export const deliveryBillingRoute = {
	path: 'delivery/billing',
	component: () =>
		import(
			/* webpackChunkName: "checkout" */ '~/pages/checkout/billing-address-page/billing-address-page.vue'
		).then((m) => m.default || m),
	meta: {
		checkoutStep: routingCheckoutSteps.PAYMENT_ADDRESS,
		headline: i18nKeys.title.checkout.paymentAddress,
	},
};
