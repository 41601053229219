import {
	TrackingDigitalDataProduct,
	TrackingDigitalDataProductBase,
	TrackingProduct,
} from '../types';
import * as cartSessionUtils from '../cartSessionUtils';
import {
	ProductColorSizeVariant,
	ProductColorVariant,
	Size,
	SpaDigitalDataCartItem,
	SpaDigitalDataProduct,
} from '~/generated/hybris-raml-api';

type MappableSpaDigitalData = SpaDigitalDataProduct | SpaDigitalDataCartItem;
type MappableProductType = TrackingProduct | MappableSpaDigitalData;

enum MappingProductType {
	SPA,
	Tracking,
}

export const mapProduct = (
	product: MappableProductType,
	includeAllCustomDimensions = false,
): TrackingDigitalDataProductBase | TrackingDigitalDataProduct => {
	const productMapper = new ProductMapper(product);

	// BASE TrackingDigitalDataProduct

	const productSize = productMapper.getSelectedProductSize();

	const base: TrackingDigitalDataProductBase = {
		id: productMapper.getProductId(), // = item_id
		variant: productMapper.getVariantCode(), // = item_variant
		dimension150: productMapper.getArticleCode(), // = item_articlenumber
		quantity: productMapper.getQuantity() ?? undefined, // = quantity
		name: productMapper.getProductName(), // = item_name
		brand: productMapper.getBrand(), // = item_brand
		category: productMapper.getCategory(), // = item_category
		price: productMapper.getPrice().toString(), // = price
		size: productSize ?? undefined, // = item_size
		dimension28: productSize, // = item_size
		dimension36: productMapper.getProductColor(), // = item_color
		dimension17: productMapper.getStockStatus(), // = item_stockstatus
		dimension35: productMapper.getEan(),
	};

	// remove keys with type of undefined (optional parameter that were not filled)
	Object.keys(base).forEach((objectKey) => {
		if (typeof base[objectKey] === 'undefined') {
			delete base[objectKey];
		}
	});

	if (!includeAllCustomDimensions) {
		return base;
	}

	// FULL TrackingDigitalDataProduct

	const productCategories = productMapper.getProductCategories();

	const fullObject = {
		...base,
		dimension19: productMapper.getArticleCode(),
		dimension20: productMapper.getColorVariantCode(),
		dimension21: productMapper.getProductCode(),
		dimension22: productMapper.getProductName(),
		dimension23: productMapper.getBrand(),
		dimension24: productCategories.first,
		dimension25: productCategories.second,
		dimension26: productCategories.third,
		dimension27: productCategories.fourth,
		dimension28: productMapper.getSelectedProductSize(),
		dimension29: productMapper.getSizingSystemCode(),
		dimension30: null, // defaultCategory (?)
		dimension31: productMapper.getDefaultCategoryId(),
		dimension32: productMapper.getDefaultCategoryName(),
		dimension33: productMapper.getTotalStockSum(),
		dimension34: productMapper.getVariantStock(),
		dimension35: null, // EAN (?)
		dimension37: parseFloat(productMapper.getPrice().toString()),
		dimension38: productMapper.getGender(),
		dimension39: productMapper.getLabels(),
		dimension40: productMapper.getManufacturerAID(),
		dimension41: productMapper.getSeason(),
		dimension42: productMapper.isHeavyItem(),
		dimension43: 'Speditionsartikelgruppe(f)',
		dimension44: 'Speditionsartikel Montage/Lieferung(f)',
		dimension45: 'Speditionsartikel Montage-/Lieferkosten(f)',
		dimension46: productMapper.productHasVideo(),
		dimension47: 'Sportart Kategorie(f)',
		dimension48: 'Fokus Gender(f)',
		dimension49: 'Sportart(f)',
		dimension50: 'Amount Variants(f)',
		dimension51: 'Amount Variants (available)(f)',
		dimension52: 'Product Date Online(f)',
		dimension53: 'Product Creation Date(f)',
		dimension54: 'Product Description Chars(f)',
		dimension55: 'Amount of Product Reviews(f)',
		dimension56: 'Product Average Rating(f)',
		dimension57: 'Artikelcode - Titel(f)',
		dimension62: 'AA-Nummer(f)',
		dimension63: 'AA-Bezeichnung(f)',
		dimension64: 'HG-Nummer(f)',
		dimension65: 'HG-Bezeichnung(f)',
		dimension66: 'OG-Nummer(f)',
		dimension67: 'OG-Bezeichnung(f)',
		dimension68: 'GR-Nummer(f)',
		dimension69: 'GR-Bezeichnung(f)',
		dimension70: 'UG-Nummer(f)',
		dimension71: 'UG-Bezeichnung(f)',
		dimension72: 'Creation Year(f)',
		dimension73: 'Product CategoryNr-Name(f)',
	};

	// remove keys with type of undefined (optional parameter that were not filled)
	Object.keys(fullObject).forEach((objectKey) => {
		if (typeof base[objectKey] === 'undefined') {
			delete base[objectKey];
		}
	});

	return fullObject;
};

class ProductMapper {
	_product: MappableProductType;
	_mappingProductType: MappingProductType;

	constructor(product: MappableProductType) {
		this._product = product;
		this.setMappingType();
	}

	// Required by TypeScript
	_mappableTrackingProduct(): TrackingProduct {
		return this._product as TrackingProduct;
	}

	// Required by TypeScript
	_mappableSpaDigitalDataProduct(): MappableSpaDigitalData {
		// @ts-ignore (Ignored, as otherwise SonarQube would complain about unnecessary explicit typing)
		return this._product;
	}

	isTrackingProduct(product: MappableProductType) {
		return !('attributes' in product) && !('productInfo' in product);
	}

	setMappingType() {
		this._mappingProductType = this.isTrackingProduct(this._product)
			? MappingProductType.Tracking
			: MappingProductType.SPA;
	}

	getBrand(): string | null {
		if (this._mappingProductType === MappingProductType.Tracking) {
			return this._mappableTrackingProduct().brand?.name ?? null;
		}

		return this._mappableSpaDigitalDataProduct().productInfo?.manufacturer ?? null;
	}

	getCategory(): string | null {
		if (this._mappingProductType === MappingProductType.Tracking) {
			return (
				this._mappableTrackingProduct()
					.breadcrumbs?.map((item) => item.name)
					.join('/') ?? null
			);
		}

		return this._mappableSpaDigitalDataProduct().productInfo?.defaultCategoryName ?? null;
	}

	_getSelectedColorVariant(): ProductColorVariant {
		if (this._mappingProductType === MappingProductType.Tracking) {
			const trackingProduct = this._mappableTrackingProduct();
			return trackingProduct.colorVariants[trackingProduct.selectedVariant[0]];
		}
	}

	getProductId(): string | null {
		if (this._mappingProductType === MappingProductType.Tracking) {
			return this._getSelectedColorVariant()?.code ?? null;
		}

		return this._mappableSpaDigitalDataProduct().productInfo?.productMasterNr ?? null;
	}

	getProductName(): string | null {
		if (this._mappingProductType === MappingProductType.Tracking) {
			return this._mappableTrackingProduct().name ?? null;
		}

		return decodeURIComponent(this._mappableSpaDigitalDataProduct().productInfo.productName || '')
			.replace(/\+/g, ' ')
			.replace(this.getBrand(), '')
			.trim();
	}

	getPrice(): string | number | null {
		if (this._mappingProductType === MappingProductType.Tracking) {
			return this._mappableTrackingProduct().price?.selling?.value ?? null;
		}

		return this._mappableSpaDigitalDataProduct().attributes?.price ?? null;
	}

	getQuantity(): string | null {
		return 'quantity' in this._product ? String(this._product.quantity) : null;
	}

	_getVariantProduct(): ProductColorVariant | ProductColorSizeVariant {
		if (this._mappingProductType !== MappingProductType.Tracking) return;

		const masterProduct = this._getSelectedColorVariant();

		return this._mappableTrackingProduct().selectedVariant.length > 1
			? (masterProduct?.sizeVariants[this._product.selectedVariant[1]] as ProductColorSizeVariant)
			: masterProduct;
	}

	getVariantCode(): string | null {
		if (this._mappingProductType === MappingProductType.Tracking) {
			return this._getVariantProduct()?.code ?? null;
		}

		return this._mappableSpaDigitalDataProduct().productInfo?.productNr ?? null;
	}

	_getSizeVariant(): ProductColorSizeVariant | undefined {
		if (this._mappingProductType !== MappingProductType.Tracking) return;

		return this._mappableTrackingProduct().selectedVariant.length === 2
			? (this._product.colorVariants[this._product.selectedVariant[0]].sizeVariants[
					this._product.selectedVariant[1]
				] as ProductColorSizeVariant)
			: undefined;
	}

	_getSize(): Size | undefined {
		if (this._mappingProductType !== MappingProductType.Tracking) return;

		return this._getSizeVariant()?.systems?.[0] ?? undefined;
	}

	getSelectedProductSize(): string | null {
		if (this._mappingProductType === MappingProductType.Tracking) {
			return this._getSize()?.value ?? null;
		}

		return this._mappableSpaDigitalDataProduct().productInfo?.size ?? null;
	}

	getEan(): string | null {
		const sessionData = cartSessionUtils.getSessionData();
		const variantCode = this.getVariantCode();

		return (sessionData?.[variantCode] as any)?.ean ?? null;
	}

	getArticleCode(): string | null {
		if (this._mappingProductType === MappingProductType.Tracking) {
			return this._mappableTrackingProduct().articleCode ?? null;
		}

		return this._mappableSpaDigitalDataProduct().productInfo?.productID ?? null;
	}

	getStockStatus(): string | null {
		return this._getVariantProduct()?.stock?.status ?? null;
	}

	getColorVariantCode(): string | null {
		if (this._mappingProductType === MappingProductType.Tracking) {
			return (
				this._mappableTrackingProduct().colorVariants?.[this._product.selectedVariant?.[0]]?.code ??
				null
			);
		}

		return this._mappableSpaDigitalDataProduct().productInfo?.productMasterNr ?? null;
	}

	getProductCode(): string | null {
		if (this._mappingProductType === MappingProductType.Tracking) {
			return this._mappableTrackingProduct().code ?? null;
		}

		return this._mappableSpaDigitalDataProduct().productInfo?.productNr ?? null;
	}

	_getCategory(level: number): string | null {
		if (this._mappingProductType !== MappingProductType.Tracking || level < 0 || level > 3) {
			return null;
		}

		return this._mappableTrackingProduct().breadcrumbs?.[level]?.name ?? null;
	}

	getProductCategories(): {
		first: string | null;
		second: string | null;
		third: string | null;
		fourth: string | null;
	} {
		if (this._mappingProductType === MappingProductType.Tracking) {
			return {
				first: this._getCategory(0),
				second: this._getCategory(1),
				third: this._getCategory(2),
				fourth: this._getCategory(3),
			};
		}

		const spaDataCategoryObject = (this._mappableSpaDigitalDataProduct() as SpaDigitalDataProduct)
			.category;

		return {
			first: spaDataCategoryObject?.productCategoryI ?? null,
			second: spaDataCategoryObject?.productCategoryII ?? null,
			third: spaDataCategoryObject?.productCategoryIII ?? null,
			fourth: spaDataCategoryObject?.productCategoryIV ?? null,
		};
	}

	getSizingSystemCode(): string | null {
		if (this._mappingProductType === MappingProductType.Tracking) {
			return this._getSize()?.sizingSystemCode ?? null;
		}

		return null;
	}

	getDefaultCategoryId(): string | null {
		if (this._mappingProductType === MappingProductType.Tracking) {
			return null;
		}

		return this._mappableSpaDigitalDataProduct().productInfo?.defaultCategoryId ?? null;
	}

	getDefaultCategoryName(): string | null {
		if (this._mappingProductType === MappingProductType.Tracking) {
			return null;
		}

		return this._mappableSpaDigitalDataProduct().productInfo?.defaultCategoryName ?? null;
	}

	getTotalStockSum(): number | null {
		if (this._mappingProductType === MappingProductType.Tracking) {
			return (
				this._mappableTrackingProduct()
					.colorVariants?.map((colorVariant) =>
						colorVariant?.sizeVariants
							? colorVariant.sizeVariants
									.map((sizeVariant) => sizeVariant?.stock?.available ?? 0)
									.reduce((a, b) => a + b, 0)
							: colorVariant?.stock?.available ?? 0,
					)
					.reduce((a, b) => a + b, 0) ?? null
			);
		}

		return null;
	}

	getVariantStock(): number | null {
		if (this._mappingProductType === MappingProductType.Tracking) {
			return this._mappableTrackingProduct().selectedVariant?.length === 2
				? this._getSizeVariant()?.stock?.available ?? null
				: this._getSelectedColorVariant()?.stock?.available ?? null;
		}

		return null;
	}

	getProductColor(): string | null {
		if (this._mappingProductType === MappingProductType.Tracking) {
			return this._mappableTrackingProduct().color?.name ?? null;
		}

		return this._mappableSpaDigitalDataProduct().attributes?.colour ?? null;
	}

	getGender(): string | null {
		if (this._mappingProductType === MappingProductType.Tracking) {
			return null;
		}

		return this._mappableSpaDigitalDataProduct().attributes?.gender ?? null;
	}

	getLabels(): string | null {
		if (this._mappingProductType === MappingProductType.Tracking) {
			this._mappableTrackingProduct()
				.labels?.map((label) => label.label)
				?.join(', ');
		}

		return null;
	}

	getManufacturerAID(): string | null {
		if (this._mappingProductType === MappingProductType.Tracking) {
			return this._mappableTrackingProduct().manufacturerAID ?? null;
		}

		return this._mappableSpaDigitalDataProduct().attributes?.manufacturerAID ?? null;
	}

	getSeason(): string | null {
		if (this._mappingProductType === MappingProductType.Tracking) {
			return null;
		}

		return this._mappableSpaDigitalDataProduct().attributes?.season?.toString() ?? null;
	}

	isHeavyItem(): number | null {
		if (this._mappingProductType === MappingProductType.Tracking) {
			return null;
		}

		return this._mappableSpaDigitalDataProduct().attributes?.isHeavyItem ? 1 : 0;
	}

	productHasVideo(): boolean | null {
		if (this._mappingProductType === MappingProductType.Tracking) {
			return this._mappableTrackingProduct().images?.some((image) => image.imageType === 'VIDEO');
		}

		return null;
	}
}
