import { Store } from 'vuex';
import {
	SEARCH_A_ELEMENTS_PER_PAGE,
	SEARCH_A_LOAD_CATEGORIES,
	SEARCH_A_NEXT_PAGE,
	SEARCH_A_PERFORM_SEARCH,
	SEARCH_A_PREVIOUS_PAGE,
	SEARCH_A_REFINE_SEARCH,
	SEARCH_A_REMOVE_FACET_VALUE,
	SEARCH_A_RESET_FACET,
	SEARCH_A_RESET_FACETS,
	SEARCH_A_SELECT_PAGE,
	SEARCH_A_SORTING,
	SEARCH_A_USE_RESPONSE,
	SEARCH_G_INITIAL_STATE,
	SEARCH_G_NEW_SEARCH_REQUEST,
	SEARCH_M_IN_PROGRESS,
	SEARCH_M_RESET_STATE,
	SEARCH_M_RESTORE_STATE,
	SEARCH_M_SHOW_ERROR,
	SEARCH_M_STORE_RESPONSE,
	SEARCH_M_STORE_RESULTS,
	SEARCH_M_SAVE_SELECTION,
	SEARCH_M_RESPONSE_FACETS,
	SEARCH_M_ACTIVATE_PAGE,
} from '~/@constants/store';
import { RootState, SearchState, SearchStateRecoveryData } from '~/@api/store.types';
import { SearchFacet, SearchRequestResponse, SearchResult } from '~/generated/hybris-raml-api';

export default function (store: Store<RootState>) {
	return {
		searchInProgress: (value: boolean) => store.commit(SEARCH_M_IN_PROGRESS, value, { root: true }),

		showError: (value: boolean) => store.commit(SEARCH_M_SHOW_ERROR, value, { root: true }),

		storeSearchResponse: (searchResponse: SearchRequestResponse) =>
			store.commit(SEARCH_M_STORE_RESPONSE, searchResponse, { root: true }),

		storeSearchResults: (page: number, searchResult: SearchResult[]) =>
			store.commit(SEARCH_M_STORE_RESULTS, { page, searchResult }, { root: true }),

		setActivePage: (pageNumber: number) =>
			store.commit(SEARCH_M_ACTIVATE_PAGE, pageNumber, { root: true }),

		saveSelection: (recoveryData: SearchStateRecoveryData | undefined) =>
			store.commit(SEARCH_M_SAVE_SELECTION, { recoveryData }, { root: true }),

		clearSelection: () => store.commit(SEARCH_M_SAVE_SELECTION, { undefined }, { root: true }),

		restoreState: (newState: SearchState) =>
			store.commit(SEARCH_M_RESTORE_STATE, newState, { root: true }),

		overwriteResponseFacets: (facets: SearchFacet[]) =>
			store.commit(SEARCH_M_RESPONSE_FACETS, facets, { root: true }),

		resetState: () => store.commit(SEARCH_M_RESET_STATE, null, { root: true }),

		nextPage: () => store.dispatch(SEARCH_A_NEXT_PAGE, null, { root: true }),

		previousPage: () => store.dispatch(SEARCH_A_PREVIOUS_PAGE, null, { root: true }),

		performSearch: (searchRequest: SearchRequestResponse, initialSearch = false) =>
			store.dispatch(SEARCH_A_PERFORM_SEARCH, { searchRequest, initialSearch }, { root: true }),

		refineSearch: (searchParamsGenerator: (searchRequest: SearchRequestResponse) => any) =>
			store.dispatch(SEARCH_A_REFINE_SEARCH, searchParamsGenerator, { root: true }),

		removeFacetValue: (facet: SearchFacet, facetValue: any) =>
			store.dispatch(SEARCH_A_REMOVE_FACET_VALUE, { facet, facetValue }, { root: true }),

		resetFacet: (facet: SearchFacet) => store.dispatch(SEARCH_A_RESET_FACET, facet, { root: true }),

		resetFacets: () => store.dispatch(SEARCH_A_RESET_FACETS, null, { root: true }),

		selectPage: (page: number, setInProgress?: boolean) =>
			store.dispatch(SEARCH_A_SELECT_PAGE, { page, setInProgress }, { root: true }),

		setElementsPerPage: (elementsPerPage: number) =>
			store.dispatch(SEARCH_A_ELEMENTS_PER_PAGE, elementsPerPage, { root: true }),

		setSorting: (sorting: string) => store.dispatch(SEARCH_A_SORTING, sorting, { root: true }),

		useSearchResponse: (searchResponse: SearchRequestResponse) =>
			store.dispatch(SEARCH_A_USE_RESPONSE, searchResponse, { root: true }),

		loadCategories: () => store.dispatch(SEARCH_A_LOAD_CATEGORIES, null, { root: true }),

		newSearchRequest: () => store.getters[SEARCH_G_NEW_SEARCH_REQUEST],

		initialState: () => store.getters[SEARCH_G_INITIAL_STATE](),
	};
}
