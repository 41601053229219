import { ActionContext, Store } from 'vuex';
import _get from 'lodash-es/get';
import { RootState, FormsState, FormModel } from '~/@api/store.types';
import {
	mapFn,
	FORMS_M_STORE_CONFIG,
	FORMS_M_STORE_VALIDATION_RESPONSE,
	FORMS_A_LOAD_FORM,
} from '~/@constants/store';
import {
	FormConfiguration,
	ZipSuggestionResponse,
	ValidationErrorResponse,
} from '~/generated/hybris-raml-api';
import { getJson } from '~/app-utils/http';
import { backend } from '~/@api/backend';

// Initial state -----------------------------------------------------------------------------------

const state = () => ({
	forms: {},
});

// Mutations ---------------------------------------------------------------------------------------

const mutations = {
	[mapFn(FORMS_M_STORE_CONFIG)](_state: FormsState, formConfig: FormConfiguration) {
		_state.forms = {
			..._state.forms,
			[formConfig.code]: {
				..._get(_state, `forms[${formConfig.code}]`, {}),
				config: formConfig,
				inputHandler: _createInputHandler(this as Store<RootState>, formConfig),
			},
		};
	},

	[mapFn(FORMS_M_STORE_VALIDATION_RESPONSE)](
		state: FormsState,
		payload: { formCode: string; validationResponse: ValidationErrorResponse },
	) {
		state.forms = {
			...state.forms,
			[payload.formCode]: {
				..._get(state, `forms[${payload.formCode}]`, {}),
				validationResponse: payload.validationResponse,
			},
		};
	},
};

// Actions -----------------------------------------------------------------------------------------

const actions = {
	async [mapFn(FORMS_A_LOAD_FORM)](
		context: ActionContext<FormsState, RootState>,
		payload: { formCode: string; forceUpdate: boolean },
	) {
		if (payload.forceUpdate || !_get(context.state, 'forms[payload.formCode].config', null)) {
			const response: FormConfiguration = (
				await getJson(backend.API.V2.FORM.CONFIG(this, payload.formCode), this)
			).json;

			context.commit(mapFn(FORMS_M_STORE_CONFIG), response);
		}

		return context.state.forms[payload.formCode];
	},
};

export default {
	state,
	mutations,
	actions,
};

// Helpers -----------------------------------------------------------------------------------------

const _createAddressInputHandler = (store: Store<RootState>) => {
	return {
		zip: {
			typeAhead: async (model: FormModel): Promise<FormModel[]> => {
				if (!model.zip || model.zip.length < 3 || model.zip.match(/\D/)) {
					return [];
				}

				const request = await getJson(
					backend.API.V2.ADDRESS.ZIP_SUGGESTION(store, model.zip),
					store,
				);

				return ((await request.json) as ZipSuggestionResponse).suggestions.map((suggestion) => ({
					label: `${suggestion.zip}, ${suggestion.city}`,
					model: {
						city: suggestion.city,
						country: suggestion.country.name,
						zip: suggestion.zip,
					},
				}));
			},
		},
	};
};

const _createInputHandler = (store: Store<RootState>, config: FormConfiguration) => {
	if (['deliveryAddress', 'billingAddress'].includes(config.code)) {
		return _createAddressInputHandler(store);
	}
};
