import { Store } from 'vuex';
import { DynamicYieldCredentials, DynamicYieldPayload, RootState } from '~/@api/store.types';
import {
	DYNAMICYIELD_A_FETCH,
	DYNAMICYIELD_G_CREDENTIALS,
	DYNAMICYIELD_G_DATA,
	DYNAMICYIELD_G_STORED_DATA_ENTRY_EXISTS,
	DYNAMICYIELD_M_SET_PATH,
	DYNAMICYIELD_M_STORE_DATA,
	DYNAMICYIELD_M_UPDATE_CHOICES,
} from '~/@constants/store';
import { MutationUpdateMode } from '~/@api/store/dynamicyieldApi/dynamicyield.store';

export default function (store: Store<RootState>) {
	return {
		setPath: (path: string) => store.commit(DYNAMICYIELD_M_SET_PATH, path, { root: true }),

		fetch: (payload: DynamicYieldPayload) =>
			store.dispatch(DYNAMICYIELD_A_FETCH, payload, { root: true }),

		storeData: (id: string, data: any) =>
			store.commit(DYNAMICYIELD_M_STORE_DATA, { id, data }, { root: true }),

		clearData: (id: string) =>
			store.commit(
				DYNAMICYIELD_M_STORE_DATA,
				{ id, data: MutationUpdateMode.remove },
				{ root: true },
			),

		removePrefetchedChoice: (name: string) =>
			store.commit(
				DYNAMICYIELD_M_UPDATE_CHOICES,
				{ name, mode: MutationUpdateMode.remove },
				{ root: true },
			),

		getData: (uid: string) => store.getters[DYNAMICYIELD_G_DATA](uid),

		storedDataEntryExists: (uid: string) =>
			store.getters[DYNAMICYIELD_G_STORED_DATA_ENTRY_EXISTS](uid),

		getCredentials: (): DynamicYieldCredentials => store.getters[DYNAMICYIELD_G_CREDENTIALS],

		buildDyDataKey: (selectorName: string): string => {
			const { path } = store.state.dynamicyield;
			const pattern = /\W/g;

			if (selectorName.toLowerCase().includes('ab-test')) {
				return selectorName.replace(pattern, '_');
			}

			const pathKey = path.substring(1).replace('.html', '').replace(pattern, '_');
			const selectorKey =
				selectorName.length === 0 ? '' : `[${selectorName.replace(pattern, '_')}]`;

			return `${pathKey}${selectorKey}`.toLowerCase();
		},
	};
}
