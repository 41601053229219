import Vue from 'vue';
import VueRouter, { Route } from 'vue-router';
import { Context } from '@nuxt/types';
import {
	setSafeInterval,
	setSafeTimeout,
} from '~/node_modules/@osp/design-system/assets/js/utilities/timeout';
import { routes } from './routes';
import { useProductsStore } from '~/@api/store/productsApi';
import { useRoutingStore } from '~/@api/store/routingApi';
import { PageTypes } from '~/@api/store.types';
import {
	GLOBAL_ROUTER_NAME,
	PRODUCT_DETAIL_PAGE_NAME,
	PRODUCT_GROUP_PDP,
} from '~/@constants/global';

interface ScrollPosition {
	x: number;
	y: number;
}

export const createRouter = (_ssrContext: Context['ssrContext']): VueRouter => {
	Vue.use(VueRouter);

	const router = new VueRouter({
		mode: 'history',
		routes,
		scrollBehavior: (to, from, savedPosition: void | ScrollPosition) => {
			if (
				history?.scrollRestoration === 'manual' &&
				history?.state?.data?.nuxtScrollRestoration === false
			) {
				return;
			}

			// savedPosition is only available on browser-back
			const savedScrollPositionResult = checkHandleSavedPosition(to.meta.pageType, savedPosition);

			if (savedScrollPositionResult) {
				return savedScrollPositionResult;
			}

			// Scroll position requested via routing api
			const scrollPostionViaRoutingResult = checkHandleScrollPositionViaRouting();

			if (scrollPostionViaRoutingResult) {
				return scrollPostionViaRoutingResult;
			}

			const exceptionPageTypeScrollPosition = checkHandleExceptionPageTypes(
				{
					pageType: from.meta.pageType,
					productCode: from.params?.productCode,
				},
				{ pageType: to.meta.pageType, productCode: to.params?.productCode },
			);

			if (exceptionPageTypeScrollPosition) {
				return exceptionPageTypeScrollPosition;
			}
		},
	});

	router.afterEach((to: Route, _from: Route) => {
		if (!process.client) return;

		const pageType = to.matched.find((match) => match.meta?.pageType)?.meta.pageType;

		if (pageType === PageTypes.PRODUCT) {
			history.replaceState(
				{
					...history.state,
					component: PRODUCT_DETAIL_PAGE_NAME,
					data: {
						productCode: to.params?.productCode,
					},
				},
				'',
				location.href,
			);
		}
	});

	if (process.client) {
		window[GLOBAL_ROUTER_NAME] = router;
	}

	return router;
};

function checkHandleExceptionPageTypes(
	from: { pageType; productCode?: string },
	to: { pageType; productCode?: string },
): ScrollPosition | undefined {
	// Do not scroll to top on navigation within those pages
	const exceptionPageTypes = [PageTypes.PRODUCT];

	if (exceptionPageTypes.includes(to.pageType) && to.pageType === from.pageType) {
		if (to.pageType === PageTypes.PRODUCT) {
			const fromProductCode = from.productCode;
			const toProductCode = to.productCode;
			const fromProduct = useProductsStore(window.$nuxt.$store).state.products?.[
				PRODUCT_GROUP_PDP
			]?.[fromProductCode];
			const navigationWithinProduct = fromProduct?.colorVariants?.some(
				(colorVariant) =>
					colorVariant.code === toProductCode ||
					colorVariant.sizeVariants?.some((sizeVariant) => sizeVariant.code === toProductCode),
			);

			// Scroll to top if its not just a color or size change
			if (!navigationWithinProduct) {
				return { x: 0, y: 0 };
			}
		}
	} else {
		// Scroll to top on route changes except on navigation within exceptionPageTypes
		return { x: 0, y: 0 };
	}
}

function checkHandleSavedPosition(
	pageType: PageTypes,
	savedPosition: void | ScrollPosition,
): ScrollPosition | Promise<ScrollPosition> {
	if (savedPosition) {
		if (
			[
				PageTypes.CATEGORY,
				PageTypes.PRODUCTSEARCH,
				PageTypes.BRAND,
				PageTypes.BRANDLIST,
				PageTypes.MYACCOUNT,
				PageTypes.PRODUCT,
			].includes(pageType)
		) {
			return new Promise((resolve) => {
				let timeToWait = pageType === PageTypes.PRODUCT ? 1200 : 800;

				// Increase waiting time as larger the saved position is (give page some time to render before scrolling)
				// This is important for extensively large pages like widely scrolled infinity scroll search result pages
				if (savedPosition.y > 15000 && savedPosition.y < 30000) {
					timeToWait = savedPosition.y / 20;
				} else if (savedPosition.y >= 30000) {
					timeToWait = savedPosition.y / 30;
				}

				return delayedPositionScrolling(resolve, timeToWait, savedPosition);
			});
		}

		if ([PageTypes.CONTENT, PageTypes.HOME].includes(pageType)) {
			return { x: 0, y: 0 };
		}

		return savedPosition;
	}
}

function delayedPositionScrolling(resolve, timeToWait, savedPosition) {
	return new Promise((resolve) => setSafeTimeout(resolve, timeToWait)).then(() => {
		// If document has already a height of at least the saved position after loading => scroll to it
		if (positionExistsOnScreen(savedPosition.y)) {
			resolve(savedPosition);

			return;
		}

		// ... otherwise perform several checks
		const maxChecks = 10;
		let cntChecks = 0;

		const checkInterval = setSafeInterval(() => {
			const positionToJumpToExistsOnScreen = positionExistsOnScreen(savedPosition.y);

			cntChecks++;

			if (positionToJumpToExistsOnScreen || cntChecks > maxChecks) {
				clearInterval(checkInterval);

				// If performed maxChecks and still not valid size - prevent page jump to wrong position (eg footer))
				if (positionToJumpToExistsOnScreen) {
					// Restoring perfomed in pagination, so no restoring by history required
					if (process.client && window.history?.state?.data?.scrollPosition) {
						window.history.state.data.scrollPosition = undefined;
					}

					return resolve(savedPosition);
				}
			}
		}, 250);
	});
}

function positionExistsOnScreen(savedPositionY: number): boolean {
	const mainSection = (document.getElementsByTagName('main') || [])[0];
	const mainSectionPositions = mainSection ? mainSection.getBoundingClientRect() : undefined;
	const mainSectionBottomPosition = mainSectionPositions
		? mainSectionPositions.bottom + window.scrollY
		: 0;
	return (mainSectionBottomPosition || window.document.body.clientHeight) >= savedPositionY;
}

function checkHandleScrollPositionViaRouting(): ScrollPosition | undefined {
	const { api: routingApi, state: routingState } = useRoutingStore(window.$nuxt.$store);

	if (routingState.scrollTargetAfterRouting) {
		const scrollToTarget = routingState.scrollTargetAfterRouting;
		let scrollTargetCoordinates;

		if (typeof scrollToTarget === 'string') {
			const targetElement = document.querySelector(scrollToTarget);

			if (targetElement !== null) {
				const targetElementPositions = (targetElement as HTMLElement).getBoundingClientRect();
				// Return position but also give it a bit "space" to the window border (10 px)
				scrollTargetCoordinates = {
					x: targetElementPositions.x + window.scrollX - 10,
					y: targetElementPositions.y + window.scrollY - 10,
				};
			}
		} else if (!isNaN(scrollToTarget.x) || !isNaN(scrollToTarget.y)) {
			scrollTargetCoordinates = { x: scrollToTarget.x || 0, y: scrollToTarget.y || 0 };
		}

		routingApi.clearScrollTarget();

		if (scrollTargetCoordinates) {
			return scrollTargetCoordinates;
		}
	}
}
