import { Store } from 'vuex';
import {
	MEDIAQUERY_G_IS_DESKTOP,
	MEDIAQUERY_G_IS_MOBILE,
	MEDIAQUERY_G_IS_TABLET,
	MEDIAQUERY_M_DIMENSIONS,
	MEDIAQUERY_M_UPDATE,
} from '~/@constants/store';
import { Breakpoint, RootState, UserAgentDeviceCategory } from '~/@api/store.types';

export default function (store: Store<RootState>) {
	return {
		isDesktop: () => store.getters[MEDIAQUERY_G_IS_DESKTOP],

		isTablet: () => store.getters[MEDIAQUERY_G_IS_TABLET],

		isMobile: () => store.getters[MEDIAQUERY_G_IS_MOBILE],

		update: (breakpoint: Breakpoint | UserAgentDeviceCategory) =>
			store.commit(MEDIAQUERY_M_UPDATE, breakpoint, { root: true }),

		resized: (width: number, height: number) =>
			store.commit(MEDIAQUERY_M_DIMENSIONS, { width, height }, { root: true }),
	};
}
