import { SpaType } from '@/generated/hybris-raml-api';
import { PageTypes } from '~/@api/store.types';
import { DynamicYieldContextType } from '~/@constants/global';
/*
	Pattern(s):
	- /{brandCode:[^-]+}-{(b|shop)}.html
	- /{gender:[^-]+}-{brandCode:[^-]+}-{(b|shop)}.html
	- /{brandAndGender:[^-]+(?:-[^-]+)?}-{categoryPath}-{categoryCode:\\d+}-{(b|shop)}.html
	URLs:
	- /de/shop/adidas-shop.html - Pattern 1 match - brandCode=adidas
	- /de/shop/herren-adidas-shop.html - Pattern 2 match - gender=herren,brandCode=adidas
	- /de/shop/herren-adidas-schuhe-0123456-b.html - Pattern 3 match - brandAndGender=herren-adidas,categoryPath=schuhe,categoryCode=0123456
	- /de/shop/adidas-schuhe-0123456-b.html - Pattern 3 match - brandAndGender=adidas,categoryPath=schuhe,categoryCode=0123456

	Category names may contain one or more dash-separated word(s). In this case URL may look like:
	- /de/shop/herren-adidas-running-trailrunning-0123456-b.html - Pattern 3 match - brandAndGender=herren-adidas,categoryPath=running-trailrunning,categoryCode=0123456
	- /de/shop/adidas-running-trailrunning-0123456-b.html - Pattern 3 match - brandAndGender=adidas-running,categoryPath=trailrunning,categoryCode=0123456
	Because category names may contain dash, we have not found (yet) other pattern to cover all cases
*/

export const categoryRoutes = [
	{
		path: ':categoryPath(.*)-:categoryCode(\\d*)-c.html',
		component: () =>
			import('~/pages/product-listing-page/product-listing-page.vue').then((m) => m.default || m),
		props: true,
		meta: {
			dynamicYieldContext: {
				type: DynamicYieldContextType.Category,
			},
			pageType: PageTypes.CATEGORY,
			spaType: SpaType.category,
			useMinHeight: true,
		},
	},
	{
		path: ':brandCode([^-]+)-:postfix(b|shop).html',
		component: () =>
			import('~/pages/product-listing-page/product-listing-page.vue').then((m) => m.default || m),
		props: true,
		meta: {
			pageType: PageTypes.BRAND,
			spaType: SpaType.category,
			useMinHeight: true,
		},
	},
	{
		path: ':gender([^-]+)-:brandCode([^-]+)-:postfix(b|shop).html',
		component: () =>
			import('~/pages/product-listing-page/product-listing-page.vue').then((m) => m.default || m),
		props: true,
		meta: {
			pageType: PageTypes.BRAND,
			spaType: SpaType.category,
			useMinHeight: true,
		},
	},
	{
		path: ':brandAndGender([^-]+-?[^-]*)-:categoryPath(.*)-:categoryCode(\\d+)-:postfix(b|shop).html',
		component: () =>
			import('~/pages/product-listing-page/product-listing-page.vue').then((m) => m.default || m),
		props: true,
		meta: {
			pageType: PageTypes.BRAND,
			spaType: SpaType.category,
			useMinHeight: true,
		},
	},
];
