import type { Store } from 'vuex';
import slugify from '@/node_modules/@osp/utils/src/slugify';
import { MediaSource } from '~/node_modules/@osp/design-system/types/media';
import { useServerContextStore } from '~/@api/store/serverContextApi';
import { useServerSettingsStore } from '~/@api/store/serverSettingsApi';
import { RootState } from '~/@api/store.types';
import { Image } from '~/generated/hybris-raml-api';

export interface ImageOptions {
	width?: number;
	height?: number;
	path?: string;
	preset?: string;
	seoText?: string;
	format?: string;
	square?: boolean;
	ignoreRetina?: boolean;
}

export const calculateImageUrl = (
	store: Store<RootState>,
	image: Image,
	options: ImageOptions,
): string => {
	const isRetina = useServerContextStore(store).state.userAgent.isRetina && !options.ignoreRetina;
	const displayFactor = isRetina ? 2 : 1;
	const width = (options.width || 0) * displayFactor;
	const height = (options.height || 0) * displayFactor;

	return (
		`${useServerSettingsStore(store).state.settings.scene7ImageUrl}` +
		`/${options.path || 'int'}` +
		// Set the preset name or the default preset if non given.
		`/preset/${options.preset || 'image'}` +
		// On retina devices, add the "-hd" postfix to the preset
		(isRetina ? '-hd' : '') +
		// Add optional settings
		(width ? `/w${width}` : '') +
		(height && !options.square ? `/h${height}` : '') +
		(options.square ? `/h${width}` : '') +
		`/fit/fit-1` +
		// Add optional seoText
		(options.seoText ? `/${slugify(options.seoText, false)}--` : '/') +
		// Add assetId and format
		`${image.assetId}.${options.format || 'jpg'}`
	).replace(/ /g, '%20');
};

export const createImageSources = (
	store: Store<RootState>,
	image: Image,
	breakpoints: { [breakpoint: string]: number },
	options: ImageOptions,
): MediaSource[] =>
	Object.keys(breakpoints)
		.sort((a, b) => Number(b) - Number(a))
		.map((breakpoint) => ({
			media: `(min-width: ${breakpoint}px)`,
			srcset: calculateImageUrl(store, image, {
				...options,
				width: breakpoints[breakpoint],
			}),
		}));
