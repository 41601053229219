import type { Store } from 'vuex';
import { clientInitStoreModule, useStoreModule } from '../useStoreModule';
import i18nStore from './i18n.store';
import i18nApi from './i18n.api';
import { I18NModuleState, RootState } from '~/@api/store.types';
import { I18N } from '~/@constants/store';

export function useI18nStore(store: Store<RootState>) {
	return useStoreModule<I18NModuleState, ReturnType<typeof i18nApi>>(
		I18N,
		store,
		i18nStore,
		i18nApi,
	);
}

export async function clientInitI18nStore(store: Store<RootState>): Promise<void> {
	return await clientInitStoreModule(I18N, store, i18nStore, i18nApi);
}
