import { i18nKeys } from '~/generated/translations';
import { routingCheckoutSteps } from '~/routing/checkout-steps';

export const summaryRoute = {
	path: 'summary',
	component: () =>
		import(
			/* webpackChunkName: "checkout" */ '~/pages/checkout/summary-page/summary-page.vue'
		).then((m) => m.default || m),
	meta: {
		checkoutStep: routingCheckoutSteps.SUMMARY,
		headline: i18nKeys.title.checkout.summary,
		hideProductLabels: true,
		showCVC: false,
		showMiniCart: false,
		showPlaceOrderButton: true,
	},
};
