// Blocking time optimized aca recommendations from
// https://github.com/nuxt/nuxt.js/discussions/9061#discussioncomment-539483

// Importing dependencies - statically ------
import { Context } from '@nuxt/types';
import clientPluginOnNuxtReady from '~/plugins/clientPluginHandler';
import { importStoreConstants } from '~/app-utils/dynamic-imports';

async function PluginIntegration(context: Context) {
	const { OVERLAY_M_OPEN } = await importStoreConstants();

	window.addEventListener('popstate', (event) => {
		if (event.state && event.state.component === 'OverlayLegacy') {
			context.store.commit(OVERLAY_M_OPEN, event.state.data.overlayId);
		}
	});
}

// Defer until Nuxt is ready
export default function (context: Context, _) {
	clientPluginOnNuxtReady(() => PluginIntegration(context));
}
