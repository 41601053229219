// Blocking time optimized aca recommendations from
// https://github.com/nuxt/nuxt.js/discussions/9061#discussioncomment-539483

// Importing dependencies - statically ------
import { Context } from '@nuxt/types';
import { setSafeTimeout } from '@/node_modules/@osp/design-system/assets/js/utilities/timeout';
import { Store } from 'vuex';
import { runTask } from '@/node_modules/@osp/design-system/assets/js/utilities/runTask';
import {
	importDebounce,
	importFirstUserInteractionTracking,
	importNuxtReadyTracking,
	importRunTask,
	importTimeout,
} from '~/app-utils/dynamic-imports';
import { clientInitMediaqueryStore, useMediaqueryStore } from '~/@api/store/mediaqueryApi';
import { clientInitUxStore, useUxStore } from '~/@api/store/uxApi';
import { RootState } from '~/@api/store.types';

// ---------------------------------------------------------------------------
// This plugin is only executed client side
// ---------------------------------------------------------------------------

// Allow to register callbacks for onFirstInteraction window event,
// which can be used in addition with 3rd party tools
const scriptBody = ((window as any).osp = (window as any).osp || {});

scriptBody.nuxtReady = false;

scriptBody.userInteraction = {
	interacted: false,
	interactedRecently: true,
	autoscrollIsHappening: false,
	autoscrollEndPosition: 0,
	_onFirstInteractionQueue: [] as Function[],
	onFirstInteraction(callback: Function) {
		scriptBody.userInteraction._onFirstInteractionQueue.push(callback);
	},
	hasUserScrolled: () => {
		return (
			window?.scrollY > 0 &&
			!scriptBody.userInteraction.autoscrollIsHappening &&
			window?.scrollY !== scriptBody.userInteraction.autoscrollEndPosition
		);
	},
};

(window as any).onNuxtReady(() => {
	scriptBody.nuxtReady = true;

	// Send event to GTM
	importRunTask().then(({ runTask }) => {
		runTask(() => importNuxtReadyTracking().then(({ nuxtReady }) => nuxtReady()));
	});
});

async function PluginIntegration(context: Context) {
	const [{ runTask }] = await Promise.all([
		importRunTask(),
		clientInitMediaqueryStore(context.store),
		clientInitUxStore(context.store),
	]);

	// If already scrolled when this code is executed, assume that user already interacted before code reached this point
	if (scriptBody.userInteraction.hasUserScrolled()) {
		runTask(() => handleUserInteraction(context.store));

		return;
	}

	const { debounce, debounceLeading } = await importDebounce();

	const isSafari = context.$ua.browser() === 'Safari';
	const isChrome = context.$ua.browser() === 'Chrome';
	const isDesktop = useMediaqueryStore(context.store).api.isDesktop();
	const isRealTouchDevice =
		((navigator as any)?.msMaxTouchPoints ?? (navigator as any)?.maxTouchPoints ?? 0) > 0;
	const isMobileIssueDevice = (isSafari || isChrome) && !isDesktop && isRealTouchDevice;
	const firstInteractionEventTypes = [
		'mousemove',
		'scroll',
		'keydown',
		'click',
		// Fix for iOS issue with first click not-triggered and mobile Safari & Chrome issues with loading content on PDP
		...(isMobileIssueDevice ? [] : ['touchstart', 'touchmove', 'touchend', 'touchcancel']),
	];

	runTask(() => useUxStore(context.store).api.registerNuxtReady());

	const firstInteractionHandler = debounce(() => {
		// Avoid wrong recognition of user interaction done by scrolling caused by implementation

		if ((window as any).osp?.userInteraction?.autoscrollIsHappening) return;

		// Remove registered event listeners after any user interaction took place
		firstInteractionEventTypes.forEach((eventType) => {
			window.removeEventListener(eventType, firstInteractionHandler, false);
		});
		runTask(() => handleUserInteraction(context.store));
	}, 250);

	firstInteractionEventTypes.forEach((eventType) => {
		window.addEventListener(eventType, firstInteractionHandler, { passive: true, once: true });
	});

	const recentInteractionEventTypes = [
		'scroll',
		'keydown',
		'click',
		// Fix for iOS issue with first click not-triggered and mobile Safari & Chrome issues with loading content on PDP
		...(isMobileIssueDevice ? [] : ['touchend', 'touchcancel']),
	];

	function updateInteractionStatus(interactedRecently: boolean) {
		if (typeof (window as any)?.osp?.userInteraction?.interactedRecently === 'undefined') return;

		(window as any).osp.userInteraction.interactedRecently = interactedRecently;
	}

	// Time span to be considered as okay for rendering happening without being considered as unexpected layout shift
	const INTERACTION_TIMELIMIT = 500;

	const recentInteractionHandler = debounceLeading(() => {
		updateInteractionStatus(true);

		setSafeTimeout(() => {
			updateInteractionStatus(false);
		}, INTERACTION_TIMELIMIT);
	}, INTERACTION_TIMELIMIT);

	recentInteractionEventTypes.forEach((eventType) => {
		window.addEventListener(eventType, recentInteractionHandler, { passive: true });
	});

	// Initial switch after 500ms
	setSafeTimeout(() => {
		updateInteractionStatus(false);
	}, INTERACTION_TIMELIMIT);
}

async function handleUserInteraction(store: Store<RootState>, timeoutLimit = undefined) {
	if (timeoutLimit === 0) {
		timeoutLimit = new Date();
		timeoutLimit = timeoutLimit.setSeconds(timeoutLimit.getSeconds() + 3);
	}

	if (!scriptBody.nuxtReady && new Date() < timeoutLimit) {
		// ... otherwise check within recursive loop without blocking
		importTimeout().then(({ setSafeTimeout }) => {
			setSafeTimeout(() => handleUserInteraction(store, timeoutLimit), 250);
		});

		return;
	}

	scriptBody.nuxtReady = true;
	scriptBody.userInteraction.interacted = true;

	// Wait until all dependencies were loaded asynchronously in parallel
	const dynamicImportResponses = await Promise.all([
		importRunTask(),
		importFirstUserInteractionTracking(),
	]);

	const runTask = dynamicImportResponses[0].runTask;
	const firstUserInteraction = dynamicImportResponses[1].firstUserInteraction;

	// If nuxt is already ready when user interacted,
	// update status, fire event and execute all attached functions
	importRunTask().then(({ runTask }) => {
		runTask(() => {
			const { api: uxApi } = useUxStore(store);

			if (!uxApi.isInteracted()) {
				uxApi.registerInteraction();
			}
		});
	});

	// Emit event for nuxt framework listeners
	window.$nuxt?.$emit('firstUserInteraction');

	// Dispatch general event (usable for 3rd party listeners)
	const event = new CustomEvent('firstUserInteraction');
	window.dispatchEvent(event);

	// Send event to GTM
	runTask(() => firstUserInteraction());

	// Have a look to possibly window attached callbacks
	const userInteractionQueue = scriptBody.userInteraction._onFirstInteractionQueue;

	if (Array.isArray(userInteractionQueue)) {
		while (userInteractionQueue.length) {
			// Execute window attached callbacks
			const queuedCallback = userInteractionQueue.pop();

			if (typeof queuedCallback === 'function') {
				queuedCallback();
			}
		}
	}
}

// Do not defer this plugin until Nuxt is ready, to watch for occurring user interaction
export default function (context: Context, _) {
	runTask(() => {
		PluginIntegration(context);
	});
}
