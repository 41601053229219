import { Store } from 'vuex';
import Logger from '@/node_modules/@osp/utils/src/logger';
import { stringify } from '@/node_modules/@osp/design-system/assets/js/utilities/stringify';
import { AbortController } from 'node-abort-controller';
import { PerformanceEvent, RootState, ServerContextState } from '~/@api/store.types';
import { FetchOptions } from '~/@types/fetch-options';
import { importRunTask } from '~/app-utils/dynamic-imports';
import {
	OspEndPerformanceMark,
	PerformanceTimings,
	performanceTracking,
} from '~/app-utils/tracking.utils';
import { useMessageboxStore } from '~/@api/store/messageboxApi';
import { useServerContextStore } from '~/@api/store/serverContextApi';

export interface JsonResponse {
	headers: Headers;
	json: any;
	ok: boolean;
	status: number;
	statusText: string;
}

interface HttpContext {
	store: Store<RootState>;
	trackingMarkId?: string;
}

interface HttpParams {
	url: string | URL;
	body?: RequestInit['body'];
	headers?: RequestInit['headers'];
	options?: FetchOptions;
	redirect?: RequestRedirect;
}

function http(
	method: RequestInit['method'],
	httpContext: HttpContext,
	params: HttpParams,
	redirect: RequestRedirect = 'follow',
): Promise<any> {
	const url = params.url.toString();
	const apiAbortController = new AbortController();

	apiAbortController.signal.addEventListener(
		'abort',
		() => Logger.error((apiAbortController.signal.reason || 'Aborted') + ` for ${url}`),
		{ once: true },
	);

	const { state: serverContextState } = useServerContextStore(httpContext.store);

	if (!isInternalApiFetch(url, serverContextState)) {
		addTimeoutAbort(apiAbortController, httpContext, url);
	}

	return useFetch()(url, {
		method,
		body: params.body,
		credentials: 'same-origin',
		headers: createHeaders(httpContext.store, url, params.headers),
		signal: apiAbortController.signal as AbortSignal,
		redirect,
		...addProxy(),
	})
		.then((response) => handleError(response, httpContext.store, !!params.options?.ignoreError))
		.catch((err) => handleCatch(err, method.toUpperCase(), url));
}

function addTimeoutAbort(apiAbortController, context, url) {
	const externalApiTimeoutValue = performanceTracking.getExternalTimeout();

	performanceTracking.debug(`Timeout for "${url}": ${externalApiTimeoutValue}ms`);

	if (externalApiTimeoutValue) {
		setTimeout(() => {
			apiAbortController.abort('Timeout reached');
			performanceTracking.debug(`Timeout reached for "${url}" (${externalApiTimeoutValue}ms)`);

			if (
				!!context.store.commit &&
				performanceTracking.isActive() &&
				performanceTracking.trackApiRequests()
			) {
				const requestAborted = performanceTracking.getTimestamp();

				if (!context.trackingMarkId) {
					context.trackingMarkId = `${PerformanceEvent.apiRequestAbort}|${url}|${requestAborted}`;
				}

				performanceTracking.markEndCollect(context.store, {
					uniqueId: context.trackingMarkId,
					commit: context.store.commit,
					markTime: requestAborted,
					performanceEvent: PerformanceEvent.apiRequestAbort,
					data: {
						ssr: process.server,
						aborted: true,
						timeout: externalApiTimeoutValue,
					},
				} as OspEndPerformanceMark);
			}
		}, externalApiTimeoutValue);
	}

	// Add script to trigger AbortController on route leave in Firefox (triggered in base-page.ts)
	if (typeof window !== 'undefined') {
		const scriptBody = ((window as any).osp = (window as any)?.osp || {});
		scriptBody.http = scriptBody.http || {
			cancel(reason: string) {
				apiAbortController.abort(reason);
			},
		};
	}
}

export const get = (
	url,
	httpContext: HttpContext,
	headers: {} = { 'X-Requested-With': 'XMLHttpRequest' },
	options: FetchOptions = { ignoreError: false },
	redirect: RequestRedirect = 'follow',
) => {
	return http('get', httpContext, { url, headers, options }, redirect);
};

export const post = (
	url,
	body,
	context: HttpContext,
	headers: {} = { 'X-Requested-With': 'XMLHttpRequest' },
	ignoreError = false,
) => {
	return http('post', context, { url, body, headers, options: { ignoreError } });
};

export const put = (
	url,
	body,
	store: Store<RootState>,
	headers: {} = { 'X-Requested-With': 'XMLHttpRequest' },
	ignoreError = false,
) => {
	return http(
		'put',
		{
			store,
		},
		{ url, body, headers, options: { ignoreError } },
	);
};

export const sendDelete = (
	url,
	store: Store<RootState>,
	headers: {} = { 'X-Requested-With': 'XMLHttpRequest' },
	ignoreError = false,
	redirect: RequestRedirect = 'follow',
) => {
	return http('delete', { store }, { url, headers, options: { ignoreError } }, redirect);
};

// Helpers -----------------------------------------------------------------------------------------

const proxy = process.env.PROXY || process.env.HTTPS_PROXY || process.env.HTTP_PROXY;

function addProxy() {
	return process.server && proxy
		? {
				// eslint-disable-next-line @typescript-eslint/no-var-requires
				agent: require('proxy-agent')(proxy),
			}
		: {};
}

// eslint-disable-next-line @typescript-eslint/no-var-requires
const NodeFetchHeaders = process.server ? require('node-fetch').Headers : null;

function isInternalApiFetch(url: string, serverContext: ServerContextState) {
	return (
		url.startsWith(`${serverContext.baseURL}/api/v2/`) ||
		(url.startsWith(serverContext.baseURL) && url.includes('/shop/api/'))
	);
}

const createHeaders = (store: Store<RootState>, url: string, headers) => {
	const { state: serverContextState } = useServerContextStore(store);
	const defaultHeader = {
		// add X-Smart-Proxy only if it's an internal fetch
		...(isInternalApiFetch(url, serverContextState) && {
			'X-Smart-Proxy': serverContextState.session.smartProxyEnabled,
		}),
	};
	const myHeader = process.server
		? {
				...defaultHeader,
				...headers,
				cookie: serverContextState.session.cookies,
				'x-forwarded-for': serverContextState.session.clientIPAddress,
			}
		: { ...defaultHeader, ...headers };

	return process.client && typeof window !== 'undefined'
		? new (window as any).Headers(myHeader)
		: new NodeFetchHeaders(myHeader);
};

interface FetchFunction {
	(input: RequestInfo | URL, init?: RequestInit): Promise<Response>;
}

function useFetch(): FetchFunction {
	// When in client and browser native fetch exists,
	// use this one (required for interception of fetch, e.g. also important for 3rd party tools
	// to work correctly, like for example BEHAMICS)
	if (process.client && fetch) {
		return fetch;
	}

	// ... otherwise use node-fetch, which states to have similar functionality and syntax like native fetch
	return require('node-fetch');
}

export async function loadJsonBody(
	response: Response,
	store: Store<RootState>,
	ignoreError = false,
): Promise<JsonResponse> {
	let json;

	try {
		json = await response?.json();
	} catch (e) {
		json = {};
	}

	const data: JsonResponse = {
		headers: response.headers,
		json,
		ok: response.ok,
		status: response.status,
		statusText: response.statusText,
	};

	return handleError(data, store, ignoreError);
}

export async function getJson(
	url,
	store: Store<RootState>,
	headers = {},
	ignoreError = false,
	redirect: RequestRedirect = 'follow',
): Promise<JsonResponse> {
	const httpContext: HttpContext = { store };

	if (!!store.commit && performanceTracking.isActive() && performanceTracking.trackApiRequests()) {
		const requestStart = performanceTracking.getTimestamp();
		httpContext.trackingMarkId = `${PerformanceEvent.apiRequest}|${url}|${requestStart}`;

		performanceTracking.markStartCollect(store, {
			uniqueId: httpContext.trackingMarkId,
			performanceEvent: PerformanceEvent.apiRequest,
			markTime: requestStart,
			data: {
				url,
			},
		});
	}

	const responseHeaderTimings: PerformanceTimings = {
		server: {
			processingTime: 0,
		},
		proxy: {
			processingTime: 0,
		},
	};

	let requestEnd;

	const response = await get(
		url,
		httpContext,
		{
			'content-type': 'application/json; charset=utf-8',
			'X-Requested-With': 'XMLHttpRequest',
			...headers,
		},
		{ ignoreError: true },
		redirect,
	)
		.then((responseData) => {
			requestEnd = performanceTracking.getTimestamp();

			if (
				store.commit &&
				performanceTracking.isActive() &&
				performanceTracking.trackApiRequests()
			) {
				// Extract performance timings from response header timing string
				function extractResponseHeaderDuration(timingTarget: string, timingName: string): number {
					const durationRegex = new RegExp(`${timingName}; dur=(\\d+)`);
					return parseInt(
						responseData.headers?.get(timingTarget)?.match(durationRegex)?.[1] ?? '0',
					);
				}

				responseHeaderTimings.server.processingTime += extractResponseHeaderDuration(
					'Server-Timing',
					'processing_time',
				);

				responseHeaderTimings.proxy.processingTime += extractResponseHeaderDuration(
					'Proxy-Timing',
					'processing_time',
				);

				// If proxy and server timings are both present at the same time, this means proxy was waiting for server
				// response and proxy time includes the server time. So server time needs to be subtracted to find out the pure
				// proxy timing (incl. the wait time for server answer) without pure server (hybris) process duration
				if (
					!!responseHeaderTimings.proxy.processingTime &&
					!!responseHeaderTimings.server.processingTime
				) {
					responseHeaderTimings.proxy.processingTime -= responseHeaderTimings.server.processingTime;
				}
			}

			return responseData;
		})
		.finally(() => {
			if (
				!store.commit ||
				!performanceTracking.isActive() ||
				!performanceTracking.trackApiRequests()
			)
				return;

			const endData = {
				uniqueId: httpContext.trackingMarkId,
				markTime: requestEnd ?? performanceTracking.getTimestamp(),
				performanceEvent: PerformanceEvent.apiRequest,
				timings: responseHeaderTimings,
			};

			performanceTracking.markEndCollect(store, endData);
		});

	return loadJsonBody(response, store, ignoreError);
}

export const postJson = (
	url,
	params: Object,
	store: Store<RootState>,
	headers = {},
	ignoreError = false,
	xRequestedWith = true,
) => {
	const httpContext: HttpContext = { store };
	const stringifiedParams = stringify(params);

	try {
		if (!!store.commit && performanceTracking.isActive()) {
			const requestStart = performanceTracking.getTimestamp();

			httpContext.trackingMarkId = `${PerformanceEvent.apiRequest}|${url}|${requestStart}`;

			performanceTracking.markStartCollect(store, {
				uniqueId: httpContext.trackingMarkId,
				performanceEvent: PerformanceEvent.apiRequest,
				markTime: requestStart,
				data: {
					url,
					params: stringifiedParams,
				},
			});
		}
	} catch (error) {
		Logger.error(`Failed to start track API performance for ${url}. Reason: `, error);
	}

	return post(
		url,
		stringifiedParams,
		httpContext,
		{
			'content-type': 'application/json; charset=utf-8',
			...(xRequestedWith && { 'X-Requested-With': 'XMLHttpRequest' }),
			...headers,
		},
		ignoreError,
	).finally(() => {
		try {
			if (!!store.commit && performanceTracking.isActive()) {
				const requestEnd = performanceTracking.getTimestamp();

				importRunTask().then(({ runTask }) => {
					runTask(() => {
						performanceTracking.markEndCollect(store, {
							uniqueId: httpContext.trackingMarkId,
							markTime: requestEnd,
							performanceEvent: PerformanceEvent.apiRequest,
						});
					});
				});
			}
		} catch (error) {
			Logger.error(`Failed to finish tracking API performance for ${url}. Reason: `, error);
		}
	});
};

export const putJson = (
	url,
	params: Object,
	store: Store<RootState>,
	headers = {},
	ignoreError = false,
	xRequestedWith = true,
) => {
	return put(
		url,
		stringify(params),
		store,
		{
			'content-type': 'application/json; charset=utf-8',
			...(xRequestedWith && { 'X-Requested-With': 'XMLHttpRequest' }),
			...headers,
		},
		ignoreError,
	);
};

export async function deleteJson(
	url,
	store: Store<RootState>,
	headers = {},
	ignoreError = false,
	redirect: RequestRedirect = 'follow',
) {
	const response = await sendDelete(
		url,
		store,
		{
			'content-type': 'application/json; charset=utf-8',
			'X-Requested-With': 'XMLHttpRequest',
			...headers,
		},
		true,
		redirect,
	);
	return loadJsonBody(response, store, ignoreError);
}

export const getWithParams = (url, params: Object, context: HttpContext, headers = {}) =>
	get(
		url +
			'?' +
			Object.keys(params)
				.map((key) => {
					return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
				})
				.join('&'),
		context,
		headers,
	);

const handleCatch = (err, method, url) => {
	// Do not report error of aborted requests by user
	if (typeof err === 'object' && err.message === 'The user aborted a request.') {
		return;
	}

	const prefix = err.error === 405 ? `Error ${err.error}: ${method} at ${url}` : undefined;

	Logger.error.apply(null, [prefix, err].filter(Boolean));

	return err;
};

const handleError = async (
	response,
	store: Store<RootState>,
	ignoreError = false,
	// eslint-disable-next-line require-await
): Promise<any> => {
	if (!response.ok && !ignoreError) {
		return useMessageboxStore(store).api.handleError(response);
	}

	return response;
};
