import _get from 'lodash-es/get';
import _isEmpty from 'lodash-es/isEmpty';
import { ActionContext, Store } from 'vuex';
import {
	CHECKOUT_A_PLACE_ORDER,
	CHECKOUT_A_SET_CLICK_AND_COLLECT_STORE,
	CHECKOUT_A_SET_CURRENT_STEP,
	CHECKOUT_A_SET_DELIVERY_ADDRESS,
	CHECKOUT_A_SET_DELIVERY_MODES,
	CHECKOUT_A_SET_PAYMENT_ADDRESS,
	CHECKOUT_A_SET_PAYMENT_INFO,
	CHECKOUT_A_SET_PAYMENT_MODE,
	CHECKOUT_A_START,
	CHECKOUT_A_STORE_CHECKOUT_STATE,
	CHECKOUT_A_UPDATE,
	CHECKOUT_A_UPDATE_PAYMENT_INFO,
	CHECKOUT_M_SET_CURRENT_STEP,
	CHECKOUT_M_SET_NEXT_STEP,
	CHECKOUT_M_STORE_CHECKOUT_STATE,
	CHECKOUT_M_STORE_PAYMENT_STATE,
	mapFn,
} from '~/@constants/store';
import { getJson, loadJsonBody, postJson } from '~/app-utils/http';
import { backend, url } from '~/@api/backend';
import { useCartStore } from '~/@api/store/cartApi';
import { useClubStore } from '~/@api/store/clubApi';
import { useFormsStore } from '~/@api/store/formsApi';
import { useLoadingStore } from '~/@api/store/loadingApi';
import { useMessageboxStore } from '~/@api/store/messageboxApi';
import { useRoutingStore } from '~/@api/store/routingApi';
import { useVoucherStore } from '~/@api/store/voucherApi';
import { CheckoutModuleState, MessageboxType, RootState } from '~/@api/store.types';
import {
	CheckoutState,
	CheckoutStep,
	DeliveryModeRequestEntry,
	GlobalMessage,
	PaymentInformationResponse,
	PlaceOrderRequest,
	PointOfService,
	SetPaymentInfoRequest,
	SetPaymentModeRequest,
	ValidationErrorResponse,
} from '~/generated/hybris-raml-api';
import { routingCheckoutSteps, RoutingStepCheckout } from '~/routing/checkout-steps';
import { eecCheckoutStep2 } from '~/tracking/events/eec.checkout.step2';

// Initial state -----------------------------------------------------------------------------------

const state = () => ({
	allowedSteps: [] as CheckoutStep[],
	currentStep: null,
	nextStep: null,
	payment: {
		iframeUrl: null,
		infos: [],
		paymentModes: [],
	},
});

// Mutations ---------------------------------------------------------------------------------------

const mutations = {
	[mapFn(CHECKOUT_M_STORE_CHECKOUT_STATE)](state: CheckoutModuleState, payload: CheckoutState) {
		state.allowedSteps = payload.allowedSteps;
	},

	[mapFn(CHECKOUT_M_STORE_PAYMENT_STATE)](
		state: CheckoutModuleState,
		payload: PaymentInformationResponse,
	) {
		state.payment = {
			iframeUrl: payload.iframeUrl,
			infos: payload.paymentInfos,
			paymentModes: payload.paymentModes,
		};
	},

	[mapFn(CHECKOUT_M_SET_CURRENT_STEP)](state: CheckoutModuleState, currentStep: CheckoutStep) {
		state.currentStep = currentStep;
	},

	[mapFn(CHECKOUT_M_SET_NEXT_STEP)](state: CheckoutModuleState, nextStep: RoutingStepCheckout) {
		state.nextStep = nextStep ? CheckoutStep[nextStep.code] : null;
	},
};

// Actions -----------------------------------------------------------------------------------------

const actions = {
	async [mapFn(CHECKOUT_A_UPDATE)](context: ActionContext<CheckoutModuleState, RootState>) {
		await _withLoader(this, async () => {
			const checkoutState: CheckoutState = (
				await getJson(backend.API.V2.CHECKOUT.STATE(this), this)
			).json;

			await context.dispatch(mapFn(CHECKOUT_A_STORE_CHECKOUT_STATE), checkoutState);
		});
	},

	async [mapFn(CHECKOUT_A_START)](context: ActionContext<CheckoutModuleState, RootState>) {
		await _postBackend(context, this, backend.API.V2.CHECKOUT.START(this), null);

		if (
			![CheckoutStep.CART, CheckoutStep.LOGIN_AND_REGISTRATION].includes(context.state.currentStep)
		) {
			useClubStore(this).api.startClubProcess();
		}
	},

	[mapFn(CHECKOUT_A_SET_DELIVERY_ADDRESS)](
		context: ActionContext<CheckoutModuleState, RootState>,
		addressId: string,
	) {
		return _postBackend(context, this, backend.API.V2.CHECKOUT.DELIVERY.ADDRESS(this), {
			id: addressId || 'clear',
			isBilling: false,
			isDelivery: true,
		});
	},

	[mapFn(CHECKOUT_A_SET_PAYMENT_ADDRESS)](
		context: ActionContext<CheckoutModuleState, RootState>,
		addressId: string,
	) {
		return _postBackend(context, this, backend.API.V2.CHECKOUT.PAYMENT.ADDRESS(this), {
			id: addressId || 'clear',
			isBilling: true,
			isDelivery: false,
		});
	},

	async [mapFn(CHECKOUT_A_SET_CLICK_AND_COLLECT_STORE)](
		context: ActionContext<CheckoutModuleState, RootState>,
		store: PointOfService,
	) {
		await _postBackend(
			context,
			this,
			backend.API.V2.CHECKOUT.DELIVERY.CLICK_AND_COLLECT(this),
			store,
		);
		eecCheckoutStep2(
			this,
			useCartStore(this).state.cart.entries.map((entry) => ({
				...entry.product,
				quantity: entry.quantity,
			})),
			{ method: 'Abholung in Filiale' },
		);
	},

	async [mapFn(CHECKOUT_A_PLACE_ORDER)](
		context: ActionContext<CheckoutModuleState, RootState>,
		request: PlaceOrderRequest,
	) {
		await _postBackend(context, this, backend.API.V2.CHECKOUT.SUMMARY.PLACE_ORDER(this), request);
		// User's gift cards must be updated after an order
		await useVoucherStore(this).api.getUserGiftCards();
	},

	async [mapFn(CHECKOUT_A_SET_DELIVERY_MODES)](
		context: ActionContext<CheckoutModuleState, RootState>,
		payload: {
			deliveryModeEntries: DeliveryModeRequestEntry[];
			phone?: string;
		},
	) {
		await _withLoader(this, async () => {
			const response = await postJson(
				backend.API.V2.CHECKOUT.DELIVERY.MODE(this),
				{
					entries: payload.deliveryModeEntries,
					phone: payload.phone,
				},
				this,
			);

			if (!response.ok) {
				const errorResponse = (await response.json()) as ValidationErrorResponse;

				useFormsStore(this).api.storeValidationResponse('assemblyPhone', errorResponse);
			} else {
				const checkoutState: CheckoutState = (await loadJsonBody(response, this)).json;

				if (payload.deliveryModeEntries.length === 0) {
					context.dispatch(mapFn(CHECKOUT_A_STORE_CHECKOUT_STATE), checkoutState);
				} else {
					await useCartStore(this).api.storeCart(checkoutState.cart);
				}
			}
		});
	},

	async [mapFn(CHECKOUT_A_UPDATE_PAYMENT_INFO)](
		context: ActionContext<CheckoutModuleState, RootState>,
	) {
		await _withLoader(this, async () => {
			const paymentState: PaymentInformationResponse = (
				await getJson(backend.API.V2.CHECKOUT.PAYMENT.INFORMATION(this), this)
			).json;
			context.commit(mapFn(CHECKOUT_M_STORE_PAYMENT_STATE), paymentState);
		});
	},

	[mapFn(CHECKOUT_A_SET_PAYMENT_MODE)](
		context: ActionContext<CheckoutModuleState, RootState>,
		payload: {
			request: SetPaymentModeRequest;
			ignoreError: boolean;
		},
	) {
		return _postBackend(context, this, backend.API.V2.CHECKOUT.PAYMENT.MODE(this), {
			...payload.request,
			ignoreError: payload.ignoreError,
		});
	},

	[mapFn(CHECKOUT_A_SET_PAYMENT_INFO)](
		context: ActionContext<CheckoutModuleState, RootState>,
		payload: SetPaymentInfoRequest,
	) {
		return _postBackend(context, this, backend.API.V2.CHECKOUT.PAYMENT.INFO(this), payload);
	},

	async [mapFn(CHECKOUT_A_SET_CURRENT_STEP)](
		context: ActionContext<CheckoutModuleState, RootState>,
		step: RoutingStepCheckout,
	) {
		const isFirstRoute = _isEmpty((this as Store<RootState>).$router.currentRoute.matched);
		const route = await useRoutingStore(this).api.navigate(url(this, step.path));

		if (route) {
			context.commit(
				mapFn(CHECKOUT_M_SET_CURRENT_STEP),
				CheckoutStep[_get(route, 'meta.checkoutStep.code', step.code)],
			);

			if (!isFirstRoute) {
				// Do not dismiss messages from jsp if this is the first route (pageReload)
				useMessageboxStore(this).api.dismissAll();
			}
		}
	},

	async [mapFn(CHECKOUT_A_STORE_CHECKOUT_STATE)](
		context: ActionContext<CheckoutModuleState, RootState>,
		checkoutState: CheckoutState,
	) {
		if (_tryExternalRedirect(checkoutState)) return;

		if (_redirectIfConfirmationPage(this, checkoutState)) return;

		await useCartStore(this).api.storeCart(checkoutState.cart);

		// If the backend tells us to go to another checkoutStep
		let nextCheckoutStep = null;

		if (context.state.currentStep !== checkoutState.currentStep) {
			const currentStep = routingCheckoutSteps[context.state.currentStep];
			const allowedStep = routingCheckoutSteps[checkoutState.currentStep];

			if (
				currentStep &&
				(allowedStep.group > currentStep.group || allowedStep.order > currentStep.order)
			) {
				// If the backend wants us to go further in the process find next checkoutStep from current group
				nextCheckoutStep = Object.values(routingCheckoutSteps)
					.filter((checkoutStep) => checkoutStep.group === currentStep.group)
					.filter((checkoutStep) => checkoutStep.order > currentStep.order)
					.filter((checkoutStep) =>
						checkoutState.allowedSteps.includes(CheckoutStep[checkoutStep.code]),
					)
					.sort((a, b) => a.order - b.order)[0];
			}

			// Otherwise or if no other step in the same group was found goto the checkoutStep from backend
			if (!nextCheckoutStep) {
				nextCheckoutStep = routingCheckoutSteps[checkoutState.currentStep];
			}
		}

		context.commit(mapFn(CHECKOUT_M_STORE_CHECKOUT_STATE), checkoutState);

		if (nextCheckoutStep) {
			await context.dispatch(mapFn(CHECKOUT_A_SET_CURRENT_STEP), nextCheckoutStep);
		}

		// Store messages only if they are given for the currentStep
		if (checkoutState.currentStep === context.state.currentStep) {
			_storeMessages(context, this, checkoutState.messages);
		}
	},
};

export default {
	state,
	mutations,
	actions,
};

// Helpers -----------------------------------------------------------------------------------------

async function _withLoader(store: Store<RootState>, action: () => Promise<any>) {
	const { api: loadingApi } = useLoadingStore(store);

	try {
		await loadingApi.setLoading('checkout', true);

		return await action();
	} finally {
		await loadingApi.setLoading('checkout', false);
	}
}

function _postBackend(
	context: ActionContext<CheckoutModuleState, RootState>,
	store: Store<RootState>,
	endpoint: string,
	payload?: any,
) {
	return _withLoader(store, async () => {
		const response = await postJson(endpoint, payload, store, {}, payload?.ignoreError || false);
		const checkoutState: CheckoutState = (
			await loadJsonBody(response, store, payload?.ignoreError || false)
		).json;

		await context.dispatch(mapFn(CHECKOUT_A_STORE_CHECKOUT_STATE), checkoutState);

		return checkoutState;
	});
}

function _tryExternalRedirect(checkoutState: CheckoutState) {
	if (checkoutState.externalRedirectUrl) {
		window.location.href = checkoutState.externalRedirectUrl;

		return true;
	}

	return false;
}

function _storeMessages(
	context: ActionContext<CheckoutModuleState, RootState>,
	store: Store<RootState>,
	messages: GlobalMessage[],
) {
	const { api: messageboxApi, state: messageboxState } = useMessageboxStore(store);
	const messagesToKeep = messageboxState.messageboxes.filter(
		(message) => message.key === `checkout-${context.state.currentStep}`,
	);

	messageboxApi.dismissAll('checkout');
	messagesToKeep.forEach((message) => messageboxApi.addMessage(message));
	(messages || []).forEach((message) =>
		messageboxApi.addMessage({
			dismissible: false,
			global: true,
			key: 'checkout',
			text: message.message,
			type: MessageboxType[message.severity],
		}),
	);
}

function _redirectIfConfirmationPage(store: Store<RootState>, checkoutState: CheckoutState) {
	if (checkoutState.currentStep === CheckoutStep.CONFIRMATION) {
		const orderCode = _get(checkoutState, 'order.code', null);

		if (orderCode) {
			useRoutingStore(store).api.navigate(
				backend.API.V2.CHECKOUT.CONFIRMATION.REDIRECT_URL(store, orderCode),
			);
		} else {
			this.logger.error(
				'Next checkoutStep is CONFIRMATION but got no orderCode! I have no clue what to do....',
			);
		}

		return true;
	}

	return false;
}
