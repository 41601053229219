// Blocking time optimized aca recommendations from
// https://github.com/nuxt/nuxt.js/discussions/9061#discussioncomment-539483

// Importing dependencies - statically ------
import clientPluginOnNuxtReady from '~/plugins/clientPluginHandler';
import { importVue, importVueObserveVisibility } from '~/app-utils/dynamic-imports';

async function PluginIntegration() {
	// Wait until all dependencies were loaded asynchronously in parallel
	const dynamicImportResponses = await Promise.all([importVue(), importVueObserveVisibility()]);

	const Vue = dynamicImportResponses[0].default;
	const VueObserveVisibility = dynamicImportResponses[1].default;

	Vue.use(VueObserveVisibility);
}

// Defer until Nuxt is ready
export default function () {
	clientPluginOnNuxtReady(() => PluginIntegration());
}
