import type { Store } from 'vuex';
import { clientInitStoreModule, useStoreModule } from '../useStoreModule';
import installpromptStore from './installprompt.store';
import installpromptApi from './installprompt.api';
import { InstallPromptState, RootState } from '~/@api/store.types';
import { INSTALLPROMPT } from '~/@constants/store';

export function useInstallpromptStore(store: Store<RootState>) {
	return useStoreModule<InstallPromptState, ReturnType<typeof installpromptApi>>(
		INSTALLPROMPT,
		store,
		installpromptStore,
		installpromptApi,
	);
}

export async function clientInitInstallpromptStore(store: Store<RootState>): Promise<void> {
	return await clientInitStoreModule(INSTALLPROMPT, store, installpromptStore, installpromptApi);
}
