import { Store } from 'vuex';
import { SearchFacet } from '~/generated/hybris-raml-api';
import { FactFinderTrackingEvent, RootState, UnsavedSearchFacet } from '~/@api/store.types';
import {
	SEARCHTRACKING_A_SEND_FACTFINDER_TRACKING_EVENT,
	SEARCHTRACKING_M_SAVE_CHANGED_FACETS,
} from '~/@constants/store';

export default function (store: Store<RootState>) {
	return {
		saveChangedFacets: (oldFacets: SearchFacet[], newFacets: UnsavedSearchFacet[]) =>
			store.commit(SEARCHTRACKING_M_SAVE_CHANGED_FACETS, { oldFacets, newFacets }, { root: true }),

		sendFactFinderTrackingEvent: (event: FactFinderTrackingEvent) =>
			store.dispatch(SEARCHTRACKING_A_SEND_FACTFINDER_TRACKING_EVENT, event, { root: true }),
	};
}
