import { Context } from '@nuxt/types';
import { runTask } from '@/node_modules/@osp/design-system/assets/js/utilities/runTask';
import { useTrackingStore } from '~/@api/store/trackingApi';
import { PerformanceEvent } from '~/@api/store.types';
import { importPerformanceTracking, importRunTask } from '~/app-utils/dynamic-imports';
import { performanceTracking, updateTrackingEnvsOnWindowObject } from '~/app-utils/tracking.utils';

const appStartMark = performanceTracking.getTimestamp();

// Do NOT defer this to wait until Nuxt is ready to be able to track loading times right as soon as possible
export default function (context: Context) {
	if (!context.$config.env.TRACKING_PERFORMANCE) return;

	if (process.client) {
		updateTrackingEnvsOnWindowObject(context.$config.env);
	}

	if (
		process.client &&
		(!!context.$config.env.TRACKING_PERFORMANCE_DEBUG || location.search.includes('_debugLUX'))
	) {
		(window as any).osp.tracking.performance.debug = true;
		(window as any).osp.tracking.performance.debugLog = [];
	}

	const processPostFix = process.client ? '' : '•SSR';

	importPerformanceTracking().then(({ performanceTracking }) => {
		const pageType = context.route.matched.find((match) => match.meta?.pageType)?.meta.pageType;

		performanceTracking.markStartCollect(context.store, {
			performanceEvent: PerformanceEvent.appLoading,
			uniqueId: `${PerformanceEvent.appLoading}${processPostFix}`,
			markTime: appStartMark,
			data: {
				pageType,
			},
		});

		if (process.client) {
			runTask(() => {
				performanceTracking.markEndOpenSsr(context.store, appStartMark);
			});

			performanceTracking.markStartCollect(context.store, {
				performanceEvent: PerformanceEvent.nuxtReady,
				uniqueId: `${PerformanceEvent.nuxtReady}${processPostFix}`,
				markTime: appStartMark,
			});

			if (typeof (window as any).onNuxtReady === 'function') {
				// Mark end of nuxt app loading and running own scripts
				(window as any).onNuxtReady(() => {
					// Mark end of app loading
					const endMark = performanceTracking.getTimestamp();

					performanceTracking.markEndCollect(context.store, {
						uniqueId: `${PerformanceEvent.nuxtReady}${processPostFix}`,
						markTime: endMark,
					});
				});
			}

			// Load event is fired when browser did all loading work for the website
			window.addEventListener('load', () => {
				// Mark end of app loading
				const endMark = performanceTracking.getTimestamp();

				importRunTask().then(({ runTask }) => {
					runTask(() => {
						performanceTracking.markEndCollect(context.store, {
							uniqueId: `${PerformanceEvent.appLoading}${processPostFix}`,
							markTime: endMark,
						});
						runTask(() => useTrackingStore(context.store).api.registerEventPageLoad());
					});
				});
			});
		}
	});
}
