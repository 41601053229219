import { Context } from '@nuxt/types';
import Logger from '@/node_modules/@osp/utils/src/logger';
import { backend } from '~/@api/backend';
import { useLoadingStore } from '~/@api/store/loadingApi';
import { PageTypes } from '~/@api/store.types';
import { getJson } from '~/app-utils/http';
import { ProductRedirect } from '~/generated/hybris-raml-api';

export const isProductDetailPage = (context: Context) =>
	PageTypes.PRODUCT ===
	[...(context.route.meta || [])].reverse().find((meta) => meta.pageType)?.pageType;

export default async (context: Context) => {
	if (isProductDetailPage(context)) {
		await useLoadingStore(context.store).api.doWithLoader('pdp.checkRedirect', async () => {
			try {
				const response: ProductRedirect = (
					await getJson(
						backend.API.V2.PRODUCT_REDIRECT(context.store, context.route.params.productCode),
						context.store,
					)
				).json;

				if (response?.targetUrl) {
					context.redirect(302, response.targetUrl);
				}
			} catch (error) {
				Logger.error('product redirect', error);
			}
		});
	}
};
