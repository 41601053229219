import { Store } from 'vuex';
import { useServerSettingsStore } from '../serverSettingsApi';
import { GiftCard, RootState, Voucher } from '~/@api/store.types';
import {
	VOUCHER_A_GET_USER_BONUS_GIFTCARDS,
	VOUCHER_A_REDEEM,
	VOUCHER_A_RELEASE,
	VOUCHER_A_SET_MESSAGE,
	VOUCHER_M_SET_CURRENT_INPUT,
	VOUCHER_M_SET_USER_BONUS_GIFTCARDS,
	VOUCHER_M_SET_VOUCHERS,
} from '~/@constants/store';
import { CustomerGiftCardsResponse } from '~/generated/hybris-raml-api';

export default function (store: Store<RootState>) {
	return {
		setCurrentInput: (voucher: GiftCard | Voucher) =>
			store.commit(VOUCHER_M_SET_CURRENT_INPUT, voucher, { root: true }),

		setMessage: (message: string) => store.dispatch(VOUCHER_A_SET_MESSAGE, message, { root: true }),

		setVouchers: (vouchers: Voucher[]) =>
			store.commit(VOUCHER_M_SET_VOUCHERS, vouchers, { root: true }),

		redeem: (voucher: GiftCard | Voucher) =>
			store.dispatch(VOUCHER_A_REDEEM, voucher, { root: true }),

		release: (voucher: GiftCard | Voucher) =>
			store.dispatch(VOUCHER_A_RELEASE, voucher, { root: true }),

		getUserGiftCards: (update?: boolean) =>
			store.dispatch(VOUCHER_A_GET_USER_BONUS_GIFTCARDS, update, { root: true }),

		setUserGiftCards: (giftCardResponse: CustomerGiftCardsResponse[]) =>
			store.commit(VOUCHER_M_SET_USER_BONUS_GIFTCARDS, giftCardResponse, { root: true }),

		isGiftCard: (voucherCode: string): boolean =>
			!!useServerSettingsStore(store).state.settings.giftcard?.cardIdPrefix?.find((prefix) =>
				voucherCode.startsWith(prefix),
			),
	};
}
