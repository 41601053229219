import { PageTypes } from '~/@api/store.types';
import { SpaType } from '~/generated/hybris-raml-api';
import { DynamicYieldContextType } from '~/@constants/global';

export const pdpRoute = {
	path: ':seoName(.*)-:productCode(\\w+)-p.html',
	component: () =>
		import('~/pages/product-detail-page/product-detail-page.vue').then((m) => m.default || m),
	props: true,
	meta: {
		dynamicYieldContext: {
			type: DynamicYieldContextType.Product,
		},
		pageType: PageTypes.PRODUCT,
		spaType: SpaType.product,
		useMinHeight: true,
	},
};
