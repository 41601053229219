import { track } from '../tracking';
import { Cart } from '@/generated/hybris-raml-api';
import type { GTM } from '~/@types/vue';
import { mapProduct } from '~/tracking/data-mapper/product-mapper';

export const eecCartSynced = (payload: Cart, $gtm: GTM) => {
	track(
		`eec.cartSynced`,
		{
			ecommerce: {
				cartSynced: {
					products: payload.entries.map((entry) => ({
						...mapProduct(entry.product),
						quantity: entry.quantity,
					})),
				},
			},
		},
		$gtm,
	);
};
