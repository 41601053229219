import _isFunction from 'lodash-es/isFunction';
import { ActionContext, Store } from 'vuex';
import Logger from '@/node_modules/@osp/utils/src/logger';
import { elapsedTime } from '@/node_modules/@osp/utils/src/performance';
import { GIFT_CARDS_STORAGE_KEY, HTTP_STATUSCODE } from '~/@constants/global';
import {
	mapFn,
	VOUCHER_A_GET_USER_BONUS_GIFTCARDS,
	VOUCHER_A_REDEEM,
	VOUCHER_A_RELEASE,
	VOUCHER_A_SET_MESSAGE,
	VOUCHER_M_SET_CURRENT_INPUT,
	VOUCHER_M_SET_MESSAGE,
	VOUCHER_M_SET_USER_BONUS_GIFTCARDS,
	VOUCHER_M_SET_VOUCHERS,
} from '~/@constants/store';
import { getJson, loadJsonBody, postJson } from '~/app-utils/http';
import { backend } from '~/@api/backend';
import { useCartStore } from '~/@api/store/cartApi';
import { useCheckoutStore } from '~/@api/store/checkoutApi';
import { useI18nStore } from '~/@api/store/i18nApi';
import { useLoadingStore } from '~/@api/store/loadingApi';
import { useVoucherStore } from '~/@api/store/voucherApi';
import { GiftCard, RootState, Voucher, VoucherState } from '~/@api/store.types';
import { CheckoutStep, CustomerGiftCardsResponse } from '~/generated/hybris-raml-api';
import { voucherApplied } from '~/tracking/events/voucherApplied';

// Initial state -----------------------------------------------------------------------------------

const state = (): VoucherState => ({
	currentInput: { code: '' },
	message: '',
	vouchers: [],
	userGiftBonusCards: [],
	userGiftBonusCardTotals: null,
});

// Mutations ---------------------------------------------------------------------------------------

const mutations = {
	[mapFn(VOUCHER_M_SET_MESSAGE)](_state: VoucherState, message: string) {
		_state.message = message;
	},

	[mapFn(VOUCHER_M_SET_VOUCHERS)](_state: VoucherState, vouchers: Voucher[]) {
		_state.vouchers = vouchers;
	},

	[mapFn(VOUCHER_M_SET_CURRENT_INPUT)](_state: VoucherState, voucher: GiftCard | Voucher) {
		_state.currentInput = voucher;
	},

	[mapFn(VOUCHER_M_SET_USER_BONUS_GIFTCARDS)](
		_state: VoucherState,
		giftCardResponse: CustomerGiftCardsResponse,
	) {
		_state.userGiftBonusCards = giftCardResponse.giftCards;
		_state.userGiftBonusCardTotals = giftCardResponse.totalAmount;
	},
};

// Actions -----------------------------------------------------------------------------------------

const actions = {
	[mapFn(VOUCHER_A_SET_MESSAGE)](context: ActionContext<VoucherState, RootState>, message: string) {
		context.commit(mapFn(VOUCHER_M_SET_MESSAGE), message);
	},

	async [mapFn(VOUCHER_A_REDEEM)](
		context: ActionContext<VoucherState, RootState>,
		voucher: Voucher | GiftCard,
	) {
		const { api: checkoutApi, state: checkoutState } = useCheckoutStore(this);

		if (useVoucherStore(this).api.isGiftCard(voucher.code)) {
			await _handleGiftCard(context, this, voucher as GiftCard, 'redeem');
		} else {
			await _handleVoucher(context, this, voucher as Voucher, 'redeem');
		}

		if (checkoutState.currentStep === CheckoutStep.PAYMENT_MODE) {
			await Promise.all([
				checkoutApi.updatePaymentInformation(),
				useCartStore(this).api.updateInstallmentCondition(),
			]);
		}
	},

	async [mapFn(VOUCHER_A_RELEASE)](
		context: ActionContext<VoucherState, RootState>,
		voucher: Voucher,
	) {
		const { api: checkoutApi, state: checkoutState } = useCheckoutStore(this);

		if (useVoucherStore(this).api.isGiftCard(voucher.code)) {
			await _handleGiftCard(context, this, voucher as GiftCard, 'release');
		} else {
			await _handleVoucher(context, this, voucher, 'release');
		}

		if (checkoutState.currentStep === CheckoutStep.PAYMENT_MODE) {
			await Promise.all([
				checkoutApi.updatePaymentInformation(),
				useCartStore(this).api.updateInstallmentCondition(),
			]);
		}
	},

	async [mapFn(VOUCHER_A_GET_USER_BONUS_GIFTCARDS)](
		context: ActionContext<VoucherState, RootState>,
		forceUpdate = false,
	) {
		try {
			const wasUpdated: boolean = process.server
				? true // Do not update on server side
				: JSON.parse((window as any).osp.sessionStorage.getItem(GIFT_CARDS_STORAGE_KEY) || 'false');
			let response;

			// Gift cards update API call should occur just once per session and after each order,
			// otherwise call normal ("false" value) API
			if (!wasUpdated || forceUpdate) {
				response = (await getJson(backend.API.V2.USER.GIFTCARDS(this, true), this))?.json;

				(window as any).osp.sessionStorage.setItem(GIFT_CARDS_STORAGE_KEY, 'true');
			} else {
				response = (await getJson(backend.API.V2.USER.GIFTCARDS(this), this))?.json;
			}

			context.commit(mapFn(VOUCHER_M_SET_USER_BONUS_GIFTCARDS), response);
		} catch (error) {
			Logger.error("Could not fetch user's gift cards", error);
		}

		elapsedTime(VOUCHER_A_GET_USER_BONUS_GIFTCARDS);
	},
};

export default {
	state,
	mutations,
	actions,
};

// Helpers -----------------------------------------------------------------------------------------

async function _handleVoucher(
	context: ActionContext<VoucherState, RootState>,
	store: Store<RootState>,
	voucher: Voucher,
	type: string,
) {
	const { api: loadingApi } = useLoadingStore(store);

	await loadingApi.setLoading('handleVoucher', true);
	context.commit(mapFn(VOUCHER_M_SET_MESSAGE), '');

	try {
		const response = await postJson(backend.API.V1.VOUCHER(store, type, voucher.code), {}, store);

		if (response?.ok) {
			await useCartStore(store).api.update(false, true, true);
			context.commit(mapFn(VOUCHER_M_SET_CURRENT_INPUT), { code: '' });

			const trackingVoucher = _findVoucher(context, voucher.code);

			if (trackingVoucher) {
				voucherApplied(trackingVoucher, type, 'voucher');
			}
		} else if (response.status === HTTP_STATUSCODE.PRECONDITION_FAILED) {
			const responseJson = _isFunction(response.json)
				? await loadJsonBody(response, store, true)
				: response;

			const statusMessageKey = (responseJson.json.statusMessage || '').endsWith('.title')
				? responseJson.json.statusMessage
				: responseJson.json.statusMessage + '.title';
			const titleId = useI18nStore(store).state.messages[statusMessageKey]
				? statusMessageKey
				: 'cart.voucher.invalid.title';

			context.dispatch(mapFn(VOUCHER_A_SET_MESSAGE), titleId);
		}
	} finally {
		await loadingApi.setLoading('handleVoucher', false);
	}
}

async function _handleGiftCard(
	context: ActionContext<VoucherState, RootState>,
	store: Store<RootState>,
	giftCard: GiftCard,
	type: string,
) {
	await useLoadingStore(store).api.setLoading('handleGiftCard', true);
	context.commit(mapFn(VOUCHER_M_SET_MESSAGE), '');

	try {
		const response = await postJson(
			backend.API.V2.GIFTCARD[type.toUpperCase()](store),
			{
				cardId: giftCard.code,
				cardPIN: giftCard.pin,
			},
			store,
			{},
			true,
		);
		const body = await loadJsonBody(response, store, true);

		if (body.json.returnCode !== '0000') {
			context.dispatch(mapFn(VOUCHER_A_SET_MESSAGE), body.json.displayMessage);
		} else {
			await useCartStore(store).api.update(false, true, true);

			const trackingGiftCard = _findGiftCard(store, giftCard.code);

			if (trackingGiftCard) {
				voucherApplied(trackingGiftCard, type, 'giftCard');
			}
		}

		context.commit(mapFn(VOUCHER_M_SET_CURRENT_INPUT), { code: '' });
	} finally {
		await useLoadingStore(store).api.setLoading('handleGiftCard', false);
	}
}

function _findVoucher(context: ActionContext<VoucherState, RootState>, code: string) {
	return context.state.vouchers.find((v) => v.code === code);
}

function _findGiftCard(store: Store<RootState>, code: string) {
	return (useCartStore(store).state.cart.giftCardRedemptions || []).find((g) => g.cardId === code);
}
