import type { Store } from 'vuex';
import * as cartSessionUtils from '../cartSessionUtils';
import { track } from '../tracking';
import { Product } from '@/generated/hybris-raml-api';
import { useRoutingStore } from '~/@api/store/routingApi';
import { RootState } from '~/@api/store.types';
import { mapProduct } from '~/tracking/data-mapper/product-mapper';

// LOGIN-PAGE
export const eecCheckoutStep1 = (store: Store<RootState>, products: Product[], payload?: any) => {
	const eventName = payload ? 'checkout_option' : 'checkout';
	const currencyCode = products[0]?.price?.selling?.currencyIso || 'CHF';

	if (useRoutingStore(store).state.spaData.datalayer) {
		track(`eec.${eventName}`, {
			ecommerce: {
				[eventName]: {
					actionField: { step: 1, ...(payload || {}) },
					products: products.map((product) => ({
						...mapProduct(product, false),
						...cartSessionUtils.getSessionProduct(product),
					})),
				},
				currencyCode,
			},
		});
	}
};
