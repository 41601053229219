import { ActionContext, Store } from 'vuex';
import _get from 'lodash-es/get';
import _set from 'lodash-es/set';
import Logger from '@/node_modules/@osp/utils/src/logger';
import { elapsedTime } from '@/node_modules/@osp/utils/src/performance';
import { stringify } from '@/node_modules/@osp/design-system/assets/js/utilities/stringify';
import { PageTypes, RootState, RoutingState } from '~/@api/store.types';
import {
	mapFn,
	ROUTING_A_DATALAYER,
	ROUTING_A_NAVIGATE,
	ROUTING_A_SPA_DATA,
	ROUTING_A_STORE_I18N_URLS,
	ROUTING_M_ADD_LINKS,
	ROUTING_M_ALLOW_ROUTING,
	ROUTING_M_DATALAYER,
	ROUTING_M_SET_PAGE_TYPE,
	ROUTING_M_SET_SCROLL_TARGET,
	ROUTING_M_SPA_DATA,
} from '~/@constants/store';
import {
	SpaDigitalDatum,
	SpaLink,
	SpaOtherId,
	SpaType,
	SpaWrapperResponse,
} from '~/generated/hybris-raml-api';
import { getJson, JsonResponse } from '~/app-utils/http';
import { backend } from '~/@api/backend';
import { useLoadingStore } from '~/@api/store/loadingApi';
import { useRoutingStore } from '~/@api/store/routingApi';
import { useServerContextStore } from '~/@api/store/serverContextApi';
import { track } from '~/tracking/tracking';
import { getPageTypeMetaInfoFromRoute } from '~/routing/utils/spa-utils';

// Initial state -----------------------------------------------------------------------------------

const state = () => ({
	allowRouting: true,
	pageType: null,
	spaData: {
		datalayer: null,
		links: [],
		meta: [],
		richSnippet: null,
		title: null,
	},
	scrollTargetAfterRouting: undefined,
});

// Mutations ---------------------------------------------------------------------------------------

const mutations = {
	[mapFn(ROUTING_M_ALLOW_ROUTING)](_state: RoutingState, allow: boolean) {
		_state.allowRouting = allow;
	},
	[mapFn(ROUTING_M_SET_SCROLL_TARGET)](
		_state: RoutingState,
		scrollTarget: string | { x: number; y: number } | undefined,
	) {
		_state.scrollTargetAfterRouting = scrollTarget;
	},

	[mapFn(ROUTING_M_SPA_DATA)](_state: RoutingState, data: SpaWrapperResponse) {
		const oldTitle = _state.spaData?.title;

		_state.spaData = {
			..._state.spaData,
			links: data?.metaInformation?.links,
			meta: data?.metaInformation?.metaInformations,
			richSnippet: data?.richSnippet ? stringify(data.richSnippet) : null,
			title: data?.metaInformation?.title,
		};

		if (oldTitle !== data?.metaInformation?.title) {
			track(
				'custom-meta',
				{
					meta: {
						title: data?.metaInformation?.title,
					},
				},
				(this as Store<RootState>).$gtm,
			);
		}
	},

	[mapFn(ROUTING_M_ADD_LINKS)](_state: RoutingState, links: SpaLink[]) {
		_state.spaData = { ..._state.spaData, links: [...links, ...(_state.spaData.links || [])] };
	},

	[mapFn(ROUTING_M_SET_PAGE_TYPE)](_state: RoutingState, payload: PageTypes) {
		_state.pageType = payload;
	},

	[mapFn(ROUTING_M_DATALAYER)](_state: RoutingState, datalayer: SpaDigitalDatum) {
		if (process.client) {
			datalayer = setBrowserLanguage(datalayer);
			datalayer = setDeviceCategory(this as Store<RootState>, datalayer);
			(window as any).digitalData = datalayer;
		}

		_state.spaData.datalayer = datalayer;

		track('digitalData', datalayer, (this as Store<RootState>).$gtm);
	},
};

// Actions -----------------------------------------------------------------------------------------

const actions = {
	[mapFn(ROUTING_A_STORE_I18N_URLS)](
		_context: ActionContext<RoutingState, RootState>,
		payload: {
			spaType: SpaType;
			identifier: string;
		},
	): Promise<JsonResponse> {
		try {
			return getJson(
				backend.API.V2.SPA(this, payload.spaType, payload.identifier).storeI18nUrls,
				this,
				{},
				true,
			);
		} catch (error) {
			Logger.error(ROUTING_A_STORE_I18N_URLS, 'payload:', payload, 'error:', error);
			return Promise.reject(error);
		}
	},

	async [mapFn(ROUTING_A_SPA_DATA)](
		context: ActionContext<RoutingState, RootState>,
		payload: {
			spaType: SpaType;
			identifier?: string;
			storeI18nUrls: boolean;
		},
	) {
		const { api: loadingApi } = useLoadingStore(this);

		try {
			loadingApi.setLoading('spaData', true);

			if (!payload.identifier) return;

			const response = await getJson(
				backend.API.V2.SPA(this, payload.spaType, payload.identifier, payload.storeI18nUrls).all,
				this,
				{},
				true,
			);

			if (!response.ok) {
				return response;
			}

			const spaResponse = response.json;

			if (spaResponse.maintenance && !isMaintenanceDisabled(this as Store<RootState>)) {
				if (process.client) {
					(window as any).osp.setItem('disableMaintenance', 'true');
				}

				useRoutingStore(this).api.navigate('/maintenance');
			}

			if (spaResponse.digitalData) {
				context.commit(mapFn(ROUTING_M_DATALAYER), spaResponse.digitalData);
			}

			// Set robots to noindex,follow on all searchpages with q- or text-parameter
			if (
				payload.spaType === SpaType.category ||
				payload.identifier.startsWith(SpaOtherId.search)
			) {
				const query = (this as Store<RootState>).$router.currentRoute.query || {};

				if (query.q || query.text) {
					spaResponse.metaInformation.metaInformations.find(
						(meta) => meta.name === 'robots',
					).content = 'noindex,follow';
				}
			}

			context.commit(mapFn(ROUTING_M_SPA_DATA), spaResponse);

			return response;
		} catch (error) {
			Logger.error(ROUTING_A_SPA_DATA, 'payload:', payload, 'error:', error);
		} finally {
			loadingApi.setLoading('spaData', false);
			elapsedTime(ROUTING_A_SPA_DATA);
		}
	},

	async [mapFn(ROUTING_A_DATALAYER)](
		context: ActionContext<RoutingState, RootState>,
		payload: { spaType: SpaType; identifier: string },
	) {
		try {
			let spaType = payload.spaType;
			let identifier = payload.identifier;

			if (!spaType) {
				// Do not execute any update in case we were called via cart-module
				// and do not have any route yet. This happens on the initial load
				// when entering e.g. the PDP.
				const currentRoute = (this as Store<RootState>).$router.currentRoute;

				if (!currentRoute || currentRoute.path === '/') return;

				const currentSpaKey = getPageTypeMetaInfoFromRoute(this, currentRoute);

				spaType = currentSpaKey.spaType;
				identifier = currentSpaKey.identifier;
			}
			const datalayer = (
				await getJson(backend.API.V2.SPA(this, spaType, identifier).digitalData, this)
			).json;

			context.commit(mapFn(ROUTING_M_DATALAYER), datalayer);
		} catch (error) {
			Logger.error(ROUTING_A_DATALAYER, 'payload:', payload, 'error:', error);
		}
	},

	// eslint-disable-next-line require-await
	async [mapFn(ROUTING_A_NAVIGATE)](
		context: ActionContext<RoutingState, RootState>,
		location: string,
	) {
		const store = this as Store<RootState>;

		// In SPA make sure the path is new
		if (context.state.allowRouting && store.$router.currentRoute.fullPath !== location) {
			return store.$router.push(location, null);
		}

		return null;
	},
};

export default {
	state,
	mutations,
	actions,
};

// Helpers -----------------------------------------------------------------------------------------

const getDeviceCategory = (store: Store<RootState>) => {
	return useServerContextStore(store).state.userAgent.deviceCategory;
};

const setBrowserLanguage = (datalayer) => {
	_set(
		datalayer,
		['user', 'profile', 'attributes', 'browserLanguage'],
		(navigator.languages && navigator.languages[0]) ||
			navigator.language ||
			_get(navigator, 'userLanguage'),
	);

	return datalayer;
};

const setDeviceCategory = (store: Store<RootState>, datalayer) => {
	_set(datalayer, ['user', 'profile', 'attributes', 'deviceCategory'], getDeviceCategory(store));

	return datalayer;
};

const isMaintenanceDisabled = (store: Store<RootState>) => {
	let disableMaintenance = store.$router.currentRoute?.query?.disableMaintenance as string;

	if (process.client) {
		if (disableMaintenance) {
			(window as any).osp.sessionStorage.setItem('disableMaintenance', disableMaintenance);
		} else {
			disableMaintenance = (window as any).osp.sessionStorage.getItem('disableMaintenance');
		}
	}

	return disableMaintenance === 'true';
};
