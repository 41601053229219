

export default () => {
    if (process.server) {
        return;
    }

    const detect = () => {
        const ElementPrototype = window.Element.prototype;
        return (
          typeof ElementPrototype.matches === 'function' &&
          typeof ElementPrototype.closest === 'function');
      };

    console.log('Detect '  + "element-closest", detect());

    if (detect()) {
        return;
    }

    return new Promise((resolve, reject) => {
        require.ensure([
            "element-closest"
        ], function (require) {
            const polyfill = require("element-closest");

            console.log('Load ' + "element-closest");

            const install = (elementClosest) => elementClosest.default(window);

            install(polyfill);

            resolve();
        });
    });
}