import { i18nKeys } from '~/generated/translations';
import { routingCheckoutSteps } from '~/routing/checkout-steps';

export const deliveryRoute = {
	path: 'delivery',
	redirect: 'delivery/address',
	component: () =>
		import(
			/* webpackChunkName: "checkout" */ '~/pages/checkout/delivery-page/delivery-page.vue'
		).then((m) => m.default || m),
	children: [
		{
			path: 'address',
			component: () =>
				import(
					/* webpackChunkName: "checkout" */ '~/components/organisms/order-process/delivery-address/delivery-address.vue'
				).then((m) => m.default || m),
			meta: {
				checkoutStep: routingCheckoutSteps.DELIVERY_ADDRESS,
				headline: i18nKeys.title.checkout.deliveryAddress,
			},
		},
		{
			path: 'clickAndCollect',
			component: () =>
				import(
					/* webpackChunkName: "checkout" */ '~/components/organisms/order-process/click-and-collect/click-and-collect.vue'
				).then((m) => m.default || m),
			meta: {
				checkoutStep: routingCheckoutSteps.CLICK_AND_COLLECT,
				headline: i18nKeys.title.checkout.clickAndCollect,
			},
		},
	],
};
