import _get from 'lodash-es/get';
import { ActionContext, Store } from 'vuex';
import Logger from '@/node_modules/@osp/utils/src/logger';
import {
	CLUB_A_ABORT,
	CLUB_A_FETCH_STATUS,
	CLUB_A_GET_PROCESS_STATE,
	CLUB_A_REGISTER,
	CLUB_A_SET_CURRENT_STEP,
	CLUB_A_START_CLUBPROCESS,
	CLUB_M_RESET_STATE,
	CLUB_M_SET_CLUBID,
	CLUB_M_SET_CURRENT_STEP,
	CLUB_M_STORE_CLUB_PROCESS_STATE,
	CLUB_M_STORE_STATUS,
	mapFn,
} from '~/@constants/store';
import { get, getJson, postJson } from '~/app-utils/http';
import { CLUB_PROCESS } from '~/@constants/global';
import { backend } from '~/@api/backend';
import { useCartStore } from '~/@api/store/cartApi';
import { useLoadingStore } from '~/@api/store/loadingApi';
import { useMessageboxStore } from '~/@api/store/messageboxApi';
import { useUserStore } from '~/@api/store/userApi';
import { ClubState, ClubStep, MessageboxType, RootState } from '~/@api/store.types';
import { ClubInfo, ClubProcessState, ClubRegisterRequest } from '~/generated/hybris-raml-api';
import { clubDialog } from '~/tracking/events/club.dialog';

// Initial state -----------------------------------------------------------------------------------

const state = (): ClubState => ({
	process: {
		clubDiscount: null,
		clubId: null,
		currentStep: null,
		email: null,
		enabled: false,
		label: null,
		messages: [],
		new: null,
	},
	status: null,
});

// Mutations ---------------------------------------------------------------------------------------

const mutations = {
	[mapFn(CLUB_M_SET_CURRENT_STEP)](state: ClubState, currentStep: ClubStep) {
		state.process.currentStep = currentStep;
	},

	[mapFn(CLUB_M_SET_CLUBID)](state: ClubState, clubId: string) {
		state.process.clubId = clubId;
	},

	[mapFn(CLUB_M_STORE_CLUB_PROCESS_STATE)](
		state: ClubState,
		payload: { ramlState: ClubProcessState; new: boolean },
	) {
		state.process.clubDiscount = payload.ramlState.clubDiscount;
		state.process.clubId = payload.ramlState.clubId;
		state.process.email = payload.ramlState.email;
		state.process.enabled = payload.ramlState.enabled;
		state.process.label = payload.ramlState.label;
		state.process.messages = payload.ramlState.messages;
		state.process.new = payload.new;
		if (!payload.ramlState.enabled) {
			state.process.currentStep = null;
		}
	},

	[mapFn(CLUB_M_RESET_STATE)](_state: ClubState) {
		const initialState = state();

		Object.keys(initialState).forEach(
			(propertyKey) => (_state[propertyKey] = initialState[propertyKey]),
		);
	},

	[mapFn(CLUB_M_STORE_STATUS)](state: ClubState, clubStatus: ClubInfo) {
		state.status = clubStatus;
	},
};

// Actions -----------------------------------------------------------------------------------------

const actions = {
	async [mapFn(CLUB_A_START_CLUBPROCESS)](context: ActionContext<ClubState, RootState>) {
		if (!useUserStore(this).state.user.clubId) {
			await _withLoader(this, async () => {
				context.commit(mapFn(CLUB_M_RESET_STATE));
				history.replaceState(
					{
						...history.state,
						component: CLUB_PROCESS,
						data: {
							currentStep: null,
						},
					},
					'',
					window.location.href,
				);

				await context.dispatch(mapFn(CLUB_A_GET_PROCESS_STATE));

				if (context.state.process.enabled) {
					clubDialog(ClubStep.INITIALIZATION);

					if (context.state.process.clubId) {
						context.dispatch(mapFn(CLUB_A_SET_CURRENT_STEP), ClubStep.MATCHED);
					} else {
						context.dispatch(mapFn(CLUB_A_SET_CURRENT_STEP), ClubStep.NO_MATCHED);
					}
				}
			});
		}
	},

	// eslint-disable-next-line require-await
	async [mapFn(CLUB_A_SET_CURRENT_STEP)](
		context: ActionContext<ClubState, RootState>,
		currentStep: ClubStep,
	) {
		const newStep = context.state.process.enabled ? currentStep : null;

		if (
			context.state.process.currentStep &&
			(newStep || context.state.process.currentStep === ClubStep.CONFIRMATION)
		) {
			clubDialog(context.state.process.currentStep, true, newStep);
		}

		if (context.state.process.currentStep !== newStep) {
			context.commit(mapFn(CLUB_M_SET_CURRENT_STEP), newStep);
			_pushState(newStep, context.state.process.clubId);
			useMessageboxStore(this).api.dismissAll(CLUB_PROCESS);
		}
	},

	[mapFn(CLUB_A_REGISTER)](
		context: ActionContext<ClubState, RootState>,
		payload: { clubId: string; new: boolean; email?: string },
	) {
		const request: ClubRegisterRequest = {
			clubId: payload.clubId,
			new: payload.new,
			email: payload.email || context.state.process.email,
		};
		return _withLoader(this, async () => {
			const response = await postJson(backend.API.V2.CLUB.REGISTER(this), request, this, {}, true);
			const responseBody: ClubProcessState = await response.json();

			if (context.state.process.currentStep !== ClubStep.ADD_NUMBER) {
				_showMessages(this, responseBody);
			}

			context.commit(mapFn(CLUB_M_STORE_CLUB_PROCESS_STATE), {
				ramlState: responseBody,
				new: payload.new,
			});

			if (response.ok) {
				context.dispatch(mapFn(CLUB_A_SET_CURRENT_STEP), ClubStep.CONFIRMATION);
				await useCartStore(this).api.update(false, true, true);
				await useUserStore(this).api.update();
				await context.dispatch(mapFn(CLUB_A_FETCH_STATUS), context.dispatch);
				return responseBody;
			}
		});
	},

	async [mapFn(CLUB_A_ABORT)](context: ActionContext<ClubState, RootState>) {
		await _withLoader(this, async () => {
			const { api: userApi, state: userState } = useUserStore(this);

			if (!userState.user.clubId) {
				await get(backend.API.V2.CLUB.ABORT(this), {
					store: this,
				});
				await useCartStore(this).api.update(false, true, true);
				await userApi.update();
				clubDialog(context.state.process.currentStep, false);
			}

			context.dispatch(mapFn(CLUB_A_SET_CURRENT_STEP), null);
		});
	},

	async [mapFn(CLUB_A_FETCH_STATUS)](context: ActionContext<ClubState, RootState>) {
		try {
			const response = await getJson(backend.API.V2.CLUB.STATUS(this), this);
			const status = response?.json;

			context.commit(mapFn(CLUB_M_STORE_STATUS), status);

			return status;
		} catch (error) {
			Logger.error(`Error while fetching club status: ${error}`);
		}
	},

	async [mapFn(CLUB_A_GET_PROCESS_STATE)](context: ActionContext<ClubState, RootState>) {
		try {
			const response: ClubProcessState = (await getJson(backend.API.V2.CLUB.START(this), this))
				.json;

			context.commit(mapFn(CLUB_M_STORE_CLUB_PROCESS_STATE), { ramlState: response });

			return response;
		} catch (error) {
			Logger.error(`Error while fetching process state: ${error}`);
		}
	},
};

export default {
	state,
	mutations,
	actions,
};

// Helpers -----------------------------------------------------------------------------------------

function _pushState(step, clubId?) {
	if (_get(history.state, 'data.currentStep', null) !== step) {
		history.pushState(
			{
				component: CLUB_PROCESS,
				data: {
					clubId,
					currentStep: step,
				},
			},
			step,
			window.location.href,
		);
	}
}

function _showMessages(store: Store<RootState>, response: ClubProcessState) {
	(response.messages || []).forEach((message) =>
		useMessageboxStore(store).api.addMessage({
			dismissible: false,
			key: CLUB_PROCESS,
			text: message.message,
			type: MessageboxType[message.severity],
		}),
	);
}

async function _withLoader(store: Store<RootState>, action: () => Promise<any>) {
	const { api: loadingApi } = useLoadingStore(store);

	try {
		await loadingApi.setLoading(CLUB_PROCESS, true);

		return await action();
	} finally {
		await loadingApi.setLoading(CLUB_PROCESS, false);
	}
}
