import Vue from 'vue';
import { ActionContext, Store } from 'vuex';
import VueRouter from 'vue-router';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { setSafeTimeout } from '~/../design-system/assets/js/utilities/timeout';
import { RootState, OverlayState, OverlayRegisterPayload } from '~/@api/store.types';
import {
	mapFn,
	OVERLAY_G_OPEN,
	OVERLAY_G_ANY_OPEN,
	OVERLAY_G_WITH_HISTORY,
	OVERLAY_M_REGISTER,
	OVERLAY_M_REMOVE,
	OVERLAY_M_OPEN,
	OVERLAY_M_CLOSE,
	OVERLAY_A_OPEN,
	OVERLAY_A_CLOSE,
	OVERLAY_A_TOGGLE,
	OVERLAY_A_REGISTER,
} from '~/@constants/store';

// Initial state -----------------------------------------------------------------------------------

const state = () => ({});

// Mutations ---------------------------------------------------------------------------------------

const mutations = {
	[mapFn(OVERLAY_M_REGISTER)](_state: OverlayState, payload: OverlayRegisterPayload) {
		Vue.set(_state, payload.id, {
			history: payload.history,
			open: false,
		});
	},

	[mapFn(OVERLAY_M_REMOVE)](_state: OverlayState, id: string) {
		clearBodyScrollLock(id);
		Vue.delete(_state, id);
	},

	[mapFn(OVERLAY_M_OPEN)](_state: OverlayState, id: string) {
		if (!id || !_state[id]) return;

		_state[id].open = true;

		pushHistory(_state, id, (this as Store<RootState>).$router);
	},

	[mapFn(OVERLAY_M_CLOSE)](_state: OverlayState, id: string) {
		if (!id || !_state[id] || !_state[id].open) return;

		_state[id].open = false;
	},
};

// Actions -----------------------------------------------------------------------------------------

const actions = {
	[mapFn(OVERLAY_A_OPEN)](context: ActionContext<OverlayState, RootState>, id: string) {
		if (!id || !context.state[id]) return;

		if (!context.state[id].open) {
			context.commit(mapFn(OVERLAY_M_OPEN), id);
			setSafeTimeout(() => {
				const element = getOverlayElement(id);

				if (element) {
					disableBodyScroll(element);
				}
			});
		}
	},

	[mapFn(OVERLAY_A_CLOSE)](context: ActionContext<OverlayState, RootState>, id: string) {
		if (!id || !context.state[id]) return;

		if (context.state[id].open) {
			clearBodyScrollLock(id);
			context.commit(mapFn(OVERLAY_M_CLOSE), id);
		}
	},

	[mapFn(OVERLAY_A_TOGGLE)](context: ActionContext<OverlayState, RootState>, id: string) {
		if (!id || !context.state[id]) return;

		if (context.state[id].open) {
			context.dispatch(mapFn(OVERLAY_A_CLOSE), id);
		} else {
			context.dispatch(mapFn(OVERLAY_A_OPEN), id);
		}
	},

	[mapFn(OVERLAY_A_REGISTER)](
		context: ActionContext<OverlayState, RootState>,
		payload: OverlayRegisterPayload,
	) {
		if (!context.state[payload.id]) {
			context.commit(mapFn(OVERLAY_M_REGISTER), payload);
		}

		if (payload.open) {
			context.dispatch(mapFn(OVERLAY_A_OPEN), payload.id);
		}
	},
};

// Getters -----------------------------------------------------------------------------------------

const getters = {
	[mapFn(OVERLAY_G_OPEN)](_state: OverlayState) {
		return (overlayId: string): boolean => (_state[overlayId] ? _state[overlayId].open : false);
	},

	[mapFn(OVERLAY_G_ANY_OPEN)](_state: OverlayState): boolean {
		return Object.values(_state).some((overlay) => overlay.open);
	},

	[mapFn(OVERLAY_G_WITH_HISTORY)](_state: OverlayState) {
		return (overlayId: string): boolean => (_state[overlayId] ? _state[overlayId].history : false);
	},
};

export default {
	state,
	mutations,
	actions,
	getters,
};

// Helpers -----------------------------------------------------------------------------------------

const pushHistory = (state, id, router: VueRouter) => {
	if (
		process.client &&
		state[id].history &&
		(!history.state ||
			history.state?.component !== 'OverlayLegacy' ||
			history.state?.data.overlayId !== id)
	) {
		history.pushState(
			{
				component: 'OverlayLegacy',
				data: {
					overlayId: id,
				},
			},
			'',
			router.currentRoute?.fullPath || '',
		);
	}
};

const clearBodyScrollLock = (id: string) => {
	const element = getOverlayElement(id);

	if (element) {
		enableBodyScroll(element);
	} else {
		clearAllBodyScrollLocks();
	}
};

const getOverlayElement = (id: string) => {
	return process.client ? document.querySelector(`#${id} [data-scroll]`) : null;
};
