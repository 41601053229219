import type { Store } from 'vuex';
import { RouteRecord } from 'vue-router';
import { stringify } from '@/node_modules/@osp/design-system/assets/js/utilities/stringify';
import { useRoutingStore } from '~/@api/store/routingApi';
import { useServerContextStore } from '~/@api/store/serverContextApi';
import { PageTypes, RootState } from '~/@api/store.types';
import { DynamicYieldContextType } from '~/@constants/global';
import { SpaDigitalDatum } from '~/generated/hybris-raml-api';

export const replaceParams = ({
	url,
	params,
}: {
	url: string;
	params: { [_k: string]: string };
}) => {
	return Object.entries(params || {}).reduce(
		(result, param) => result.replace(param[0], param[1]),
		url,
	);
};

export const getTrackingPreconnects = (store: Store<RootState>) =>
	useServerContextStore(store).state.session.trackingEnabled
		? [
				// Tracking enabled
				{ rel: 'preconnect', href: 'https://www.googletagmanager.com' },
				...getDynamicYieldPreconnects(store),
			]
		: [];

const getDynamicYieldPreconnects = (store: Store<RootState>) =>
	isDYEnabledForPage(store)
		? [
				// GTM enabled
				{ rel: 'preconnect', href: '//cdn-eu.dynamicyield.com' },
				{ rel: 'preconnect', href: '//st-eu.dynamicyield.com' },
				{ rel: 'preconnect', href: '//rcom-eu.dynamicyield.com' },
				{ rel: 'preconnect', href: '//async-px-eu.dynamicyield.com' },
				{ rel: 'dns-prefetch', href: '//cdn-eu.dynamicyield.com' },
				{ rel: 'dns-prefetch', href: '//st-eu.dynamicyield.com' },
				{ rel: 'dns-prefetch', href: '//rcom-eu.dynamicyield.com' },
				{ rel: 'dns-prefetch', href: '//async-px-eu.dynamicyield.com' },
			]
		: [];

export const getDynamicYieldScripts = (vm: Vue) => {
	const { state: serverContextState } = useServerContextStore(vm.$store);

	return isDYEnabledForPage(vm.$store)
		? [
				{
					hid: 'DY_recommendationContext',
					type: 'text/javascript',
					innerHTML: `
						window.DY = window.DY || {};
						DY.recommendationContext = ${stringify(getDYPageContext(vm.$route.matched, vm.$store))};
					`,
				},
				{
					src: `//cdn-eu.dynamicyield.com/api/${serverContextState.session.dynamicYieldSectionId}/api_dynamic.js`,
					type: 'text/javascript',
					async: serverContextState.session.dynamicYieldAsync,
				},
				{
					src: `//cdn-eu.dynamicyield.com/api/${serverContextState.session.dynamicYieldSectionId}/api_static.js`,
					type: 'text/javascript',
					async: serverContextState.session.dynamicYieldAsync,
				},
			]
		: [];
};

export const isDYEnabledForPage = (store: Store<RootState>) => {
	const { state: serverContextState } = useServerContextStore(store);

	return (
		serverContextState.session.trackingEnabled &&
		serverContextState.session.googleTagManagerEnabled &&
		![PageTypes.CLUB_REGISTER_SHOP, PageTypes.CLUB_REGISTER_POINT_OF_SALE].includes(
			useRoutingStore(store).state.pageType,
		)
	);
};

export const getDYPageContext = (routeMatched: RouteRecord[], store: Store<RootState>) => {
	const type = getDYContextType(routeMatched);

	return {
		data: getDYData(useRoutingStore(store).state.spaData.datalayer, type),
		lng: `${useServerContextStore(store).state.session.language}_CH`,
		type,
	};
};

const getDYContextType = (routeRecords: RouteRecord[]) =>
	[...(routeRecords || [])].reverse().find((route) => route.meta?.dynamicYieldContext)?.meta
		.dynamicYieldContext?.type || DynamicYieldContextType.Other;

const getDYData = (datalayer: SpaDigitalDatum, type: DynamicYieldContextType) => {
	switch (type) {
		case DynamicYieldContextType.Category:
			return datalayer?.page?.pageInfo?.breadCrumbs?.slice(1) ?? [];
		case DynamicYieldContextType.Product:
			return [datalayer?.product?.productInfo?.productNr];
		case DynamicYieldContextType.Cart:
			return datalayer?.cart?.item?.map((entry) => entry.productInfo.productNr) ?? [];
		default:
			return [];
	}
};
