import type { Store } from 'vuex';
import { clientInitStoreModule, useStoreModule } from '../useStoreModule';
import trackingStore from './tracking.store';
import trackingApi from './tracking.api';
import { RootState, TrackingState } from '~/@api/store.types';
import { TRACKING } from '~/@constants/store';

export function useTrackingStore(store: Store<RootState>) {
	return useStoreModule<TrackingState, ReturnType<typeof trackingApi>>(
		TRACKING,
		store,
		trackingStore,
		trackingApi,
	);
}

export async function clientInitTrackingStore(store: Store<RootState>): Promise<void> {
	return await clientInitStoreModule(TRACKING, store, trackingStore, trackingApi);
}
