

export default () => {
    if (process.server) {
        return;
    }

            const polyfill = require("console-polyfill");

            console.log('Load ' + "console-polyfill");
}