import { addToCart } from './cart.addEntry';
import { removeFromCart } from './cart.removeEntry';
import { SpaDigitalDataProduct } from '~/generated/hybris-raml-api';
import { mapProduct } from '~/tracking/data-mapper/product-mapper';

export const changeCartEntryQuantity = (
	datalayerProduct: SpaDigitalDataProduct,
	quantityAdded: number,
) => {
	if (quantityAdded > 0) {
		addToCart(
			mapProduct(datalayerProduct),
			quantityAdded,
			datalayerProduct?.attributes?.currency || 'CHF',
		);
	} else if (quantityAdded < 0) {
		removeFromCart(datalayerProduct, quantityAdded * -1);
	}
};
