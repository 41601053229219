// A pretty simple way to get the URL-Parameters.
// Use this instead of the URLSearchParamPolyfill since this is way smaller
export interface URLParameters {
	[name: string]: any;
}

export function getQueryParameters(url: string = window.location.href): URLParameters {
	const parameters: URLParameters = {};
	const queryString = url.split('?')[1];

	(queryString || '').split('&').forEach((paramString: string) => {
		const parameter = paramString.split('=');
		const name = parameter[0].toLowerCase();
		// Set parameter name and value (use 'true' if empty)
		let value = typeof parameter[1] === 'undefined' ? true : parameter[1];

		if (typeof value === 'string') {
			value = decodeURIComponent(value);
		}

		parameters[name] = value;
	});

	return parameters;
}
