import type { Store } from 'vuex';
import { clientInitStoreModule, useStoreModule } from '../useStoreModule';
import uxStore from './ux.store';
import uxApi from './ux.api';
import { RootState, UxState } from '~/@api/store.types';
import { UX } from '~/@constants/store';

export function useUxStore(store: Store<RootState>) {
	return useStoreModule<UxState, ReturnType<typeof uxApi>>(UX, store, uxStore, uxApi);
}

export async function clientInitUxStore(store: Store<RootState>): Promise<void> {
	return await clientInitStoreModule(UX, store, uxStore, uxApi);
}
