import { Context } from '@nuxt/types';
import { elapsedTime } from '@/node_modules/@osp/utils/src/performance';
import { isSearchPage, isPaginationRequest } from './search';
import { useRoutingStore } from '~/@api/store/routingApi';
import { getPageTypeMetaInfoFromRoute } from '~/routing/utils/spa-utils';

export default async (context: Context) => {
	const spaKey = getPageTypeMetaInfoFromRoute(context.store, context.route);

	if (
		spaKey?.spaType?.length > 0 &&
		spaKey.identifier?.length > 0 &&
		(!isSearchPage(context) || !isPaginationRequest(context))
	) {
		await useRoutingStore(context.store).api.updateDatalayer(spaKey.spaType, spaKey.identifier);
	}

	elapsedTime('middleware: update-datalayer');
};
