import { SpaOtherId, SpaType } from '@/generated/hybris-raml-api';
import { PageTypes } from '~/@api/store.types';

export const newsletterRoutes = (defaultPath: string) => [
	{
		path: `${defaultPath}/kundendienst/newsletter`,
		component: () =>
			import('~/layouts/default-route/default-route.vue').then((m) => m.default || m),
		meta: {
			routeType: 'template',
			spaType: SpaType.other,
		},
		children: [
			{
				path: 'subscribe.html',
				component: () =>
					import('~/pages/newsletter-subscribe-page/newsletter-subscribe-page.vue').then(
						(m) => m.default || m,
					),
				meta: {
					pageType: PageTypes.NEWSLETTER,
					spaOtherId: SpaOtherId.newsletter,
				},
			},
			{
				path: 'global-unsubscribe.html',
				component: () =>
					import(
						'~/pages/newsletter-global-unsubscribe-page/newsletter-global-unsubscribe-page.vue'
					).then((m) => m.default || m),
				meta: {
					pageType: PageTypes.NEWSLETTER_GLOBAL_UNSUBSCRIBE,
					spaOtherId: SpaOtherId.newsletter_global_unsubscribe,
				},
			},
			{
				path: 'unsubscribe.html',
				component: () =>
					import('~/pages/newsletter-unsubscribe-page/newsletter-unsubscribe-page.vue').then(
						(m) => m.default || m,
					),
				meta: {
					pageType: PageTypes.NEWSLETTER_UNSUBSCRIBE,
					spaOtherId: SpaOtherId.newsletter_unsubscribe,
				},
			},
			{
				path: 'unsubscribe-club.html',
				component: () =>
					import(
						'~/pages/newsletter-unsubscribe-club-page/newsletter-unsubscribe-club-page.vue'
					).then((m) => m.default || m),
				meta: {
					pageType: PageTypes.NEWSLETTER_UNSUBSCRIBE_CLUB,
					spaOtherId: SpaOtherId.newsletter_unsubscribe_club,
				},
			},
		],
	},
];
