import { track } from '../tracking';
import { PromotionTrackingParam } from './eec.promotionClick';

export const eecPromotionView = (promotions: PromotionTrackingParam[]) => {
	if (promotions && promotions.length > 0) {
		track('eec.promotionView', {
			ecommerce: {
				promoView: { promotions },
			},
		});
	}
};
