import { stringify } from '@/node_modules/@osp/design-system/assets/js/utilities/stringify';
import { CartSessionData } from './types';
import { Product } from '@/generated/hybris-raml-api';

const STORAGE_KEY = 'ospCartSessionData';

export const addProduct = (product, additionalData?) => {
	if (process.client) {
		const cartSessionData = getSessionData();

		cartSessionData[product.variant] = { category: product.category, ...additionalData };

		(window as any).osp.localStorage.setItem(STORAGE_KEY, stringify(cartSessionData));
	}
};

export const getSessionData = (): CartSessionData =>
	(process.client &&
		(JSON.parse((window as any).osp.localStorage.getItem(STORAGE_KEY)) as CartSessionData)) ||
	{};

export const getSessionProduct = (product: Product) => {
	const masterProduct = product.colorVariants[product.selectedVariant[0]];
	const variantProduct =
		product.selectedVariant.length > 1
			? masterProduct.sizeVariants[product.selectedVariant[1]]
			: masterProduct;

	return getSessionData()[variantProduct.code];
};
