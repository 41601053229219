import { Route } from 'vue-router';
import { Context } from '@nuxt/types';
import { elapsedTime } from '@/node_modules/@osp/utils/src/performance';
import { CheckoutStep } from '@/generated/hybris-raml-api';
import { useMessageboxStore } from '~/@api/store/messageboxApi';
import { PageTypes } from '~/@api/store.types';

// eslint-disable-next-line require-await
export default async (context: Context) => {
	const currentCheckoutStep = getMetaFromRoute(context.route, 'checkoutStep');
	const isFromCheckout =
		getMetaFromRoute(context.from, 'checkoutStep') ||
		getMetaFromRoute(context.from, 'pageType') === PageTypes.CART;
	const { api: messageboxApi } = useMessageboxStore(context.store);

	if (currentCheckoutStep || isFromCheckout) {
		// If we navigate within the checkout, dismiss all messages from other steps
		// Or if we leave the checkout, dismiss all messages from steps
		Object.keys(CheckoutStep)
			.filter((step) => step !== currentCheckoutStep?.code)
			.forEach((step) => messageboxApi.dismissAll(`checkout-${step}`));
	}

	// If we leave the checkout, dismiss all global checkout messages
	if (!currentCheckoutStep && isFromCheckout) {
		messageboxApi.dismissAll('checkout');
	}

	if (context.from) {
		// Dissmiss backend messages if routing from SPA-Page to anouther SPA-page.
		// Do not dismiss if its the initial route
		messageboxApi.dismissAll('backend');
	}

	elapsedTime('middleware: reset-messages');
};

const getMetaFromRoute = (route: Route, meta: string) =>
	[...(route?.matched || [])].find((route) => route.meta && route.meta[meta])?.meta[meta];
