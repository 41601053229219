import type { Store } from 'vuex';
import { clientInitStoreModule, useStoreModule } from '../useStoreModule';
import routingStore from './routing.store';
import routingApi from './routing.api';
import { RootState, RoutingState } from '~/@api/store.types';
import { ROUTING } from '~/@constants/store';

export function useRoutingStore(store: Store<RootState>) {
	return useStoreModule<RoutingState, ReturnType<typeof routingApi>>(
		ROUTING,
		store,
		routingStore,
		routingApi,
	);
}

export async function clientInitRoutingStore(store: Store<RootState>): Promise<void> {
	return await clientInitStoreModule(ROUTING, store, routingStore, routingApi);
}
