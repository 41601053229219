import type { Store } from 'vuex';
import { clientInitStoreModule, useStoreModule } from '../useStoreModule';
import clubStore from './club.store';
import clubApi from './club.api';
import { ClubState, RootState } from '~/@api/store.types';
import { CLUB } from '~/@constants/store';

export function useClubStore(store: Store<RootState>) {
	return useStoreModule<ClubState, ReturnType<typeof clubApi>>(CLUB, store, clubStore, clubApi);
}

export async function clientInitClubStore(store: Store<RootState>): Promise<void> {
	return await clientInitStoreModule(CLUB, store, clubStore, clubApi);
}
