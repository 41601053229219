import { yieldToMain } from '@/node_modules/@osp/design-system/assets/js/utilities/runTask';
import {
	mapFn,
	UX_G_INTERACTED,
	UX_G_NUXT_IS_READY,
	UX_G_PAGE_SWITCH_HOOK_ACTIVE,
	UX_G_PAGE_SWITCH_IN_PROGRESS,
	UX_M_INTERACT,
	UX_M_NUXTREADY,
	UX_M_CMP_CHOICE_EXISTS,
	UX_M_PAGE_SWITCH_STATUS,
	UX_M_SET_FOOTER_VISIBLE,
	UX_M_SET_PAGE_SWITCH_HOOK_ACTIVE,
} from '~/@constants/store';
import { UxState } from '~/@api/store.types';

// Initial state -----------------------------------------------------------------------------------

const state = (): UxState => ({
	userInteracted: false,
	cmpUserChoiceExists: false,
	nuxtReady: false,
	pageSwitchInProgress: false,
	isPageSwitchHookActive: true,
	displayFooter: true,
});

// Mutations ---------------------------------------------------------------------------------------

const mutations = {
	[mapFn(UX_M_INTERACT)](state: UxState, payload: boolean) {
		state.userInteracted = payload;
	},
	[mapFn(UX_M_NUXTREADY)](state: UxState, payload: boolean) {
		state.nuxtReady = payload;
		yieldToMain();
	},
	[mapFn(UX_M_CMP_CHOICE_EXISTS)](state: UxState, payload: boolean) {
		state.cmpUserChoiceExists = payload;
	},
	[mapFn(UX_M_PAGE_SWITCH_STATUS)](state: UxState, payload: boolean) {
		state.pageSwitchInProgress = payload;
	},
	[mapFn(UX_M_SET_PAGE_SWITCH_HOOK_ACTIVE)](state: UxState, payload: boolean) {
		state.isPageSwitchHookActive = payload;
	},
	[mapFn(UX_M_SET_FOOTER_VISIBLE)](state: UxState, payload: boolean) {
		state.displayFooter = payload;
	},
};

// Getters -----------------------------------------------------------------------------------------

const getters = {
	[mapFn(UX_G_INTERACTED)](_state: UxState): boolean {
		return _state.userInteracted;
	},
	[mapFn(UX_G_NUXT_IS_READY)](_state: UxState): boolean {
		return _state.nuxtReady;
	},
	[mapFn(UX_G_PAGE_SWITCH_IN_PROGRESS)](_state: UxState): boolean {
		return _state.pageSwitchInProgress;
	},
	[mapFn(UX_G_PAGE_SWITCH_HOOK_ACTIVE)](_state: UxState): boolean {
		return _state.isPageSwitchHookActive;
	},
};

export default {
	state,
	mutations,
	getters,
};
