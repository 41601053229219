import { ActionContext, Store } from 'vuex';
import _find from 'lodash-es/find';
import _cloneDeep from 'lodash-es/cloneDeep';
import _values from 'lodash-es/values';
import VueRouter from 'vue-router';
import { yieldToMain } from '@/node_modules/@osp/design-system/assets/js/utilities/runTask';
import {
	mapFn,
	SEARCHUNSAVED_A_SAVE_FACET,
	SEARCHUNSAVED_A_SAVE_FACETS,
	SEARCHUNSAVED_G_CHANGED,
	SEARCHUNSAVED_M_RESET_UNSAVED_FACET,
	SEARCHUNSAVED_M_RESET_UNSAVED_FACETS,
	SEARCHUNSAVED_M_SET_PRICE_VALUE,
	SEARCHUNSAVED_M_TOGGLE_FACET_VALUE,
} from '~/@constants/store';
import { createSearchUrl, getSelectedFacets } from '~/app-utils/search.utils';
import { useCmsContentStore } from '~/@api/store/cmsContentApi';
import { useLoadingStore } from '~/@api/store/loadingApi';
import { useRoutingStore } from '~/@api/store/routingApi';
import { useSearchStore } from '~/@api/store/searchApi';
import { useUserStore } from '~/@api/store/userApi';
import { RootState, UnsavedSearchFacet, UnsavedSearchState } from '~/@api/store.types';
import { SearchFacet, SearchRequestResponse } from '~/generated/hybris-raml-api';
import { getPageTypeMetaInfoFromRoute } from '~/routing/utils/spa-utils';
import { customPageView } from '~/tracking/events/customPageview';

// Initial state -----------------------------------------------------------------------------------

const state = () => ({
	facets: {},
});

// Mutations ---------------------------------------------------------------------------------------

const mutations = {
	[mapFn(SEARCHUNSAVED_M_RESET_UNSAVED_FACET)](_state: UnsavedSearchState, _facet: SearchFacet) {
		const facet: SearchFacet = _find(useSearchStore(this).state.response.facets, {
			code: _facet.code,
		});

		_state.facets = {
			..._state.facets,
			[facet.code]: {
				...facet,
				changed: 0,
				values: facet.values.filter(
					(value: any) => value.selected || value.selectedMin || value.selectedMax,
				),
			},
		};
	},

	async [mapFn(SEARCHUNSAVED_M_RESET_UNSAVED_FACETS)](_state: UnsavedSearchState) {
		const facets = _cloneDeep(
			getSelectedFacets(useSearchStore(this).state.response.facets, {}) as {},
		);

		Object.values(facets).forEach((facet: UnsavedSearchFacet) => (facet.changed = 0));

		_state.facets = facets;

		await yieldToMain();
	},

	[mapFn(SEARCHUNSAVED_M_SET_PRICE_VALUE)](
		_state: UnsavedSearchState,
		payload: { facet: SearchFacet; selectedMin: number; selectedMax: number },
	) {
		_modifyUnsavedFacetValue(_state, payload.facet.code, payload.facet.code, (unsavedValue) => {
			unsavedValue.selectedMin =
				payload.selectedMin === undefined ? unsavedValue.selectedMin : payload.selectedMin;
			unsavedValue.selectedMax =
				payload.selectedMax === undefined ? unsavedValue.selectedMax : payload.selectedMax;
			unsavedValue.selected =
				Math.floor(unsavedValue.selectedMin) !== Math.floor(unsavedValue.absoluteMin) ||
				Math.ceil(unsavedValue.selectedMax) !== Math.ceil(unsavedValue.absoluteMax);
			unsavedValue.changed = true;
		});
	},

	[mapFn(SEARCHUNSAVED_M_TOGGLE_FACET_VALUE)](
		_state: UnsavedSearchState,
		payload: { facet: SearchFacet; value: any },
	) {
		_modifyUnsavedFacetValue(_state, payload.facet.code, payload.value.code, (unsavedValue) => {
			unsavedValue.selected = !unsavedValue.selected;
			unsavedValue.changed = !unsavedValue.changed;
		});
	},
};

// Actions -----------------------------------------------------------------------------------------

const actions = {
	async [mapFn(SEARCHUNSAVED_A_SAVE_FACET)](
		context: ActionContext<UnsavedSearchState, RootState>,
		unsavedFacet: SearchFacet,
	) {
		const { api: loadingApi } = useLoadingStore(this);

		loadingApi.setLoading('unsavedSearchModule.saveFacet', true);

		const request = useSearchStore(this).api.newSearchRequest();
		const currentPage = request?.pagination?.page || 1;

		await _saveFacetsInternal(context, this, (this as Store<RootState>).$router, {
			...request,
			facets: [
				...request?.facets?.filter((facet) => facet.code !== unsavedFacet.code),
				context.state.facets[unsavedFacet.code],
			],
			pagination: {
				...request?.pagination,
				page: 1,
			},
		});
		loadingApi.setLoading('unsavedSearchModule.saveFacet', false);

		if (currentPage > 1) {
			customPageView();
		}
	},

	async [mapFn(SEARCHUNSAVED_A_SAVE_FACETS)](
		context: ActionContext<UnsavedSearchState, RootState>,
	) {
		const { api: loadingApi } = useLoadingStore(this);

		loadingApi.setLoading('unsavedSearchModule.saveFacets', true);
		const request = useSearchStore(this).api.newSearchRequest();
		const currentPage = request?.pagination?.page || 1;

		await _saveFacetsInternal(context, this, (this as Store<RootState>).$router, {
			...request,
			facets: [
				...request?.facets?.filter((facet) => context.state.facets[facet.code] === undefined),
				..._values(context.state.facets),
			],
			pagination: {
				...request.pagination,
				page: 1,
			},
		});
		loadingApi.setLoading('unsavedSearchModule.saveFacets', false);

		if (currentPage > 1) {
			customPageView();
		}
	},
};

// Getters -----------------------------------------------------------------------------------------

const getters = {
	[mapFn(SEARCHUNSAVED_G_CHANGED)](_state: UnsavedSearchState) {
		return Object.values(_state.facets).filter((facet) => facet.changed).length > 0;
	},
};

export default {
	state,
	mutations,
	actions,
	getters,
};

// Helpers -----------------------------------------------------------------------------------------

const _modifyUnsavedFacetValue = (
	_state: UnsavedSearchState,
	facetCode: string,
	valueCode: string,
	valueModification: (facetValue) => void,
) => {
	const unsavedFacet = _state.facets[facetCode];
	let facetValue = {
		changed: false,
		code: valueCode,
		selected: false,
	};

	if (unsavedFacet && facetCode !== 'navigation') {
		if (facetCode === 'gender') {
			unsavedFacet.values = [facetValue];
		} else {
			const unsavedValue: any = _find(unsavedFacet.values, { code: valueCode });

			if (unsavedValue) {
				facetValue = unsavedValue;
			} else {
				unsavedFacet.values.push(facetValue);
			}
		}

		valueModification(facetValue);

		unsavedFacet.changed = unsavedFacet.values.filter((value: any) => value.changed).length;
		_state.facets = {
			..._state.facets,
		};
	} else {
		valueModification(facetValue);
		_state.facets = {
			..._state.facets,
			[facetCode]: {
				changed: 1,
				code: facetCode,
				name: facetCode,
				values: [facetValue],
			},
		};
	}
};

const _saveFacetsInternal = async (
	context: ActionContext<UnsavedSearchState, RootState>,
	store: Store<RootState>,
	router: VueRouter,
	request: SearchRequestResponse,
) => {
	const { api: routingApi } = useRoutingStore(store);
	const gender = context.state.facets.gender || ([] as any);

	if (gender.changed) {
		const selectedGender = (gender.values || []).find((value) => value.selected);
		const spaKey = getPageTypeMetaInfoFromRoute(store, router.currentRoute);

		if (selectedGender) {
			const { api: userApi } = useUserStore(store);

			await userApi.update();
			await userApi.setGender(selectedGender.code);
			useCmsContentStore(store).api.update(spaKey.spaType, spaKey.identifier, true);
		}
	}

	const url = createSearchUrl(store, router.currentRoute, request);
	await routingApi.navigate(url);
	routingApi.updateDatalayer();
};
