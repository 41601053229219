import { SpaOtherId, SpaType } from '@/generated/hybris-raml-api';
import { PageTypes } from '~/@api/store.types';

export const productReleaseCalendarRoutes = (defaultPath: string) => [
	{
		path: `${defaultPath}/product-release-calendar`,
		component: () =>
			import('~/layouts/default-route/default-route.vue').then((m) => m.default || m),
		meta: {
			routeType: 'template',
			spaType: SpaType.other,
		},
		children: [
			{
				path: '',
				component: () =>
					import('~/pages/product-release-calendar-page/product-release-calendar-page.vue').then(
						(m) => m.default || m,
					),
				meta: {
					pageType: PageTypes.PRODUCT_RELEASE_CALENDAR,
					spaOtherId: SpaOtherId.product_release_calendar,
				},
			},
		],
	},
];
