// Blocking time optimized aca recommendations from
// https://github.com/nuxt/nuxt.js/discussions/9061#discussioncomment-539483
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { Context } from '@nuxt/types';
import { i18nKeys } from '@/generated/translations';
import { clientInitI18nStore, useI18nStore } from '~/@api/store/i18nApi';
import { clientInitServerContextStore, useServerContextStore } from '~/@api/store/serverContextApi';

Vue.use(VueI18n);

let mergedI18nKeys = null;

Vue.mixin({
	computed: {
		$i18nKeys(): unknown {
			if (mergedI18nKeys) {
				return mergedI18nKeys;
			}

			const i18nApiKeys = transformI18nKeys(
				Object.keys(this.$store.state?.i18n?.messages?.de || {}),
			);

			if (i18nApiKeys && !mergedI18nKeys) {
				mergedI18nKeys = deepMerge(i18nKeys, i18nApiKeys);
			}

			return mergedI18nKeys || i18nKeys;
		},
	},
});

export default async function (context: Context) {
	if (process.server) {
		context.app.i18n = new VueI18n({
			locale: useServerContextStore(context.store).state.session.language,
			messages: useI18nStore(context.store).state.messages,
		});

		return;
	}

	await Promise.all([
		clientInitServerContextStore(context.store),
		clientInitI18nStore(context.store),
	]);

	context.app.i18n = new VueI18n({
		locale: useServerContextStore(context.store).state.session.language,
		messages: useI18nStore(context.store).state.messages,
	});
}

function transformI18nKeys(i18nKeys: string[]) {
	let i18nKeysObject = {};

	i18nKeys.forEach((i18nKey) => {
		i18nKeysObject = deepMerge(i18nKeysObject, dotstringToObject(i18nKey));
	});

	return i18nKeysObject;
}

function dotstringToObject(dotstring: string): Object {
	const tmpObj = {};
	const keys = dotstring.split('.');
	const lastIndex = keys.length - 1;

	keys.reduce(function (r, a, ix) {
		r[a] = r[a] || (ix === lastIndex ? dotstring : {});
		return r[a];
	}, tmpObj);

	return tmpObj;
}

function isObject(item: any) {
	return item !== null && typeof item === 'object' && !Array.isArray(item);
}

function deepMerge(target: unknown, ...sources: unknown[]) {
	if (!sources.length) return target;
	const source = sources.shift();

	if (isObject(target) && isObject(source)) {
		Object.keys(source).forEach((key) => {
			if (Object.prototype.hasOwnProperty.call(source, key)) {
				const sourceValue = source[key];
				if (isObject(sourceValue) && isObject(target[key])) {
					target[key] = deepMerge(target[key], sourceValue);
				} else {
					target[key] = sourceValue;
				}
			}
		});
	}

	return deepMerge(target, ...sources);
}
