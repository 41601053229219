import Vue from 'vue';
import { InstallPromptState } from '~/@api/store.types';
import {
	mapFn,
	INSTALLPROMPT_M_BEFORE_INSTALL,
	INSTALLPROMPT_M_PROMPT,
	INSTALLPROMPT_M_ACCEPT,
	INSTALLPROMPT_M_DISMISS,
} from '~/@constants/store';
import { useInstallpromptStore } from '~/@api/store/installpromptApi';

// Initial state -----------------------------------------------------------------------------------

const state = () => ({
	choiceResult: null,
	deferredPrompt: null,
});

// Mutations ---------------------------------------------------------------------------------------

const mutations = {
	[mapFn(INSTALLPROMPT_M_BEFORE_INSTALL)](_state: InstallPromptState, event: Event) {
		Vue.set(_state, 'deferredPrompt', event);
	},

	[mapFn(INSTALLPROMPT_M_PROMPT)](_state: InstallPromptState, _event: Event) {
		Vue.set(_state, 'installprompt', false);
		_state.deferredPrompt.prompt();
	},

	[mapFn(INSTALLPROMPT_M_ACCEPT)](_state: InstallPromptState) {
		Vue.set(_state, 'choiceResult', 'accepted');
		Vue.set(_state, 'deferredPrompt', null);
		useInstallpromptStore(this).api.trackPWA('prompt/accepted');
	},

	[mapFn(INSTALLPROMPT_M_DISMISS)](_state: InstallPromptState) {
		Vue.set(_state, 'choiceResult', 'dismissed');
		Vue.set(_state, 'deferredPrompt', null);
		useInstallpromptStore(this).api.trackPWA('prompt/dismissed');
	},
};

export default {
	state,
	mutations,
};
