import type { Store } from 'vuex';
import { CartStatusMessage, RootState } from '~/@api/store.types';
import {
	CART_A_ADD_ITEM,
	CART_A_CHANGE_SIZE,
	CART_A_MODIFY_ITEM_QUANTITY,
	CART_A_REMOVE_ITEM,
	CART_A_STORE_CART,
	CART_A_UPDATE,
	CART_A_UPDATE_INSTALLMENT_CONDITION,
	CART_A_UPDATE_TRUSTIES,
	CART_G_QUANTITY,
	CART_M_SAVE_MODIFICATION_STATUS,
	CART_M_STORE_TRUSTIES,
} from '~/@constants/store';
import { AddToCartRequest, Cart, Trusty } from '~/generated/hybris-raml-api';

export default function (store: Store<RootState>) {
	return {
		saveModificationStatus: (status: CartStatusMessage) =>
			store.commit(CART_M_SAVE_MODIFICATION_STATUS, status, { root: true }),

		storeTrusties: (trusties: Trusty[]) =>
			store.commit(CART_M_STORE_TRUSTIES, trusties, { root: true }),

		update: (updateExternalStocks = false, updateDatalayer = true, recalculate = false) =>
			store.dispatch(
				CART_A_UPDATE,
				{ recalculate, updateExternalStocks, updateDatalayer },
				{ root: true },
			),

		updateInstallmentCondition: () =>
			store.dispatch(CART_A_UPDATE_INSTALLMENT_CONDITION, null, { root: true }),

		updateTrusties: () => store.dispatch(CART_A_UPDATE_TRUSTIES, null, { root: true }),

		storeCart: (cart: Cart, updateDatalayer = true) =>
			store.dispatch(CART_A_STORE_CART, { cart, updateDatalayer }, { root: true }),

		addItem: (product: AddToCartRequest) =>
			store.dispatch(CART_A_ADD_ITEM, product, { root: true }),

		changeSize: (entryNumber: number, newSizeVariantCode: string) =>
			store.dispatch(CART_A_CHANGE_SIZE, {
				entryNumber,
				newSizeVariantCode,
			}),

		removeItem: (entryNumber: number) =>
			store.dispatch(CART_A_REMOVE_ITEM, entryNumber, { root: true }),

		modifyItemQuantity: (entryNumber: number, quantity: number) =>
			store.dispatch(CART_A_MODIFY_ITEM_QUANTITY, {
				entryNumber,
				quantity,
			}),

		quantity: () => store.getters[CART_G_QUANTITY],
	};
}
