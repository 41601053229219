import { Store } from 'vuex';
import { Context } from '@nuxt/types';
import {
	SERVERCONTEXT_M_INIT,
	SERVERCONTEXT_M_RESOLUTION,
	SERVERCONTEXT_M_SAVE_HYBRIS_SESSION,
} from '~/@constants/store';
import { Breakpoint, HybrisSession, RootState } from '~/@api/store.types';

export default function (store: Store<RootState>) {
	return {
		init: (serverCtx: Context) => store.commit(SERVERCONTEXT_M_INIT, serverCtx, { root: true }),

		storeHybrisSession: (hybrisSession: HybrisSession) =>
			store.commit(SERVERCONTEXT_M_SAVE_HYBRIS_SESSION, hybrisSession, { root: true }),

		setResolution: (resolution: Breakpoint) =>
			store.commit(SERVERCONTEXT_M_RESOLUTION, resolution, { root: true }),
	};
}
