import { PropType } from 'vue';
import { Props } from '../../types/core';
import { Text } from '../../types/text';
import { Link } from '../../types/link';
import { Media, MediaLoading, MediaObjectPosition } from '../../types/media';
import { TrackingEvents } from '../../types/tracking';

export enum PromitionTeaserVariant {
	SMALL = 'small',
	BIG = 'big',
}

// Model -------------------------------------------------------------------------------------------

export interface PromotionTeaserModel {
	link: Link;
	image: Media;
	imageObjectPosition: MediaObjectPosition;
	imageLoading?: MediaLoading;
	preHeadline?: Text;
	headline?: Text;
	subline?: Text;
	uid: string;
	variant?: PromitionTeaserVariant;
	overlayColor?: string;
	tracking?: TrackingEvents;
	previewImageOptimization?: boolean;
}

// Props -------------------------------------------------------------------------------------------

export const PromotionTeaserProps: Props<PromotionTeaserModel> = {
	uid: {
		type: String as PropType<PromotionTeaserModel['uid']>,
		required: true,
	},
	link: {
		type: Object as PropType<PromotionTeaserModel['link']>,
		required: true,
	},
	image: {
		type: Object as PropType<PromotionTeaserModel['image']>,
		required: true,
	},
	imageObjectPosition: {
		type: String as PropType<PromotionTeaserModel['imageObjectPosition']>,
		default: MediaObjectPosition.CENTER,
	},
	imageLoading: {
		type: String as PropType<PromotionTeaserModel['imageLoading']>,
		default: MediaLoading.LAZY,
	},
	preHeadline: {
		type: Object as PropType<PromotionTeaserModel['preHeadline']>,
	},
	headline: {
		type: Object as PropType<PromotionTeaserModel['headline']>,
	},
	subline: {
		type: Object as PropType<PromotionTeaserModel['subline']>,
	},
	variant: {
		type: String as PropType<PromotionTeaserModel['variant']>,
		default: PromitionTeaserVariant.SMALL,
	},
	overlayColor: {
		type: String as PropType<PromotionTeaserModel['overlayColor']>,
	},
	tracking: {
		type: Object as PropType<PromotionTeaserModel['tracking']>,
	},
	previewImageOptimization: {
		type: Boolean as PropType<PromotionTeaserModel['previewImageOptimization']>,
		default: false,
	},
};
