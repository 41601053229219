import { Route } from 'vue-router';
import { ActionContext, Store } from 'vuex';
import Logger from '@/node_modules/@osp/utils/src/logger';
import { elapsedTime } from '@/node_modules/@osp/utils/src/performance';
import { getPageTypeMetaInfoFromRoute } from '@/routing/utils/spa-utils';
import {
	mapFn,
	USER_A_CHANGE_EMAIL,
	USER_A_CHANGE_PASSWORD,
	USER_A_FORGOTTEN_PASSWORD,
	USER_A_GENDER,
	USER_A_GUEST_REGISTRATION,
	USER_A_LOGIN,
	USER_A_REGISTRATION,
	USER_A_UPDATE,
	USER_A_REMOVE_CREDIT_CARD,
	USER_G_HAS_ERROR,
	USER_M_SAVE,
	USER_M_SAVE_GENDER,
	USER_M_SAVE_RESPONSE,
} from '~/@constants/store';
import { getJson, post, postJson } from '~/app-utils/http';
import { backend } from '~/@api/backend';
import { useCartStore } from '~/@api/store/cartApi';
import { useCheckoutStore } from '~/@api/store/checkoutApi';
import { useClubStore } from '~/@api/store/clubApi';
import { useFormsStore } from '~/@api/store/formsApi';
import { useLoadingStore } from '~/@api/store/loadingApi';
import { useMessageboxStore } from '~/@api/store/messageboxApi';
import { useRoutingStore } from '~/@api/store/routingApi';
import { useServerContextStore } from '~/@api/store/serverContextApi';
import { useVoucherStore } from '~/@api/store/voucherApi';
import {
	LoginData,
	MessageboxType,
	PageTypes,
	RootState,
	UserAction,
	UserActionType,
	UserState,
} from '~/@api/store.types';
import {
	ErrorResponse,
	ForgottenPaswordRequest,
	Gender,
	GuestRegistrationRequest,
	RegistrationRequest,
	User,
	UserChangeEmailRequest,
	UserChangePasswordRequest,
	ValidationErrorResponse,
} from '~/generated/hybris-raml-api';
import { eecCheckoutStep1 } from '~/tracking/events/eec.checkout.step1';
import { track } from '~/tracking/tracking';

// Initial state -----------------------------------------------------------------------------------

const state = (): UserState => ({
	response: {
		details: null,
		type: null,
	},
	user: {
		alreadyAskedClubInterest: false,
		birthday: null,
		ccMode: false,
		customerNumber: null,
		email: null,
		gender: null,
		genders: [],
		language: {} as any,
		languages: [],
		loaded: false,
		loggedIn: false,
		firstName: null,
		lastName: null,
		phone: null,
		title: null,
		uid: null,
	},
});

// Mutations ---------------------------------------------------------------------------------------

const mutations = {
	[mapFn(USER_M_SAVE)](state: UserState, payload: User) {
		state.user = {
			alreadyAskedClubInterest: payload.alreadyAskedClubInterest,
			birthday: payload.birthday,
			ccMode: payload.ccMode,
			clubId: payload.clubId,
			customerNumber: payload.customerNumber,
			email: payload.email,
			gender: payload.gender,
			genders: payload.genders,
			language: payload.language,
			languages: payload.languages,
			loaded: true,
			loggedIn: payload.loggedIn,
			firstName: payload.firstName,
			lastName: payload.lastName,
			phone: payload.phone,
			title: payload.title,
			uid: payload.uid,
		};
	},

	[mapFn(USER_M_SAVE_GENDER)](state: UserState, gender: Gender) {
		state.user = { ...state.user, gender };
	},

	[mapFn(USER_M_SAVE_RESPONSE)](state: UserState, payload: UserAction) {
		state.response = {
			details: payload.details,
			type: payload.type,
		};
	},
};

// Actions -----------------------------------------------------------------------------------------

const actions = {
	[mapFn(USER_A_LOGIN)](
		_context: ActionContext<UserState, RootState>,
		payload: { data: LoginData; isCheckout: boolean },
	) {
		if (process.client) {
			try {
				const body = Object.entries({
					CSRFToken: useServerContextStore(this).state.session.csrfToken,
					j_password: payload.data.password,
					j_username: payload.data.email,
				})
					.map(([key, value]) => encodeURIComponent(key) + '=' + encodeURIComponent(value))
					.join('&');
				const request = post(
					backend.API.V2.USER[payload.isCheckout ? 'LOGIN_CHECKOUT' : 'LOGIN'](this),
					body,
					{
						store: this,
					},
					{
						'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
						'Upgrade-Insecure-Requests': 1,
					},
					true,
				);
				const successCallback = async () => {
					const route = (this as Store<RootState>).$router.currentRoute;
					const spaKey = getPageTypeMetaInfoFromRoute(this, route);

					await useRoutingStore(this).api.updateDatalayer(spaKey.spaType, spaKey.identifier);

					if (payload.isCheckout) {
						eecCheckoutStep1(
							this,
							useCartStore(this).state.cart.entries.map((entry) => ({
								...entry.product,
								quantity: entry.quantity,
							})),
							{ option: 'Kunde' },
						);
						useCheckoutStore(this).api.startCheckout();
					} else {
						track('user.login');
						_redirectFromLoginToMyAccountPage(this, route);
					}

					await useVoucherStore(this).api.getUserGiftCards(true);
				};

				return _sendUserForm(this, request, UserActionType.LOGIN, { successCallback });
			} catch (error) {
				Logger.error('Could not send login form', error);
			}
		}
	},

	[mapFn(USER_A_REGISTRATION)](
		_context: ActionContext<UserState, RootState>,
		payload: { data: RegistrationRequest; isCheckout: boolean; message: string },
	) {
		if (process.client) {
			try {
				const request = postJson(
					backend.API.V2.USER.REGISTRATION(this),
					payload.data,
					this,
					null,
					true,
				);
				const successCallback = async () => {
					const route = (this as Store<RootState>).$router.currentRoute;
					const spaKey = getPageTypeMetaInfoFromRoute(this, route);

					await useRoutingStore(this).api.updateDatalayer(spaKey.spaType, spaKey.identifier);

					if (payload.isCheckout) {
						eecCheckoutStep1(
							this,
							useCartStore(this).state.cart.entries.map((entry) => ({
								...entry.product,
								quantity: entry.quantity,
							})),
							{ option: 'Neukunde' },
						);
						useCheckoutStore(this).api.startCheckout();
						_showGlobalMessage(this, payload.message, MessageboxType.SUCCESS);
					} else {
						track('user.signup');
						_redirectFromLoginToMyAccountPage(this, route);
					}

					await useVoucherStore(this).api.getUserGiftCards(true);
				};

				return _sendUserForm(
					this,
					request,
					payload.isCheckout ? UserActionType.REGISTRATION_CHECKOUT : UserActionType.REGISTRATION,
					{ successCallback },
				);
			} catch (error) {
				Logger.error('Could not send registration form', error);
			}
		}
	},

	[mapFn(USER_A_GUEST_REGISTRATION)](
		_context: ActionContext<UserState, RootState>,
		data: GuestRegistrationRequest,
	) {
		if (process.client) {
			try {
				const request = postJson(
					backend.API.V2.USER.REGISTRATION_GUEST(this),
					data,
					this,
					null,
					true,
				);
				const successCallback = async () => {
					eecCheckoutStep1(
						this,
						useCartStore(this).state.cart.entries.map((entry) => ({
							...entry.product,
							quantity: entry.quantity,
						})),
						{ option: 'Gast' },
					);
					await useCheckoutStore(this).api.update();
					await useClubStore(this).api.startClubProcess();
					await useVoucherStore(this).api.getUserGiftCards(true);
				};

				return _sendUserForm(this, request, UserActionType.GUEST_REGISTRATION, {
					successCallback,
				});
			} catch (error) {
				Logger.error('Could not send guestRegistrion form', error);
			}
		}
	},

	[mapFn(USER_A_FORGOTTEN_PASSWORD)](
		_context: ActionContext<UserState, RootState>,
		email: ForgottenPaswordRequest,
	) {
		try {
			const request = postJson(
				backend.API.V2.USER.FORGOTTEN_PASSWORD(this),
				email,
				this,
				null,
				true,
			);

			return _sendUserForm(this, request, UserActionType.FORGOTTEN_PASSWORD, {});
		} catch (error) {
			Logger.error('Could not send forgottenPassword form', error);
		}
	},

	async [mapFn(USER_A_CHANGE_EMAIL)](
		context: ActionContext<UserState, RootState>,
		data: UserChangeEmailRequest,
	) {
		try {
			const response = await postJson(
				backend.API.V2.SESSION.CHANGE_EMAIL(this),
				data,
				this,
				null,
				true,
			);
			if (response?.ok) {
				const user = await response.json();
				context.commit(mapFn(USER_M_SAVE), user);
			}
			return response;
		} catch (error) {
			Logger.error('Could not send changeEmail form', error);
		}
	},

	async [mapFn(USER_A_CHANGE_PASSWORD)](
		_context: ActionContext<UserState, RootState>,
		data: UserChangePasswordRequest,
	) {
		try {
			return await postJson(backend.API.V2.SESSION.CHANGE_PASSWORD(this), data, this, null, true);
		} catch (error) {
			Logger.error('Could not send changePassword form', error);
		}
	},

	async [mapFn(USER_A_UPDATE)](context: ActionContext<UserState, RootState>) {
		try {
			const response = await getJson(backend.API.V2.USER.SESSION_USER(this), this);

			if (response) {
				context.commit(mapFn(USER_M_SAVE), response.json);
			}
		} catch (error) {
			Logger.error('Could not fetch UserAPI', error);
		}

		elapsedTime(USER_A_UPDATE);
	},

	async [mapFn(USER_A_GENDER)](context: ActionContext<UserState, RootState>, code: string) {
		try {
			await postJson(backend.API.V2.SESSION.CHANGE_GENDER(this, code), null, this);
			context.commit(
				mapFn(USER_M_SAVE_GENDER),
				context.state.user.genders.find((gender) => gender.code === code),
			);
		} catch (error) {
			Logger.error('Could not fetch User API', error);
		}
	},
	async [mapFn(USER_A_REMOVE_CREDIT_CARD)](
		_context: ActionContext<UserState, RootState>,
		payload: { code: string },
	) {
		try {
			const response = await postJson(
				backend.API.V2.USER.PAYMENT.DELETE(this),
				payload,
				this,
				null,
				true,
			);

			return response;
		} catch (error) {
			Logger.error('Could not remove credit card', error);
		}
	},
};

// Getters -----------------------------------------------------------------------------------------

const getters = {
	[mapFn(USER_G_HAS_ERROR)](_state: UserState) {
		return (type?: UserActionType) =>
			(!type || _state.response?.type === type) && !_state.response?.details?.success;
	},
};

export default {
	state,
	mutations,
	actions,
	getters,
};

// Helpers -----------------------------------------------------------------------------------------

const _showGlobalMessage = (store: Store<RootState>, text: string, type: MessageboxType) => {
	useMessageboxStore(store).api.addMessage({ dismissible: true, text, type }, 'checkout');
};

const _handleBackendServerErrors = (
	store: Store<RootState>,
	response: ErrorResponse,
	userAction: UserActionType,
) => {
	store.commit(USER_M_SAVE_RESPONSE, {
		details: { errors: [{ errorMessage: response.message }], success: false },
		type: userAction,
	});
	_showGlobalMessage(store, response.message, MessageboxType.ERROR);
};

const _defaultErrorCallback = (
	store: Store<RootState>,
	userAction: UserActionType,
	json: ValidationErrorResponse,
) => useFormsStore(store).api.storeValidationResponse(userAction, json);

const _sendUserForm = (
	store: Store<RootState>,
	request: Promise<Response>,
	userAction: UserActionType,
	callbacks: { successCallback?; errorCallback? },
) => {
	return useLoadingStore(store).api.doWithLoader('user.module', async () => {
		const response = await request;
		const json = await response.json();

		store.commit(USER_M_SAVE_RESPONSE, {
			details: json,
			type: userAction,
		});
		store.dispatch(USER_A_UPDATE);

		if (response.status === 200) {
			if (callbacks.successCallback) {
				await callbacks.successCallback(json);
			}

			return true;
		} else if (response.status === 500) {
			_handleBackendServerErrors(store, json, userAction);

			return false;
		} else if (callbacks.errorCallback) {
			await callbacks.errorCallback(json);

			return false;
		} else {
			_defaultErrorCallback(store, userAction, json);

			return false;
		}
	});
};

const _redirectFromLoginToMyAccountPage = (store: Store<RootState>, route: Route) => {
	const isLoginPage = !!route.matched.find((match) => {
		return match.meta?.pageType === PageTypes.LOGIN;
	});

	if (isLoginPage) {
		useRoutingStore(store).api.navigate(backend.API.V2.MY_ACCOUNT.START(store));
	}
};
