import { track } from '../tracking';
import { SpaDigitalDataProduct } from '~/generated/hybris-raml-api';
import { mapProduct } from '~/tracking/data-mapper/product-mapper';

export const removeFromCart = (datalayerProduct: SpaDigitalDataProduct, quantity?: number) => {
	const product = mapProduct(datalayerProduct);
	track('eec.remove', {
		ecommerce: {
			remove: {
				products: [{ ...product, quantity: quantity || product.quantity }],
			},
		},
	});
};
