import type { Store } from 'vuex';
import { clientInitStoreModule, useStoreModule } from '../useStoreModule';
import userStore from './user.store';
import userApi from './user.api';
import { RootState, UserState } from '~/@api/store.types';
import { USER } from '~/@constants/store';

export function useUserStore(store: Store<RootState>) {
	return useStoreModule<UserState, ReturnType<typeof userApi>>(USER, store, userStore, userApi);
}

export async function clientInitUserStore(store: Store<RootState>): Promise<void> {
	return await clientInitStoreModule(USER, store, userStore, userApi);
}
