import type { Store } from 'vuex';
import * as cartSessionUtils from '../cartSessionUtils';
import { track } from '../tracking';
import { DeliveryModeRequest, Product } from '@/generated/hybris-raml-api';
import { useRoutingStore } from '~/@api/store/routingApi';
import { RootState } from '~/@api/store.types';
import { mapProduct } from '~/tracking/data-mapper/product-mapper';
import { TrackingProduct } from '~/tracking/types';

// DELIVERY-PAGE
export const eecCheckoutStep2 = (
	store: Store<RootState>,
	products: TrackingProduct[],
	payload?: any,
) => {
	const eventName = payload ? 'checkout_option' : 'checkout';
	const currencyCode = products[0]?.price?.selling?.currencyIso || 'CHF';

	if (useRoutingStore(store).state.spaData.datalayer) {
		track(`eec.${eventName}`, {
			...(payload && {
				// Add property cart only if payload is available
				cart: {
					...(payload.method && { price: { shippingMethod: payload.method } }),
					...(payload.isSameAddress && { address: { isSameAddress: payload.isSameAddress } }),
				},
			}),
			ecommerce: {
				[eventName]: {
					actionField: {
						...(payload &&
							payload.method && {
								option: `${payload.method},${payload.isSameAddress ? 'same' : 'different'}`,
							}),
						step: 2,
					},
					products: products.map((product) => ({
						...mapProduct(product, false),
						...cartSessionUtils.getSessionProduct(product as Product),
					})),
				},
				currencyCode,
			},
		});
	}
};

export const eecCheckoutStep2Assembly = (payload: DeliveryModeRequest) =>
	track('eec.checkout_assembly', payload);
