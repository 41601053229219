export const updateUrlParam = (url: string, key: string, value: string | undefined): string => {
	if (typeof value === 'undefined') {
		return url
			.replace(new RegExp('[?&]' + key + '=[^&#]*(#.*)?$'), '$1')
			.replace(new RegExp('([?&])' + key + '=[^&]*&'), '$1');
	}

	const pattern = new RegExp('\\b(' + key + '=).*?(&|#|$)');

	if (url.search(pattern) >= 0) {
		return url.replace(pattern, '$1' + value + '$2');
	}

	url = url.replace(/[?#]$/, '');
	return url + (url.indexOf('?') > 0 ? '&' : '?') + key + '=' + value;
};
