import { PageTypes } from '~/@api/store.types';
import { DynamicYieldContextType } from '~/@constants/global';

export const cartRoute = {
	path: 'cart.html',
	component: () => import('~/pages/cart-page/cart-page.vue').then((m) => m.default || m),
	meta: {
		dynamicYieldContext: {
			type: DynamicYieldContextType.Cart,
		},
		pageType: PageTypes.CART,
	},
};
