import Logger from './logger';

const performance = (process as any).server
	? require('perf_hooks').performance
	: window.performance;
let globalTime = performance.now();

export const performanceNow = () => {
	return performance.now();
};

export const elapsedTime = (name: string, timestamp?: number) => {
	if (!timestamp) {
		timestamp = performance.now();
	}
	const ms = timestamp - globalTime;
	const msg = `(+${ms.toFixed(2)}ms) ${name}`;

	Logger.createLogger('performance')(msg);

	globalTime = timestamp;
};

export const start = (name: string) => {
	if (isPerformanceMeasuringEnabled()) {
		performance.mark(startName(name));
	}
};

export const stop = (name: string) => {
	if (isPerformanceMeasuringEnabled()) {
		performance.mark(endName(name));
		performance.measure(name, startName(name), endName(name));
	}
};

const startName = (name: string) => name + '.start';
const endName = (name: string) => name + '.end';

const isPerformanceMeasuringEnabled = () =>
	(process as any).client &&
	typeof (window as any)?.performance?.mark === 'function' &&
	((window as any)?.kps || (window as any)?.debug);
