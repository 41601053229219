import { lazyImportWrapper } from '@/node_modules/@osp/design-system/assets/js/utilities/dynamicImports';

export const importBackendApi = () =>
	lazyImportWrapper(() => import('~/@api/backend' /* webpackChunkName: 'backendApi' */));

export const importDebounce = () =>
	lazyImportWrapper(
		() =>
			import(
				'@/node_modules/@osp/design-system/assets/js/utilities/debounce' /* webpackChunkName: 'debounce' */
			),
	);

export const importDebug = () =>
	lazyImportWrapper(() => import('debug' /* webpackChunkName: 'debug' */));

export const importElapsedTime = () =>
	lazyImportWrapper(
		() => import('@/node_modules/@osp/utils/src/performance' /* webpackChunkName: 'elapsedTime' */),
	);

export const importFilterClick = () =>
	lazyImportWrapper(
		() => import('~/tracking/events/filterClick' /* webpackChunkName: 'filterClick' */),
	);

export const importFirstUserInteractionTracking = () =>
	lazyImportWrapper(
		() =>
			import(
				'~/tracking/events/firstUserInteraction' /* webpackChunkName: 'trackingFirstUserInteraction' */
			),
	);

export const importFollowRedirectIfGiven = () =>
	lazyImportWrapper(
		() => import('~/app-utils/redirect' /* webpackChunkName: 'followRedirectIfGiven' */),
	);

export const importGlobalConstants = () =>
	lazyImportWrapper(() => import('~/@constants/global' /* webpackChunkName: 'globalConstants' */));

export const importLodashCeil = () =>
	lazyImportWrapper(() => import('lodash-es/ceil' /* webpackChunkName: 'lodash.ceil' */));

export const importLodashFlatten = () =>
	lazyImportWrapper(() => import('lodash-es/flatten' /* webpackChunkName: 'lodash.flatten' */));

export const importLodashForEach = () =>
	lazyImportWrapper(() => import('lodash-es/forEach' /* webpackChunkName: 'lodash.forEach' */));

export const importLodashGet = () =>
	lazyImportWrapper(() => import('lodash-es/get' /* webpackChunkName: 'lodash.get' */));

export const importLodashSet = () =>
	lazyImportWrapper(() => import('lodash-es/set' /* webpackChunkName: 'lodash.set' */));

export const importLodashThrottle = () =>
	lazyImportWrapper(() => import('lodash-es/throttle' /* webpackChunkName: 'lodash.throttle' */));

export const importLogger = () =>
	lazyImportWrapper(
		() => import('@/node_modules/@osp/utils/src/logger' /* webpackChunkName: 'logger' */),
	);

export const importMiddlewareSearch = () =>
	lazyImportWrapper(() => import('~/middleware/search' /* webpackChunkName: 'search' */));

export const importNuxtReadyTracking = () =>
	lazyImportWrapper(
		() => import('~/tracking/events/nuxtReady' /* webpackChunkName: 'trackingNuxtReady' */),
	);

export const importRegularPageview = () =>
	lazyImportWrapper(
		() => import('~/tracking/events/regularPageview' /* webpackChunkName: 'regularPageview' */),
	);

export const importRunTask = () =>
	lazyImportWrapper(
		() =>
			import(
				'@/node_modules/@osp/design-system/assets/js/utilities/runTask' /* webpackChunkName: 'runTask' */
			),
	);

export const importSearchUtils = () =>
	lazyImportWrapper(
		() => import('~/app-utils/search.utils' /* webpackChunkName: 'search.utils' */),
	);

export const importTimeout = () =>
	lazyImportWrapper(
		() =>
			import(
				'@/node_modules/@osp/design-system/assets/js/utilities/timeout' /* webpackChunkName: 'setSafeTimeout' */
			),
	);

export const importSpaUtils = () =>
	lazyImportWrapper(() => import('~/routing/utils/spa-utils' /* webpackChunkName: 'spa-utils' */));

export const importSsm = () =>
	lazyImportWrapper(
		() => import('simplestatemanager' /* webpackChunkName: 'simplestatemanager' */),
	);

export const importStoreConstants = () =>
	lazyImportWrapper(() => import('~/@constants/store' /* webpackChunkName: 'constants.store' */));

export const importTracking = () =>
	lazyImportWrapper(() => import('~/tracking/tracking' /* webpackChunkName: 'tracking' */));

export const importSpaClsTracker = () =>
	lazyImportWrapper(
		() => import('~/tracking/performance/SpaClsTracker' /* webpackChunkName: 'spaClsTracker' */),
	);

export const importSpaRumThresholdReporter = () =>
	lazyImportWrapper(
		() =>
			import(
				'~/tracking/performance/SpaRumThresholdReporter' /* webpackChunkName: 'spaRumThresholdReporting' */
			),
	);

export const importLongTasksTracker = () =>
	lazyImportWrapper(
		() =>
			import('~/tracking/performance/LongTasksTracker' /* webpackChunkName: 'longTasksTracker' */),
	);

export const importVue = () => lazyImportWrapper(() => import('vue' /* webpackChunkName: 'vue' */));

export const importVueCustomElement = () =>
	lazyImportWrapper(
		() => import('vue-custom-element' /* webpackChunkName: 'vue-custom-element' */),
	);

export const importVueObserveVisibility = () =>
	lazyImportWrapper(
		() => import('vue-observe-visibility' /* webpackChunkName: 'VueObserveVisibility' */),
	);

export const importPerformanceTracking = () =>
	lazyImportWrapper(
		() => import('~/app-utils/tracking.utils' /* webpackChunkName: 'trackingUtils' */),
	);

export const importTrackingApi = () =>
	lazyImportWrapper(() => import('~/@api/store/trackingApi' /* webpackChunkName: 'trackingApi' */));

export const importClsApi = () =>
	lazyImportWrapper(() => import('~/@api/store/clsApi' /* webpackChunkName: 'clsApi' */));

export const importCheckoutApi = () =>
	lazyImportWrapper(() => import('~/@api/store/checkoutApi' /* webpackChunkName: 'checkoutApi' */));

export const importLoadingApi = () =>
	lazyImportWrapper(() => import('~/@api/store/loadingApi' /* webpackChunkName: 'loadingApi' */));

export const importMessageboxApi = () =>
	lazyImportWrapper(
		() => import('~/@api/store/messageboxApi' /* webpackChunkName: 'messageboxApi' */),
	);
export const importServerContextApi = () =>
	lazyImportWrapper(
		() => import('~/@api/store/serverContextApi' /* webpackChunkName: 'serverContextApi' */),
	);
export const importEecPromotionClick = () =>
	lazyImportWrapper(
		() =>
			import('@/tracking/events/eec.promotionClick' /* webpackChunkName: 'eecPromotionClick' */),
	);

export const importMyAccountApi = () =>
	lazyImportWrapper(
		() => import('~/@api/store/myAccountApi' /* webpackChunkName: 'myAccountApi' */),
	);

export const importVoucherApi = () =>
	lazyImportWrapper(() => import('~/@api/store/voucherApi' /* webpackChunkName: 'voucherApi' */));

export const importBrandSearchTracking = () =>
	lazyImportWrapper(
		() => import('~/tracking/events/brandSearch' /* webpackChunkName: 'trackingBrandSearch' */),
	);

export const importSuggestedSearchClickedTracking = () =>
	lazyImportWrapper(
		() =>
			import(
				'~/tracking/events/suggestedSearchClicked' /* webpackChunkName: 'trackingSuggestedSearchClicked' */
			),
	);

export const importSuggestedSearchUsageMarker = () =>
	lazyImportWrapper(
		() =>
			import(
				'~/tracking/helpers/suggestedSearchUsageMarker' /* webpackChunkName: 'trackingSuggestedSearchUsageMarker' */
			),
	);

export const importCmsContentApi = () =>
	lazyImportWrapper(
		() => import('~/@api/store/cmsContentApi' /* webpackChunkName: 'cmsContentApi' */),
	);

export const importUserApi = () =>
	lazyImportWrapper(() => import('~/@api/store/userApi' /* webpackChunkName: 'userApi' */));

