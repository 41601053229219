import type { Store } from 'vuex';
import { Category } from '@/node_modules/@osp/design-system/types/category';
import { MediaObjectPosition } from '@/node_modules/@osp/design-system/types/media';
import { mapImage } from './image';
import { url } from '~/@api/backend';
import { RootState } from '~/@api/store.types';
import { Category as RamlCategory } from '~/generated/hybris-raml-api';

export function mapRamlCategory(
	store: Store<RootState>,
	category: RamlCategory,
	imageWidth: number | undefined = undefined,
	breakpoints: { [breakpoint: string]: number } | undefined = undefined,
): Category {
	return {
		code: category.code,
		name: category.name,
		url: url(store, category.url),
		image: mapImage(store, category.image, breakpoints || {}, {
			preset: 'v3-product-tile',
			width: imageWidth || 150,
			path: 'product/tile',
		}),
		imageObjectPosition: MediaObjectPosition[category?.image?.imageObjectPosition],
	};
}
