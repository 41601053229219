import type { Store } from 'vuex';
import { clientInitStoreModule, useStoreModule } from '../useStoreModule';
import serverSettingsStore from './serverSettings.store';
import serverSettingsApi from './serverSettings.api';
import { RootState, ServerSettingsState } from '~/@api/store.types';
import { SERVERSETTINGS } from '~/@constants/store';

export function useServerSettingsStore(store: Store<RootState>) {
	return useStoreModule<ServerSettingsState, ReturnType<typeof serverSettingsApi>>(
		SERVERSETTINGS,
		store,
		serverSettingsStore,
		serverSettingsApi,
	);
}

export async function clientInitServerSettings(store: Store<RootState>): Promise<void> {
	return await clientInitStoreModule(SERVERSETTINGS, store, serverSettingsStore, serverSettingsApi);
}
