import { Context } from '@nuxt/types';
import { Inject } from '@nuxt/types/app';
import {
	importHtmlParserWorker,
	importMarkedParseWorker,
} from '../assets/js/utilities/dynamicImports';

export default (_: Context, inject: Inject) => {
	inject('worker', {
		async createMarkedParseWorker(): Promise<Worker> {
			const { default: MarkedParseWorker } = await importMarkedParseWorker();
			return new MarkedParseWorker();
		},
		async createHtmlParserWorker(): Promise<Worker> {
			const { default: HtmlParserWorker } = await importHtmlParserWorker();
			return new HtmlParserWorker();
		},
	});
};
