import { track } from '../tracking';
import { SearchRequestResponse } from '@/generated/hybris-raml-api';

export const internalSearch = (payload: {
	searchResponse: SearchRequestResponse;
	gender: string;
	additionalData?: any;
}) => {
	if (payload.searchResponse.text) {
		track('internalSearch', {
			actions: {
				search: {
					gender: payload.gender,
					nullSearch: payload.searchResponse.pagination.totalCount > 0 ? '0' : '1',
					results: payload.searchResponse.pagination.totalCount, // Amount of search results
					term: payload.searchResponse.text, // Search term
					...(payload.additionalData || {}),
				},
			},
		});
	}
};
