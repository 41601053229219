/**
 * @param name namespaced action, getter or mutation key
 * @returns the actual function name used in the vuex store
 */
export const mapFn = (name: string) => name.split('/').reverse()[0];

export const BRAND = 'brand';
export const BRAND_A_FETCH = `${BRAND}/fetch`;
export const BRAND_M_STORE = `${BRAND}/store`;

export const CART = 'cart';
export const CART_A_ADD_ITEM = `${CART}/addItem`;
export const CART_A_CHANGE_SIZE = `${CART}/changeSize`;
export const CART_A_MODIFY_ITEM_QUANTITY = `${CART}/modifyItemQuantity`;
export const CART_A_REMOVE_ITEM = `${CART}/removeItem`;
export const CART_A_STORE_CART = `${CART}/storeCart`;
export const CART_A_UPDATE = `${CART}/update`;
export const CART_A_UPDATE_TRUSTIES = `${CART}/updateTrusties`;
export const CART_A_UPDATE_INSTALLMENT_CONDITION = `${CART}/updateInstallmentCondition`;
export const CART_G_HAS_BULKY = `${CART}/hasBulky`;
export const CART_G_QUANTITY = `${CART}/quantity`;
export const CART_M_SAVE_MODIFICATION_STATUS = `${CART}/saveModificationStatus`;
export const CART_M_STORE_CART_STATE = `${CART}/storeCartState`;
export const CART_M_STORE_TRUSTIES = `${CART}/storeTrusties`;
export const CART_M_STORE_INSTALLMENT_CONDITION = `${CART}/installmentCondition`;

export const CHECKOUT = 'checkout';
export const CHECKOUT_A_PLACE_ORDER = `${CHECKOUT}/placeOrder`;
export const CHECKOUT_A_SET_CLICK_AND_COLLECT_STORE = `${CHECKOUT}/setClickAndCollectStore`;
export const CHECKOUT_A_SET_CURRENT_STEP = `${CHECKOUT}/setCurrentStep`;
export const CHECKOUT_A_SET_DELIVERY_ADDRESS = `${CHECKOUT}/setDeliveryAddress`;
export const CHECKOUT_A_SET_DELIVERY_MODES = `${CHECKOUT}/setDeliveryModes`;
export const CHECKOUT_A_SET_PAYMENT_ADDRESS = `${CHECKOUT}/setPaymentAddress`;
export const CHECKOUT_A_SET_PAYMENT_INFO = `${CHECKOUT}/setPaymentInfo`;
export const CHECKOUT_A_SET_PAYMENT_MODE = `${CHECKOUT}/setPaymentMode`;
export const CHECKOUT_A_START = `${CHECKOUT}/startCheckout`;
export const CHECKOUT_A_STORE_CHECKOUT_STATE = `${CHECKOUT}/storeCheckoutState`;
export const CHECKOUT_A_UPDATE = `${CHECKOUT}/update`;
export const CHECKOUT_A_UPDATE_PAYMENT_INFO = `${CHECKOUT}/updatePaymentInfo`;
export const CHECKOUT_M_SET_CURRENT_STEP = `${CHECKOUT}/setCurrentStep`;
export const CHECKOUT_M_SET_NEXT_STEP = `${CHECKOUT}/setNextStep`;
export const CHECKOUT_M_STORE_CHECKOUT_STATE = `${CHECKOUT}/storeCheckoutState`;
export const CHECKOUT_M_STORE_PAYMENT_STATE = `${CHECKOUT}/storePaymentState`;

export const CLS = 'cls';
export const CLS_A_STORECLSSTATES = `${CLS}/storeClsStates`;
export const CLS_A_ADDMESSAGE = `${CLS}/addMessage`;
export const CLS_A_CLEARMESSAGES = `${CLS}/clearMessages`;
export const CLS_M_UPDATESSRREGISTER = `${CLS}/updateSsrRegister`;
export const CLS_M_TOUCHACTIVATECLSSTATEALLTRIGGER = `${CLS}/touchActivateClsStateAllTrigger`;
export const CLS_M_TOUCHRESETCLSSTATEALLTRIGGER = `${CLS}/touchResetClsStateAllTrigger`;
export const CLS_A_ACTIVATECLSSTATEALL = `${CLS}/activateClsStateAll`;
export const CLS_A_RESETCLSSTATEALL = `${CLS}/resetClsStateAll`;

export const CLUB = 'club';
export const CLUB_A_ABORT = `${CLUB}/abort`;
export const CLUB_A_START_CLUBPROCESS = `${CLUB}/startClubProcess`;
export const CLUB_A_REGISTER = `${CLUB}/register`;
export const CLUB_A_SET_CURRENT_STEP = `${CLUB}/setCurrentStep`;
export const CLUB_A_FETCH_STATUS = `${CLUB}/fetchStatus`;
export const CLUB_A_GET_PROCESS_STATE = `${CLUB}/getProcessState`;
export const CLUB_M_SET_CURRENT_STEP = `${CLUB}/setCurrentStep`;
export const CLUB_M_SET_CLUBID = `${CLUB}/setClubId`;
export const CLUB_M_STORE_CLUB_PROCESS_STATE = `${CLUB}/storeClubProcessState`;
export const CLUB_M_RESET_STATE = `${CLUB}/resetState`;
export const CLUB_M_STORE_STATUS = `${CLUB}/storeStatus`;

export const CMSCONTENT = 'cmscontent';
export const CMSCONTENT_M_SAVE = `${CMSCONTENT}/save`;
export const CMSCONTENT_A_LOAD_SINGLE_COMPONENT = `${CMSCONTENT}/loadSingleCMSComponent`;
export const CMSCONTENT_A_UPDATE = `${CMSCONTENT}/update`;
export const CMSCONTENT_G_DEREFERENCE = `${CMSCONTENT}/dereference`;
export const CMSCONTENT_G_REFERENCE_CONTENT = `${CMSCONTENT}/referenceContent`;
export const CMSCONTENT_G_SLOT_CONTENT = `${CMSCONTENT}/slotContent`;
export const CMSCONTENT_G_SLOT_HAS_CONTENT = `${CMSCONTENT}/slotHasContent`;

export const COMMUNICATION_CENTER = 'communicationCenter';
export const COMMUNICATION_CENTER_A_FETCH = `${COMMUNICATION_CENTER}/fetch`;
export const COMMUNICATION_CENTER_A_UPDATE_PROPERTY = `${COMMUNICATION_CENTER}/updateProperty`;
export const COMMUNICATION_CENTER_A_UPDATE_VALUE = `${COMMUNICATION_CENTER}/updateValue`;
export const COMMUNICATION_CENTER_M_STORE = `${COMMUNICATION_CENTER}/store`;
export const COMMUNICATION_CENTER_M_STORE_VALUE = `${COMMUNICATION_CENTER}/storeValue`;
export const COMMUNICATION_CENTER_M_STORE_VALUES = `${COMMUNICATION_CENTER}/storeValues`;

export const DYNAMICYIELD = 'dynamicyield';
export const DYNAMICYIELD_A_FETCH = `${DYNAMICYIELD}/fetch`;
export const DYNAMICYIELD_A_REPORT = `${DYNAMICYIELD}/report`;
export const DYNAMICYIELD_M_SET_PATH = `${DYNAMICYIELD}/setPath`;
export const DYNAMICYIELD_M_SET_FETCHING = `${DYNAMICYIELD}/setFetching`;
export const DYNAMICYIELD_M_SET_INITIALIZING = `${DYNAMICYIELD}/setInitializing`;
export const DYNAMICYIELD_M_UPDATE_CHOICES = `${DYNAMICYIELD}/updateChoices`;
export const DYNAMICYIELD_M_SET_CREDENTIALS = `${DYNAMICYIELD}/setCredentials`;
export const DYNAMICYIELD_G_CREDENTIALS = `${DYNAMICYIELD}/getCredentials`;
export const DYNAMICYIELD_G_DATA = `${DYNAMICYIELD}/getData`;
export const DYNAMICYIELD_G_STORED_DATA_ENTRY_EXISTS = `${DYNAMICYIELD}/storedDataEntryExists`;
export const DYNAMICYIELD_M_STORE_DATA = `${DYNAMICYIELD}/storeData`;
export const DYNAMICYIELD_CREDENTIAL_DYID_SERVER = '_dyid_server';
export const DYNAMICYIELD_CREDENTIAL_DYID = '_dyid';
export const DYNAMICYIELD_CREDENTIAL_DYJSESSION = '_dyjsession';

export const FORMS = 'forms';
export const FORMS_A_LOAD_FORM = `${FORMS}/loadForm`;
export const FORMS_M_STORE_CONFIG = `${FORMS}/storeConfig`;
export const FORMS_M_STORE_VALIDATION_RESPONSE = `${FORMS}/storeValidationResponse`;

export const I18N = 'i18n';
export const I18N_A_MESSAGES = `${I18N}/messages`;
export const I18N_G_MESSAGE = `${I18N}/message`;
export const I18N_M_LANGUAGE = `${I18N}/language`;
export const I18N_M_MESSAGES = `${I18N}/messages`;

export const INSTALLPROMPT = 'installprompt';
export const INSTALLPROMPT_M_BEFORE_INSTALL = `${INSTALLPROMPT}/beforeinstall`;
export const INSTALLPROMPT_M_PROMPT = `${INSTALLPROMPT}/prompt`;
export const INSTALLPROMPT_M_ACCEPT = `${INSTALLPROMPT}/accept`;
export const INSTALLPROMPT_M_DISMISS = `${INSTALLPROMPT}/dismiss`;

export const LOADING = 'loading';
export const LOADING_A_SET_LOADING = `${LOADING}/setLoading`;
export const LOADING_G_IS_LOADING = `${LOADING}/isLoading`;
export const LOADING_M_RESET_STATE = `${LOADING}/resetState`;
export const LOADING_M_SET_LOADING = `${LOADING}/setLoading`;

export const MEDIAQUERY = 'mediaquery';
export const MEDIAQUERY_G_IS_DESKTOP = `${MEDIAQUERY}/isDesktop`;
export const MEDIAQUERY_G_IS_TABLET = `${MEDIAQUERY}/isTablet`;
export const MEDIAQUERY_G_IS_MOBILE = `${MEDIAQUERY}/isMobile`;
export const MEDIAQUERY_M_DIMENSIONS = `${MEDIAQUERY}/dimensions`;
export const MEDIAQUERY_M_UPDATE = `${MEDIAQUERY}/update`;

export const MESSAGEBOX = 'messagebox';
export const MESSAGEBOX_A_HANDLE_ERROR = `${MESSAGEBOX}/handleError`;
export const MESSAGEBOX_M_DISMISS = `${MESSAGEBOX}/dismiss`;
export const MESSAGEBOX_M_SAVE = `${MESSAGEBOX}/save`;

export const MYACCOUNT = 'myaccount';
export const MYACCOUNT_A_FETCH_NAVIGATION = `${MYACCOUNT}/fetchNavigation`;
export const MYACCOUNT_A_FETCH_ORDERS = `${MYACCOUNT}/fetchOrders`;
export const MYACCOUNT_A_UPDATE_PERSONAL_DETAILS = `${MYACCOUNT}/updatePersonalDetails`;
export const MYACCOUNT_M_SAVE_ORDER = `${MYACCOUNT}/saveOrder`;
export const MYACCOUNT_M_STORE_NAVIGATION = `${MYACCOUNT}/setNavigation`;
export const MYACCOUNT_M_STORE_ORDERS = `${MYACCOUNT}/setOrders`;

export const OVERLAY = 'overlay';
export const OVERLAY_A_CLOSE = `${OVERLAY}/close`;
export const OVERLAY_A_OPEN = `${OVERLAY}/open`;
export const OVERLAY_A_TOGGLE = `${OVERLAY}/toggle`;
export const OVERLAY_A_REGISTER = `${OVERLAY}/register`;
export const OVERLAY_G_OPEN = `${OVERLAY}/open`;
export const OVERLAY_G_ANY_OPEN = `${OVERLAY}/anyOpen`;
export const OVERLAY_G_WITH_HISTORY = `${OVERLAY}/withHistory`;
export const OVERLAY_M_CLOSE = `${OVERLAY}/close`;
export const OVERLAY_M_OPEN = `${OVERLAY}/open`;
export const OVERLAY_M_REGISTER = `${OVERLAY}/register`;
export const OVERLAY_M_REMOVE = `${OVERLAY}/remove`;

export const PRODUCTS = 'products';
export const PRODUCTS_A_LOAD_PRODUCT = `${PRODUCTS}/loadProduct`;
export const PRODUCTS_A_LOAD_PRODUCTS = `${PRODUCTS}/loadProducts`;
export const PRODUCTS_A_REFRESH_FIELDS = `${PRODUCTS}/refreshFields`;
export const PRODUCTS_G_PRODUCTS = `${PRODUCTS}/products`;
export const PRODUCTS_M_ADD_PRODUCT = `${PRODUCTS}/addProduct`;
export const PRODUCTS_M_ADD_PRODUCTS = `${PRODUCTS}/addProducts`;
export const PRODUCTS_M_CLEAR = `${PRODUCTS}/clear`;

export const POINTOFSERVICE = 'pointofservice';
export const POINTOFSERVICE_A_UPDATE = `${POINTOFSERVICE}/update`;
export const POINTOFSERVICE_A_UPDATE_STOREAVAILABILITY_TRUSTIES = `${POINTOFSERVICE}/updateStoreAvailabilityTrusties`;
export const POINTOFSERVICE_M_STORE_RESPONSE = `${POINTOFSERVICE}/storeResponse`;
export const POINTOFSERVICE_M_STORE_STOREAVAILABILITY_TRUSTIES = `${POINTOFSERVICE}/storeStoreAvailabilityTrusties`;

export const ROUTING = 'routing';
export const ROUTING_A_SPA_DATA = `${ROUTING}/spaData`;
export const ROUTING_A_STORE_I18N_URLS = `${ROUTING}/storeI18NUrls`;
export const ROUTING_A_DATALAYER = `${ROUTING}/datalayer`;
export const ROUTING_A_NAVIGATE = `${ROUTING}/navigate`;
export const ROUTING_M_ADD_LINKS = `${ROUTING}/addLinks`;
export const ROUTING_M_ALLOW_ROUTING = `${ROUTING}/allowRouting`;
export const ROUTING_M_SPA_DATA = `${ROUTING}/spaData`;
export const ROUTING_M_SET_PAGE_TYPE = `${ROUTING}/setPageType`;
export const ROUTING_M_SET_SCROLL_TARGET = `${ROUTING}/setScrollTarget`;
export const ROUTING_M_DATALAYER = `${ROUTING}/datalayer`;

export const SEARCH = 'search';
export const SEARCH_A_USE_RESPONSE = `${SEARCH}/useSearchResponse`;
export const SEARCH_A_PERFORM_SEARCH = `${SEARCH}/performSearch`;
export const SEARCH_A_NEXT_PAGE = `${SEARCH}/nextPage`;
export const SEARCH_A_PREVIOUS_PAGE = `${SEARCH}/prviousPage`;
export const SEARCH_A_SELECT_PAGE = `${SEARCH}/selectPage`;
export const SEARCH_A_ELEMENTS_PER_PAGE = `${SEARCH}/elementsPerPage`;
export const SEARCH_A_SORTING = `${SEARCH}/sorting`;
export const SEARCH_A_RESET_FACET = `${SEARCH}/resetFacet`;
export const SEARCH_A_RESET_FACETS = `${SEARCH}/resetFacets`;
export const SEARCH_A_REMOVE_FACET_VALUE = `${SEARCH}/removeFacetValue`;
export const SEARCH_A_REFINE_SEARCH = `${SEARCH}/refineSearch`;
export const SEARCH_A_LOAD_CATEGORIES = `${SEARCH}/loadCategories`;
export const SEARCH_G_INITIAL_STATE = `${SEARCH}/initialState`;
export const SEARCH_G_NEW_SEARCH_REQUEST = `${SEARCH}/newSearchRequest`;
export const SEARCH_M_STORE_RESPONSE = `${SEARCH}/storeSearchResponse`;
export const SEARCH_M_STORE_RESULTS = `${SEARCH}/storeSearchResults`;
export const SEARCH_M_ACTIVATE_PAGE = `${SEARCH}/activatePage`;
export const SEARCH_M_SHOW_ERROR = `${SEARCH}/showError`;
export const SEARCH_M_REQUEST_FACETS = `${SEARCH}/requestFacets`;
export const SEARCH_M_RESPONSE_FACETS = `${SEARCH}/responseFacets`;
export const SEARCH_M_IN_PROGRESS = `${SEARCH}/searchInProgress`;
export const SEARCH_M_RESTORE_STATE = `${SEARCH}/restoreState`;
export const SEARCH_M_RESET_STATE = `${SEARCH}/resetState`;
export const SEARCH_M_SAVE_CATEGORIES = `${SEARCH}/saveCategories`;
export const SEARCH_M_SAVE_SELECTION = `${SEARCH}/saveSelection`;

export const SEARCHTRACKING = 'search_tracking';
export const SEARCHTRACKING_A_SEND_FACTFINDER_TRACKING_EVENT = `${SEARCHTRACKING}/sendFactfinderTrackingEvent`;
export const SEARCHTRACKING_M_SAVE_CHANGED_FACETS = `${SEARCHTRACKING}/saveChangedFacets`;

export const SEARCHUNSAVED = 'search_unsaved';
export const SEARCHUNSAVED_A_SAVE_FACET = `${SEARCHUNSAVED}/saveFacet`;
export const SEARCHUNSAVED_A_SAVE_FACETS = `${SEARCHUNSAVED}/saveFacets`;
export const SEARCHUNSAVED_G_CHANGED = `${SEARCHUNSAVED}/changed`;
export const SEARCHUNSAVED_M_RESET_UNSAVED_FACET = `${SEARCHUNSAVED}/resetUnsavedFacet`;
export const SEARCHUNSAVED_M_RESET_UNSAVED_FACETS = `${SEARCHUNSAVED}/resetUnsavedFacets`;
export const SEARCHUNSAVED_M_SET_PRICE_VALUE = `${SEARCHUNSAVED}/setPriceValue`;
export const SEARCHUNSAVED_M_TOGGLE_FACET_VALUE = `${SEARCHUNSAVED}/toggleFacetValue`;

export const SERVERCONTEXT = 'servercontext';
export const SERVERCONTEXT_M_INIT = `${SERVERCONTEXT}/init`;
export const SERVERCONTEXT_M_SAVE_HYBRIS_SESSION = `${SERVERCONTEXT}/saveHybrisSession`;
export const SERVERCONTEXT_M_RESOLUTION = `${SERVERCONTEXT}/setResolution`;

export const SERVERSETTINGS = 'serversettings';
export const SERVERSETTINGS_A_UPDATE_SETTINGS = `${SERVERSETTINGS}/updateSettings`;
export const SERVERSETTINGS_M_SAVE_SETTINGS = `${SERVERSETTINGS}/saveSettings`;

export const TRACKING = 'tracking';
export const TRACKING_A_REGISTER_PASSED_EVENT = `${TRACKING}/registerPassedEvent`;
export const TRACKING_A_REPORT_PERFORMANCE_MARKS = `${TRACKING}/reportPerformanceMarks`;
export const TRACKING_A_RESET_READY_TO_SEND_STATE = `${TRACKING}/resetReadyToSendState`;
export const TRACKING_M_SET_PERFORMANCE_MARK = `${TRACKING}/setPerformanceMark`;
export const TRACKING_M_END_UNFINISHED_SSR_MARKS = `${TRACKING}/endUnfinishedSsrMarks`;
export const TRACKING_M_CLOSE_PERFORMANCE_MARK = `${TRACKING}/closePerformanceMark`;
export const TRACKING_M_ADD_PERFORMANCE_MARK = `${TRACKING}/addPerformanceMark`;
export const TRACKING_M_UPDATE_PASSED_EVENT = `${TRACKING}/updatePassedEvent`;
export const TRACKING_M_SET_READY_TO_SEND = `${TRACKING}/setReadyToSend`;

export const USER = 'user';
export const USER_A_FORGOTTEN_PASSWORD = `${USER}/forgottenPassword`;
export const USER_A_GENDER = `${USER}/changeGender`;
export const USER_A_GUEST_REGISTRATION = `${USER}/guestRegistration`;
export const USER_A_LOGIN = `${USER}/login`;
export const USER_A_REGISTRATION = `${USER}/registration`;
export const USER_A_UPDATE = `${USER}/update`;
export const USER_A_CHANGE_EMAIL = `${USER}/changeEmail`;
export const USER_A_CHANGE_PASSWORD = `${USER}/changePassword`;
export const USER_A_REMOVE_CREDIT_CARD = `${USER}/removeCreditCard`;
export const USER_G_HAS_ERROR = `${USER}/hasError`;
export const USER_M_SAVE_GENDER = `${USER}/gender`;
export const USER_M_SAVE = `${USER}/save`;
export const USER_M_SAVE_RESPONSE = `${USER}/saveResponse`;

export const USER_ADDRESSBOOK = 'user_addressbook';
export const USER_ADDRESSBOOK_A_LOAD = `${USER_ADDRESSBOOK}/load`;
export const USER_ADDRESSBOOK_A_ADD = `${USER_ADDRESSBOOK}/add`;
export const USER_ADDRESSBOOK_A_EDIT = `${USER_ADDRESSBOOK}/edit`;
export const USER_ADDRESSBOOK_A_REMOVE = `${USER_ADDRESSBOOK}/remove`;
export const USER_ADDRESSBOOK_M_SAVE = `${USER_ADDRESSBOOK}/save`;

export const UX = 'ux';
export const UX_G_INTERACTED = `${UX}/interacted`;
export const UX_G_NUXT_IS_READY = `${UX}/nuxtIsReady`;
export const UX_G_PAGE_SWITCH_IN_PROGRESS = `${UX}/pageSwitchInProgress`;
export const UX_G_PAGE_SWITCH_HOOK_ACTIVE = `${UX}/isPageSwitchHookActive`;
export const UX_M_INTERACT = `${UX}/interact`;
export const UX_M_NUXTREADY = `${UX}/nuxtready`;
export const UX_M_CMP_CHOICE_EXISTS = `${UX}/cmpUserChoiceExists`;
export const UX_M_PAGE_SWITCH_STATUS = `${UX}/pageSwitchStatus`;
export const UX_M_SET_FOOTER_VISIBLE = `${UX}/setFooterVisible`;
export const UX_M_SET_PAGE_SWITCH_HOOK_ACTIVE = `${UX}/setSkipPageSwitchHookActive`;

export const VOUCHER = 'voucher';
export const VOUCHER_A_GET_USER_BONUS_GIFTCARDS = `${VOUCHER}/getUserBonusGiftCards`;
export const VOUCHER_A_REDEEM = `${VOUCHER}/redeem`;
export const VOUCHER_A_RELEASE = `${VOUCHER}/release`;
export const VOUCHER_A_SET_MESSAGE = `${VOUCHER}/setMessage`;
export const VOUCHER_M_SET_CURRENT_INPUT = `${VOUCHER}/setCurrentInput`;
export const VOUCHER_M_SET_MESSAGE = `${VOUCHER}/setMessage`;
export const VOUCHER_M_SET_USER_BONUS_GIFTCARDS = `${VOUCHER}/setUserBonusGiftCards`;
export const VOUCHER_M_SET_VOUCHERS = `${VOUCHER}/setVouchers`;
