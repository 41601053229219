const performance =
	typeof window !== 'undefined' && typeof window.performance !== 'undefined'
		? window.performance
		: undefined;

export function measureStartEnd(measureName: string, start: number, end: number): void {
	if (performance) return;

	measureOptions(measureName, {
		start,
		end,
	});
}

export function measureStartDuration(measureName: string, start: number, duration: number): void {
	if (performance) return;

	measureOptions(measureName, {
		start,
		duration,
	});
}

export function measureOptions(
	name: string,
	options: {
		start?: number;
		end?: number;
		duration?: number;
		detail?: unknown;
	},
): void {
	if (!performance) return;

	// @ts-ignore
	performance.measure(name, options);
}
