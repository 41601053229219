import { setSafeTimeout } from './timeout';

// Lets provided function run in its own task to avoid long tasks ( > 50ms) in main thread
export function runTask<Func extends (...args: any) => any>(func: Func): void {
	// Skip when not running in window as there will be no user interaction the ui would have to react on
	if (typeof window === 'undefined') {
		func();
		return;
	}

	useZeroTimeout(func);
}

export function runTaskWithPromise<Func extends (...args: any) => any>(
	func: Func,
): Promise<ReturnType<Func>> {
	// Skip when not running in window as there will be no user interaction the ui would have to react on
	if (typeof window === 'undefined') {
		return Promise.resolve(func());
	}

	return new Promise((resolve) => {
		useZeroTimeout(() => {
			resolve(func());
		});
	});
}

// Use async/await to create yield points
// https://web.dev/optimize-long-tasks/?utm_source=devtools#use-asyncawait-to-create-yield-points
export function yieldToMain(): Promise<undefined> {
	// Skip when not running in window as there will be no user interaction the ui would have to react on
	if (typeof window === 'undefined') {
		return Promise.resolve(undefined);
	}

	// If new performance relevant feature is already available in browser, use this
	// (For more info, see https://developer.chrome.com/blog/introducing-scheduler-yield-origin-trial/ .)
	if ('scheduler' in window && 'yield' in (window as any).scheduler) {
		return (window as any).scheduler.yield();
	}

	// ... otherwise use setTimeout which has the nice side effect to break up tasks, even with time of 0 set
	return new Promise((resolve): void => {
		setTimeout(resolve, 0);
	});
}

// If no window object present, execute function immediately
// otherwise use setZeroTimeout performance function and initialize if not yet exists
function useZeroTimeout(func: Function) {
	if (typeof window === 'undefined') {
		func();
	}

	if (!('setZeroTimeout' in window)) {
		initSetZeroTimeout();
	}

	// Use fallback when setZeroTimeout still does not exist
	if (!('setZeroTimeout' in window)) {
		(window as any).setTimeout(func);
	}

	(window as any).setZeroTimeout(func);
}

// If in browser, create function that tries to utilize postMessage to break up tasks (even faster than setTimeout
// with "0") - Otherwise, use setTimeout(fn, delay) as fallback.
function initSetZeroTimeout() {
	// @ts-ignore
	window.setZeroTimeout = (function (windowObject: Window & typeof globalThis) {
		if (!('postMessage' in windowObject)) return;

		const timeouts: Function[] = [];
		const msgName = 'asc0tmot';

		// Like setTimeout, but only takes a function argument.  There's no time argument (always zero) and no arguments
		// (you have to use a closure).
		const _postTimeout = function (fn: Function) {
			timeouts.push(fn);
			windowObject.postMessage(msgName, windowObject.location.origin);
		};

		const _handleMessage = function (eventObject: any) {
			if (eventObject.source !== windowObject || eventObject.data !== msgName) return;

			eventObject.stopPropagation?.();

			if (timeouts.length) {
				try {
					timeouts.shift()?.();
				} catch (error) {
					// Throw in an asynchronous closure to prevent setZeroTimeout from hanging due to error
					setSafeTimeout(
						(function (error) {
							return function () {
								throw (error as Error).stack || error;
							};
						})(error),
						0,
					);
				}
			}

			if (timeouts.length) {
				// Is more left?
				windowObject.postMessage(msgName, windowObject.location.origin);
			}
		};

		if ('addEventListener' in windowObject) {
			windowObject.addEventListener('message', _handleMessage, true);
			return _postTimeout;
		} else if ('attachEvent' in windowObject) {
			(windowObject as any).attachEvent('onmessage', _handleMessage);
			return _postTimeout;
		}

		return setSafeTimeout;
		// prettier-ignore
	})(window);
}
