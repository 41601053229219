import { Store, ActionContext } from 'vuex';
import _size from 'lodash-es/size';
import _omit from 'lodash-es/omit';
import { elapsedTime } from '@/node_modules/@osp/utils/src/performance';
import { Image, Product, ProductLabel, ProductList } from '@/generated/hybris-raml-api';
import { get } from '@/app-utils/http';
import { RootState, ProductsState, EnhancedProduct, ProductImageType } from '~/@api/store.types';
import {
	mapFn,
	PRODUCTS_M_ADD_PRODUCT,
	PRODUCTS_M_ADD_PRODUCTS,
	PRODUCTS_M_CLEAR,
	PRODUCTS_A_LOAD_PRODUCT,
	PRODUCTS_G_PRODUCTS,
	PRODUCTS_A_LOAD_PRODUCTS,
	PRODUCTS_A_REFRESH_FIELDS,
} from '~/@constants/store';
import { backend } from '~/@api/backend';
import { PRODUCT_GROUP_DEFAULT } from '~/@constants/global';
import { useServerContextStore } from '~/@api/store/serverContextApi';

// Initial state -----------------------------------------------------------------------------------

const state = () => ({
	products: {},
});

// Mutations ---------------------------------------------------------------------------------------

const mutations = {
	[mapFn(PRODUCTS_M_ADD_PRODUCT)](
		_state: ProductsState,
		payload: { product: Product; group?: string },
	) {
		const group = payload.group || PRODUCT_GROUP_DEFAULT;
		const product = payload.product;

		_state.products = {
			..._state.products,
			[group]: {
				..._state.products[group],
				[product.code]: enhanceProduct(product),
			},
		};
	},

	[mapFn(PRODUCTS_M_ADD_PRODUCTS)](
		_state: ProductsState,
		payload: { products: Product[]; group?: string },
	) {
		const group = payload.group || PRODUCT_GROUP_DEFAULT;
		const enhancedProducts = {};

		(payload.products || []).forEach(
			(product) => (enhancedProducts[product.code] = enhanceProduct(product)),
		);

		_state.products = {
			..._state.products,
			[group]: {
				..._state.products[group],
				...enhancedProducts,
			},
		};
	},

	[mapFn(PRODUCTS_M_CLEAR)](_state: ProductsState, group: string | RegExp = PRODUCT_GROUP_DEFAULT) {
		if (group instanceof RegExp) {
			_state.products = _omit(
				_state.products,
				Object.keys(_state.products).filter((productGroup) => group.test(productGroup)),
			);
		} else {
			_state.products = _omit(_state.products, group);
		}
	},
};

// Actions -----------------------------------------------------------------------------------------

const actions = {
	async [mapFn(PRODUCTS_A_LOAD_PRODUCT)](
		context: ActionContext<ProductsState, RootState>,
		payload: {
			productCode: string;
			group: string;
			updateExternalStocks: boolean;
		},
	) {
		const { productCode, group, updateExternalStocks } = payload;
		const store = this as Store<RootState>;
		const cacheKey = `{product}-${
			useServerContextStore(this).state.session.language
		}-${group}-${productCode}`;
		let product = (await store.$cache.get(cacheKey)) as Product;

		if (!product) {
			// If the product is not found in the cache: load and cache it
			product = await (
				await get(
					backend.API.V2.PRODUCT(this, productCode, [], updateExternalStocks),
					{
						store: this,
					},
					{},
				)
			).json();

			store.$cache.set(cacheKey, product);
		} else {
			// If the product is found in the cache: update realTimeInfos
			const realTimeProductInfo = await (
				await get(
					backend.API.V2.PRODUCT(
						this,
						productCode,
						['BREADCRUMBS', 'COLORVARIANTS', 'LABELS', 'PRICE'],
						updateExternalStocks,
					),
					{
						store: this,
					},
					{},
				)
			).json();

			product = { ...product, ...realTimeProductInfo };
		}

		context.commit(mapFn(PRODUCTS_M_ADD_PRODUCT), { group, product });
		elapsedTime(PRODUCTS_A_LOAD_PRODUCT);
	},

	async [mapFn(PRODUCTS_A_LOAD_PRODUCTS)](
		context: ActionContext<ProductsState, RootState>,
		payload: {
			productCodes: string[];
			group: string;
			updateExternalStocks: boolean;
		},
	) {
		const { productCodes, group, updateExternalStocks } = payload;
		const store = this as Store<RootState>;
		const cacheKey = `{product}-${
			useServerContextStore(this).state.session.language
		}-${group}-${productCodes}`;
		let productList = (await store.$cache.get(cacheKey)) as ProductList;

		if (!productList) {
			// If the product is not found in the cache: load and cache it
			productList = await (
				await get(
					backend.API.V2.PRODUCTS(this, productCodes, [], updateExternalStocks),
					{
						store: this,
					},
					{},
				)
			).json();

			store.$cache.set(cacheKey, productList);
		} else {
			// If the product is found in the cache: update realTimeInfos
			const realTimeProductInfo: ProductList = await (
				await get(
					backend.API.V2.PRODUCTS(
						this,
						productCodes,
						['BREADCRUMBS', 'COLORVARIANTS', 'LABELS', 'PRICE'],
						updateExternalStocks,
					),
					{
						store: this,
					},
					{},
				)
			).json();

			productList.products = productList.products.map((product, index) => ({
				...product,
				...realTimeProductInfo.products[index],
			}));
		}

		context.commit(mapFn(PRODUCTS_M_ADD_PRODUCTS), { group, products: productList.products });
		elapsedTime(PRODUCTS_A_LOAD_PRODUCTS);
	},

	async [mapFn(PRODUCTS_A_REFRESH_FIELDS)](
		context: ActionContext<ProductsState, RootState>,
		payload: {
			productCodes: string[];
			fields: string[];
			group: string;
			updateExternalStocks: boolean;
		},
	) {
		const { productCodes, fields, group, updateExternalStocks } = payload;

		// Ensure code is loaded as well for merging with already stored products
		if (!fields.includes('CODE')) {
			fields.push('CODE');
		}

		// Get productList with fields as requested
		const productfieldsList: ProductList = await (
			await get(
				backend.API.V2.PRODUCTS(this, productCodes, fields, updateExternalStocks),
				{
					store: this,
				},
				{},
			)
		).json();
		// Get stored products
		let storedProductList = context.rootGetters[PRODUCTS_G_PRODUCTS](productCodes, group);

		// Update product items
		storedProductList = storedProductList.map((storedProduct) => ({
			...storedProduct,
			...productfieldsList.products.find((fp) => fp.code === storedProduct.code),
		}));

		// Put products back to store
		context.commit(mapFn(PRODUCTS_M_ADD_PRODUCTS), { group, products: storedProductList });
		elapsedTime(PRODUCTS_A_REFRESH_FIELDS);
	},
};

// Getters -----------------------------------------------------------------------------------------

const getters = {
	[mapFn(PRODUCTS_G_PRODUCTS)](_state: ProductsState) {
		return (productCodes: string[], group: string = PRODUCT_GROUP_DEFAULT): EnhancedProduct[] =>
			productCodes
				.filter((code) => !!_state.products[group] && !!_state.products[group][code])
				.map((code: string): EnhancedProduct => _state.products[group][code]);
	},
};

export default {
	state,
	mutations,
	actions,
	getters,
};

// Helpers -----------------------------------------------------------------------------------------

export const enhanceProduct = (product: Product): EnhancedProduct => {
	(product as EnhancedProduct).computedProperties = {
		get images() {
			return computedImages(product);
		},
		get inStock() {
			return computedStock(product);
		},
		get labels() {
			return computedLabels(product);
		},
		get showSizeSelector() {
			return computedShowSizeSelector(product);
		},
		get url() {
			return computedUrl(product);
		},
	};

	return product as EnhancedProduct;
};

const computedImages = (product: Product) => {
	const productImageType = (img: Image): ProductImageType => {
		switch (img.assetId.slice(0, 2)) {
			case ProductImageType.MODEL:
				return ProductImageType.MODEL;
			case ProductImageType.SHADOW:
				return ProductImageType.SHADOW;
			default:
				return ProductImageType.PRODUCT;
		}
	};
	const find = (type) =>
		(product.images || [])
			.filter((img) => img.imageType === type)
			.map((img) => ({ ...img, productImageType: productImageType(img) }));

	return {
		gallery: find('GALLERY'),
		primary: find('PRIMARY')[0] || {
			assetId: 'undefined',
			productImageType: ProductImageType.PRODUCT,
		},
	};
};

const computedLabels = (product: Product) => {
	const reducer = (acc: {}, currentValue: ProductLabel) => {
		const position = currentValue.position || 'LEFT';
		let labelAlreadyOnProduct = false;

		// Avoid adding same label multiple times
		['TOP', 'RIGHT', 'BOTTOM', 'LEFT'].forEach((checkPosition) => {
			if (labelAlreadyOnProduct) return;

			labelAlreadyOnProduct = acc[checkPosition]?.some(
				(label: ProductLabel) => label.code === currentValue.label,
			);
		});

		if (labelAlreadyOnProduct) {
			return acc;
		}

		if (acc[position]) {
			acc[position].push(currentValue);
		} else {
			acc[position] = [currentValue];
		}

		return acc;
	};

	let labels = {};

	if (product.promotionTypes) {
		labels = product.promotionTypes.reduce(reducer, labels);
	}

	if (product.labels) {
		labels = product.labels.reduce(reducer, labels);
	}

	return labels;
};

const computedShowSizeSelector = (product: Product) => {
	if ((product.colorVariants || []).length === 0 || (product.selectedVariant || []).length === 0) {
		return false;
	}

	return (
		(product.colorVariants[product.selectedVariant[0]].sizeVariants || []).filter(
			(variant) => variant.visible,
		).length > 0
	);
};

const computedStock = (product: Product): boolean => {
	const colorVariantIndex = (product.selectedVariant || [])[0];
	const selectedColorVariant = (product.colorVariants || [])[colorVariantIndex];

	if (!selectedColorVariant) {
		return null; // For example suggest-search response product
	}

	if (product.purchasable) {
		const stock =
			_size(product.selectedVariant) > 1
				? // If size is selected
					(selectedColorVariant.sizeVariants || [])[product.selectedVariant[1]].stock
				: // If color is selected
					selectedColorVariant.stock;

		return stock ? stock.status !== 'OUTOFSTOCK' : false;
	}

	// If is not purchasable (a color is selected but non of its sizes)
	return (selectedColorVariant.sizeVariants || [])
		.filter((sizeVariant) => sizeVariant.stock)
		.map((sizeVariant) => sizeVariant.stock)
		.some((stock) => stock.status !== 'OUTOFSTOCK');
};

const computedUrl = (product: Product): string => {
	const colorVariantIndex = (product.selectedVariant || [])[0];
	const selectedColorVariant = (product.colorVariants || [])[colorVariantIndex];

	if (selectedColorVariant) {
		const sizeIndex = product.selectedVariant[1];
		const sizeVariant = (selectedColorVariant.sizeVariants || [])[sizeIndex];

		return sizeVariant ? sizeVariant.url : selectedColorVariant.url;
	}

	return product.url;
};
