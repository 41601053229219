import type { Store } from 'vuex';
import { clientInitStoreModule, useStoreModule } from '../useStoreModule';
import dynamicyieldStore from './dynamicyield.store';
import dynamicyieldApi from './dynamicyield.api';
import { DynamicYieldState, RootState } from '~/@api/store.types';
import { DYNAMICYIELD } from '~/@constants/store';

export function useDynamicyieldStore(store: Store<RootState>) {
	return useStoreModule<DynamicYieldState, ReturnType<typeof dynamicyieldApi>>(
		DYNAMICYIELD,
		store,
		dynamicyieldStore,
		dynamicyieldApi,
	);
}

export async function clientInitDynamicyieldStore(store: Store<RootState>): Promise<void> {
	return await clientInitStoreModule(DYNAMICYIELD, store, dynamicyieldStore, dynamicyieldApi);
}
