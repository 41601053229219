import type { Store } from 'vuex';
import { Context } from '@nuxt/types';
import { Route } from 'vue-router';
import { elapsedTime } from '@/node_modules/@osp/utils/src/performance';
import { setSafeTimeout } from '@/node_modules/@osp/design-system/assets/js/utilities/timeout';
import { useUserStore } from '~/@api/store/userApi';
import { PageTypes, RootState } from '~/@api/store.types';

/**
 * Handling requests to login-pages
 * Redirects to cart-page if already logged in
 */
export default async (context: Context) => {
	const toPageType = getPageType(context.route);

	if ([PageTypes.LOGIN, PageTypes.CHECKOUTLOGIN].includes(toPageType)) {
		const fromPageType = getPageType(context.from);

		if ((await checkIfLoggedIn(context.store)) || fromPageType === PageTypes.CHECKOUT) {
			context.redirect(302, '/cart.html');
		}
	}

	elapsedTime('middleware: login-guard');
};

const getPageType = (route: Route) =>
	route?.matched.find((match) => match.meta?.pageType)?.meta.pageType;

const checkIfLoggedIn = async (store: Store<RootState>) => {
	const { state: userState } = useUserStore(store);
	const wait = (ms) => new Promise((resolve, _reject) => setSafeTimeout(resolve, ms));

	// Max 20 seconds
	for (let retries = 0; retries < 500 && !userState.user.loaded; retries++) {
		await wait(20);
	}

	return userState.user.loggedIn;
};
