import { track } from '../tracking';

export interface PromotionTrackingParam {
	creative?: string;
	group?: string;
	id: string;
	name: string;
	position?: number;
	cd122?: string;
	cd123?: string;
}

export const eecPromotionClick = (promotion: PromotionTrackingParam) => {
	track('eec.promotionClick', {
		ecommerce: {
			promoClick: {
				promotions: [promotion],
			},
		},
	});
};
