import { Store } from 'vuex';
import { Messagebox, RootState } from '~/@api/store.types';
import {
	MESSAGEBOX_A_HANDLE_ERROR,
	MESSAGEBOX_M_DISMISS,
	MESSAGEBOX_M_SAVE,
} from '~/@constants/store';

export default function (store: Store<RootState>) {
	return {
		dismiss: (messagebox: Messagebox) =>
			store.commit(MESSAGEBOX_M_DISMISS, { messagebox }, { root: true }),

		dismissAll: (group?: string) =>
			store.commit(MESSAGEBOX_M_DISMISS, { messagebox: null, group }, { root: true }),

		addMessage: (messagebox: Messagebox, path?: string) =>
			store.commit(MESSAGEBOX_M_SAVE, [{ ...messagebox, path: path || 'global' }], { root: true }),

		handleError: (response: any) =>
			store.dispatch(MESSAGEBOX_A_HANDLE_ERROR, response, { root: true }),
	};
}
