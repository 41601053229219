import { Context } from '@nuxt/types';
import { runTask } from '@/node_modules/@osp/design-system/assets/js/utilities/runTask';
import { getPerformanceTrackingConfig } from '~/app-utils/tracking.utils';
import { OspNuxtRuntimeConfig } from '~/@types/OspNuxtRuntimeConfig';
import { importSpaClsTracker } from '~/app-utils/dynamic-imports';

async function PluginIntegration(context: Context) {
	const trackingConfig = getPerformanceTrackingConfig(context.$config as OspNuxtRuntimeConfig);
	if (!trackingConfig.cls.active) {
		return;
	}

	// Only import SpaClsTracker code if tracking is activated
	const { SpaClsTracker } = await importSpaClsTracker();
	SpaClsTracker.observeLayoutShifts(trackingConfig.cls);
}

// Do not defer this plugin until Nuxt is ready, to watch for occurring layout shifts right from the beginning
export default function (context: Context) {
	runTask(() => {
		PluginIntegration(context);
	});
}
