import { Store } from 'vuex';
import { RouteRecord } from 'vue-router';
import {
	CmsContentEntry,
	CmsContentEntryReference,
	CmsContentState,
	CmsDereferencedEntry,
	RootState,
} from '~/@api/store.types';
import {
	CMSCONTENT_A_LOAD_SINGLE_COMPONENT,
	CMSCONTENT_A_UPDATE,
	CMSCONTENT_G_DEREFERENCE,
	CMSCONTENT_G_REFERENCE_CONTENT,
	CMSCONTENT_G_SLOT_CONTENT,
	CMSCONTENT_G_SLOT_HAS_CONTENT,
	CMSCONTENT_M_SAVE,
} from '~/@constants/store';
import { SpaType } from '~/generated/hybris-raml-api';

export default function (store: Store<RootState>) {
	return {
		slot: (id: string): CmsContentEntryReference[] => store.getters[CMSCONTENT_G_SLOT_CONTENT](id),

		slotHasContent: (id: string): CmsContentEntryReference[] =>
			store.getters[CMSCONTENT_G_SLOT_HAS_CONTENT](id),

		element: (id: string): CmsContentEntry => store.getters[CMSCONTENT_G_REFERENCE_CONTENT](id),

		lookupReference: (ref: CmsContentEntryReference): CmsContentEntry =>
			(store.getters[CMSCONTENT_G_DEREFERENCE](store, ref) as CmsDereferencedEntry).content,

		dereference: (ref: CmsContentEntryReference): CmsDereferencedEntry =>
			store.getters[CMSCONTENT_G_DEREFERENCE](store, ref),

		loadSingleCMSComponent: (componentUid: string) =>
			store.dispatch(CMSCONTENT_A_LOAD_SINGLE_COMPONENT, componentUid, { root: true }),

		update: (
			spaType: SpaType,
			identifier: string,
			full = false,
			location?: string,
			routeMatched?: RouteRecord[],
		) =>
			store.dispatch(
				CMSCONTENT_A_UPDATE,
				{ spaType, identifier, full, location, routeMatched },
				{ root: true },
			),

		save: (newState: CmsContentState) => store.commit(CMSCONTENT_M_SAVE, newState, { root: true }),
	};
}
