import { Store } from 'vuex';
import { RootState } from '~/@api/store.types';

// Vuex plugin which dispatches a window custom event after every mutation -------------------------

const trackingPlugin = (store: Store<RootState>) => {
	store.subscribe((mutation, state) => {
		const customEvent = new CustomEvent(`store:${mutation.type}`, {
			detail: { mutation, state },
		});

		document.body.dispatchEvent(customEvent);
	});
};

// Nuxt state info ---------------------------------------------------------------------------------

export const plugins = process.client ? [trackingPlugin] : [];
