import { Context } from '@nuxt/types';
import { elapsedTime } from '@/node_modules/@osp/utils/src/performance';
import { useRoutingStore } from '~/@api/store/routingApi';

// eslint-disable-next-line require-await
export default async (context: Context) => {
	const pageType = context.route.matched.find((page) => page.meta?.pageType)?.meta.pageType;

	if (pageType) {
		useRoutingStore(context.store).api.setPageType(pageType);
	}

	elapsedTime('middleware: set-page-type');
};
