import { track } from '../tracking';
import { Messagebox } from '~/@api/store.types';
import type { GTM } from '~/@types/vue';

export const messagebox = (mb: Messagebox, $gtm?: GTM) =>
	track(
		'messagebox',
		{
			dismissable: mb.dismissible,
			group: mb.key || 'nogroup',
			text: mb.text,
			type: mb.type,
		},
		$gtm,
	);
