export enum MediaLoading {
	LAZY = 'lazy',
	EAGER = 'eager',
}

export interface MediaAspectRatio {
	ratio?: number;
	width: number;
	height: number;
}

export interface MediaSource {
	media: string;
	srcset: string;
}

export interface Media {
	uid?: string;
	src: string;
	sources: MediaSource[];
	placeholder?: string;
	alt: string;
	title?: string;
}

export enum MediaObjectPosition {
	BOTTOM = 'tw-object-bottom',
	CENTER = 'tw-object-center',
	LEFT = 'tw-object-left',
	LEFT_BOTTOM = 'tw-object-left-bottom',
	LEFT_TOP = 'tw-object-left-top',
	RIGHT = 'tw-object-right',
	RIGHT_BOTTOM = 'tw-object-right-bottom',
	RIGHT_TOP = 'tw-object-right-top',
	TOP = 'tw-object-top',
}

export enum MediaPixelDensity {
	DEFAULT = 1,
	RETINA = 2,
}
