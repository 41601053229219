import { importElapsedTime, importRunTask } from '~/app-utils/dynamic-imports';

export function elapseMiddlewareTime(message: string) {
	importRunTask().then(({ runTask }) => {
		runTask(() => {
			importElapsedTime().then(({ elapsedTime }) => {
				elapsedTime(message);
			});
		});
	});
}
