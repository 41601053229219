import type { Store } from 'vuex';
import { EnhancedBrand } from '@/node_modules/@osp/design-system/types/product';
import { mapImage } from './image';
import { url } from '~/@api/backend';
import { RootState } from '~/@api/store.types';
import { Brand } from '~/generated/hybris-raml-api';

export function mapRamlBrand(store: Store<RootState>, brand: Brand): EnhancedBrand {
	return {
		uid: brand.code,
		image: mapImage(store, brand.image, {}, { preset: 'v4-brandteaser' }),
		name: brand.name,
		url: url(store, brand.url),
	};
}
