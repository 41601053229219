import type { Store } from 'vuex';
import { Media, MediaObjectPosition } from '@/node_modules/@osp/design-system/types/media';
import { calculateImageUrl, createImageSources, ImageOptions } from '../images';
import { useMediaqueryStore } from '~/@api/store/mediaqueryApi';
import { MediaqueryDeviceCategory, RootState } from '~/@api/store.types';
import { CmsImagesWrapper, Image } from '~/generated/hybris-raml-api';
import { useI18nStore } from '~/@api/store/i18nApi';

const FALLBACK_TRANSLATION_KEY_PREFIX = 'v2.assets.mapper.image.fallback';

export function mapImage(
	store: Store<RootState>,
	image: Image,
	breakpoints: { [breakpoint: string]: number },
	options: ImageOptions,
	isObligatory = false,
): Media {
	if (image) {
		return {
			alt: image.altText,
			title: image.title,
			src: calculateImageUrl(store, image, options),
			sources: createImageSources(store, image, breakpoints, options),
		};
	}

	if (isObligatory) {
		return _createImageFallback(store, breakpoints, options);
	}

	return null;
}

export function mapImageWrapper(
	store: Store<RootState>,
	wrapper: CmsImagesWrapper,
	breakpoints: { [breakpoint: string]: number },
	defaultWidth = 768,
	ignoreRetina = false,
	isObligatory = false,
): { image: Media; imageObjectPosition: MediaObjectPosition } {
	let image = wrapper.desktop || null;

	if (
		(useMediaqueryStore(store).state.device?.category === MediaqueryDeviceCategory.MOBILE &&
			wrapper.mobile) ||
		(useMediaqueryStore(store).state.device?.category === MediaqueryDeviceCategory.TABLET &&
			wrapper.mobile &&
			!wrapper.desktop)
	) {
		image = wrapper.mobile;
	}

	return {
		image: mapImage(store, image, breakpoints, { width: defaultWidth, ignoreRetina }, isObligatory),
		imageObjectPosition: MediaObjectPosition[image?.imageObjectPosition],
	};
}

function _createImageFallback(
	store: Store<RootState>,
	breakpoints: { [breakpoint: string]: number },
	options: ImageOptions,
): Media {
	const { api: i18nApi } = useI18nStore(store);
	const image: Image = { assetId: i18nApi.translate(`${FALLBACK_TRANSLATION_KEY_PREFIX}.assetId`) };

	return {
		alt: i18nApi.translate(`${FALLBACK_TRANSLATION_KEY_PREFIX}.alt`),
		title: i18nApi.translate(`${FALLBACK_TRANSLATION_KEY_PREFIX}.title`),
		src: calculateImageUrl(store, image, options),
		sources: createImageSources(store, image, breakpoints, options),
	};
}
