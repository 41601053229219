import _get from 'lodash-es/get';
import _includes from 'lodash-es/includes';
import {
	MediaqueryState,
	MediaqueryDeviceCategory,
	MediaqueryDeviceOrientation,
	Breakpoint,
	MediaqueryDimensions,
} from '~/@api/store.types';
import {
	mapFn,
	MEDIAQUERY_M_UPDATE,
	MEDIAQUERY_M_DIMENSIONS,
	MEDIAQUERY_G_IS_DESKTOP,
	MEDIAQUERY_G_IS_TABLET,
	MEDIAQUERY_G_IS_MOBILE,
} from '~/@constants/store';

// Initial state -----------------------------------------------------------------------------------

const state = (): MediaqueryState => ({
	currentBreakpoint: null,
	lastBreakpoint: null,
	dimensions: {
		height: process.client ? window.innerHeight : 0,
		width: process.client ? window.innerWidth : 0,
	},
	device: {
		category: MediaqueryDeviceCategory.DESKTOP,
		isStandalone: _isStandalone(),
		orientation: MediaqueryDeviceOrientation.LANDSCAPE,
	},
	desktopBreakpoints: [Breakpoint.DESKTOP],
	mobileBreakpoints: [Breakpoint.MOBILE, Breakpoint.MOBILE_LANDSCAPE],
	tabletBreakpoints: [Breakpoint.TABLET, Breakpoint.TABLET_LANDSCAPE],
});

// Mutations ---------------------------------------------------------------------------------------

const mutations = {
	[mapFn(MEDIAQUERY_M_UPDATE)](_state: MediaqueryState, breakpoint: Breakpoint) {
		_state.lastBreakpoint = _state.currentBreakpoint;
		_state.currentBreakpoint = breakpoint;

		switch (breakpoint) {
			case Breakpoint.DESKTOP:
				_state.device = {
					category: MediaqueryDeviceCategory.DESKTOP,
					isStandalone: _isStandalone(),
					orientation: MediaqueryDeviceOrientation.LANDSCAPE,
				};
				break;
			case Breakpoint.TABLET_LANDSCAPE:
				_state.device = {
					category: MediaqueryDeviceCategory.TABLET,
					isStandalone: _isStandalone(),
					orientation: MediaqueryDeviceOrientation.LANDSCAPE,
				};
				break;
			case Breakpoint.MOBILE_LANDSCAPE:
				_state.device = {
					category: MediaqueryDeviceCategory.MOBILE,
					isStandalone: _isStandalone(),
					orientation: MediaqueryDeviceOrientation.LANDSCAPE,
				};
				break;
			case Breakpoint.TABLET:
				_state.device = {
					category: MediaqueryDeviceCategory.TABLET,
					isStandalone: _isStandalone(),
					orientation: MediaqueryDeviceOrientation.PORTRAIT,
				};
				break;
			case Breakpoint.MOBILE:
				_state.device = {
					category: MediaqueryDeviceCategory.MOBILE,
					isStandalone: _isStandalone(),
					orientation: MediaqueryDeviceOrientation.PORTRAIT,
				};
				break;
		}
	},

	[mapFn(MEDIAQUERY_M_DIMENSIONS)](_state: MediaqueryState, payload: MediaqueryDimensions) {
		_state.dimensions = payload;
	},
};

// Getters -----------------------------------------------------------------------------------------

const getters = {
	[mapFn(MEDIAQUERY_G_IS_DESKTOP)](_state: MediaqueryState): boolean {
		return _includes(_state.desktopBreakpoints, _state.currentBreakpoint);
	},

	[mapFn(MEDIAQUERY_G_IS_TABLET)](_state: MediaqueryState): boolean {
		return _includes(_state.tabletBreakpoints, _state.currentBreakpoint);
	},

	[mapFn(MEDIAQUERY_G_IS_MOBILE)](_state: MediaqueryState): boolean {
		return _includes(_state.mobileBreakpoints, _state.currentBreakpoint);
	},
};

export default {
	state,
	mutations,
	getters,
};

// Helpers -----------------------------------------------------------------------------------------

const _isStandalone = (): boolean => {
	if (process.server) {
		return false;
	}

	// This is documented to work on iOS (probably also older versions)
	// Property is navigator.standalone
	// lodash is used to prevent errors of not present properties or similar, just so be sure
	const iOsStandalone = _get(navigator, 'standalone', false);
	// This is tested to work in chrome (desktop and mobile) and also seams to also work on new iOS
	const chromeStandalone = window.matchMedia('(display-mode: standalone)').matches;

	return iOsStandalone || chromeStandalone;
};
