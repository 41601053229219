const middleware = {}

middleware['begin-loader'] = require('../middleware/begin-loader.ts')
middleware['begin-loader'] = middleware['begin-loader'].default || middleware['begin-loader']

middleware['checkout-guard'] = require('../middleware/checkout-guard.ts')
middleware['checkout-guard'] = middleware['checkout-guard'].default || middleware['checkout-guard']

middleware['checkout-start'] = require('../middleware/checkout-start.ts')
middleware['checkout-start'] = middleware['checkout-start'].default || middleware['checkout-start']

middleware['finish-loader'] = require('../middleware/finish-loader.ts')
middleware['finish-loader'] = middleware['finish-loader'].default || middleware['finish-loader']

middleware['js-profiling'] = require('../middleware/js-profiling.ts')
middleware['js-profiling'] = middleware['js-profiling'].default || middleware['js-profiling']

middleware['login-guard'] = require('../middleware/login-guard.ts')
middleware['login-guard'] = middleware['login-guard'].default || middleware['login-guard']

middleware['middleware.helper'] = require('../middleware/middleware.helper.ts')
middleware['middleware.helper'] = middleware['middleware.helper'].default || middleware['middleware.helper']

middleware['page-load-guard'] = require('../middleware/page-load-guard.ts')
middleware['page-load-guard'] = middleware['page-load-guard'].default || middleware['page-load-guard']

middleware['pdp-redirect'] = require('../middleware/pdp-redirect.ts')
middleware['pdp-redirect'] = middleware['pdp-redirect'].default || middleware['pdp-redirect']

middleware['reset-messages'] = require('../middleware/reset-messages.ts')
middleware['reset-messages'] = middleware['reset-messages'].default || middleware['reset-messages']

middleware['search'] = require('../middleware/search.ts')
middleware['search'] = middleware['search'].default || middleware['search']

middleware['set-page-type'] = require('../middleware/set-page-type.ts')
middleware['set-page-type'] = middleware['set-page-type'].default || middleware['set-page-type']

middleware['update-datalayer'] = require('../middleware/update-datalayer.ts')
middleware['update-datalayer'] = middleware['update-datalayer'].default || middleware['update-datalayer']

middleware['update-gender'] = require('../middleware/update-gender.ts')
middleware['update-gender'] = middleware['update-gender'].default || middleware['update-gender']

middleware['update-spa-data'] = require('../middleware/update-spa-data.ts')
middleware['update-spa-data'] = middleware['update-spa-data'].default || middleware['update-spa-data']

export default middleware
