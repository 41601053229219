import { Store } from 'vuex';
import {
	TRACKING_A_REGISTER_PASSED_EVENT,
	TRACKING_M_CLOSE_PERFORMANCE_MARK,
	TRACKING_M_ADD_PERFORMANCE_MARK,
	TRACKING_A_RESET_READY_TO_SEND_STATE,
	TRACKING_M_SET_PERFORMANCE_MARK,
	TRACKING_M_END_UNFINISHED_SSR_MARKS,
	TRACKING_A_REPORT_PERFORMANCE_MARKS,
} from '~/@constants/store';
import { PerformanceTrackingMark, RootState } from '~/@api/store.types';

export default function (store: Store<RootState>) {
	return {
		markStart: (mark: PerformanceTrackingMark) =>
			store.commit(TRACKING_M_SET_PERFORMANCE_MARK, mark, { root: true }),

		markEnd: (mark: { uniqueId: string; markTime: number }) =>
			store.commit(TRACKING_M_CLOSE_PERFORMANCE_MARK, mark, { root: true }),

		endUnfinishedSsrMarks: (timestamp: number) =>
			store.commit(TRACKING_M_END_UNFINISHED_SSR_MARKS, timestamp, { root: true }),

		addMark: (mark: PerformanceTrackingMark) =>
			store.commit(TRACKING_M_ADD_PERFORMANCE_MARK, mark, { root: true }),

		reportSsrPerformanceMarks: () =>
			store.dispatch(TRACKING_A_REPORT_PERFORMANCE_MARKS, undefined, { root: true }),

		resetReadyToSendState: () =>
			store.dispatch(TRACKING_A_RESET_READY_TO_SEND_STATE, undefined, { root: true }),

		registerEventPageLoad: () =>
			store.dispatch(
				TRACKING_A_REGISTER_PASSED_EVENT,
				{ eventName: 'load', value: true },
				{ root: true },
			),

		registerEventRenderingFinished: () =>
			store.dispatch(
				TRACKING_A_REGISTER_PASSED_EVENT,
				{ eventName: 'render', value: true },
				{ root: true },
			),

		registerEventClsFinished: () =>
			store.dispatch(
				TRACKING_A_REGISTER_PASSED_EVENT,
				{ eventName: 'clsControlFinished', value: true },
				{ root: true },
			),

		registerAllMarksFinished: () =>
			store.dispatch(
				TRACKING_A_REGISTER_PASSED_EVENT,
				{ eventName: 'allMarksFinished', value: true },
				{ root: true },
			),

		resetEventForSPANavigation: async () => {
			await Promise.all([
				store.dispatch(
					TRACKING_A_REGISTER_PASSED_EVENT,
					{ eventName: 'clsControlFinished', value: false },
					{ root: true },
				),
				store.dispatch(
					TRACKING_A_REGISTER_PASSED_EVENT,
					{ eventName: 'allMarksFinished', value: false },
					{ root: true },
				),
			]);
		},
	};
}
