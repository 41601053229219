import { Route } from 'vue-router';
import { Context } from '@nuxt/types';
import Logger from '@/node_modules/@osp/utils/src/logger';
import { elapsedTime } from '@/node_modules/@osp/utils/src/performance';
import { PageTypes } from '~/@api/store.types';

const checkoutPageTypes = [PageTypes.CART, PageTypes.CHECKOUTLOGIN, PageTypes.CHECKOUT];

const isCheckoutRoute = (route: Route) =>
	(route?.matched || []).filter((matchedRoute) =>
		checkoutPageTypes.includes(matchedRoute.meta?.pageType),
	).length > 0;

// eslint-disable-next-line require-await
export default async (context: Context) => {
	if (process.client) {
		/** In routes.ts are defined some global paths, which will be always inside "to.matched" and that's why they must be sorted out */
		const isRouteOutisdeSpa =
			(context.route.matched || []).filter(
				(route) => route.meta?.routeType !== 'template' && route.name !== 'defaultFallback',
			).length === 0;
		const url = context.route.fullPath;

		if (isRouteOutisdeSpa) {
			Logger.info(`No route found for url '${url}', going to do a page-load!`);

			if (process.client && typeof window !== 'undefined') {
				window.location.href = url;

				// Stop navigation by redirecting to current route
				// Otherwise backend apis from subordinate middlewares would be called
				context.redirect(context.from);
			}
		} else if (
			// isIE11
			!!(window as any)?.MSInputMethodContext &&
			!!(document as any).documentMode &&
			// is no pageLoad
			context.from.fullPath !== '/' &&
			context.from.name !== null &&
			// is no navigation within checkout
			!(isCheckoutRoute(context.route) && isCheckoutRoute(context.from))
		) {
			Logger.info(`Routing on IE11 outside checkout detected, going to do a page-load!`);

			if (typeof window !== 'undefined') {
				window.location.href = url;
			}
		}

		elapsedTime('middleware: page-load-guard');
	}
};
