import { Store } from 'vuex';
import { stringify } from '@/node_modules/@osp/design-system/assets/js/utilities/stringify';
import { measureOptions } from '@/node_modules/@osp/design-system/assets/js/utilities/performance';
import { importLogger, importRunTask } from './dynamic-imports';
import { PerformanceEvent, PerformanceTrackingMark, RootState } from '~/@api/store.types';
import { Lux } from '~/@types/lux';
import { OspNuxtRuntimeConfig, OspNuxtRuntimeConfigEntries } from '~/@types/OspNuxtRuntimeConfig';
import { useTrackingStore } from '~/@api/store/trackingApi';
import { CallStackDetailsList, TraceData } from '~/tracking/performance/LongTasksTracker';

export interface PerformanceTimings {
	server?: {
		processingTime?: number;
	};
	proxy?: {
		processingTime?: number;
	};
}

export interface OspEndPerformanceMark {
	uniqueId: string;
	markTime?: number;
	performanceEvent?: PerformanceEvent;
	timings?: PerformanceTimings;
	data?: any;
}

export interface OspStartPerformanceMark extends OspEndPerformanceMark {
	performanceEvent: PerformanceEvent;
}

interface MeasurementOptions {
	start?: number;
	end?: number;
	duration?: number;
}

type StartOrMeasureOptions = string | MeasurementOptions;

const firstCaseUp = (s) => (s && s[0].toUpperCase() + s.slice(1)) || '';

export const performanceTracking = {
	setLabelsByCode: true,
	apiRequestTimeoutFallbacks: {
		external: {
			client: 5000,
			ssr: 2000,
		},
	},
	isDebugOn(): boolean {
		return (
			(process?.client &&
				typeof window !== 'undefined' &&
				(window as any)?.osp?.tracking?.performance?.debug === true) ||
			process?.env?.TRACKING_PERFORMANCE_DEBUG === 'true'
		);
	},
	isActive(): boolean {
		return (
			process?.env?.TRACKING_PERFORMANCE === 'true' ||
			(process?.client &&
				typeof window !== 'undefined' &&
				(window as any)?.osp?.tracking?.performance?.active === true)
		);
	},
	trackApiRequests(): boolean {
		return (
			process?.env?.TRACKING_API_REQUESTS === 'true' ||
			(process?.client && (window as any)?.osp?.tracking?.apis?.active === true)
		);
	},
	getExternalTimeout(): number {
		if (process.client || typeof window === 'object') {
			return (
				parseInt(process?.env?.API_REQUEST_TIMEOUT_EXTERNAL_CLIENT) ||
				(window as any)?.osp?.tracking?.timeouts?.external?.client ||
				performanceTracking.apiRequestTimeoutFallbacks.external.client
			);
		}

		return (
			parseInt(process?.env?.API_REQUEST_TIMEOUT_EXTERNAL_SSR) ||
			performanceTracking.apiRequestTimeoutFallbacks.external.ssr
		);
	},
	isDebugLoggingActive(): boolean {
		return (
			process?.client &&
			(window as any)?.osp?.tracking?.performance?.debug === true &&
			!!(window as any)?.osp?.tracking?.performance?.debugLog
		);
	},
	getTimestamp(): number {
		return Date.now();
	},
	markEndOpenSsr(store: Store<RootState>, timestamp: number): void {
		const trackingStoreApi = useTrackingStore(store).api;

		importRunTask().then(({ runTask }) =>
			runTask(() => {
				trackingStoreApi.endUnfinishedSsrMarks(timestamp);
			}),
		);
	},
	// Collect start & end measurement points data in store (e.g. while on SSR) to submit when app finished loading on client
	markStartCollect(store: Store<RootState>, markData: OspStartPerformanceMark): void {
		if (!this.isActive()) return;

		if (!markData.markTime) {
			markData.markTime = performanceTracking.getTimestamp();
		}

		// API request details only when debugging on
		if (!this.isDebugOn()) {
			if (markData.data?.url) {
				markData.data.url = undefined;
			}

			if (markData.data?.params) {
				markData.data.params = undefined;
			}
		}

		// Mask API requests
		if (markData.performanceEvent === PerformanceEvent.apiRequest) {
			markData = this.maskApiRequestMarks(markData);
		}

		// If is in client, directly mark start event and leave function
		if (process?.client) {
			this.markStart(undefined, markData.uniqueId);
			return;
		}

		// ... otherwise use trackingApi to store the server side start event mark
		importRunTask().then(({ runTask }) => {
			runTask(() => {
				const marker = {
					uniqueId: markData.uniqueId,
					event: markData.performanceEvent,
					times: { start: markData.markTime },
					data: {
						...markData.data,
						ssr: !process?.client,
					},
				};
				useTrackingStore(store).api.markStart(marker);
			});
		});
	},
	// Collect end measurement points data of SSR (and optional client) in store to bulk submit when app finished loading on client
	markEndCollect(store: Store<RootState>, markData: OspEndPerformanceMark): void {
		if (!this.isActive()) return;

		if (!markData.markTime) {
			markData.markTime = Date.now();
		}

		// Mask API requests
		if (
			markData.performanceEvent === PerformanceEvent.apiRequest ||
			markData.performanceEvent === PerformanceEvent.apiRequestAbort
		) {
			markData = this.maskApiRequestMarks(markData);
		}

		// If in client, do performance tracking right away
		if (process?.client) {
			// In case it was an abort event
			if (markData.performanceEvent === PerformanceEvent.apiRequestAbort) {
				this.measure(undefined, `${markData.uniqueId}•Aborted`, {
					end: markData.markTime,
					duration: markData.data?.timeout,
				});
				return;
			}

			this.markEnd(undefined, markData.uniqueId);
			this.measure(undefined, markData.uniqueId);

			// In case mark data holds timings, create performance measurement points for these as well
			if (markData.timings) {
				Object.keys(markData.timings).forEach((timingTarget) => {
					Object.keys(markData.timings[timingTarget]).forEach((timingName) => {
						if (!markData.timings[timingTarget][timingName]) return;

						this.measure(
							undefined,
							`${markData.uniqueId}${this.getTimestamp()}•${firstCaseUp(timingTarget)}${firstCaseUp(
								timingName,
							)}`,
							{
								end: markData.markTime,
								start: markData.markTime - markData.timings[timingTarget][timingName],
								duration: markData.timings[timingTarget][timingName],
							},
						);
					});
				});
			}

			return;
		}

		// ... else if on server side, store the performance information and submit
		// it as soon as the website reaches client side
		importRunTask().then(({ runTask }) => {
			runTask(() => {
				this.addMarkToStore(store, markData);
			});
		});
	},
	addMarkToStore(store: Store<RootState>, markData: OspEndPerformanceMark) {
		const { api: trackingApi } = useTrackingStore(store);

		const endMark: { uniqueId: string; markTime: number; data?: { [key: string]: any } } = {
			uniqueId: markData.uniqueId,
			markTime: markData.markTime,
		};

		if (markData.performanceEvent === PerformanceEvent.apiRequestAbort) {
			endMark.data = {
				ssr: process.server,
				aborted: true,
			};
		}

		trackingApi.markEnd(endMark);

		if (markData.performanceEvent === PerformanceEvent.apiRequestAbort) {
			trackingApi.addMark({
				uniqueId: `${markData.uniqueId.replace(
					PerformanceEvent.apiRequest.toString(),
					PerformanceEvent.apiRequestAbort.toString(),
				)}${this.getTimestamp()}`,
				event: markData.performanceEvent,
				times: {
					end: markData.markTime,
				},
				duration: markData.data?.timeout,
				data: {
					ssr: process.server,
					aborted: true,
				},
			} as PerformanceTrackingMark);
		}

		if (markData.timings) {
			Object.keys(markData.timings).forEach((timingTarget) => {
				Object.keys(markData.timings[timingTarget]).forEach((timingName) => {
					if (!markData.timings[timingTarget][timingName]) return;

					trackingApi.addMark({
						uniqueId: `${markData.uniqueId}${this.getTimestamp()}`,
						event: markData.performanceEvent,
						times: {
							end: markData.markTime,
						},
						duration: markData.timings[timingTarget][timingName],
						data: {
							serverTimingType: `${firstCaseUp(timingTarget)}${firstCaseUp(timingName)}`,
							ssr: !process?.client,
						},
					} as PerformanceTrackingMark);
				});
			});
		}
	},
	maskApiRequestMarks(
		markData: OspStartPerformanceMark | OspEndPerformanceMark | string,
	): OspStartPerformanceMark | OspEndPerformanceMark | string {
		if (!this.isActive()) return;

		let searchPattern, replaceString;

		const isMarkDataObject = typeof markData === 'object' && 'uniqueId' in markData;

		let markerName = isMarkDataObject
			? (markData as OspEndPerformanceMark).uniqueId
			: markData.toString();

		if (markerName.includes('ochsnersport')) {
			// Mask OSP API requests
			searchPattern = /http.+ochsnersport.+\/v(\d+)/g;
			replaceString = '[OSPAPI][$1]';
		} else if (markerName.includes('dy-api')) {
			// Mask DY API requests
			searchPattern = /http.+dy-api.+\/v(\d+)/g;
			replaceString = '[DYAPI][$1]';
		}

		if (searchPattern) {
			markerName = markerName.replace(searchPattern, replaceString);

			if (isMarkDataObject) {
				(markData as OspEndPerformanceMark).uniqueId = markerName;

				if ((markData as any).data?.url) {
					(markData as OspStartPerformanceMark).data.url = (
						markData as OspStartPerformanceMark
					).data.url.replace(searchPattern, replaceString);
				}
			} else {
				markData = markerName;
			}
		}

		return markData;
	},
	// Mark performance points right away in LUX / native performance API
	markStart(lux: Lux, name: string): void {
		if (!this.isActive()) return;

		// If neither lux API nor perfromance API are available, skip measurements
		if (!lux && !performance) return;

		const markerName = this.maskApiRequestMarks(`${name}_START`);

		this.debug(`${this.usedApiName(!!lux)}.mark('${markerName}')`);

		this.performanceApi(lux).mark(markerName);
	},
	markEnd(lux: Lux, name: string): void {
		if (!this.isActive()) return;

		// If neither lux API nor perfromance API are available, skip measurements
		if (!lux && !performance) return;

		const markerName = this.maskApiRequestMarks(`${name}_END`);

		this.debug(`${this.usedApiName(!!lux)}.mark('${markerName}')`);

		this.performanceApi(lux).mark(markerName);
	},
	init(lux: Lux): void {
		if (!this.isActive()) return;

		this.debug(`LUX.init()`);

		this.performanceApi(lux, true)?.init?.();
		this.pageLoading(lux, true);
	},
	measure(
		lux: Lux | undefined,
		name: string,
		startOrMeasureOptions?: StartOrMeasureOptions,
		endMark?: string,
	): void {
		// If is not active, nor lux API nor performance API are available, skip measurements
		if (!this.isActive() || (!lux && !performance)) return;

		if (typeof startOrMeasureOptions === 'undefined' && typeof endMark === 'undefined') {
			startOrMeasureOptions = `${name}_START`;
			endMark = `${name}_END`;
		}

		// Check/ensure measure options object has at least a start or end property information (required)
		// but never have all three of start, end and duration
		startOrMeasureOptions = this.checkUpdateMeasureObject(startOrMeasureOptions);
		name = this.normalizeTrackingName(name);

		let measureEndMark = typeof startOrMeasureOptions !== 'object' && endMark ? endMark : undefined;

		// Check if all measure point information is valid before submitting
		if (!this.measureInfoIsValid(name, startOrMeasureOptions, measureEndMark)) return;

		// Submit duration via native Performance API when lux was set to undefined
		// IMPORTANT: When tracking values of synthetic tests should be collected, they MUST be transmitted via native
		// performance API (performance.measure())
		if (
			lux === undefined &&
			typeof startOrMeasureOptions === 'object' &&
			startOrMeasureOptions.duration &&
			!!performance
		) {
			this.submitNativeMeasurement(name, startOrMeasureOptions);
			return;
		}

		if (typeof startOrMeasureOptions === 'string') {
			startOrMeasureOptions = this.maskApiRequestMarks(startOrMeasureOptions);
		}

		measureEndMark = this.maskApiRequestMarks(measureEndMark);

		if (measureEndMark.indexOf(PerformanceEvent.apiRequestAbort) === 0) {
			name = `${name}•Aborted`;
		}

		this.debug(
			`${this.usedApiName(!!lux)}.measure('${[
				name,
				typeof startOrMeasureOptions === 'object'
					? stringify(startOrMeasureOptions)
					: startOrMeasureOptions,
				measureEndMark,
			].join("', '")}')`,
		);

		// If startOrMeasureOptions is an object
		if (typeof startOrMeasureOptions === 'object') {
			this.performanceApi(lux)?.measure(name, startOrMeasureOptions);

			return;
		}

		// Ignore typescript as it says type of object is not allowed for startOrMeasureOptions, but it is
		// @ts-ignore
		this.performanceApi(lux)?.measure(name, startOrMeasureOptions.toString(), measureEndMark);
	},
	// Native performance measurement has to be used to be trackable for synthetic page tests
	submitNativeMeasurement(name: string, measurementOptions: MeasurementOptions) {
		this.debug(`performance.measure('${[name, stringify(measurementOptions)].join("', '")}')`);

		if (!measurementOptions) {
			// eslint-disable-next-line no-console
			console.error(
				`Parameter measurementOptions is ${typeof measurementOptions} for "${name}". Measurement avoided.`,
				measurementOptions,
			);

			return;
		}

		try {
			measureOptions(name, measurementOptions);
		} catch (error) {
			if (error.message.includes('The string did not match the expected pattern.')) {
				// Fallback if browser does not support measurement options
				(async () => {
					performance.mark(`${name}_START`);

					setTimeout(() => {
						performance.mark(`${name}_END`);
						performance.measure(name, `${name}_START`, `${name}_END`);
					}, measurementOptions.duration);

					await Promise.resolve();
				})();
			}
		}
	},
	checkUpdateMeasureObject(
		startOrMeasureOptions?: StartOrMeasureOptions,
	): StartOrMeasureOptions | undefined {
		if (typeof startOrMeasureOptions === 'object') {
			if (
				startOrMeasureOptions.duration &&
				startOrMeasureOptions.start &&
				startOrMeasureOptions.end
			) {
				delete startOrMeasureOptions.end;
			} else if (
				!startOrMeasureOptions.start &&
				!startOrMeasureOptions.end &&
				!!startOrMeasureOptions.duration
			) {
				const measureStart = this.getTimestamp() - startOrMeasureOptions.duration;
				startOrMeasureOptions.start = measureStart > 0 ? measureStart : 0;
			}
		}

		return startOrMeasureOptions;
	},
	measureInfoIsValid(name, startOrMeasureOptions, measureEndMark) {
		if (
			typeof startOrMeasureOptions === 'object' &&
			(isNaN(startOrMeasureOptions?.duration) || startOrMeasureOptions?.duration === 0)
		) {
			this.debug(
				'Avoided to measure object with invalid duration',
				[name, startOrMeasureOptions, measureEndMark],
				'warn',
			);

			return false;
		}

		if (!name) {
			this.debug(
				'No name for measure point',
				[name, startOrMeasureOptions, measureEndMark],
				'warn',
			);

			return false;
		}

		return true;
	},
	normalizeTrackingName(name: string): string {
		if (!this.isActive()) {
			return '';
		}

		name = this.maskApiRequestMarks(name);

		// Temporary remove process postfix(es) from name if present
		let processPostfix = '';
		const firstProcessSeparator = name.indexOf('•');

		if (firstProcessSeparator >= 0) {
			processPostfix = name.substring(firstProcessSeparator);
			name = name.substring(0, firstProcessSeparator);
		}

		if (
			name.indexOf(`${PerformanceEvent.apiRequest}|`) === 0 ||
			name.indexOf(`${PerformanceEvent.apiRequestAbort}|`) === 0
		) {
			name = name.split('|')[1];
		}

		if (name.includes('/translations/')) {
			return name.slice(0, name.lastIndexOf('/') + 1) + 'xx' + processPostfix;
		}

		if (name.includes('/customer/giftcards?') || name.includes('/cart?')) {
			// Just strip off all url parameters
			return name.slice(0, name.indexOf('?')) + processPostfix;
		}

		if (
			name.includes('/content/category/') ||
			name.includes('/content/content/') ||
			name.includes('/content/product/')
		) {
			/* Examples:
				[OSPAPI][2]/content/content/homepage_SSR
				[OSPAPI][2]/content/category/00015640?full=false&location=%2Fde%2Fshop%2Fneutral-sportarten-00015640-c.html&cleanUrl=false

				Expected results:
				[OSPAPI][2]/content/content/xxx_SSR
				[OSPAPI][2]/content/category/xxx
			*/
			name = name.split('?')[0];

			return (
				name.replace(
					/(\[\w{1,10}]\[\d{1,2}]\/content\/(content|category|product)\/)([^•?]{1,100})/,
					'$1xxx',
				) + processPostfix
			);
		}

		if (name.includes('/products/') && (name.includes('/redirect') || name.includes('/reviews'))) {
			/* Examples:
				[OSPAPI][2]/products/00002000795073/redirect
				[OSPAPI][2]/products/00002000795073/reviews
			*/
			const lastSlashIndex = name.lastIndexOf('/');

			return (
				name.slice(0, name.lastIndexOf('/', lastSlashIndex - 1) + 1) +
				'xxx' +
				name.slice(lastSlashIndex) +
				processPostfix
			);
		}

		if (
			name.includes('/spa/content/') ||
			name.includes('/spa/category/') ||
			name.includes('/spa/product/')
		) {
			/* Examples:
				[OSPAPI][2]/spa/content/cp__ochsnersport-club/digital-data
				[OSPAPI][2]/spa/content/cp__ochsnersport-club/store-i18n-urls
				[OSPAPI][2]/spa/content/cp__ochsnersport-club?storeI18nUrls=false
				[OSPAPI][2]/spa/category/00015726/digital-data
				[OSPAPI][2]/spa/category/00015726/store-i18n-urls
				[OSPAPI][2]/spa/category/00015726?storeI18nUrls=false
				[OSPAPI][2]/spa/product/00002000795073/digital-data
				[OSPAPI][2]/spa/product/00002000795073/store-i18n-urls
				[OSPAPI][2]/spa/product/00002000795073?storeI18nUrls=false

				Expected result examples:
				[OSPAPI][2]/spa/content/cp__ochsnersport-club?storeI18nUrls=false => [OSPAPI][2]/spa/content/cp__xxx?storeI18nUrls
				[OSPAPI][2]/spa/product/00002000795073/digital-data => [OSPAPI][2]/spa/product/xxx/digital-data
			*/
			const startPart = /(\[\w{1,10}]\[\d{1,2}]\/spa\/(content|category|product)\/(cp__)?)/g;
			const endPart = /([/?]?[^=]{0,50})/g;

			const extractingRegex = new RegExp(startPart.source + '[^/?]{1,50}' + endPart.source);
			const extractedParts = extractingRegex.exec(name);

			return `${extractedParts[1]}xxx${extractedParts[4]}${processPostfix}`;
		}

		// Re-add process postfix
		return name + processPostfix;
	},
	pageLoading(lux: Lux, isLoading = true): void {
		if (!this.isActive() || !lux) return;

		this.debug(`LUX.pageLoading(${isLoading})`);

		lux?.pageLoading(isLoading);
	},
	// Adds custom data to LUX (only available within RUM)
	addData(lux: Lux, name: string, value: number | string): void {
		if (!this.isActive()) return;

		name = this.normalizeTrackingName(name);
		lux = this.performanceApi(lux, true);

		if (lux && typeof value !== 'undefined') {
			this.debug(`LUX.addData('${name}', ${value}`);

			lux.addData(name, value);
		}
	},
	ensurePageLabel(lux?: Lux, pageLabel?: string): void {
		if (!pageLabel?.trim()?.length || !performanceTracking.getLabel()) return;

		performanceTracking?.setLabel(lux, pageLabel);
	},
	setLabel(lux: Lux | undefined, pageLabel: string): void {
		if (!this.setLabelsByCode || !this.isActive()) return;

		const labelFormatted = pageLabel.charAt(0).toUpperCase() + pageLabel.slice(1).toLowerCase();

		if (lux && lux.label() !== labelFormatted) {
			lux.label(labelFormatted);

			this.debug(`LUX.label('${labelFormatted}')`);
		}

		if (process?.client && (window as any)?.LUX && (window as any).LUX.label !== labelFormatted) {
			(window as any).LUX.label = labelFormatted;

			this.debug(`LUX.label = ${labelFormatted}`);
		}
	},
	getLabel(): string | undefined {
		if (!this.isActive()) return;

		return process?.client ? (window as any)?.LUX?.label : undefined;
	},
	markLoadTime(lux: Lux): void {
		this.performanceApi(lux)?.markLoadTime?.();
	},
	async luxSend(lux?: Lux, label?: string): Promise<void> {
		if (!this.isActive()) return;

		if (typeof (window as any)?.LUX === 'undefined') return;

		// Submit counts of specific performance measurements
		this.measureStatistics();

		if (!!label && (lux?.label() !== label || (window as any).LUX?.label !== label)) {
			this.setLabel(lux, label);
		}

		let performanceApiReference;

		const { runTask, runTaskWithPromise } = await importRunTask();

		await runTaskWithPromise(async () => {
			performanceApiReference = this.performanceApi(lux, true);
			await Promise.resolve();
		});

		runTask(() => {
			performanceApiReference?.send?.();
		});

		runTask(() => {
			this.debug(`LUX.send() for page label "${(window as any).LUX.label}"`);
		});
	},
	loadingFinished(lux: Lux): void {
		this.pageLoading(lux, false);
	},
	performanceApi(lux, luxOnly = false) {
		return (window as any)?.LUX ?? lux ?? (luxOnly ? undefined : performance);
	},
	usedApiName(apiParam: boolean): string {
		if (process.client && (window as any)?.LUX) {
			return 'LUX';
		}

		if (apiParam) {
			return 'lux';
		}

		if (performance) {
			return 'performance';
		}

		return 'unknown';
	},
	debug(message: string, params?: any[], level: 'info' | 'warn' | 'error' = 'info') {
		if (!this.isDebugOn()) return;

		importLogger().then(({ default: Logger }) => {
			Logger[level](message, params);
		});

		if (this.isDebugLoggingActive()) {
			this.logDebug(level, message, params);
		}
	},
	logDebug(level: string, message: string, params?: any[]) {
		const log = (window as any)?.osp?.tracking?.performance?.debugLog;

		if (log) {
			log.push({ level, message, params });
		}
	},
	measureStatistics(ssrOrClient: 'ssr' | 'client' = 'client') {
		if (!performance) {
			return;
		}

		['OSP', 'DY'].forEach((apiName) => {
			const abortedMeasurements = this.getAbortedApiRequestMeasurements(apiName)?.filter(
				(marker) => {
					return ssrOrClient === 'ssr' ? marker.includes('•SSR') : !marker.includes('•SSR');
				},
			);

			// Do not submit 0 counted aborted api requests when in RUM / for client
			if (ssrOrClient === 'client' && abortedMeasurements.length === 0) {
				return;
			}

			// If on SSR and 0 requests were aborted: Transmit 1 millisecond as "lifesign" of abort-tracking for synthetic tests
			// (value "0" is not valid to measure). Otherwise, multiply counts with 1000 as 1 second equals 1 count
			const measureValue = abortedMeasurements.length === 0 ? 1 : abortedMeasurements.length * 1000;

			this.measure(
				undefined,
				`${apiName}_API_CALLS_COUNT_ABORTED${ssrOrClient === 'ssr' ? '•SSR' : ''}`,
				{
					duration: measureValue,
					end: Date.now(),
				},
			);
		});

		this.clearAbortedMeasurements();
	},
	clearAbortedMeasurements() {
		['OSP', 'DY'].forEach((apiName) => {
			this.getAbortedApiRequestMeasurements(apiName).forEach((measureName) => {
				performance.clearMeasures(measureName);
			});
		});
	},
	getAbortedApiRequestMeasurements(apiName: string): string[] {
		if (!performance) {
			return [];
		}

		return performance
			?.getEntriesByType('measure')
			.map((e) =>
				e.name.startsWith(`[${apiName.toUpperCase()}API]`) && e.name.includes('•Aborted')
					? e.name
					: undefined,
			)
			.filter((e) => !!e);
	},
};

export function updateTrackingEnvsOnWindowObject(configEntries: OspNuxtRuntimeConfigEntries): void {
	if (!process.client || typeof window === 'undefined') return;

	const scriptBody = ((window as any).osp = (window as any)?.osp || {});

	scriptBody.tracking = {
		performance: {
			active: configEntries.TRACKING_PERFORMANCE,
			debug: configEntries.TRACKING_PERFORMANCE_DEBUG,
		},
		cls: {
			active: configEntries.TRACKING_CLS,
			thresholds: {
				total: configEntries.TRACKING_CLS_THRESHOLD_TOTAL,
				page: configEntries.TRACKING_CLS_THRESHOLD_PAGE,
				item: configEntries.TRACKING_CLS_THRESHOLD_ITEM,
			},
		},
		apis: {
			active: configEntries.TRACKING_API_REQUESTS,
		},
		longTasks: {
			active: configEntries.TRACKING_LONG_TASKS,
			tti: configEntries.TRACKING_TTI,
		},
		spaRumThresholdReporting: {
			active: configEntries.TRACKING_SPA_RUM_THRESHOLD_REPORTING,
			thresholds: {
				lcp: configEntries.TRACKING_SPA_RUM_LCP_THRESHOLD,
				fcp: configEntries.TRACKING_SPA_RUM_FCP_THRESHOLD,
				inp: configEntries.TRACKING_SPA_RUM_INP_THRESHOLD,
				fid: configEntries.TRACKING_SPA_RUM_FID_THRESHOLD,
				tti: configEntries.TRACKING_SPA_RUM_TTI_THRESHOLD,
				tbt: configEntries.TRACKING_SPA_RUM_TBT_THRESHOLD,
			},
		},
		timeouts: {
			external: {
				ssr: configEntries.API_REQUEST_TIMEOUT_EXTERNAL_SSR,
				client: configEntries.API_REQUEST_TIMEOUT_EXTERNAL_CLIENT,
			},
		},
	};
}

export function logPerformanceFinding(
	message: string,
	performanceEntryDescription?: string,
	performanceEntryData?: PerformanceEntry | PerformanceEntryList | TraceData | CallStackDetailsList,
	styles?: { messageStyle?: string; messageStyleHighlighted?: string },
) {
	const messageStyle = styles?.messageStyle ?? 'color: #00A3BF';
	const messageStyleHighlighted =
		styles?.messageStyleHighlighted ?? 'background-color: #00B8D9; color: white';

	importLogger().then(({ default: _Logger }) => {
		// Logger.debug(`%c️${message}`, messageStyleHighlighted);
		console.log(`%c️${message}`, messageStyleHighlighted);

		if (!!performanceEntryDescription || !!performanceEntryData) {
			// Logger.debug(`%c ${performanceEntryDescription}`, messageStyle, performanceEntryData);
			console.log(`%c ${performanceEntryDescription}`, messageStyle, performanceEntryData);
		}
	});
}

export interface PerformanceTrackingPluginConfig {
	active: boolean;
	debug: boolean;
}

export interface ClsTrackingPluginConfig {
	active: boolean;
	thresholds: {
		total: number;
		page: number;
		item: number;
	};
}

export interface LongTasksTrackingPluginConfig {
	active: boolean;
	tti: boolean;
}

export interface SpaRumThresholdReportingPluginConfig {
	active: boolean;
	thresholds: {
		lcp: number;
		fcp: number;
		inp: number;
		fid: number;
		tbt: number;
		tti: number;
	};
}

export interface PerformanceTrackingConfig {
	cls: ClsTrackingPluginConfig;
	longTasks: LongTasksTrackingPluginConfig;
	performance: PerformanceTrackingPluginConfig;
	spaRumThresholdReporting: SpaRumThresholdReportingPluginConfig;
}

export function getPerformanceTrackingConfig(
	contextConfig: OspNuxtRuntimeConfig,
): PerformanceTrackingConfig {
	if (!process.client) return;

	if (!(window as any)?.osp?.tracking) {
		updateTrackingEnvsOnWindowObject(contextConfig.env);
	}

	return {
		...(window as any)?.osp?.tracking,
	} as PerformanceTrackingConfig;
}
