import { Context } from '@nuxt/types';
import { useUserStore } from '~/@api/store/userApi';
import { PageTypes } from '~/@api/store.types';

export default async (context: Context) => {
	await updateGenderOnHomepage(context);
};

/**
 * Updates the gender on homepage navigations if it is not correct (especially for browser back)
 * @param context
 */
const updateGenderOnHomepage = async (context: Context) => {
	if (
		[...(context.route.matched || [])].reverse().find((route) => route.meta?.pageType)?.meta
			.pageType === PageTypes.HOME
	) {
		const { api: userApi, state: userState } = useUserStore(context.store);
		const localizedGender = context.route.params.gender?.toLowerCase();
		const gender =
			userState.user.genders.find((gender) => gender.name.toLowerCase() === localizedGender)
				?.code || 'NEUTRAL';

		if (gender && gender !== userState.user.gender?.code) {
			await userApi.setGender(gender);
		}
	}
};
