export function setSafeTimeout(callback: (args: void) => void, ms?: number): number | null {
	if (process.server) {
		callback();

		return null;
	}

	return self.setTimeout(callback, ms);
}

export function setSafeInterval(
	callback: (args: void) => void,
	ms?: number,
	maxDurationMs?: number,
): number | null {
	if (process.server) {
		callback();

		return null;
	}

	let timeout: number | undefined;

	if (maxDurationMs) {
		const currentDateTime = new Date();
		currentDateTime.setMilliseconds(currentDateTime.getMilliseconds() + maxDurationMs);
		timeout = currentDateTime.getTime();
	}

	const requestedInterval = self.setInterval(() => {
		if (timeout && timeout >= Date.now()) {
			clearInterval(requestedInterval);
		}

		callback();
	}, ms);

	return requestedInterval;
}
