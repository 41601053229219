import Vue from 'vue';
import { ActionContext } from 'vuex';
import _remove from 'lodash-es/remove';
import _uniq from 'lodash-es/uniq';
import { setSafeTimeout } from '~/node_modules/@osp/design-system/assets/js/utilities/timeout';
import { RootState, LoadingState, LoadingPayload } from '~/@api/store.types';
import {
	mapFn,
	LOADING_M_SET_LOADING,
	LOADING_G_IS_LOADING,
	LOADING_A_SET_LOADING,
	LOADING_M_RESET_STATE,
} from '~/@constants/store';

// Constants ---------------------------------------------------------------------------------------

const LOADING_MIN_ANIM_DELAY_EVENT = 'minAnimationDelay';

// Initial state -----------------------------------------------------------------------------------

const state = () => ({
	loadingComponents: [],
	minAnimationDelay: 500,
});

// Mutations ---------------------------------------------------------------------------------------

const mutations = {
	[mapFn(LOADING_M_SET_LOADING)](_state: LoadingState, payload: LoadingPayload[]) {
		payload.forEach((entry) =>
			entry.loading
				? _state.loadingComponents.push(entry.name)
				: _remove(_state.loadingComponents, (c) => c === entry.name),
		);
		Vue.set(_state, 'loadingComponents', _uniq(_state.loadingComponents));
	},

	[mapFn(LOADING_M_RESET_STATE)](_state: LoadingState) {
		const initialState = state();

		Object.keys(initialState).forEach(
			(propertyKey) => (_state[propertyKey] = initialState[propertyKey]),
		);
	},
};

// Actions -----------------------------------------------------------------------------------------

const actions = {
	[mapFn(LOADING_A_SET_LOADING)](
		context: ActionContext<LoadingState, RootState>,
		payload: {
			name: string;
			loading: boolean;
		},
	) {
		// Do not activate loading overlay on SSR rendering
		if (process.server) return;

		if (
			payload.loading &&
			context.state.minAnimationDelay > 0 &&
			!context.state.loadingComponents.includes(LOADING_MIN_ANIM_DELAY_EVENT)
		) {
			context.commit(mapFn(LOADING_M_SET_LOADING), [
				{ name: LOADING_MIN_ANIM_DELAY_EVENT, loading: true },
				payload,
			]);
			setSafeTimeout(() => {
				context.commit(mapFn(LOADING_M_SET_LOADING), [
					{ name: LOADING_MIN_ANIM_DELAY_EVENT, loading: false },
				]);
			}, context.state.minAnimationDelay);
		} else {
			context.commit(mapFn(LOADING_M_SET_LOADING), [payload]);
		}
	},
};

// Getters -----------------------------------------------------------------------------------------

const getters = {
	[mapFn(LOADING_G_IS_LOADING)](_state: LoadingState): boolean {
		return _state.loadingComponents.length > 0;
	},
};

export default {
	state,
	mutations,
	actions,
	getters,
};
