export default function ({ req, res }) {
	if (
		req?.url
			?.split('?')[1]
			?.split('&')
			?.some((param: string) => param === '_eT' || param === 'js-profiling')
	) {
		return res?.setHeader('document-policy', 'js-profiling');
	}
}
