import { Store } from 'vuex';
import { RootState } from '~/@api/store.types';
import { FORMS_A_LOAD_FORM, FORMS_M_STORE_VALIDATION_RESPONSE } from '~/@constants/store';
import { ValidationErrorResponse } from '~/generated/hybris-raml-api';

export default function (store: Store<RootState>) {
	return {
		loadForm: (formCode: string, forceUpdate = false) =>
			store.dispatch(FORMS_A_LOAD_FORM, { formCode, forceUpdate }, { root: true }),

		storeValidationResponse: (formCode: string, validationResponse: ValidationErrorResponse) =>
			store.commit(
				FORMS_M_STORE_VALIDATION_RESPONSE,
				{ formCode, validationResponse },
				{ root: true },
			),
	};
}
