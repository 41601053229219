import { runTask } from '@/node_modules/@osp/design-system/assets/js/utilities/runTask';

// eslint-disable-next-line require-await
async function PluginIntegration() {
	if ('serviceWorker' in navigator && !navigator.userAgent.includes('Safari')) {
		window.addEventListener('load', function () {
			navigator.serviceWorker.register('/sw.js');
		});
	}
}

// NO defer until nuxtReady as service worker has script imports causing unresolvable long running task
// As executed in its own threat, can be loaded right away without blocking main thread to at least optimize TTI
export default function () {
	runTask(PluginIntegration);
}
