import { SpaOtherId, SpaType } from '@/generated/hybris-raml-api';
import { PageTypes } from '~/@api/store.types';

export const bikefinderRoutes = (defaultPath: string) => [
	{
		path: `${defaultPath}/bike-finder`,
		component: () =>
			import('~/layouts/default-route/default-route.vue').then((m) => m.default || m),
		meta: {
			routeType: 'template',
			spaType: SpaType.other,
		},
		children: [
			{
				path: '',
				component: () =>
					import('~/pages/bike-finder-page/bike-finder-page.vue').then((m) => m.default || m),
				meta: {
					pageType: PageTypes.BIKE_FINDER,
					spaOtherId: SpaOtherId.bike_finder,
				},
			},
		],
	},
];
