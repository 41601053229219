import { i18nKeys } from '~/generated/translations';
import { routingCheckoutSteps } from '~/routing/checkout-steps';

export const paymentRoute = {
	path: 'payment',
	component: () =>
		import(
			/* webpackChunkName: "checkout" */ '~/pages/checkout/payment-page/payment-page.vue'
		).then((m) => m.default || m),
	meta: {
		checkoutStep: routingCheckoutSteps.PAYMENT_MODE,
		headline: i18nKeys.title.checkout.PaymentMode,
		showCVC: true,
	},
};
