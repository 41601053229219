import { Store } from 'vuex';
import { RootState } from '~/@api/store.types';
import {
	I18N_A_MESSAGES,
	I18N_G_MESSAGE,
	I18N_M_LANGUAGE,
	I18N_M_MESSAGES,
} from '~/@constants/store';

export default function (store: Store<RootState>) {
	return {
		setLanguage: (language: string) => store.commit(I18N_M_LANGUAGE, language, { root: true }),

		setMessages: (messages) => store.commit(I18N_M_MESSAGES, messages, { root: true }),

		load: (key: string) => store.dispatch(I18N_A_MESSAGES, key, { root: true }),

		translate: (key: string, language = 'de') => store.getters[I18N_G_MESSAGE](key, language),
	};
}
