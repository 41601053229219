import { Context } from '@nuxt/types';
import _findLast from 'lodash-es/findLast';
import _isEmpty from 'lodash-es/isEmpty';
import { RouteRecord } from 'vue-router';
import { routingCheckoutSteps, RoutingStepCheckout } from '@/routing/checkout-steps';
import { url } from '~/@api/backend';
import { CheckoutStep } from '~/generated/hybris-raml-api';
import { importCheckoutApi, importMessageboxApi, importRunTask } from '~/app-utils/dynamic-imports';
import { elapseMiddlewareTime } from '~/middleware/middleware.helper';

export default async (context: Context) => {
	const checkoutRoute = _findLast(
		context.route.matched,
		(route) => route.meta.checkoutStep,
	) as RouteRecord;

	if (!checkoutRoute) {
		elapseMiddlewareTime('middleware: checkout-guard');
		return;
	}

	importRunTask().then(({ runTask }) => {
		runTask(async () => {
			const isFirstRoute = _isEmpty(context.route.matched); // pageLoad
			const { useMessageboxStore } = await importMessageboxApi();
			useMessageboxStore(context.store).api.dismissAll('checkout');

			const currentCheckoutStep = checkoutRoute.meta.checkoutStep as RoutingStepCheckout;

			if (currentCheckoutStep === routingCheckoutSteps.START) {
				elapseMiddlewareTime('middleware: checkout-guard');
				return;
			}

			runTask(async () => {
				const { useCheckoutStore } = await importCheckoutApi();
				const { api: checkoutApi, state: checkoutState } = useCheckoutStore(context.store);
				let allowedSteps: CheckoutStep[] = checkoutState.allowedSteps;

				if (!allowedSteps || allowedSteps.length === 0) {
					await checkoutApi.update();

					if (isFirstRoute) return;

					allowedSteps = checkoutState.allowedSteps;
				}

				const RoutingStepsCheckout = allowedSteps.map((step) => routingCheckoutSteps[step]);
				const lastCheckoutStepPassed = checkManageLastCheckoutStepPassed(
					context,
					RoutingStepsCheckout,
					currentCheckoutStep,
				);

				if (!lastCheckoutStepPassed) {
					await checkManageMoreStepsToGo(context, RoutingStepsCheckout, currentCheckoutStep);
				}

				elapseMiddlewareTime('middleware: checkout-guard');
			});
		});
	});
};

function currentStepOrMoreStepsToGo(allowedSteps, currentCheckoutStep): boolean {
	return allowedSteps.some((allowedStep) => allowedStep.order >= currentCheckoutStep.order);
}

async function checkManageMoreStepsToGo(
	context: Context,
	allowedSteps: RoutingStepCheckout[],
	currentCheckoutStep: RoutingStepCheckout,
): Promise<boolean> {
	if (!currentStepOrMoreStepsToGo(allowedSteps, currentCheckoutStep)) {
		return Promise.resolve(false);
	}

	const { useCheckoutStore } = await importCheckoutApi();
	const { api: checkoutApi } = useCheckoutStore(context.store);

	if (allowedSteps.includes(currentCheckoutStep)) {
		checkoutApi.setCurrentStepMutation(CheckoutStep[currentCheckoutStep.code]);

		return Promise.resolve(true);
	}

	const nextAllowedStep = allowedSteps
		.filter((allowedStep) => allowedStep.order >= currentCheckoutStep.order)
		.sort((a, b) => a.order - b.order)[0];

	checkoutApi.setCurrentStepMutation(CheckoutStep[nextAllowedStep.code]);
	context.redirect(302, url(context.store, nextAllowedStep.path));

	return true;
}

function sortStepsByOrder(stepsToSort: RoutingStepCheckout[]): RoutingStepCheckout[] {
	return stepsToSort.sort((stepA, stepB) => stepA.order - stepB.order);
}

function checkManageLastCheckoutStepPassed(
	context: Context,
	allowedSteps: RoutingStepCheckout[],
	currentCheckoutStep: RoutingStepCheckout,
): boolean {
	if (currentStepOrMoreStepsToGo(allowedSteps, currentCheckoutStep)) {
		return false;
	}

	const sortedSteps = sortStepsByOrder(allowedSteps);
	const lastAllowedStep = _findLast(sortedSteps);

	if (lastAllowedStep) {
		context.redirect(302, url(context.store, lastAllowedStep.path));

		return true;
	}

	context.redirect(
		302,
		// Find first checkout step
		url(
			context.store,
			Object.values(routingCheckoutSteps)
				.filter((checkoutStep) => checkoutStep.group === 1)
				.sort((a, b) => a.order - b.order)[0].path,
		),
	);

	return true;
}
