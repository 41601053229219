import { Store } from 'vuex';
import { RootState } from '~/@api/store.types';
import {
	UX_M_INTERACT,
	UX_G_INTERACTED,
	UX_M_NUXTREADY,
	UX_M_CMP_CHOICE_EXISTS,
	UX_G_NUXT_IS_READY,
	UX_G_PAGE_SWITCH_IN_PROGRESS,
	UX_G_PAGE_SWITCH_HOOK_ACTIVE,
	UX_M_PAGE_SWITCH_STATUS,
	UX_M_SET_PAGE_SWITCH_HOOK_ACTIVE,
	UX_M_SET_FOOTER_VISIBLE,
} from '~/@constants/store';

export default function (store: Store<RootState>) {
	return {
		isInteracted: (): boolean => store.getters[UX_G_INTERACTED],

		isNuxtReady: (): boolean => store.getters[UX_G_NUXT_IS_READY],

		isPageSwitchInProgress: (): boolean => store.getters[UX_G_PAGE_SWITCH_IN_PROGRESS],

		isPageSwitchHookActive: (): boolean => store.getters[UX_G_PAGE_SWITCH_HOOK_ACTIVE],

		registerInteraction: () => store.commit(UX_M_INTERACT, true, { root: true }),

		registerNuxtReady: () => store.commit(UX_M_NUXTREADY, true, { root: true }),

		registerCmpUserChoice: () => store.commit(UX_M_CMP_CHOICE_EXISTS, true, { root: true }),

		setPageSwitchProgressStatus: (isInProgress: boolean) =>
			store.commit(UX_M_PAGE_SWITCH_STATUS, isInProgress, { root: true }),

		setPageSwitchHookActive: (hookActive: boolean) =>
			store.commit(UX_M_SET_PAGE_SWITCH_HOOK_ACTIVE, hookActive, { root: true }),

		setFooterVisible: (displayFooter: boolean) =>
			store.commit(UX_M_SET_FOOTER_VISIBLE, displayFooter, { root: true }),
	};
}
