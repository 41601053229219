import { Context } from '@nuxt/types';
import { Inject } from '@nuxt/types/app';
import VueRouter from 'vue-router';

declare module '@nuxt/types' {
	interface Context {
		$router: VueRouter;
	}
}

declare module 'vue/types/vue' {
	interface Vue {
		$router: VueRouter;
	}
}

declare module 'vuex/types/index' {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	interface Store<S> {
		$router: VueRouter;
	}
}

export default function (context: Context, inject: Inject) {
	inject('router', context.app.router);
}
