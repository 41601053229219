import { PageTypes } from '~/@api/store.types';
import { SpaType } from '~/generated/hybris-raml-api';

export const brandsRoute = [
	{
		path: ':gender([^-]+)-shops.html',
		component: () => import('@/pages/brand-page/brand-page.vue').then((m) => m.default || m),
		meta: {
			pageType: PageTypes.BRANDLIST,
			spaType: SpaType.other,
		},
	},
	{
		path: ':gender([^-]+)-(marken|marques|marchi).html',
		component: () => import('@/pages/brand-page/brand-page.vue').then((m) => m.default || m),
		meta: {
			pageType: PageTypes.BRANDLIST,
			spaType: SpaType.other,
		},
	},
	{
		path: 'shops.html',
		component: () => import('@/pages/brand-page/brand-page.vue').then((m) => m.default || m),
		meta: {
			pageType: PageTypes.BRANDLIST,
			spaType: SpaType.other,
		},
	},
];
