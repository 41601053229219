import { track } from '../tracking';
import { ClubStep } from '~/@api/store.types';

function getStepId(stepName: ClubStep) {
	switch (stepName) {
		case ClubStep.INITIALIZATION:
			return '1';
		case ClubStep.MATCHED:
		case ClubStep.NO_MATCHED:
			return '2';
		case ClubStep.ADD_NUMBER:
			return '3';
		case ClubStep.FORGOTTEN_NUMBER:
			return '3a';
		case ClubStep.CONFIRMATION:
			return '4';
		default:
			return null;
	}
}

export const clubDialog = (
	stepName: ClubStep,
	processContinued = true,
	nextStepName?: ClubStep,
) => {
	track('club.dialog', {
		'cp-name': stepName,
		'cp-next-name': nextStepName,
		'cp-next-stepID': getStepId(nextStepName),
		'cp-result': processContinued,
		'cp-stepID': getStepId(stepName),
	});
};
