import { Store } from 'vuex';
import { RootState } from '~/@api/store.types';
import {
	CLS_A_ACTIVATECLSSTATEALL,
	CLS_A_ADDMESSAGE,
	CLS_A_CLEARMESSAGES,
	CLS_A_RESETCLSSTATEALL,
	CLS_A_STORECLSSTATES,
} from '~/@constants/store';

export default function (store: Store<RootState>) {
	return {
		resetClsStateAll: () => store.dispatch(CLS_A_RESETCLSSTATEALL, null, { root: true }),

		activateClsStateAll: () => store.dispatch(CLS_A_ACTIVATECLSSTATEALL, null, { root: true }),

		storeClsStates: (clsComponentKey, clsPreparedComponentStates) =>
			store.dispatch(
				CLS_A_STORECLSSTATES,
				{
					clsComponentKey,
					clsPreparedComponentStates,
				},
				{ root: true },
			),

		clearClsStates: (clsComponentKey) =>
			store.dispatch(
				CLS_A_STORECLSSTATES,
				{
					clsComponentKey,
					undefined,
				},
				{ root: true },
			),

		addMessage: (message: string) => store.dispatch(CLS_A_ADDMESSAGE, message, { root: true }),

		clearMessages: () => store.dispatch(CLS_A_CLEARMESSAGES, null, { root: true }),
	};
}
