

export default () => {
    if (process.server) {
        return;
    }

            const polyfill = require("svg4everybody");

            console.log('Load ' + "svg4everybody");
}