import { SpaType } from '@/generated/hybris-raml-api';
import { PageTypes } from '~/@api/store.types';
import { DynamicYieldContextType } from '~/@constants/global';

const homeRoute = {
	component: () => import('~/pages/content-page/content-page.vue').then((m) => m.default || m),
	props: () => {
		return {
			labelOrId: 'homepage',
		};
	},
	meta: {
		dynamicYieldContext: {
			type: DynamicYieldContextType.Homepage,
		},
		pageType: PageTypes.HOME,
		spaType: SpaType.content,
	},
};

const contentRoute = {
	component: () => import('~/pages/content-page/content-page.vue').then((m) => m.default || m),
	props: (route) => ({
		...route.query,
		...route.params,
	}),
	meta: {
		pageType: PageTypes.CONTENT,
		spaType: SpaType.content,
	},
};

export const contentRoutes = [
	{
		path: '/',
		...homeRoute,
	},
	{
		path: ':gender(.*)-home.html',
		...homeRoute,
	},
	{
		path: ':labelOrId(kontakt|contact|contatto)(.html)?',
		component: () => import('~/pages/contact-page/contact-page.vue').then((m) => m.default || m),
		meta: {
			pageType: PageTypes.CONTACT,
			spaType: SpaType.content,
		},
	},
	{
		path: ':labelOrId(.+):fileExtension(.html)',
		...contentRoute,
	},
	{
		path: ':labelOrId(.+)',
		...contentRoute,
	},
];
