import Vue from 'vue';
import { ActionContext, Store } from 'vuex';
import VueRouter from 'vue-router';
import _ceil from 'lodash-es/ceil';
import _without from 'lodash-es/without';
import _find from 'lodash-es/find';
import Logger from '@/node_modules/@osp/utils/src/logger';
import { elapsedTime } from '@/node_modules/@osp/utils/src/performance';
import { scrollPage } from '@/node_modules/@osp/utils/src/scroll-to';
import { useSearchTrackingStore } from '../searchTrackingApi';
import { PRODUCT_GROUP_SEARCH } from '~/@constants/global';
import {
	mapFn,
	SEARCH_A_ELEMENTS_PER_PAGE,
	SEARCH_A_LOAD_CATEGORIES,
	SEARCH_A_NEXT_PAGE,
	SEARCH_A_PERFORM_SEARCH,
	SEARCH_A_PREVIOUS_PAGE,
	SEARCH_A_REFINE_SEARCH,
	SEARCH_A_REMOVE_FACET_VALUE,
	SEARCH_A_RESET_FACET,
	SEARCH_A_RESET_FACETS,
	SEARCH_A_SELECT_PAGE,
	SEARCH_A_SORTING,
	SEARCH_A_USE_RESPONSE,
	SEARCH_G_INITIAL_STATE,
	SEARCH_G_NEW_SEARCH_REQUEST,
	SEARCH_M_ACTIVATE_PAGE,
	SEARCH_M_IN_PROGRESS,
	SEARCH_M_REQUEST_FACETS,
	SEARCH_M_RESPONSE_FACETS,
	SEARCH_M_RESET_STATE,
	SEARCH_M_RESTORE_STATE,
	SEARCH_M_SAVE_CATEGORIES,
	SEARCH_M_SAVE_SELECTION,
	SEARCH_M_SHOW_ERROR,
	SEARCH_M_STORE_RESPONSE,
	SEARCH_M_STORE_RESULTS,
} from '~/@constants/store';
import {
	SearchFacet,
	SearchFacetValuePrice,
	SearchRequestResponse,
	SearchResult,
} from '~/generated/hybris-raml-api';
import { getJson, loadJsonBody, postJson } from '~/app-utils/http';
import { createSearchUrl, getSelectedFacets } from '~/app-utils/search.utils';
import { backend } from '~/@api/backend';
import { useLoadingStore } from '~/@api/store/loadingApi';
import { useMediaqueryStore } from '~/@api/store/mediaqueryApi';
import { useProductsStore } from '~/@api/store/productsApi';
import { useRoutingStore } from '~/@api/store/routingApi';
import { useSearchUnsavedStore } from '~/@api/store/searchUnsavedApi';
import { CategoryList, RootState, SearchState, SearchStateRecoveryData } from '~/@api/store.types';

// Initial state -----------------------------------------------------------------------------------

const state = () => ({
	categories: {},
	headline: '',
	requestFacets: [],
	response: {
		brandCategoryCode: null,
		breadcrumbs: [],
		facets: [],
		pagination: {
			count: 0,
			page: 1,
			selectedOption: null,
			totalCount: 0,
		},
		seoHeadline: '',
		seoImage: null,
		seoPosition: 'left',
		seoText: '',
		sorts: {
			selectedOption: null,
		},
		timestamp: 0,
	},
	results: [],
	searchInProgress: false,
	showError: false,
	lastSelection: undefined,
});

// Mutations ---------------------------------------------------------------------------------------

const mutations = {
	[mapFn(SEARCH_M_STORE_RESPONSE)](_state: SearchState, searchResponse: SearchRequestResponse) {
		_state.response = {
			brandCategoryCode: searchResponse.brandCategoryCode,
			breadcrumbs: searchResponse.breadcrumbs,
			campaign: searchResponse.campaign,
			campaignRedirectDestination: searchResponse.campaignRedirectDestination,
			categoryCode: searchResponse.categoryCode,
			categoryHeadline: searchResponse.categoryHeadline,
			categoryName: searchResponse.categoryName,
			currentNavigation: searchResponse.currentNavigation,
			didYouMean: searchResponse.didYouMean,
			facets: searchResponse.facets,
			pagination: searchResponse.pagination,
			seoHeadline: searchResponse.categorieInfoHeadline,
			seoImage: searchResponse.categorieInfoImage,
			seoPosition: searchResponse.categorieInfoPosition,
			seoText: searchResponse.categorieInfoText,
			sorts: searchResponse.sorts,
			suggestedSearchGendersLinks: searchResponse.suggestedSearchGendersLinks,
			text: searchResponse.text,
			showSwissBillingMessage: searchResponse.showSwissBillingMessage,
			timestamp: Date.now(),
		};
		// Reset results on new search
		_state.results = [];
	},

	[mapFn(SEARCH_M_SAVE_SELECTION)](
		_state: SearchState,
		payload: { recoveryData: SearchStateRecoveryData },
	) {
		_state.lastSelection = payload.recoveryData;
	},

	[mapFn(SEARCH_M_STORE_RESULTS)](
		_state: SearchState,
		payload: { page: number; searchResult: SearchResult[] },
	) {
		Vue.set(
			_state.results,
			payload.page,
			payload.searchResult.map((result: SearchResult) => {
				return {
					origPos: result.origPos,
					pos: result.pos,
					productCode: result.product.code,
				};
			}),
		);
	},

	[mapFn(SEARCH_M_ACTIVATE_PAGE)](_state: SearchState, page: number) {
		_state.response.pagination = {
			..._state.response.pagination,
			page,
		};
	},

	[mapFn(SEARCH_M_SHOW_ERROR)](_state: SearchState, value: boolean) {
		_state.showError = value;
	},

	[mapFn(SEARCH_M_IN_PROGRESS)](_state: SearchState, value: boolean) {
		_state.searchInProgress = value;
	},

	[mapFn(SEARCH_M_REQUEST_FACETS)](_state: SearchState, value: SearchFacet[]) {
		_state.requestFacets = value;
	},

	[mapFn(SEARCH_M_RESTORE_STATE)](_state: SearchState, newState: SearchState) {
		_state.headline = newState.headline;
		_state.response = newState.response;
		_state.results = newState.results;
	},

	[mapFn(SEARCH_M_RESPONSE_FACETS)](_state: SearchState, facets: SearchFacet[]) {
		_state.response.facets = facets;
	},

	[mapFn(SEARCH_M_RESET_STATE)](_state: SearchState) {
		const initialState = state();

		Object.keys(initialState).forEach(
			(propertyKey) => (_state[propertyKey] = initialState[propertyKey]),
		);
	},

	[mapFn(SEARCH_M_SAVE_CATEGORIES)](_state: SearchState, categories: CategoryList) {
		_state.categories = categories;
	},
};

// Actions -----------------------------------------------------------------------------------------

const actions = {
	async [mapFn(SEARCH_A_USE_RESPONSE)](
		context: ActionContext<SearchState, RootState>,
		payload: { router: VueRouter; searchResponse: SearchRequestResponse },
	) {
		context.commit(mapFn(SEARCH_M_IN_PROGRESS), true);
		await useLoadingStore(this).api.doWithLoader('searchModule.useSearchResponse', async () => {
			if (!(await _doCampaignRedirect(this, payload.searchResponse))) {
				const page =
					payload.searchResponse.pagination && payload.searchResponse.pagination.page
						? payload.searchResponse.pagination.page
						: 1;

				_handleSearchResponse(context, this, payload.searchResponse);

				const preloadRequest = context.getters[mapFn(SEARCH_G_NEW_SEARCH_REQUEST)];

				if (useMediaqueryStore(this).api.isMobile()) {
					context.dispatch(mapFn(SEARCH_A_SELECT_PAGE), 1);

					if (page > 1) {
						scrollPage();
					}
				} else {
					_loadPage(context, this, page + 1, preloadRequest);
					_loadPage(context, this, page - 1, preloadRequest);
				}
			}
		});
		context.commit(mapFn(SEARCH_M_IN_PROGRESS), false);
	},

	async [mapFn(SEARCH_A_PERFORM_SEARCH)](
		context: ActionContext<SearchState, RootState>,
		payload: { router: VueRouter; searchRequest: SearchRequestResponse; initialSearch: boolean },
	) {
		const initialState = state();

		const mySearchRequest: SearchRequestResponse = payload.initialSearch
			? {
					...initialState.response,
					brandCategoryCode: payload.searchRequest.brandCategoryCode,
					categoryCode: payload.searchRequest.categoryCode,
					pagination: {
						...initialState.response.pagination,
						page: payload.searchRequest.pagination?.page || 1,
					},
					text: payload.searchRequest.text,
				}
			: payload.searchRequest;

		_storeRequestFacets(context, mySearchRequest.facets);

		context.commit(mapFn(SEARCH_M_IN_PROGRESS), true);

		const page = mySearchRequest.pagination?.page || 1;

		// Perform search and preload adjacent pages
		await useLoadingStore(this).api.doWithLoader('searchModule.performSearch', async () => {
			await _performSearchInternal(
				context,
				this,
				mySearchRequest,
				(data: SearchRequestResponse) => {
					_doCampaignRedirect(this, data).then((redirected) => {
						if (!redirected) {
							_handleSearchResponse(context, this, data);

							if (data?.pagination?.totalCount > (data?.pagination?.selectedOption || 0)) {
								const preloadRequest = context.getters[mapFn(SEARCH_G_NEW_SEARCH_REQUEST)];

								_loadPage(context, this, page + 1, preloadRequest);
								_loadPage(context, this, page - 1, preloadRequest);
							}
						}
					});
				},
			);
		});
		context.commit(mapFn(SEARCH_M_IN_PROGRESS), false);
	},

	[mapFn(SEARCH_A_NEXT_PAGE)](context: ActionContext<SearchState, RootState>) {
		return context.dispatch(mapFn(SEARCH_A_SELECT_PAGE), {
			page: context.state.response.pagination.page + 1,
			setInProgress: false,
		});
	},

	[mapFn(SEARCH_A_PREVIOUS_PAGE)](context: ActionContext<SearchState, RootState>) {
		return context.dispatch(
			mapFn(SEARCH_A_SELECT_PAGE),
			context.state.response.pagination.page - 1,
		);
	},

	async [mapFn(SEARCH_A_SELECT_PAGE)](
		context: ActionContext<SearchState, RootState>,
		payload: { page: number; setInProgress: boolean },
	) {
		const pages = _ceil(
			context.state.response.pagination.totalCount /
				context.state.response.pagination.selectedOption,
		);

		if (context.state.response.pagination.page !== payload.page && payload.page <= pages) {
			const { api: loadingApi } = useLoadingStore(this);

			context.commit(mapFn(SEARCH_M_IN_PROGRESS), payload.setInProgress);
			loadingApi.setLoading('searchModule.selectPage', true);

			const searchRequest = context.getters[mapFn(SEARCH_G_NEW_SEARCH_REQUEST)];

			await _loadPage(context, this, payload.page, searchRequest);
			context.commit(mapFn(SEARCH_M_ACTIVATE_PAGE), payload.page);
			await useRoutingStore(this).api.updateDatalayer();
			loadingApi.setLoading('searchModule.selectPage', false);
			_loadPage(context, this, payload.page + 1, searchRequest);
			_loadPage(context, this, payload.page - 1, searchRequest);
			context.commit(mapFn(SEARCH_M_IN_PROGRESS), false);
		}

		return payload.page;
	},

	async [mapFn(SEARCH_A_ELEMENTS_PER_PAGE)](
		context: ActionContext<SearchState, RootState>,
		elementsPerPage: number,
	) {
		const previousElementsPerPage = context.state.response.pagination.selectedOption;
		if (elementsPerPage !== previousElementsPerPage) {
			if (process.client && elementsPerPage > previousElementsPerPage) {
				useRoutingStore(this).api.setScrollTarget({ x: window.scrollX, y: window.scrollY });
			}
			await context.dispatch(mapFn(SEARCH_A_REFINE_SEARCH), () => ({
				pagination: {
					count: -1,
					page: 1,
					selectedOption: elementsPerPage,
					totalCount: -1,
				},
			}));
		}
	},

	async [mapFn(SEARCH_A_SORTING)](context: ActionContext<SearchState, RootState>, sorting: string) {
		if (sorting !== context.state.response.sorts.selectedOption) {
			await context.dispatch(mapFn(SEARCH_A_REFINE_SEARCH), () => ({
				sorts: {
					selectedOption: sorting,
				},
			}));
		}
	},

	[mapFn(SEARCH_A_RESET_FACETS)](context: ActionContext<SearchState, RootState>) {
		context.dispatch(mapFn(SEARCH_A_REFINE_SEARCH), (searchRequest) => {
			const gender = searchRequest.facets.find((facet) => facet.code === 'gender');
			const navigation = searchRequest.text
				? undefined
				: searchRequest.facets.find((facet) => facet.code === 'navigation');

			return { facets: _without([gender, navigation], undefined) };
		});
	},

	[mapFn(SEARCH_A_RESET_FACET)](
		context: ActionContext<SearchState, RootState>,
		facet: SearchFacet,
	) {
		context.dispatch(mapFn(SEARCH_A_REFINE_SEARCH), (searchRequest) => ({
			facets: searchRequest.facets.filter((requestFacet) => requestFacet.code !== facet.code),
		}));
	},

	async [mapFn(SEARCH_A_REMOVE_FACET_VALUE)](
		context: ActionContext<SearchState, RootState>,
		payload: { facet: SearchFacet; facetValue: any },
	) {
		await context.dispatch(mapFn(SEARCH_A_REFINE_SEARCH), (searchRequest) => {
			const facet = _find<SearchFacet[]>(searchRequest.facets, {
				code: payload.facet.code,
			}) as SearchFacet;

			if (facet) {
				facet.values = facet.values.filter((value: any) => value.code !== payload.facetValue.code);
			}

			return { facets: searchRequest.facets };
		});
	},

	async [mapFn(SEARCH_A_REFINE_SEARCH)](
		context: ActionContext<SearchState, RootState>,
		searchParamsGenerator: (searchRequest: SearchRequestResponse) => any,
	) {
		const { api: loadingApi } = useLoadingStore(this);

		loadingApi.setLoading('searchModule.refineSearch', true);

		const request = context.getters[mapFn(SEARCH_G_NEW_SEARCH_REQUEST)];

		await _refineSearchInternal(this, {
			...request,
			pagination: { ...request.pagination, page: 1 },
			...searchParamsGenerator(request),
		});
		loadingApi.setLoading('searchModule.refineSearch', false);
	},

	[mapFn(SEARCH_A_LOAD_CATEGORIES)](context: ActionContext<SearchState, RootState>) {
		useLoadingStore(this).api.doWithLoader('loadCategories', async () => {
			const response = await getJson(backend.API.V2.CATEGORIES.LIST(this), this);

			context.commit(mapFn(SEARCH_M_SAVE_CATEGORIES), response.json);
			elapsedTime(SEARCH_A_LOAD_CATEGORIES);
		});
	},
};

// Getters -----------------------------------------------------------------------------------------

const getters = {
	[mapFn(SEARCH_G_NEW_SEARCH_REQUEST)](_state: SearchState) {
		const { brandCategoryCode, categoryCode, currentNavigation, facets, pagination, sorts, text } =
			_state.response;

		return {
			brandCategoryCode,
			categoryCode,
			currentNavigation: {
				code: currentNavigation ? currentNavigation.code : undefined,
			},
			facets: getSelectedFacets(facets, []) as SearchFacet[],
			pagination,
			sorts,
			text,
		};
	},

	[mapFn(SEARCH_G_INITIAL_STATE)](_state: SearchState) {
		return () => state();
	},
};

export default {
	state,
	mutations,
	actions,
	getters,
};

// Helpers -----------------------------------------------------------------------------------------

const _performSearchInternal = async (
	context: ActionContext<SearchState, RootState>,
	store: Store<RootState>,
	searchRequest: SearchRequestResponse,
	successCallback?: (response: SearchRequestResponse) => void,
) => {
	try {
		const callback = successCallback || ((data) => _handleSearchResponse(context, store, data));

		await postJson(backend.API.V2.SEARCH(store), searchRequest, store, { mode: 'no-cors' })
			.then((response) => loadJsonBody(response, store))
			.then((response) => callback(response.json))
			.catch((error) => {
				if (error.status === 404) {
					context.commit(mapFn(SEARCH_M_RESET_STATE));
				} else {
					context.commit(mapFn(SEARCH_M_SHOW_ERROR), true);
				}
			});
	} catch (e) {
		Logger.error('Could not perform search');
	}
};

const _loadPage = async (
	context: ActionContext<SearchState, RootState>,
	store: Store<RootState>,
	page: number,
	searchRequest: SearchRequestResponse,
) => {
	// Load page only if not already loaded
	const pages = _ceil(
		context.state.response.pagination.totalCount / context.state.response.pagination.selectedOption,
	);

	if (!context.state.results[page] && page > 0 && page <= pages) {
		await _performSearchInternal(
			context,
			store,
			{
				...searchRequest,
				pagination: {
					...searchRequest.pagination,
					page,
				},
			},
			(response) => _handleSearchResults(context, store, page, response.results),
		);
	}
};

const _handleSearchResponse = (
	context: ActionContext<SearchState, RootState>,
	store: Store<RootState>,
	searchResponse: SearchRequestResponse,
) => {
	const { api: productsApi } = useProductsStore(store);
	const { api: searchUnsavedApi, state: searchUnsavedState } = useSearchUnsavedStore(store);
	const { api: searchTrackingApi } = useSearchTrackingStore(store);

	context.commit(mapFn(SEARCH_M_SHOW_ERROR), false);
	productsApi.clear(PRODUCT_GROUP_SEARCH);
	searchTrackingApi.saveChangedFacets(
		context.state.response.facets,
		Object.values(searchUnsavedState.facets),
	);
	context.commit(mapFn(SEARCH_M_STORE_RESPONSE), searchResponse);
	searchUnsavedApi.resetFacets();
	_handleSearchResults(context, store, searchResponse.pagination.page, searchResponse.results);
};

const _handleSearchResults = (
	context: ActionContext<SearchState, RootState>,
	store: Store<RootState>,
	page: number,
	searchResult: SearchResult[] = [],
) => {
	useProductsStore(store).api.addProducts(
		searchResult.map((result) => result.product),
		PRODUCT_GROUP_SEARCH,
	);
	context.commit(mapFn(SEARCH_M_STORE_RESULTS), { page, searchResult });
};

/* eslint-disable require-await */
const _refineSearchInternal = async (
	store: Store<RootState>,
	searchRequest: SearchRequestResponse,
) => {
	const url = createSearchUrl(store, store.$router.currentRoute, searchRequest);

	useRoutingStore(store).api.navigate(url);
};
/* eslint-enable */

const _doCampaignRedirect = async (
	store: Store<RootState>,
	searchResponse: SearchRequestResponse,
) => {
	if (searchResponse?.text && searchResponse?.campaignRedirectDestination) {
		const matcher = /(https?:\/\/[^/]*)?(?<path>.*)/.exec(
			searchResponse.campaignRedirectDestination,
		);

		if (matcher?.groups?.path) {
			await useRoutingStore(store).api.navigate(matcher?.groups?.path);

			return true;
		}
	}

	return false;
};

const _storeRequestFacets = (
	context: ActionContext<SearchState, RootState>,
	facets: SearchFacet[],
) => {
	// Copy stored facets data of facets that are part of the request
	const requestFacets = [];

	if (context.state.response?.facets) {
		facets.forEach((requestFacet) => {
			// Make a copy and hard break to original object by stringify
			// to create a new facet object and unchain object completely from VUEX
			const storedFacetCopy = JSON.parse(
				JSON.stringify(
					context.state.response.facets.find(
						(storeFacet) => storeFacet.code === requestFacet.code,
					) ?? {},
				),
			);

			if (storedFacetCopy) {
				// Special adjustment for price facets
				if (
					storedFacetCopy.code?.includes('price') &&
					storedFacetCopy.values?.length === 1 &&
					requestFacet.values.length === 1
				) {
					// Merge initial item with selection
					const newValue = {
						...storedFacetCopy.values[0],
						...(requestFacet.values[0] as SearchFacetValuePrice),
					};

					// Update facet value
					newValue.absoluteMin = newValue.selectedMin;
					newValue.absoluteMax = newValue.selectedMax;
					newValue.formattedSelectedMin = `${newValue.selectedMin} ${
						newValue.formattedSelectedMin.split(' ')[1]
					}`;
					newValue.formattedSelectedMax = `${newValue.selectedMax} ${
						newValue.formattedSelectedMax.split(' ')[1]
					}`;
					newValue.name = newValue.name
						.replace(storedFacetCopy.values[0].formattedSelectedMin, newValue.formattedSelectedMin)
						.replace(storedFacetCopy.values[0].formattedSelectedMax, newValue.formattedSelectedMax);

					storedFacetCopy.values[0] = newValue;
				}

				requestFacets.push(storedFacetCopy);
			}
		});
	}

	// Store relevant search params as fallback when not provided in search response
	context.commit(mapFn(SEARCH_M_REQUEST_FACETS), requestFacets);
};
