import { Store } from 'vuex';
import {
	SEARCHUNSAVED_A_SAVE_FACET,
	SEARCHUNSAVED_A_SAVE_FACETS,
	SEARCHUNSAVED_G_CHANGED,
	SEARCHUNSAVED_M_RESET_UNSAVED_FACET,
	SEARCHUNSAVED_M_RESET_UNSAVED_FACETS,
	SEARCHUNSAVED_M_SET_PRICE_VALUE,
	SEARCHUNSAVED_M_TOGGLE_FACET_VALUE,
} from '~/@constants/store';
import { RootState } from '~/@api/store.types';
import { SearchFacet } from '~/generated/hybris-raml-api';

export default function (store: Store<RootState>) {
	return {
		resetFacet: (facet: SearchFacet) => {
			if (facet) {
				store.commit(SEARCHUNSAVED_M_RESET_UNSAVED_FACET, facet, { root: true });
			}
		},

		resetFacets: () => store.commit(SEARCHUNSAVED_M_RESET_UNSAVED_FACETS, null, { root: true }),

		setPriceValue: (facet: SearchFacet, selectedMin: number, selectedMax: number) =>
			store.commit(
				SEARCHUNSAVED_M_SET_PRICE_VALUE,
				{ facet, selectedMin, selectedMax },
				{ root: true },
			),

		toggleFacetValue: (facet: SearchFacet, value: any) =>
			store.commit(SEARCHUNSAVED_M_TOGGLE_FACET_VALUE, { facet, value }, { root: true }),

		saveFacet: (unsavedFacet: SearchFacet) =>
			store.dispatch(SEARCHUNSAVED_A_SAVE_FACET, unsavedFacet, { root: true }),

		saveFacets: () => store.dispatch(SEARCHUNSAVED_A_SAVE_FACETS, null, { root: true }),

		changed: () => store.getters[SEARCHUNSAVED_G_CHANGED],
	};
}
