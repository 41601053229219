import { PageTypes } from '~/@api/store.types';
import { SpaOtherId, SpaType } from '~/generated/hybris-raml-api';

/* tslint:disable:object-literal-sort-keys */

export const myAccountRoute = (defaultPath: string) => [
	{
		path: `${defaultPath}/my-account/`,
		component: () =>
			import(/* webpackChunkName: "checkout" */ '~/layouts/my-account/my-account.vue').then(
				(m) => m.default || m,
			),
		meta: {
			spaType: SpaType.other,
			spaOtherId: SpaOtherId.myAccount,
			pageType: PageTypes.MYACCOUNT,
		},
		children: [
			{
				path: '(start.html)?',
				component: () =>
					import('~/pages/my-account/my-overview-page/my-overview-page.vue').then(
						(m) => m.default || m,
					),
			},
			{
				path: 'orders.html',
				component: () =>
					import('~/pages/my-account/my-orders-page/my-orders-page.vue').then(
						(m) => m.default || m,
					),
			},
			{
				path: 'order/([\\d]{14}).html',
				component: () =>
					import('~/pages/my-account/my-orders-page/order-details/order-details.vue').then(
						(m) => m.default || m,
					),
			},
			{
				path: '(details|payment-details|update-email|update-password).html',
				component: () =>
					import('~/pages/my-account/my-details-page/my-details-page.vue').then(
						(m) => m.default || m,
					),
			},
			{
				path: 'notifications.html',
				component: () =>
					import('~/pages/my-account/my-notifications-page/my-notifications-page.vue').then(
						(m) => m.default || m,
					),
			},
			{
				path: 'address-book.html',
				component: () =>
					import('~/pages/my-account/my-addresses-page/my-addresses-page.vue').then(
						(m) => m.default || m,
					),
			},
			{
				path: 'club.html',
				component: () =>
					import('~/pages/my-account/my-club-page/my-club-page.vue').then((m) => m.default || m),
			},
		],
	},
];
