import Vue from 'vue';
import { ActionContext } from 'vuex';
import { elapsedTime } from '@/node_modules/@osp/utils/src/performance';
import {
	I18N_A_MESSAGES,
	I18N_G_MESSAGE,
	I18N_M_LANGUAGE,
	I18N_M_MESSAGES,
	mapFn,
} from '~/@constants/store';
import { I18NModuleState, RootState } from '~/@api/store.types';
import { backend } from '~/@api/backend';
import { getJson } from '~/app-utils/http';

// Initial state -----------------------------------------------------------------------------------

const state = () => ({
	locale: 'de',
	fallbackLocale: 'de',
	messages: {},
});

// Mutations ---------------------------------------------------------------------------------------

const mutations = {
	[mapFn(I18N_M_LANGUAGE)](_state: I18NModuleState, locale: string) {
		Vue.set(_state, 'locale', locale);
	},

	[mapFn(I18N_M_MESSAGES)](_state: I18NModuleState, messages: any) {
		Vue.set(_state, 'messages', messages);
	},
};

// Actions -----------------------------------------------------------------------------------------

const actions = {
	async [mapFn(I18N_A_MESSAGES)](context: ActionContext<I18NModuleState, RootState>, key = 'de') {
		// Get translations from cache
		key = key.toLocaleLowerCase();

		const response = await getJson(backend.API.V2.TRANSLATIONS(this, key), this);
		let translations = response.json.properties || null;

		if (process.env.NODE_ENV === 'development') {
			const frontendTranslations = (
				await import(/* webpackChunkName: "[request]" */ `@/generated/translations-${key}`)
			).default;

			translations = { ...frontendTranslations, ...translations };
		}

		context.commit(mapFn(I18N_M_MESSAGES), {
			[key]: translations,
		});
		elapsedTime(I18N_A_MESSAGES);
	},
};

// Getters -----------------------------------------------------------------------------------------

const getters = {
	[mapFn(I18N_G_MESSAGE)]: (state: I18NModuleState) => (key: string, lang: string) =>
		state.messages[lang]?.[key],
};

export default {
	state,
	mutations,
	actions,
	getters,
};
