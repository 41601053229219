// Blocking time optimized aca recommendations from
// https://github.com/nuxt/nuxt.js/discussions/9061#discussioncomment-539483

// Importing dependencies - statically ------
import { Context } from '@nuxt/types';
import { runTask } from '@/node_modules/@osp/design-system/assets/js/utilities/runTask';
import { clientInitServerContextStore, useServerContextStore } from '~/@api/store/serverContextApi';

// Importing dependencies - dynamically ------
import { importDebug } from '~/app-utils/dynamic-imports';

// On the client side we need to pass the logger configuration to the debug package
// manually because the env property is not available within the browser
async function PluginIntegration(context: Context) {
	await clientInitServerContextStore(context.store);

	(window as any).osp.localStorage.setItem(
		'debug',
		useServerContextStore(context.store).state.env.DEBUG,
	);

	const { default: debug } = await importDebug();
	debug.load();
	debug.enable(debug.load());
}

// Do not defer this plugin until Nuxt is ready, to have debug available ASAP
export default function (context: Context) {
	runTask(() => {
		PluginIntegration(context);
	});
}
