import _get from 'lodash-es/get';
import _set from 'lodash-es/set';

// Store a temporary variable, which indicates if user used suggested search
// (in order to navigate to the product listing)
class SuggestedSearchUsageMarker {
	readonly SUGGESTED_SEARCH_KEY = 'wasSuggestedSearchUsed';

	public add(): void {
		if ((window as any).osp.sessionStorage) {
			(window as any).osp.sessionStorage.setItem(this.SUGGESTED_SEARCH_KEY, 'true');
		}
	}

	public remove(): void {
		if (
			(window as any).osp.sessionStorage &&
			(window as any).osp.sessionStorage.getItem(this.SUGGESTED_SEARCH_KEY)
		) {
			// Persist data until the next page reload on window and remove key in storage
			_set(window, 'wasSuggestedSearchUsed', 'true');
			(window as any).osp.sessionStorage.removeItem(this.SUGGESTED_SEARCH_KEY);
		}
	}

	public get marker(): string | boolean {
		if (process.server) {
			return false;
		}

		const defaultValue =
			(window as any).osp.sessionStorage?.getItem(this.SUGGESTED_SEARCH_KEY) ?? false;
		return _get(window, 'wasSuggestedSearchUsed', defaultValue);
	}
}

export const suggestedSearchUsageMarker = new SuggestedSearchUsageMarker();
