import { Cart } from '@/generated/hybris-raml-api';

export interface RoutingStepCheckout {
	code: string;
	path: string;
	order: number;
	group: number;
	validator?: (cart: Cart) => boolean;
}

const addStep = (
	code: string,
	group: number,
	order: number,
	path: string,
	validator?: (cart: Cart) => boolean,
): {} => {
	const obj = {
		[code]: {
			code,
			group,
			order,
			path,
			validator,
		},
	};
	return obj;
};

export const routingCheckoutSteps: {
	[code: string]: RoutingStepCheckout;
} = {
	...addStep('CART', 0, 0, `/cart.html`),
	...addStep('LOGIN_AND_REGISTRATION', -100, 100, `/checkout/login`),
	...addStep('START', -1000, 0, `/checkout/start`),
	...addStep(
		'DELIVERY_ADDRESS',
		1,
		250,
		`/checkout/delivery/address`,
		(cart: Cart): boolean => !cart.deliveryAddress || !cart.deliveryAddress.pointOfServiceCode,
	),
	...addStep(
		'CLICK_AND_COLLECT',
		1,
		250,
		`/checkout/delivery/clickAndCollect`,
		(cart: Cart): boolean => !cart.deliveryAddress || !!cart.deliveryAddress.pointOfServiceCode,
	),
	...addStep('PAYMENT_ADDRESS', 1, 275, `/checkout/delivery/billing`),
	...addStep('DELIVERY_MODE', 1, 285, `/checkout/delivery/assembly`),
	...addStep('PAYMENT_MODE', 2, 300, `/checkout/payment`),
	...addStep('SUMMARY', 3, 1000, `/checkout/summary`),
};
