import { Store } from 'vuex';
import { SpaLink, SpaType, SpaWrapperResponse } from '~/generated/hybris-raml-api';
import {
	ROUTING_A_DATALAYER,
	ROUTING_A_NAVIGATE,
	ROUTING_A_SPA_DATA,
	ROUTING_A_STORE_I18N_URLS,
	ROUTING_M_ADD_LINKS,
	ROUTING_M_ALLOW_ROUTING,
	ROUTING_M_SET_PAGE_TYPE,
	ROUTING_M_SET_SCROLL_TARGET,
	ROUTING_M_SPA_DATA,
} from '~/@constants/store';
import { PageTypes, RootState } from '~/@api/store.types';
import { JsonResponse } from '~/app-utils/http';

export default function (store: Store<RootState>) {
	return {
		addLinks: (links: SpaLink[]) => store.commit(ROUTING_M_ADD_LINKS, links, { root: true }),

		allowRouting: () => store.commit(ROUTING_M_ALLOW_ROUTING, true, { root: true }),

		disallowRouting: () => store.commit(ROUTING_M_ALLOW_ROUTING, false, { root: true }),

		setPageType: (payload: PageTypes) =>
			store.commit(ROUTING_M_SET_PAGE_TYPE, payload, { root: true }),

		updateSPAData: (
			spaType: SpaType,
			identifier?: string,
			storeI18nUrls = false,
		): Promise<JsonResponse> =>
			store.dispatch(ROUTING_A_SPA_DATA, { spaType, identifier, storeI18nUrls }, { root: true }),

		storeI18NUrls: (spaType: SpaType, identifier: string): Promise<JsonResponse> =>
			store.dispatch(ROUTING_A_STORE_I18N_URLS, { spaType, identifier }, { root: true }),

		updateDatalayer: (spaType?: SpaType, identifier?: string) =>
			store.dispatch(ROUTING_A_DATALAYER, { spaType, identifier }, { root: true }),

		navigate: (location: string) => {
			return store.dispatch(ROUTING_A_NAVIGATE, location, { root: true });
		},

		saveSPAData: (data: SpaWrapperResponse) =>
			store.commit(ROUTING_M_SPA_DATA, data, { root: true }),

		setScrollTarget: (target: string | { x: number; y: number } | undefined) =>
			store.commit(ROUTING_M_SET_SCROLL_TARGET, target, { root: true }),

		clearScrollTarget: () => store.commit(ROUTING_M_SET_SCROLL_TARGET, undefined, { root: true }),
	};
}
