import { i18nKeys } from '~/generated/translations';
import { routingCheckoutSteps } from '~/routing/checkout-steps';

export const deliveryAssemblyRoute = {
	path: 'delivery/assembly',
	component: () =>
		import(
			/* webpackChunkName: "checkout" */ '~/pages/checkout/assembly-page/assembly-page.vue'
		).then((m) => m.default || m),
	meta: {
		checkoutStep: routingCheckoutSteps.DELIVERY_MODE,
		headline: i18nKeys.title.checkout.assemblyOption,
	},
};
