import { Store } from 'vuex';
import {
	CHECKOUT_A_PLACE_ORDER,
	CHECKOUT_A_SET_CLICK_AND_COLLECT_STORE,
	CHECKOUT_A_SET_CURRENT_STEP,
	CHECKOUT_A_SET_DELIVERY_ADDRESS,
	CHECKOUT_A_SET_DELIVERY_MODES,
	CHECKOUT_A_SET_PAYMENT_ADDRESS,
	CHECKOUT_A_SET_PAYMENT_INFO,
	CHECKOUT_A_SET_PAYMENT_MODE,
	CHECKOUT_A_START,
	CHECKOUT_A_STORE_CHECKOUT_STATE,
	CHECKOUT_A_UPDATE,
	CHECKOUT_A_UPDATE_PAYMENT_INFO,
	CHECKOUT_M_SET_CURRENT_STEP,
	CHECKOUT_M_SET_NEXT_STEP,
	CHECKOUT_M_STORE_CHECKOUT_STATE,
} from '~/@constants/store';
import { VALIDATION_IGNORE_FIELD_VALUE } from '~/@constants/global';
import { RootState } from '~/@api/store.types';
import { RoutingStepCheckout } from '~/routing/checkout-steps';
import {
	CheckoutState,
	CheckoutStep,
	DeliveryModeRequestEntry,
	PlaceOrderRequest,
	PointOfService,
	SetPaymentInfoRequest,
	SetPaymentModeRequest,
} from '~/generated/hybris-raml-api';

export default function (store: Store<RootState>) {
	return {
		update: () => store.dispatch(CHECKOUT_A_UPDATE, null, { root: true }),

		setCurrentStep: (step: RoutingStepCheckout) =>
			store.dispatch(CHECKOUT_A_SET_CURRENT_STEP, step, { root: true }),

		setCurrentStepMutation: (step: CheckoutStep) =>
			store.commit(CHECKOUT_M_SET_CURRENT_STEP, step, { root: true }),

		startCheckout: () => store.dispatch(CHECKOUT_A_START, null, { root: true }),

		setDeliveryAddress: (id: string) =>
			store.dispatch(CHECKOUT_A_SET_DELIVERY_ADDRESS, id, { root: true }),

		setClickAndCollectStore: (pos: PointOfService) =>
			store.dispatch(CHECKOUT_A_SET_CLICK_AND_COLLECT_STORE, pos, { root: true }),

		setPaymentAddress: (id: string) =>
			store.dispatch(CHECKOUT_A_SET_PAYMENT_ADDRESS, id, { root: true }),

		setDeliveryModes: (
			deliveryModeEntries: DeliveryModeRequestEntry[],
			phone = VALIDATION_IGNORE_FIELD_VALUE,
		) =>
			store.dispatch(CHECKOUT_A_SET_DELIVERY_MODES, { deliveryModeEntries, phone }, { root: true }),

		updatePaymentInformation: () =>
			store.dispatch(CHECKOUT_A_UPDATE_PAYMENT_INFO, null, { root: true }),

		setPaymentMode: (request: SetPaymentModeRequest, ignoreError = false) =>
			store.dispatch(CHECKOUT_A_SET_PAYMENT_MODE, { request, ignoreError }, { root: true }),

		setPaymentInfo: (setPaymentInfoRequest: SetPaymentInfoRequest) =>
			store.dispatch(CHECKOUT_A_SET_PAYMENT_INFO, setPaymentInfoRequest, { root: true }),

		setNextStep: (checkoutStep: RoutingStepCheckout) =>
			store.commit(CHECKOUT_M_SET_NEXT_STEP, checkoutStep, { root: true }),

		placeOrder: (request: PlaceOrderRequest) =>
			store.dispatch(CHECKOUT_A_PLACE_ORDER, request, { root: true }),

		storeCheckoutStateMutation: (checkoutState: CheckoutState) =>
			store.commit(CHECKOUT_M_STORE_CHECKOUT_STATE, checkoutState, { root: true }),

		storeCheckoutState: (checkoutState: CheckoutState) =>
			store.dispatch(CHECKOUT_A_STORE_CHECKOUT_STATE, checkoutState, { root: true }),
	};
}
