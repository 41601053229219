export const HTTP_STATUSCODE = {
	BAD_REQUEST: 400,
	CREATED: 201,
	NOT_FOUND: 404,
	POLICY_NOT_FULFILLED: 420,
	PRECONDITION_FAILED: 412,
	SERVER_ERROR: 500,
	TIMEOUT: 408,
};

export const CART_PAGE_NAME = 'cartPage';
export const CLUB_PROCESS = 'clubProcess';
export const GIFT_CARDS_STORAGE_KEY = 'ochsner-sport-update-giftcards';
export const GLOBAL_ROUTER_NAME = 'RoutingModule_Router';
export const MESSAGEBOX_SERVERERROR_GLOBAL_KEY = 'serverError--global';
export const MESSAGEBOX_SERVERERROR_OVERLAY_KEY = 'serverError--overlay';
export const PRODUCT_DETAIL_PAGE_NAME = 'ProductDetailPage';
export const PRODUCT_GROUP_DEFAULT = 'default';
export const PRODUCT_GROUP_PDP = 'productDetailPage';
export const PRODUCT_GROUP_SEARCH = 'searchPage';
export const PRODUCT_GROUP_SUGGESTION = 'suggestion';
export const PRODUCT_TEASER_NAME = 'ProductTeaserData';
export const SEARCH_PAGE_NAME = 'SearchPage';
export const TEASER_EXPIRING_SHORTAGE_NAME = 'TeaserExpiringShortageData';
export const TEXT_NAME = 'TextData';
export const TEXT_PICTURE_TEASER_NAME = 'TextPictureTeaserData';
export const VALIDATION_IGNORE_FIELD_VALUE = '<<IGNORE>>';
export const FORCE_DEVICE = 'force-device';

export enum DynamicYieldContextType {
	Cart = 'CART',
	Category = 'CATEGORY',
	Homepage = 'HOMEPAGE',
	Product = 'PRODUCT',
	Other = 'OTHER',
}

export enum JerseyPrintMode {
	NONE = 'NONE',
	REPLICA = 'REPLICA',
	ORIGINAL = 'ORIGINAL',
}
