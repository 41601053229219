import { SpaOtherId, SpaType } from '@/generated/hybris-raml-api';
import { PageTypes } from '~/@api/store.types';

export const clubRoutes = (defaultPath: string) => [
	{
		path: `${defaultPath}/club`,
		component: () =>
			import('~/layouts/default-route/default-route.vue').then((m) => m.default || m),
		props: {
			footer: false,
			header: false,
		},
		meta: {
			routeType: 'template',
			spaType: SpaType.other,
		},
		children: [
			{
				path: '',
				component: () =>
					import('~/pages/club-pos-registration-page/club-pos-registration-page.vue').then(
						(m) => m.default || m,
					),
				meta: { spaType: SpaType.other },
				children: [
					{
						path: 'registration',
						meta: { pageType: PageTypes.CLUB_REGISTER_SHOP },
					},
					{
						path: 'pos',
						meta: { pageType: PageTypes.CLUB_REGISTER_POINT_OF_SALE },
					},
				],
			},
			{
				path: 'info',
				component: () =>
					import('~/pages/club-info-page/club-info-page.vue').then((m) => m.default || m),
				meta: {
					pageType: PageTypes.CLUB_POS_INFO,
					spaOtherId: SpaOtherId.club_info,
				},
			},
		],
	},
	{
		path: `${defaultPath}/club`,
		component: () =>
			import('~/layouts/default-route/default-route.vue').then((m) => m.default || m),
		meta: {
			routeType: 'template',
			spaType: SpaType.other,
		},
		children: [
			{
				path: 'sms',
				component: () =>
					import('~/pages/club-sms-page/club-sms-page.vue').then((m) => m.default || m),
				meta: {
					pageType: PageTypes.CLUB_SMS,
					spaOtherId: SpaOtherId.club_sms,
				},
			},
		],
	},
];
