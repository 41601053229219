import { deliveryRoute } from './checkout/delivery.route';
import { deliveryAssemblyRoute } from './checkout/assembly.route';
import { deliveryBillingRoute } from './checkout/delivery-billing.route';
import { paymentRoute } from './checkout/payment.route';
import { summaryRoute } from './checkout/summary.route';
import { PageTypes } from '~/@api/store.types';
import { routingCheckoutSteps } from '~/routing/checkout-steps';
import { SpaOtherId, SpaType } from '~/generated/hybris-raml-api';

export const checkoutRoute = (defaultPath: string) => [
	{
		path: `${defaultPath}/checkout`,
		component: () =>
			import(
				/* webpackChunkName: "checkout" */ '~/layouts/checkout-template/checkout-template.vue'
			).then((m) => m.default || m),
		children: [
			// CHECKOUT START
			{
				path: 'start',
				meta: {
					checkoutStep: routingCheckoutSteps.START,
				},
			},
			// CHECKOUT STEPS
			{
				path: '',
				component: () =>
					import(
						/* webpackChunkName: "checkout" */ '~/layouts/checkout-step/checkout-step.vue'
					).then((m) => m.default || m),
				meta: {
					pageType: PageTypes.CHECKOUT,
				},
				children: [
					deliveryRoute,
					deliveryBillingRoute,
					deliveryAssemblyRoute,
					paymentRoute,
					summaryRoute,
				],
			},
		],
	},

	// ---------------------------------------------------------------------------

	{
		path: `${defaultPath}/checkout/orderConfirmation/`,
		component: () =>
			import('~/layouts/default-route/default-route.vue').then((m) => m.default || m),
		props: {
			footer: true,
			header: true,
		},
		meta: {
			routeType: 'template',
			spaType: SpaType.other,
		},
		children: [
			{
				path: `:id`,
				component: () =>
					import(
						/* webpackChunkName: "checkout" */ '~/pages/checkout/order-confirmation-page/order-confirmation-page.vue'
					).then((m) => m.default || m),
				meta: {
					pageType: PageTypes.ORDERCONFIRMATION,
					spaType: SpaType.other,
					spaOtherId: SpaOtherId.checkout_orderconfirmation,
				},
				props: true,
			},
		],
	},
];
