export enum PaymentModeType {
	CASH_ON_DELIVERY = 'cashOnDelivery',
	CREDIT_CARD = 'creditCard',
	GIFTCARDONLY = 'GiftCardOnly',
	INVOICE = 'invoice',
	KLARNA = 'klarna',
	PAY_PAL = 'paypal',
	POST_FINANCE = 'postFinance',
	SWISSBILLING = 'swissbilling',
	TWINT = 'twint',
}

export interface PaymentMode {
	icon: string;
	name: string;
	mode: PaymentModeType;
}
