import { Store } from 'vuex';
import {
	CLUB_A_ABORT,
	CLUB_A_FETCH_STATUS,
	CLUB_A_GET_PROCESS_STATE,
	CLUB_A_REGISTER,
	CLUB_A_SET_CURRENT_STEP,
	CLUB_A_START_CLUBPROCESS,
	CLUB_M_SET_CLUBID,
} from '~/@constants/store';
import { ClubStep, RootState } from '~/@api/store.types';
import { ClubProcessState } from '~/generated/hybris-raml-api';

export default function (store: Store<RootState>) {
	return {
		setClubId: (clubId: string) => store.commit(CLUB_M_SET_CLUBID, clubId, { root: true }),

		setCurrentStep: (step: ClubStep) =>
			store.dispatch(CLUB_A_SET_CURRENT_STEP, step, { root: true }),

		startClubProcess: () => store.dispatch(CLUB_A_START_CLUBPROCESS, null, { root: true }),

		register: (clubId: string, isNew = false, email: string = null): Promise<ClubProcessState> =>
			store.dispatch(CLUB_A_REGISTER, {
				clubId,
				new: isNew,
				email,
			}),

		abort: () => store.dispatch(CLUB_A_ABORT, null, { root: true }),

		fetchStatus: () => {
			return store.dispatch(CLUB_A_FETCH_STATUS, null, { root: true });
		},

		getProcessState: () => {
			return store.dispatch(CLUB_A_GET_PROCESS_STATE, null, { root: true });
		},
	};
}
