import { ActionContext } from 'vuex';
import {
	yieldToMain,
	runTask,
} from '@/node_modules/@osp/design-system/assets/js/utilities/runTask';
import {
	mapFn,
	TRACKING_A_REGISTER_PASSED_EVENT,
	TRACKING_A_REPORT_PERFORMANCE_MARKS,
	TRACKING_A_RESET_READY_TO_SEND_STATE,
	TRACKING_M_CLOSE_PERFORMANCE_MARK,
	TRACKING_M_ADD_PERFORMANCE_MARK,
	TRACKING_M_SET_PERFORMANCE_MARK,
	TRACKING_M_END_UNFINISHED_SSR_MARKS,
	TRACKING_M_SET_READY_TO_SEND,
	TRACKING_M_UPDATE_PASSED_EVENT,
} from '~/@constants/store';
import {
	PerformanceEvent,
	PerformanceTrackingMark,
	RootState,
	TrackingState,
} from '~/@api/store.types';
import { importPerformanceTracking } from '~/app-utils/dynamic-imports';

// Initial state -----------------------------------------------------------------------------------
interface MarkData {
	start: undefined;
	end: undefined;
	duration: undefined;
}

const state = (): TrackingState => ({
	performance: {
		readyToSend: false,
		reportedEvents: {
			load: false,
			render: false,
			clsControlFinished: false,
			allMarksFinished: false,
		},
		marks: [],
		unresolvedMarks: 0,
	},
});

// Mutations ---------------------------------------------------------------------------------------

const mutations = {
	[mapFn(TRACKING_M_SET_READY_TO_SEND)](state: TrackingState, status: boolean) {
		state.performance.readyToSend = status;
	},
	[mapFn(TRACKING_M_END_UNFINISHED_SSR_MARKS)](state: TrackingState, timestamp: number) {
		state.performance.marks.forEach((mark, markIndex) => {
			yieldToMain();
			if (!mark.data?.ssr || !!mark.times.end || !!mark.duration) return;

			if (mark.data?.ssr && !!mark.times.start && !mark.times.end) {
				state.performance.marks[markIndex].times.end = timestamp;

				if (!state.performance.marks[markIndex].duration) {
					state.performance.marks[markIndex].duration =
						state.performance.marks[markIndex].times.end -
						state.performance.marks[markIndex].times.start;
				}

				if (mark.event === PerformanceEvent.apiRequest) {
					state.performance.marks[markIndex].event = PerformanceEvent.apiRequestAbort;
				}
			}
		});
		yieldToMain();
	},
	[mapFn(TRACKING_M_SET_PERFORMANCE_MARK)](
		state: TrackingState,
		newPerformanceMark: PerformanceTrackingMark,
	) {
		if (!state) return;

		if (newPerformanceMark.event === 'flushData') {
			state.performance.marks = [];

			return;
		}

		newPerformanceMark.data = {
			...newPerformanceMark?.data,
			entryNo: state.performance.marks.length + 1,
		};

		const existingIndex = state.performance.marks.findIndex(
			(existingMark) => existingMark.uniqueId === newPerformanceMark.uniqueId,
		);

		if (existingIndex >= 0) {
			// If endtime is provided but was not yet set in existing, decrease count of unresolved marks
			if (
				(newPerformanceMark.times?.end || 0) >
				(state.performance.marks[existingIndex].times?.end || 0)
			) {
				state.performance.unresolvedMarks--;
			}

			state.performance.marks[existingIndex] = manageExistingPerformanceMark(
				newPerformanceMark,
				state.performance.marks[existingIndex],
			);
		} else {
			state.performance.marks.push(newPerformanceMark);

			if (!newPerformanceMark.times?.end) {
				state.performance.unresolvedMarks++;
			}
		}
	},
	[mapFn(TRACKING_M_UPDATE_PASSED_EVENT)](
		state: TrackingState,
		payload: {
			eventName: string;
			value: boolean;
		},
	) {
		if (!state) return;

		const availableEvents = Object.keys(state.performance.reportedEvents);

		if (availableEvents.includes(payload.eventName)) {
			state.performance.reportedEvents[payload.eventName] = payload.value;
		}
	},
	[mapFn(TRACKING_M_CLOSE_PERFORMANCE_MARK)](
		state: TrackingState,
		payload: {
			uniqueId: string;
			markTime: number;
			data?: { [key: string]: any };
		},
	) {
		if (!state) return;

		const markIndex = state.performance.marks.findIndex(
			(mark) => mark.uniqueId === payload.uniqueId && !mark.times.end,
		);

		if (markIndex >= 0) {
			state.performance.marks[markIndex].times.end = payload.markTime;

			state.performance.marks[markIndex].duration =
				state.performance.marks[markIndex].times.end -
				state.performance.marks[markIndex].times.start;

			if (payload.data) {
				state.performance.marks[markIndex].data = {
					...state.performance.marks[markIndex].data,
					...payload.data,
				};
			}

			state.performance.unresolvedMarks--;
		}
	},
	[mapFn(TRACKING_M_ADD_PERFORMANCE_MARK)](
		state: TrackingState,
		newPerformanceMark: PerformanceTrackingMark,
	) {
		if (!state) return;

		// Ensure performance mark with unique ID does not yet exist
		const markIndex = state.performance.marks.findIndex(
			(mark) => mark.uniqueId === newPerformanceMark.uniqueId,
		);

		if (markIndex > 0) return;

		state.performance.marks.push(newPerformanceMark);
	},
};

// Actions -----------------------------------------------------------------------------------------

const actions = {
	[mapFn(TRACKING_A_RESET_READY_TO_SEND_STATE)](context: ActionContext<TrackingState, RootState>) {
		context.commit(mapFn(TRACKING_M_SET_READY_TO_SEND), false);
	},
	async [mapFn(TRACKING_A_REGISTER_PASSED_EVENT)](
		context: ActionContext<TrackingState, RootState>,
		payload: {
			eventName: string;
			value: boolean;
		},
	) {
		context.commit(mapFn(TRACKING_M_UPDATE_PASSED_EVENT), payload);

		await yieldToMain();

		if (
			context.state.performance.reportedEvents.load &&
			context.state.performance.reportedEvents.render &&
			context.state.performance.reportedEvents.clsControlFinished &&
			(context.state.performance.marks.length > 0 ||
				(context.state.performance.marks.length === 0 &&
					!context.state.performance.reportedEvents.allMarksFinished))
		) {
			// If all watched events are done except the marks, so not yet all performance marks seem to be closed,
			// give max 2 sec additional to wait, otherwise report marks as done anyway (in case 3rd party causes API mark
			// issue or delay)
			if (
				!context.state.performance.reportedEvents.allMarksFinished &&
				context.state.performance.unresolvedMarks > 0
			) {
				await new Promise((resolve) => setTimeout(resolve, 2000));

				await yieldToMain();
			}

			if (!context.state.performance.readyToSend) {
				context.commit(mapFn(TRACKING_M_SET_READY_TO_SEND), true);

				await yieldToMain();
			}
		}
	},
	[mapFn(TRACKING_A_REPORT_PERFORMANCE_MARKS)](context: ActionContext<TrackingState, RootState>) {
		// Make copy of up to now collected performance marks and flush to not collide with new marks that might be added
		// while reporting logic is processing
		if (!context.state.performance.marks.length) return;

		const markCollection = [...context.state.performance.marks];

		context.commit(mapFn(TRACKING_M_SET_PERFORMANCE_MARK), { event: 'flushData' });

		markCollection.forEach((performanceMark) => {
			if (!performanceMark.duration && performanceMark.times.start && performanceMark.times.end) {
				performanceMark.duration = performanceMark.times.end - performanceMark.times.start;
			}
		});

		runTask(() => {
			transmitTrackingResults(addApiStatisticData(markCollection));
		});
	},
};

export default {
	state,
	mutations,
	actions,
};

// Helpers -----------------------------------------------------------------------------------------

function manageExistingPerformanceMark(newMark: PerformanceTrackingMark, existingMark) {
	// Merge new mark data into existing by giving priority what is already in existing
	existingMark = {
		...newMark?.data,
		...existingMark,
	};

	// If payload start time is before already marked start time, give earliest tracking time the priority
	if (newMark.times.start < (existingMark.times?.start || 0)) {
		existingMark.times.start = newMark.times.start;
	}

	// If payload end time is after already marked end time, give latest tracking time the priority
	if (
		(!existingMark.times?.end && newMark.times?.end) ||
		(newMark.times.end || 0) > (existingMark.times?.end || 0)
	) {
		existingMark.times.end = newMark.times.end;
	}

	return existingMark;
}

function addApiStatisticData(markCollection: PerformanceTrackingMark[]) {
	const apiStatistics: {
		[key: string]: number;
	} = {};

	function addToApiStatistic(apiKey: string, performanceMark: PerformanceTrackingMark): void {
		const ssrMarker = performanceMark.data?.ssr ? '•SSR' : '';

		if (
			(performanceMark.event === PerformanceEvent.apiRequestAbort ||
				!!performanceMark.data?.aborted) &&
			performanceMark.data?.ssr
		) {
			const countKey = `_API_CALLS_COUNT_ABORTED${ssrMarker}`;
			apiStatistics[apiKey + countKey] = +(apiStatistics[apiKey + countKey] ?? 0) + 1;

			return;
		}

		const countKey = `_API_CALLS_COUNT${ssrMarker}`;
		const durationKey = `_API_CALLS_DURATION${ssrMarker}`;

		const duration =
			performanceMark.duration ||
			(performanceMark.times?.end && performanceMark.times?.start
				? performanceMark.times.end - performanceMark.times.start
				: 0) ||
			performanceMark.data.timeout ||
			0;

		apiStatistics[apiKey + countKey] = +(apiStatistics[apiKey + countKey] ?? 0) + 1;
		apiStatistics[apiKey + durationKey] = +(apiStatistics[apiKey + durationKey] ?? 1) + duration;
	}

	markCollection.forEach((performanceMark) => {
		if (
			performanceMark.event !== PerformanceEvent.apiRequest &&
			performanceMark.event !== PerformanceEvent.apiRequestAbort
		) {
			return;
		}

		if (performanceMark.uniqueId.includes('[OSPAPI]')) {
			addToApiStatistic('OSP', performanceMark);
		} else if (performanceMark.uniqueId.includes('[DYAPI]')) {
			addToApiStatistic('DY', performanceMark);
		}
	});

	Object.keys(apiStatistics).forEach((apiStatisticItemKey) => {
		markCollection.push({
			uniqueId: apiStatisticItemKey,
			data: apiStatistics[apiStatisticItemKey],
			event: PerformanceEvent.data,
		} as PerformanceTrackingMark);
	});

	return markCollection;
}

function transmitTrackingResults(markCollection: PerformanceTrackingMark[]): void {
	runTask(async () => {
		const { performanceTracking } = await importPerformanceTracking();

		performanceTracking.debug('Transmit marks', markCollection);

		const PerformanceMarkEventUsedAsMarkName = [
			PerformanceEvent.apiRequest.toString(),
			PerformanceEvent.apiRequestAbort.toString(),
		];

		markCollection.forEach((performanceMark) => {
			let markName =
				performanceMark.event && PerformanceMarkEventUsedAsMarkName.includes(performanceMark.event)
					? performanceMark.uniqueId
					: undefined;

			if (!markName) {
				markName =
					performanceMark.event === PerformanceEvent.data.toString()
						? performanceMark.uniqueId
						: performanceMark.event;
			}

			const markData = createMarkData(performanceMark);

			const ssrProcessMarker =
				performanceMark.data?.ssr && !markName.toString().includes('•SSR') ? '•SSR' : '';

			// IMPORTANT: Tracking results of synthetic tests should be collected, they MUST be transmitted via native
			// performance API, so lux parameter is set to undefined
			const serverTimingMarker =
				performanceMark.data?.serverTimingType &&
				!markName.toString().includes(performanceMark.data?.serverTimingType)
					? `•${performanceMark.data?.serverTimingType}`
					: '';

			const abortMarker =
				performanceMark.data?.aborted ||
				markName.toString().includes(PerformanceEvent.apiRequestAbort.toString())
					? `•Aborted`
					: '';

			const markerName = `${markName}${ssrProcessMarker}${serverTimingMarker}${abortMarker}`;

			// IMPORTANT: Tracking results of synthetic tests should be collected, they MUST be transmitted via native
			// performance API, so lux parameter is set to undefined
			performanceTracking.measure(undefined, markerName, markData);
		});

		performanceTracking.clearAbortedMeasurements();
	});
}

function createMarkData(performanceMark: PerformanceTrackingMark): MarkData {
	const markData = {
		start: undefined,
		end: undefined,
		duration: undefined,
	};

	// Use data value as duration, to make it trackable via performance.measure() for synthetic page tests.
	// Transform it to milliseconds (for performance.measure() ), but will be displayed in seconds on the dashboard.
	// (Example: Count data of 6 = 6000 milliseconds for measurement = displayed as 6 (sec) on dashboard)
	if (performanceMark.event === PerformanceEvent.data && typeof performanceMark.data === 'number') {
		performanceMark.duration = performanceMark.data * 1000;
	}

	// Use duration that was measured with Date.now() during SSR as performance.now() was not supported and create
	// an end and start mark point by performance.now() to match the required timing points for performance.measure().
	// As fallback in case performance API would not be available for any reason, go with the original time points
	markData.start = performanceMark.times?.start > 0 ? performanceMark.times?.start : undefined;

	if (!markData.start) {
		markData.end = performanceMark.times?.end ?? Date.now();
	}

	const duration =
		performanceMark.duration ??
		performanceMark.data?.timeout ??
		(markData.start && markData.end ? markData.end - markData.start : 0);

	markData.duration = duration > 0 ? duration : 0;

	return markData;
}
