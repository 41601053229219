import { SpaOtherId, SpaType } from '@/generated/hybris-raml-api';
import { PageTypes } from '~/@api/store.types';

export const storefinderRoutes = (defaultPath: string) => [
	{
		path: `${defaultPath}/store-finder`,
		component: () =>
			import('~/layouts/storelocator-template/storelocator-template.vue').then(
				(m) => m.default || m,
			),
		meta: {
			pageType: PageTypes.STORELOCATOR,
			spaOtherId: SpaOtherId.store_finder,
			spaType: SpaType.other,
		},
		children: [
			{
				path: '',
				component: () =>
					import('~/pages/store-finder-page/store-finder-page.vue').then((m) => m.default || m),
			},
		],
	},
	{
		path: `${defaultPath}/store/:town([^-]+)-:line1(.+)-:storeCode(.+).html`,
		component: () =>
			import('~/layouts/default-route/default-route.vue').then((m) => m.default || m),
		meta: {
			pageType: PageTypes.STORE,
			spaOtherId: SpaOtherId.store_detail,
			spaType: SpaType.other,
		},
		children: [
			{
				path: '',
				component: () =>
					import('~/pages/store-detail-page/store-detail-page.vue').then((m) => m.default || m),
			},
		],
	},
];
