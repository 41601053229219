import { ActionContext, Store } from 'vuex';
import _pick from 'lodash-es/pick';
import {
	RootState,
	TrackingSearchState,
	UnsavedSearchFacet,
	FactFinderTrackingEvent,
} from '~/@api/store.types';
import {
	mapFn,
	SEARCHTRACKING_M_SAVE_CHANGED_FACETS,
	SEARCHTRACKING_A_SEND_FACTFINDER_TRACKING_EVENT,
} from '~/@constants/store';
import { SearchFacet } from '~/generated/hybris-raml-api';
import { getWithParams } from '~/app-utils/http';
import { backend } from '~/@api/backend';

// Initial state -----------------------------------------------------------------------------------

const state = (): TrackingSearchState => ({
	changedFacets: [],
});

// Mutations ---------------------------------------------------------------------------------------

const mutations = {
	[mapFn(SEARCHTRACKING_M_SAVE_CHANGED_FACETS)](
		_state: TrackingSearchState,
		payload: {
			oldFacets: SearchFacet[];
			newFacets: UnsavedSearchFacet[];
		},
	) {
		_state.changedFacets = payload.newFacets
			.filter((facet) => facet.changed)
			.map((unsavedFacet) => ({
				code: unsavedFacet.code,
				name: unsavedFacet.name || unsavedFacet.code,
				position:
					payload.oldFacets
						.filter((facet) => facet.code !== 'navigation')
						.findIndex((facet) => facet.code === unsavedFacet.code) + 1,
				values: unsavedFacet.values
					.filter((value: { changed?: boolean }) => value.changed)
					.map((value) => _pick(value, ['code', 'name', 'selected', 'selectedMin', 'selectedMax'])),
			}));
	},
};

// Actions -----------------------------------------------------------------------------------------

const actions = {
	[mapFn(SEARCHTRACKING_A_SEND_FACTFINDER_TRACKING_EVENT)](
		_: ActionContext<TrackingSearchState, RootState>,
		event: FactFinderTrackingEvent,
	) {
		if (
			// Only send the tracking event if all data is available
			!isNaN(event.origPageSize) &&
			!isNaN(event.origPos) &&
			!isNaN(event.page) &&
			!isNaN(event.pos) &&
			event.productId &&
			event.query
		) {
			const store = this as Store<RootState>;

			getWithParams(backend.API.V2.FACTFINDERCLICK(store), event, { store });
		}
	},
};

export default {
	state,
	mutations,
	actions,
};
