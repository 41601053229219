import { Context } from '@nuxt/types';
import { NuxtCookies } from 'cookie-universal-nuxt';

interface ConsentDirectory {
	[key: string]: boolean;
}

export const FALLBACK_CMP_STORAGE_ID = 'consentFallbackData';

export function getCmpConsentData(): ConsentDirectory {
	return (window as any).__cmp('getCMPData')?.vendorConsents;
}

// Get local storage consent data copy
// (Use osp.localStorage that has fallback handling for in-private mode blocked local storage use on some devices)
export function getLocalStorageConsentData(): ConsentDirectory {
	return JSON.parse((window as any).osp.localStorage.getItem(FALLBACK_CMP_STORAGE_ID) ?? '{}');
}

// Get cookie consent data copy
export function getCookieConsentData(cookies: NuxtCookies): ConsentDirectory {
	return cookies.get(FALLBACK_CMP_STORAGE_ID);
}

// Get dataLayer consent data copy
export function getDataLayerConsentData(): ConsentDirectory {
	let foundData: ConsentDirectory;

	// Search dataLayer from last to first to catch the latest one in case user changed consent during dataLayer session
	((window as any).dataLayer as unknown[])
		.reverse()
		.forEach((dataLayerEntry: { event: string; cmpConsentVendors?: string }) => {
			if (foundData) return;

			if (dataLayerEntry.event === 'cmpEvent' && dataLayerEntry.cmpConsentVendors.length > 0) {
				foundData = {};

				dataLayerEntry.cmpConsentVendors
					.split(',')
					.filter((vendorId) => !!vendorId)
					.forEach((vendorId: string) => {
						foundData[vendorId] = true;
					});
			}
		});

	return foundData ?? {};
}

export function consentDataHasAnyTrue(consentData: { [key: string]: boolean }): boolean {
	return Object.values(consentData ?? {}).includes(true);
}

export function getReasonableCmpData(): { [key: string]: boolean } | false {
	const consentData = getCmpConsentData();

	return !!consentData && consentDataHasAnyTrue(consentData) ? consentData : false;
}

export function cmpDebug(message: string, args?: any) {
	if (!location.href.includes('cmp_debug')) return;

	if (args) {
		console.log(`[CMP] ${message}`, args); // eslint-disable-line no-console
	} else {
		console.log(`[CMP] ${message}`); // eslint-disable-line no-console
	}
}

export function anyConsentDataExists(cookies?: NuxtCookies) {
	return (
		consentDataHasAnyTrue(getCmpConsentData()) ||
		consentDataHasAnyTrue(getDataLayerConsentData()) ||
		consentDataHasAnyTrue(getLocalStorageConsentData()) ||
		(!!cookies && consentDataHasAnyTrue(getCookieConsentData(cookies)))
	);
}

// Update local stored consent data
export function updateConsentDataFallbacks(context: Context) {
	cmpDebug('start update local consent data');

	const consentData = getReasonableCmpData();

	// Only store fallback data when user really did a choice, not only the default active vendors are set
	if (consentData && !!(window as any).__cmp('consentStatus')?.userChoiceExists) {
		(window as any).osp.localStorage.setItem(FALLBACK_CMP_STORAGE_ID, JSON.stringify(consentData));

		const expires = new Date();
		expires.setFullYear(expires.getFullYear() + 1);
		context.$cookies.set(FALLBACK_CMP_STORAGE_ID, consentData, { expires, path: '/' });

		cmpDebug('local consent data updated', consentData);
	}
}

export function consentCascadeChecks(cmpVendorId: string, cookies: NuxtCookies) {
	if (getCmpConsentData()?.[cmpVendorId]) {
		cmpDebug('interim consent data provided by cmp');
		return true;
	}

	if (getDataLayerConsentData()?.[cmpVendorId]) {
		cmpDebug('interim consent data provided by dataLayer');
		return true;
	}

	if (getLocalStorageConsentData()?.[cmpVendorId]) {
		cmpDebug('interim consent data provided by local storage');
		return true;
	}

	if (cookies && getCookieConsentData(cookies)?.[cmpVendorId]) {
		cmpDebug('interim consent data provided by cookies');
		return true;
	}

	cmpDebug('interim consent data not available');

	return false;
}
