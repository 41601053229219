import _get from 'lodash-es/get';
import { TrackingProduct } from '../types';
import * as cartSessionUtils from '../cartSessionUtils';
import { track } from '../tracking';
import { mapProduct } from '~/tracking/data-mapper/product-mapper';

export const mapAndAddToCart = (product: TrackingProduct, additionalData?) => {
	cartSessionUtils.addProduct(mapProduct(product), additionalData);
	// We need to map the product again to have access to the cartSessionUtils within the mapping
	addToCart(mapProduct(product), 1, _get(product, 'price.selling.currencyIso', 'CHF'));
};

export const addToCart = (product: any, quantity: number, currencyCode: string) => {
	track('eec.add', {
		ecommerce: {
			add: {
				products: [{ ...product, quantity }],
			},
			currencyCode,
		},
	});
};
