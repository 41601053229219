import { ActionContext } from 'vuex';
import {
	CLS_A_ACTIVATECLSSTATEALL,
	CLS_A_RESETCLSSTATEALL,
	CLS_A_STORECLSSTATES,
	CLS_M_TOUCHACTIVATECLSSTATEALLTRIGGER,
	CLS_M_TOUCHRESETCLSSTATEALLTRIGGER,
	CLS_M_UPDATESSRREGISTER,
	mapFn,
} from '~/@constants/store';

import { ClsState, RootState } from '~/@api/store.types';

// Initial state -----------------------------------------------------------------------------------

const state = (): ClsState => ({
	config: {
		optimizationActive: true,
		allowFallback: true,
		fallbackTimerMs: 1500,
		fallbackTimerMsSpa: 2500,
		visibilityCheck: true,
		debugging: {
			active: false,
		},
	},
	resetStateAllTrigger: false,
	activateStateAllTrigger: false,
	ssrRegister: {},
	messages: [],
});

// Mutations ---------------------------------------------------------------------------------------

const mutations = {
	[mapFn(CLS_M_TOUCHACTIVATECLSSTATEALLTRIGGER)](state: ClsState) {
		state.activateStateAllTrigger = !state.activateStateAllTrigger;
	},

	[mapFn(CLS_M_TOUCHRESETCLSSTATEALLTRIGGER)](state: ClsState) {
		state.resetStateAllTrigger = !state.resetStateAllTrigger;
	},

	[mapFn(CLS_M_UPDATESSRREGISTER)](
		state: ClsState,
		payload: {
			clsComponentKey: string;
			clsPreparedComponentStates: { [key: string]: boolean };
		},
	) {
		if (typeof payload.clsPreparedComponentStates === 'undefined') {
			delete state.ssrRegister[payload.clsComponentKey];
		} else {
			state.ssrRegister[payload.clsComponentKey] = { ...payload.clsPreparedComponentStates };
		}
	},
};

// Actions -----------------------------------------------------------------------------------------

const actions = {
	[mapFn(CLS_A_ACTIVATECLSSTATEALL)](context: ActionContext<ClsState, RootState>) {
		context.commit(mapFn(CLS_M_TOUCHACTIVATECLSSTATEALLTRIGGER));
	},

	[mapFn(CLS_A_RESETCLSSTATEALL)](context: ActionContext<ClsState, RootState>) {
		context.commit(mapFn(CLS_M_TOUCHRESETCLSSTATEALLTRIGGER));
	},

	[mapFn(CLS_A_STORECLSSTATES)](
		context: ActionContext<ClsState, RootState>,
		payload: {
			clsComponentKey: string;
			clsPreparedComponentStates: { [key: string]: boolean };
		},
	) {
		context.commit(mapFn(CLS_M_UPDATESSRREGISTER), payload);
	},
};

export default {
	state,
	mutations,
	actions,
};
