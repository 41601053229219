import type { Store } from 'vuex';
import { Route } from 'vue-router';
import _flatten from 'lodash-es/flatten';
import { SpaOtherId, SpaType } from '@/generated/hybris-raml-api';
import { useUserStore } from '~/@api/store/userApi';
import { RootState } from '~/@api/store.types';

const getPropsFromMatchedRoutes = (matchedRoute, route) => {
	const props = matchedRoute.props;

	return (props?.default instanceof Function ? props.default(route) : props?.default) || {};
};

export const getPageTypeMetaInfoFromRoute = (store: Store<RootState>, route: Route) => {
	const spaType =
		// Route itself
		[...(route.matched || [])].reverse().find((route) => route.meta?.spaType)?.meta.spaType ??
		// or fallback
		SpaType.other;
	const params = {
		...route.matched
			// Get props from matched routes
			.map((matchedRoute) => getPropsFromMatchedRoutes(matchedRoute, route))
			// Convert array to object
			.reduce((obj, item) => ({ ...obj, ...item }), {}),
		...route.query,
		...route.params,
	};
	const identifier = getIdFromParams(store, params, route, paramKeys[spaType] || []);

	return { identifier, spaType };
};

const getIdFromParams = (
	store: Store<RootState>,
	params: any,
	route: Route,
	keys: (((store: Store<RootState>, params: any, route: Route) => string) | string)[],
) =>
	keys
		.map((key) => (key instanceof Function ? key(store, params, route) : params[key]))
		.find((value) => !!value);

const paramKeys = {
	[SpaType.product]: ['productCode'],
	[SpaType.category]: [
		(store: Store<RootState>, params: any, _route: Route) => {
			const genderNames = _flatten(
				useUserStore(store).state.user?.genders.map((gender) => [
					gender.code.toLowerCase(),
					gender.name.toLowerCase(),
				]),
			);
			const brandAndGender = (params.brandAndGender || '').split('-');
			const brand =
				params.brandCode ||
				(genderNames.includes(brandAndGender[0]) ? brandAndGender[1] : brandAndGender[0]);

			return `${brand || params.categoryCode}${
				brand && params.categoryCode ? ':' + params.categoryCode : ''
			}`;
		},
	],
	[SpaType.content]: [
		// Replace '/' with '__'
		(store: Store<RootState>, params: any, _route: Route) => {
			const currentGender = useUserStore(store).state.user?.gender?.code;
			let labelOrId = params.labelOrId;

			if (labelOrId === 'homepage') {
				labelOrId = currentGender === 'NEUTRAL' ? 'homepage' : `gender_${currentGender}`;
			}

			return labelOrId?.replace(/\//g, '__');
		},
	],
	[SpaType.other]: [
		(store: Store<RootState>, params: any, route: Route): string => {
			const path = route.fullPath;
			if (path) {
				let otherId =
					// Route itself
					route?.meta?.spaOtherId ??
					// or matched route
					route?.matched.find((match) => match.meta?.spaOtherId)?.meta.spaOtherId ??
					// or fallback
					null;

				if (!otherId) {
					// Remove "/de/shop/" and file extensions, e.g. ".html"
					const toRemove = /(\/(de|it|fr)\/shop\/)|([.][a-z]+)|(\?.*)|(#.*)/gi;
					const cleanPath = path.replace(toRemove, '');

					otherId = cleanPath.replaceAll('/', '_').toLowerCase();
				}

				if (otherId.match('-shops') || otherId.match('-(marken|marques|marchi)')) {
					const currentGender = useUserStore(store).state.user?.gender?.code;

					if (currentGender) {
						return `shops_${currentGender}`;
					}
				}

				if (otherIdKeys[otherId]) {
					const otherParam = getIdFromParams(store, params, route, otherIdKeys[otherId]);

					if (otherParam) {
						return `${otherId}:${otherParam}`;
					}
				}

				return otherId;
			}
		},
	],
};

const otherIdKeys = {
	[SpaOtherId.checkout_orderconfirmation]: ['id'],
	[SpaOtherId.search]: [
		'text',
		(_store: Store<RootState>, params: any, _route: Route) =>
			(/^[^:]+/.exec(params.q || '') || [])[0],
	],
	[SpaOtherId.store_detail]: ['storeCode'],
};
