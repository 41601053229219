import _upperFirst from 'lodash-es/upperFirst';
import { track } from '../tracking';
import { GiftCardRedemption, Voucher as RamlVoucher } from '@/generated/hybris-raml-api';
import { Voucher } from '~/@api/store.types';

// VOUCHER INPUT
export const voucherApplied = (
	voucher: Voucher | RamlVoucher | GiftCardRedemption,
	action: string,
	type: string,
) => {
	track(`eec.voucher${_upperFirst(action)}`, {
		actions: {
			voucher: {
				code: (voucher as Voucher).code || (voucher as GiftCardRedemption).cardId,
				discount:
					(voucher as RamlVoucher).valueFormatted ||
					(voucher as Voucher).formattedAmount ||
					(voucher as GiftCardRedemption).amount.formattedValue,
				name: (voucher as Voucher).name || (voucher as GiftCardRedemption).cardId,
				type,
			},
		},
	});
};
