import type { Store } from 'vuex';
import { clientInitStoreModule, useStoreModule } from '../useStoreModule';
import searchStore from './search.store';
import searchApi from './search.api';
import { RootState, SearchState } from '~/@api/store.types';
import { SEARCH } from '~/@constants/store';

export function useSearchStore(store: Store<RootState>) {
	return useStoreModule<SearchState, ReturnType<typeof searchApi>>(
		SEARCH,
		store,
		searchStore,
		searchApi,
	);
}

export async function clientInitSearchStore(store: Store<RootState>): Promise<void> {
	return await clientInitStoreModule(SEARCH, store, searchStore, searchApi);
}
