import { setSafeTimeout } from '../../../assets/js/utilities/timeout';

export const importHtmlParserWorker = () =>
	lazyImportWrapper(
		() =>
			import(
				'../../../assets/workers/html-parser.worker' /* webpackChunkName: 'HtmlParserWorker' */
			),
	);

export const importLogger = () =>
	lazyImportWrapper(() => import('@osp/utils/src/logger' /* webpackChunkName: 'logger' */));

export const importMarked = () =>
	lazyImportWrapper(() => import('marked' /* webpackChunkName: 'marked' */));

export const importMarkedParseWorker = () =>
	lazyImportWrapper(
		() =>
			import(
				'../../../assets/workers/marked-parse.worker' /* webpackChunkName: 'MarkedParseWorker' */
			),
	);

export const importNodeHtmlParser = () =>
	lazyImportWrapper(() => import('node-html-parser' /* webpackChunkName: 'NodeHtmlParser' */));

export const importRunTask = () =>
	lazyImportWrapper(() => import('./runTask' /* webpackChunkName: 'runTask' */));

export const importScrollTo = () =>
	lazyImportWrapper(
		() => import('../../../../utils/src/scroll-to' /* webpackChunkName: 'scrollTo' */),
	);

export const importTimeout = () =>
	lazyImportWrapper(
		() => import('../../../assets/js/utilities/timeout' /* webpackChunkName: 'timeout' */),
	);

export const importScrollLock = () =>
	lazyImportWrapper(
		() => import('../../../assets/js/utilities/scrollLock' /* webpackChunkName: 'scrollLock' */),
	);

export const importDebounce = () =>
	lazyImportWrapper(
		() => import('../../../assets/js/utilities/debounce' /* webpackChunkName: 'debounce' */),
	);

export const importUrlUtils = () =>
	lazyImportWrapper(
		() => import('../../../assets/js/utilities/url' /* webpackChunkName: 'urlUtils' */),
	);

export function lazyImportWrapper(importFunction: () => Promise<any>): Promise<any> {
	let retryCounter = 0;
	// Last try delay of 15000 is mainly for testing purposes by blocking / unblocking a certain chunk
	const retriesAfter = [250, 250, 500, 1000, 15000];

	const importWithRetry = (
		importFunction: () => Promise<any>,
		retryAfter: number | undefined = undefined,
	) => {
		return new Promise((resolve, reject) => {
			importFunction()
				.then(resolve)
				.catch((error) => {
					// Maximum retries exceeded -> return error
					if (typeof retryAfter === 'undefined' || retryCounter > retriesAfter.length) {
						reject(error);
						return;
					}

					// Create a retry after n milliseconds
					setSafeTimeout(() => {
						// Passing on "reject" is the important part
						retryCounter++;
						importWithRetry(importFunction, retriesAfter[retryCounter]).then(resolve, reject);
					}, retryAfter);
				});
		});
	};

	return importWithRetry(importFunction, retriesAfter[retryCounter]);
}
