import { Context } from '@nuxt/types';
import Logger from '@/node_modules/@osp/utils/src/logger';

export const followRedirectIfGiven = (response, context: Context) => {
	if (!response || ![301, 302, 410].includes(response.status)) {
		return false;
	}

	const location = getLocationFromHeaders(response.headers.get('location'));

	if (response.status !== 410) {
		Logger.info(
			`Redirecting from ${context.route.fullPath} to ${location} with status ${response.status}`,
		);
	}

	if (!process.server) {
		context.$router.replace(location);

		return true;
	}

	context.redirect(response.status, location);

	return true;
};

function getLocationFromHeaders(headersLocation: string | string[]) {
	const locationString = Array.isArray(headersLocation) ? headersLocation[0] : headersLocation;

	return /https?:\/\/[^/]*(?<path>.*)/.exec(locationString)?.groups?.path || locationString;
}
