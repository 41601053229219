import { Context } from '@nuxt/types';
import { importLoadingApi, importRunTask } from '~/app-utils/dynamic-imports';

export default async (context: Context) => {
	if (process.client) {
		const { runTask } = await importRunTask();

		runTask(async () => {
			const { useLoadingStore } = await importLoadingApi();
			useLoadingStore(context.store).api.setLoading('middleware', true);
		});
	}
};
