import _isFunction from 'lodash-es/isFunction';
import _pull from 'lodash-es/pull';
import { ActionContext, Store } from 'vuex';
import { messagebox as trackMessagebox } from '@/tracking/events/messagebox';
import {
	MESSAGEBOX_SERVERERROR_GLOBAL_KEY,
	MESSAGEBOX_SERVERERROR_OVERLAY_KEY,
} from '~/@constants/global';
import { useLoadingStore } from '~/@api/store/loadingApi';
import { useOverlayStore } from '~/@api/store/overlayApi';
import { Messagebox, MessageboxState, MessageboxType, RootState } from '~/@api/store.types';
import {
	mapFn,
	MESSAGEBOX_A_HANDLE_ERROR,
	MESSAGEBOX_M_DISMISS,
	MESSAGEBOX_M_SAVE,
} from '~/@constants/store';

// Initial state -----------------------------------------------------------------------------------

const state = () => ({
	messageboxes: [],
});

// Mutations ---------------------------------------------------------------------------------------

const mutations = {
	[mapFn(MESSAGEBOX_M_SAVE)](_state: MessageboxState, payload: Messagebox[]) {
		const messageboxes = [];

		_state.messageboxes.forEach((messagebox) => {
			if (messagebox.key) {
				const payloadMessagebox = payload.find(
					(aPayloadMessagebox) => aPayloadMessagebox.key === messagebox.key,
				);

				messageboxes.push(payloadMessagebox || messagebox);
			} else {
				messageboxes.push(messagebox);
			}
		});
		payload.forEach((payloadMessagebox) => {
			if (!messageboxes.find((messagebox) => messagebox.text === payloadMessagebox.text)) {
				messageboxes.push(payloadMessagebox);
				trackMessagebox(payloadMessagebox, (this as Store<RootState>).$gtm);
			}
		});

		_state.messageboxes = messageboxes;
	},

	[mapFn(MESSAGEBOX_M_DISMISS)](
		_state: MessageboxState,
		payload: { messagebox: Messagebox; group?: string },
	) {
		if (payload) {
			let toRemove = _state.messageboxes.filter(
				(messagebox) =>
					(payload.messagebox && messagebox.key === payload.messagebox.key) ||
					messagebox.key === payload.group,
			);

			if (toRemove.length === 0) {
				toRemove = [payload.messagebox];
			}

			_state.messageboxes = [..._pull(_state.messageboxes, ...toRemove)];
		} else {
			_state.messageboxes = [];
		}
	},
};

// Actions -----------------------------------------------------------------------------------------

const actions = {
	async [mapFn(MESSAGEBOX_A_HANDLE_ERROR)](
		context: ActionContext<MessageboxState, RootState>,
		response: any,
	) {
		// Remove all loaders after error appeared to make the page interactive
		useLoadingStore(this).api.resetState();

		const body = _isFunction(response.json) ? await response.json() : response.json;

		if (body.message) {
			const isOverlayOpen = useOverlayStore(this).api.isAnyOpen();
			const key = isOverlayOpen
				? MESSAGEBOX_SERVERERROR_OVERLAY_KEY
				: MESSAGEBOX_SERVERERROR_GLOBAL_KEY;

			// Close server-error-messagebox if any is displayed
			context.commit(mapFn(MESSAGEBOX_M_DISMISS), {
				messagebox: null,
				group: MESSAGEBOX_SERVERERROR_GLOBAL_KEY,
			});
			context.commit(mapFn(MESSAGEBOX_M_DISMISS), {
				messagebox: null,
				group: MESSAGEBOX_SERVERERROR_OVERLAY_KEY,
			});
			// Show messagebox in overlay if any is opened, otherwise show global messagebox on the page
			context.commit(mapFn(MESSAGEBOX_M_SAVE), [
				{
					dismissible: true,
					global: !isOverlayOpen,
					key,
					text: body.message,
					type: MessageboxType.ERROR,
				},
			]);
		}

		return Promise.reject(response);
	},
};

export default {
	state,
	mutations,
	actions,
};
