import { Context } from '@nuxt/types';
import shareMutations from 'vuex-shared-mutations';
import clientPluginOnNuxtReady from '~/plugins/clientPluginHandler';

// eslint-disable-next-line require-await
async function PluginIntegration({ store, $config }: Context) {
	const sharedVuexMutations = $config.env.SHARED_VUEX_MUTATIONS?.split(',') || [];

	shareMutations({
		predicate: ({ type }) => {
			const lowerType = type.toLowerCase();

			return sharedVuexMutations.some((mutation) => {
				const lowerMutation = mutation.toLowerCase();

				return lowerMutation.endsWith('/*')
					? lowerType.startsWith(lowerMutation.slice(0, -1))
					: lowerType === lowerMutation;
			});
		},
	})(store);
}

// Defer until Nuxt is ready
export default function (context: Context) {
	clientPluginOnNuxtReady(() => PluginIntegration(context));
}
