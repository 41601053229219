import { SpaOtherId, SpaType } from '@/generated/hybris-raml-api';
import { PageTypes } from '~/@api/store.types';

export const searchRoute = {
	path: '(search|suche)(.html)?',
	component: () =>
		import('~/pages/product-listing-page/product-listing-page.vue').then((m) => m.default || m),
	props: (route) => ({ ...route.query, ...route.params }),
	meta: {
		pageType: PageTypes.PRODUCTSEARCH,
		spaType: SpaType.other,
		spaOtherId: SpaOtherId.search,
		useMinHeight: true,
	},
};
