export enum FONTSIZE {
	TEXT_XS = 'tw-text-3',
	TEXT_SM = 'tw-text-3.5',
	TEXT_BASE = 'tw-text-4',
	TEXT_LG = 'tw-text-4.5',
	TEXT_XL = 'tw-text-5',
	TEXT_2XL = 'tw-text-6',
	TEXT_3XL = 'tw-text-7.5',
	TEXT_4XL = 'tw-text-9',
	TEXT_5XL = 'tw-text-12',
	TEXT_6XL = 'tw-text-15',
	TEXT_7XL = 'tw-text-18',
	TEXT_8XL = 'tw-text-24',
	TEXT_9XL = 'tw-text-32',
}

export enum FONTCOLOR {
	TEXT_WHITE = 'tw-text-white',
	TEXT_BLACK = 'tw-text-black',
}

export enum BACKGROUNDCOLOR {
	RED = 'tw-bg-shiraz',
	GREEN = 'tw-bg-moss',
	BLUE = 'tw-bg-gull',
	GREY = 'tw-bg-storm',
	YELLOW = 'tw-bg-gorse',
	BLACK = 'tw-bg-black',
}
export interface Text {
	text: string;
	size?: FONTSIZE;
	color?: FONTCOLOR;
	customColor?: string;
}

export interface TextBlock {
	content: Text;
	backgroundColor?: BACKGROUNDCOLOR;
	customBackgroundColor?: string;
}

export interface IconText {
	icon: string;
	content: Text;
}
