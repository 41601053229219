import { Context } from '@nuxt/types';
import { elapsedTime } from '@/node_modules/@osp/utils/src/performance';
import { routingCheckoutSteps } from '@/routing/checkout-steps';
import { useCheckoutStore } from '~/@api/store/checkoutApi';
import { url } from '~/@api/backend';

export default async (context: Context) => {
	if (
		// if the current route is START
		[...(context.route.matched || [])]
			.reverse()
			.find((route) => route.meta?.checkoutStep === routingCheckoutSteps.START)
	) {
		const { api: checkoutApi, state: checkoutState } = useCheckoutStore(context.store);

		try {
			await checkoutApi.startCheckout();

			const checkoutStep = routingCheckoutSteps[checkoutState.currentStep] || {
				path: routingCheckoutSteps.DELIVERY_ADDRESS.path,
			};

			context.redirect(302, url(context.store, checkoutStep.path));
		} catch (_error) {
			context.redirect(307, url(context.store, context.from.fullPath));
		}
	}

	elapsedTime('middleware: checkout-start');
};
