import { Store } from 'vuex';
import { Product } from '~/generated/hybris-raml-api';
import { PRODUCT_GROUP_DEFAULT } from '~/@constants/global';
import {
	PRODUCTS_A_LOAD_PRODUCT,
	PRODUCTS_A_LOAD_PRODUCTS,
	PRODUCTS_A_REFRESH_FIELDS,
	PRODUCTS_G_PRODUCTS,
	PRODUCTS_M_ADD_PRODUCT,
	PRODUCTS_M_ADD_PRODUCTS,
	PRODUCTS_M_CLEAR,
} from '~/@constants/store';
import { EnhancedProduct, RootState } from '~/@api/store.types';

export default function (store: Store<RootState>) {
	return {
		addProduct: (product: Product, group: string = PRODUCT_GROUP_DEFAULT) =>
			store.commit(PRODUCTS_M_ADD_PRODUCT, { group, product }, { root: true }),

		addProducts: (products: Product[], group: string = PRODUCT_GROUP_DEFAULT) =>
			store.commit(PRODUCTS_M_ADD_PRODUCTS, { group, products }, { root: true }),

		clear: (group: string | RegExp = PRODUCT_GROUP_DEFAULT) =>
			store.commit(PRODUCTS_M_CLEAR, group, { root: true }),

		loadProduct: (
			productCode: string,
			group: string = PRODUCT_GROUP_DEFAULT,
			updateExternalStocks = false,
		) =>
			store.dispatch(
				PRODUCTS_A_LOAD_PRODUCT,
				{ productCode, group, updateExternalStocks },
				{ root: true },
			),

		loadProducts: (
			productCodes: string[],
			group: string = PRODUCT_GROUP_DEFAULT,
			updateExternalStocks = false,
		) =>
			store.dispatch(
				PRODUCTS_A_LOAD_PRODUCTS,
				{ productCodes, group, updateExternalStocks },
				{ root: true },
			),
		refreshFields: (
			productCodes: string[],
			fields: string[],
			group: string = PRODUCT_GROUP_DEFAULT,
			updateExternalStocks = false,
		) =>
			store.dispatch(
				PRODUCTS_A_REFRESH_FIELDS,
				{ productCodes, fields, group, updateExternalStocks },
				{ root: true },
			),

		getProduct: (productCode: string, group: string = PRODUCT_GROUP_DEFAULT): EnhancedProduct =>
			store.getters[PRODUCTS_G_PRODUCTS]([productCode], group)[0],

		getProducts: (
			productCodes: string[],
			group: string = PRODUCT_GROUP_DEFAULT,
		): EnhancedProduct[] => store.getters[PRODUCTS_G_PRODUCTS](productCodes, group),
	};
}
