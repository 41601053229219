import { Store } from 'vuex';
import { RootState } from '~/@api/store.types';
import {
	INSTALLPROMPT_M_ACCEPT,
	INSTALLPROMPT_M_BEFORE_INSTALL,
	INSTALLPROMPT_M_DISMISS,
	INSTALLPROMPT_M_PROMPT,
} from '~/@constants/store';
import { track } from '~/tracking/tracking';

export default function (store: Store<RootState>) {
	return {
		beforeinstall: (event: Event) =>
			store.commit(INSTALLPROMPT_M_BEFORE_INSTALL, event, { root: true }),

		prompt: (event: Event) => store.commit(INSTALLPROMPT_M_PROMPT, event, { root: true }),

		accept: () => store.commit(INSTALLPROMPT_M_ACCEPT, null, { root: true }),

		dismiss: () => store.commit(INSTALLPROMPT_M_DISMISS, null, { root: true }),

		trackPWA: (result: string) => track('pwaInstallPrompt', { result }),
	};
}
